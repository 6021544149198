<div class="container">
    <div class="map" *ngIf="selectedBuilding">
        <app-static-map [latitude]="selectedBuilding.latitude" [longitude]="selectedBuilding.longitude"></app-static-map>
    </div>
    <div class="editorItem" *ngIf="!hideSearchInput">
        <div class="label">Building</div>
        <div class="inputContainer">
            <app-type-ahead-buildings
                [isReadOnly]="isReadOnly"
                [displaySelected]="displaySelectedBuilding"
                [selectedBuilding]="selectedBuilding"
                (selectedBuildingChange)="onSelectedBuilding($event)"
                [error]="!isValid"
            >
            </app-type-ahead-buildings>
            <app-validation-error-message [show]="!isValid" [errors]="validationErrors"></app-validation-error-message>
        </div>
    </div>

    <app-location-details [location]="selectedBuilding"></app-location-details>
</div>
