import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { AuthService } from "../../../services/auth.service";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
    isPasswordVisible: boolean;

    constructor(
        private readonly router: Router,
        private readonly authService: AuthService
    ) {
        this.isPasswordVisible = false;
    }

    ngOnInit(): void {
        this.authService.redirectIfAuthenticated();
    }

    async login(event: NgForm): Promise<void> {
        if (event.invalid) {
            return;
        }

        await this.authService.login(event);
    }

    togglePasswordVisibility(event: Event): void {
        event.preventDefault();
        this.isPasswordVisible = !this.isPasswordVisible;
    }

    onForgotPassword(event: Event): void {
        event.preventDefault();
        this.router.navigate(["/forgot-password"]);
    }
}
