import { AfterViewInit, Directive, ElementRef, EventEmitter, OnInit, Output } from "@angular/core";

@Directive({
    selector: "[libIntersectionListener]"
})
export class IntersectionListenerDirective implements OnInit, AfterViewInit {
    @Output()
    intersectionListener = new EventEmitter<boolean>();

    observer!: IntersectionObserver;

    constructor(private element: ElementRef) {}

    ngOnInit(): void {
        this.intersectionObserver();
    }

    ngAfterViewInit(): void {
        this.observer.observe(this.element.nativeElement);
    }

    intersectionObserver() {
        this.observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting) {
                    this.intersectionListener.emit(true);
                }
            },
            { root: null, rootMargin: "0px", threshold: 0.5 }
        );
    }
}
