import { Injectable } from '@angular/core';
import { IStorageProvider } from '../interfaces';
import { LocalStorageProvider } from '../providers';

@Injectable({
	providedIn: 'root'
})
export class StorageService {
	_provider?: IStorageProvider;

	constructor(localStorageProvider: LocalStorageProvider) {
		// By default use the local storage
		this._provider = localStorageProvider;
	}

	set provider(provider: IStorageProvider) {
		this._provider = provider;
	}

	setItem<V>(key: string, value: V) {
		this._provider?.setItem(key, value);
	}

	getItem<V>(key: string): V | undefined {
		return this._provider?.getItem(key);
	}

	removeItem(key: string): void {
		this._provider?.removeItem(key);
	}

	removeAllItems(): void {
		this._provider?.removeAllItems();
	}
}
