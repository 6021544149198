import { Component, OnInit } from "@angular/core";
import { CompanyView, ContactView } from "projects/app/src/app/services/http/clients/api-proxies";
import { ContactService } from "projects/app/src/app/services/http/clients/contact.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-company-main-contact-editor",
    templateUrl: "./company-main-contact-editor.component.html",
    styleUrls: ["./company-main-contact-editor.component.scss"]
})
export class CompanyMainContactEditorComponent extends EditorComponentBase<number | undefined> implements OnInit {
    selectedItem?: ContactView;

    companyId: number | undefined;

    contactInfo!: {
        position: string;
        email: string;
        phone: string;
    };

    displaySelectedContact: boolean;

    constructor(private readonly contactService: ContactService) {
        super();
        this.displaySelectedContact = true;
    }

    async ngOnInit(): Promise<void> {
        this.companyId = (this.bindContext as CompanyView).id;

        if (this.bindingProperty) {
            this.selectedItem = await this.contactService.getById(this.bindingProperty);
            this.contactInfo = this.setContactInfo(this.selectedItem);
        }
    }

    onSelectedContact(contact: ContactView) {
        this.selectedItem = contact;
        this.bindingProperty = this.selectedItem?.id;
        this.contactInfo = this.setContactInfo(contact);
    }

    setContactInfo(contact: ContactView | undefined) {
        const defaultEmptyText = "-";
        return {
            position: contact?.position ?? defaultEmptyText,
            email: contact?.email?.length ? contact.email[0] : defaultEmptyText,
            phone: contact?.phone?.length ? contact.phone[0] : defaultEmptyText
        };
    }
}
