import { Injectable } from "@angular/core";
import { IOpportunityStatusClient, OpportunityStatusClient, OpportunityStatusView } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class OpportunityStatusService {
    private client: IOpportunityStatusClient;
    constructor() {
        this.client = new OpportunityStatusClient();
    }

    async getAll(): Promise<OpportunityStatusView[]> {
        return await this.client.opportunityStatusGetAll();
    }
}
