import { Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from "@angular/core";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { EntityTypeCustomFieldView, SearchEntityTypeCustomFieldParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { EntityTypeCustomFieldService } from "projects/app/src/app/services/http/clients/entity-type-custom-field.service";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { ObjectExtensionsService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { CustomFieldsItemComponent } from "../../../../shared/custom-fields-item/custom-fields-item.component";
import { ICustomizationFilters, ICustomizationFiltersOptions } from "../customization-filters/customization-filters.component";
import { Condition } from "projects/app/src/app/models/enums/Condition";
import { CustomFieldTypes } from "projects/app/src/app/models/enums/CustomFieldTypes";
import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";
import { CustomFieldsFilterType } from "projects/app/src/app/models/enums/CustomFieldsFilterType";

@Component({
    selector: "app-customization-custom-fields",
    templateUrl: "./customization-custom-fields.component.html",
    styleUrls: ["./customization-custom-fields.component.scss"]
})
export class CustomizationCustomFieldsComponent implements OnChanges {
    @Input()
    entityTypeId: number;

    @Input()
    title?: string;

    @Input()
    customFields?: EntityTypeCustomFieldView[];

    @Input()
    filterOptions?: ICustomizationFiltersOptions;

    @Output()
    customFieldsChange: EventEmitter<EntityTypeCustomFieldView[]>;

    @Input()
    customFieldsInitialState?: EntityTypeCustomFieldView[];

    @Output()
    customFieldsInitialStateChange: EventEmitter<EntityTypeCustomFieldView[]>;

    @Input()
    isModelEqual?: boolean;

    showActions?: boolean;

    cardConfig: IEditorItemConfig[];

    factory: AppEditorFactory;

    searchConfig: SearchEntityTypeCustomFieldParameters;

    filters: ICustomizationFilters;

    @ViewChildren("customFieldsItem")
    customFieldsItems?: QueryList<CustomFieldsItemComponent>;

    archiveStatus: Condition;

    reservedFilterType: CustomFieldsFilterType;

    constructor(
        private readonly entityTypeCustomFieldService: EntityTypeCustomFieldService,
        private readonly awaiter: AwaiterService,
        private readonly objectExtensionService: ObjectExtensionsService,
        private readonly snackbarNotificationService: SnackbarNotificationService
    ) {
        this.factory = new AppEditorFactory();
        this.cardConfig = [];
        this.entityTypeId = 0;
        this.filters = {};
        this.searchConfig = {
            isActive: true
        } as SearchEntityTypeCustomFieldParameters;
        this.customFieldsChange = new EventEmitter<EntityTypeCustomFieldView[]>();
        this.customFieldsInitialStateChange = new EventEmitter<EntityTypeCustomFieldView[]>();
        this.archiveStatus = Condition.Archived;
        this.reservedFilterType = CustomFieldsFilterType.Reserved;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["isModelEqual"]) {
            this.showActions = this.isModelEqual;
        }
    }

    onAddNewField(): void {
        if (this.filters.condition?.id === Condition.Archived || this.filters.type?.id === CustomFieldsFilterType.Reserved) return;

        this.customFields?.unshift({
            editor: EditorType.textInput,
            entityTypeId: this.entityTypeId,
            customFieldTypeId: CustomFieldTypes.String,
            isActive: true
        } as EntityTypeCustomFieldView);
        this.customFieldsChange.emit(this.customFields);
    }

    async onToggleCondition(item: EntityTypeCustomFieldView, index: number, archive: boolean): Promise<void> {
        const action = archive ? "Archiving" : "Restoring";
        const message = archive ? "Custom Field successfully archived." : "Custom Field successfully restored.";
        const serviceAction = archive ? this.entityTypeCustomFieldService.deactivate(item.id) : this.entityTypeCustomFieldService.activate(item.id);

        await this.awaiter.awaitAction(`${action} Custom Field`, async () => {
            if (this.customFields && this.customFields[index]) {
                await serviceAction;
                item.isActive = !archive;
                if ((archive && this.filters.condition?.id === Condition.Active) || (!archive && this.filters.condition?.id === Condition.Archived))
                    this.refreshListView();
                this.snackbarNotificationService.success(message);
            }
        });
    }

    onItemsChange(): void {
        this.customFieldsChange.emit(this.customFields);
    }

    async onFiltersChange(): Promise<void> {
        await this.refreshListView();
    }

    private async refreshListView(): Promise<void> {
        this.buildSearchConfig();

        await this.awaiter.awaitAction("Getting Custom Fields", async () => {
            this.customFields = await this.entityTypeCustomFieldService.search(this.searchConfig);
        });
        this.customFieldsInitialState = this.customFields?.map(item => this.objectExtensionService.clone(item) as EntityTypeCustomFieldView);
        this.customFieldsInitialStateChange.emit(this.customFieldsInitialState);
        this.customFieldsChange.emit(this.customFields);
    }

    private buildSearchConfig() {
        this.searchConfig = {
            entityTypeId: this.entityTypeId,
            isActive: this.filters.condition?.value,
            isReserved: this.filters.type?.value
        } as SearchEntityTypeCustomFieldParameters;
    }
}
