import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
    name: "highlightText"
})

/**
 * Highlights all occurrences of a specified substring within a given text.
 * It safely bypasses Angular's default sanitization to render the highlighted text as HTML.
 * Should be used along with [innerHTML] prop.
 */
export class HighlightTextPipe implements PipeTransform {
    constructor(private readonly sanitizer: DomSanitizer) {}

    transform(text: unknown, inputText: string): unknown {
        if (!text || !inputText.length) return this.sanitizer.bypassSecurityTrustHtml(text as string);
        const textHtml = (text as string).replace(new RegExp(inputText, "gi"), "<b>$&</b>");
        return this.sanitizer.bypassSecurityTrustHtml(textHtml);
    }
}
