import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BasicUserService } from "projects/ngx-lib/src/public-api";
import { AuthService } from "../../../services/auth.service";

@Component({
    selector: "app-welcome-screen",
    templateUrl: "./welcome-screen.component.html",
    styleUrls: ["./welcome-screen.component.scss"]
})
export class WelcomeScreenComponent implements OnInit {
    constructor(
        private readonly router: Router,
        private readonly authService: AuthService
    ) {}

    ngOnInit(): void {
        this.authService.redirectIfAuthenticated();
    }

    redirectToLogin() {
        this.router.navigate(["/login"]);
    }
}
