<div class="container">
    <span *ngIf="iconName" class="icon" [innerHTML]="svg"
    [ngClass]="{
        success: colorType === 'success',
        error: colorType === 'error',
        pending: colorType === 'pending',
        info: colorType === 'info',
        idle: colorType === 'idle'
        }"
    ></span>
    <span *ngIf="text" class="text">{{ text }}</span>
</div>