import { Injectable } from "@angular/core";
import { ILegalStatusClient, LegalStatusClient, LegalStatusView } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class LegalStatusService {
    private client: ILegalStatusClient;
    constructor() {
        this.client = new LegalStatusClient();
    }
    async getAll(): Promise<LegalStatusView[]> {
        return await this.client.legalStatusGetAll();
    }

    async getById(id: number): Promise<LegalStatusView> {
        return await this.client.legalStatusGetById(id);
    }
}
