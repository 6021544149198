import { Component, Input } from "@angular/core";

@Component({
    selector: "app-descriptive-item",
    templateUrl: "./descriptive-item.component.html",
    styleUrls: ["./descriptive-item.component.scss"]
})
export class DescriptiveItemComponent {
    @Input()
    text: string;

    @Input()
    tooltip?: string;

    constructor() {
        this.text = "";
    }
}
