export enum EditorType {
    numberInput = "number-input",
    textInput = "text-input",
    toggle = "toggle",
    checkboxInput = "checkbox-input",
    currencyInput = "currency-input",
    projectType = "project-type",
    building = "building",
    text = "text",
    division = "division",
    date = "date",
    company = "company",
    opportunityContact = "opportunity-contact",
    opportunityProbability = "opportunity-probability",
    opportunityStatus = "opportunity-status",
    leadSource = "lead-source",
    opportunityUser = "opportunity-user",
    link = "link",
    multiInput = "multi-input",
    entity = "entity",
    buildingClass = "building-class",
    legalStatus = "legal-status",
    companyType = "company-type",
    textarea = "textarea",
    companyMainContact = "company-main-contact",
    percentageInput = "percentage-input",
    buildingContactRole = "building-contact-role",
    address = "address",
    contact = "contact",
    noteUser = "note-user",
    user = "user",
    estimateType = "estimate-type"
}
