import { Component } from "@angular/core";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-text-input-editor",
    templateUrl: "./text-input-editor.component.html",
    styleUrls: ["./text-input-editor.component.scss"]
})
export class TextInputEditorComponent extends EditorComponentBase<string> {
    constructor() {
        super();
    }

    dataChanged() {
        this.valueChange.emit();
        this.validate();
    }
}
