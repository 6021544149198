<lib-generic-type-ahead-dropdown
    [(selectedItem)]="selectedEntity"
    placeholder="Search"
    (selectedItemChange)="onSelectedEntity($event)"
    [displaySelected]="false"
    [(text)]="searchText"
    [source]="searchResult"
    (textChange)="onEntityTextChanged()"
    [config]="{
        defaultText: 'Search',
        itemDisplayKey: 'entityName',
        itemTemplate: entitiesTemplate
    }"
    >
</lib-generic-type-ahead-dropdown>

<ng-template #entitiesTemplate let-data>
    <div class="item">
        <ng-container [ngSwitch]="data.object?.entityTypeId">
            <ng-container *ngSwitchCase="entities['company'].type">
                <span class="icon" [innerHTML]="entities['company'].icon"></span>
            </ng-container>
            <ng-container *ngSwitchCase="entities['contact'].type">
                <span class="icon" [innerHTML]="entities['contact'].icon"></span>
            </ng-container>
            <ng-container *ngSwitchCase="entities['building'].type">
                <span class="icon" [innerHTML]="entities['building'].icon"></span>
            </ng-container>
            <ng-container *ngSwitchCase="entities['opportunity'].type">
                <span class="icon" [innerHTML]="entities['opportunity'].icon"></span>
            </ng-container>
        </ng-container>

        <div class="textWrapper">
            <div class="entityName">
                {{ data.object?.entityName }}
            </div>
            <div class="entityTypeName">
                {{ data.object?.entityTypeName }}
            </div>
        </div>
    </div>
</ng-template>