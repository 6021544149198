import { Injectable } from "@angular/core";
import { ILeadSourceClient, LeadSourceClient, LeadSourceView } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class LeadSourceService {
    private client: ILeadSourceClient;
    constructor() {
        this.client = new LeadSourceClient();
    }

    async getAll(): Promise<LeadSourceView[]> {
        return await this.client.leadSourceGetAll();
    }
}
