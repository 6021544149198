import { Injectable } from "@angular/core";
import { EstimateClient, IEstimateClient, OpportunityEstimateView } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class EstimateService {
    private client: IEstimateClient;
    constructor() {
        this.client = new EstimateClient();
    }

    async getFinalEstimateOverview(estimateId: number): Promise<OpportunityEstimateView> {
        return await this.client.estimateGetFinalEstimateOverview(estimateId);
    }
}
