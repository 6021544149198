import { Component, OnInit } from "@angular/core";
import { AuthService } from "projects/app/src/app/services/auth.service";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { OpportunityEstimateView, SearchEstimateTemplatesResultDto } from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityEstimateService } from "projects/app/src/app/services/http/clients/opportunity-estimate.service";
import { ModalComponentBase } from "projects/ngx-lib/src/lib/classes/modal-component-base";
import { IUserOrganization } from "projects/ngx-lib/src/lib/interfaces/user-organization.interface";
import { SnackbarNotificationService } from "projects/ngx-lib/src/lib/services/snackbar-notification.service";

interface IModalCreateEstimateParameters {
    opportunityId: number;
}

@Component({
    selector: "app-modal-create-estimate",
    templateUrl: "./modal-create-estimate.component.html",
    styleUrls: ["./modal-create-estimate.component.scss"]
})
export class ModalCreateEstimateComponent extends ModalComponentBase<IModalCreateEstimateParameters, OpportunityEstimateView> implements OnInit {
    protected override parameters?: IModalCreateEstimateParameters;

    estimateType: "blank" | "template";

    searchText: string;

    selectedTemplate?: SearchEstimateTemplatesResultDto;

    source?: SearchEstimateTemplatesResultDto[];

    userOrganization?: IUserOrganization;

    isLoading: boolean;

    canCreateBlankEstimate?: boolean;

    constructor(
        private readonly opportunityEstimateService: OpportunityEstimateService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly authService: AuthService,
        private readonly awaiter: AwaiterService
    ) {
        super();
        this.searchText = "";
        this.estimateType = "blank";
        this.isLoading = false;
    }

    ngOnInit(): void {
        this.canCreateBlankEstimate = !this.authService.hasRestrictedAccess();
    }

    async onTemplateTextChanged(): Promise<void> {
        if (!this.searchText) return;

        this.source = await this.opportunityEstimateService.searchBaseEstimateTemplates({
            filterText: this.searchText
        });
    }

    onTemplateChange(itemSelected: SearchEstimateTemplatesResultDto): void {
        this.selectedTemplate = itemSelected;
    }

    async crateEstimate(): Promise<void> {
        await this.awaiter.awaitAction(
            "Creating Opportunity Estimate...",
            async () => {
                if (!this.parameters) return;

                try {
                    const response = await this.opportunityEstimateService.createBaseEstimate({
                        opportunityId: this.parameters?.opportunityId,
                        templateId: this.selectedTemplate?.id
                    });

                    if (response) {
                        this.snackbarNotificationService.success("Opportunity Estimate created successfully.");
                        this.close(response);
                    }
                } catch (error) {
                    const message = this.awaiter.processError(error);
                    this.snackbarNotificationService.error(message);
                    this.close(undefined);
                }
            },
            loading => (this.isLoading = loading)
        );
    }

    removeSelectedTemplate() {
        this.selectedTemplate = undefined;
    }
}
