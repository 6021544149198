<lib-section-header
    [iconName]="'goBack'"
    [title]="finalEstimate?.name"
    [subTitle]="'Final Estimate'"
    [infoTemplate]="infoTemplate"
    [mainAdditionalTemplate]="mainAdditionalTemplate"
    [titleStyle]="'secondary'"
    [goBackAction]="'custom'"
    (goBackActionChange)="onGoBackClicked()"
    >

    <ng-template #mainAdditionalTemplate>
        <lib-badge text="Selected" [color]="finalEstimate?.isSelected ? 'secondary' : 'primary'"></lib-badge>
        <lib-external-link *ngIf="finalEstimate?.externalUrl" [url]="finalEstimate?.externalUrl"></lib-external-link>
    </ng-template>

    <ng-template #infoTemplate>
        <div class="info">
            <div class="item">
                <div class="label">Total Direct Cost</div>
                <div class="value">{{ finalEstimate?.directCost| currency }} </div>
            </div>
            <div class="item">
                <div class="label">Sell Price</div>
                <div class="value">{{ finalEstimate?.sellPrice| currency }} </div>
            </div>
            <div class="item">
                <div class="label">Gross Margin</div>
                <div class="value">{{ finalEstimate?.grossMarginPercentage| customPercentage }} </div>
            </div>
            <div class="item">
                <div class="label">Description</div>
                <div class="value">{{ finalEstimate?.description }} </div>
            </div>
        </div>
    </ng-template>
</lib-section-header>

<div class="contentSummary">
    <div class="title">Estimate to Include</div>
    <div class="subtitle">Base Estimate</div>

    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'name', label: 'Name', sortable: false },
                { key: 'description', label: 'Description', sortable: false },
                { key: 'grossMarginPercentage', label: 'Gross Margin', sortable: false, cellTemplate: grossMarginTemplate },
                { key: 'sellPrice', label: 'Sell Price', sortable: false, cellTemplate: sellPriceTemplate },
            ],
        }"
        [source]="baseEstimate"
        (rowClicked)="onRowClicked($event)"
        [hasExternalLink]="true">
    </lib-grid-view>


    <div class="subtitle">Alternate Estimate</div>

    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'name', label: 'Name', sortable: false },
                { key: 'description', label: 'Description', sortable: false },
                { key: 'order', label: 'Alt #', sortable: false },
                { key: 'grossMarginPercentage', label: 'Gross Margin', sortable: false, cellTemplate: grossMarginTemplate },
                { key: 'sellPrice', label: 'Sell Price', sortable: false, cellTemplate: sellPriceTemplate },
            ],
        }"
        [source]="alternateEstimates"
        (rowClicked)="onRowClicked($event)"
        [hasExternalLink]="true">
    </lib-grid-view>

    <div class="title">Summary</div>

    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'opportunityEstimateSummaryTypeDescription', label: 'Description', sortable: false },
                { key: 'grossMarginPercentage', label: 'Gross Margin', sortable: false, cellTemplate: grossMarginTemplate },
                { key: 'sellPrice', label: 'Sell Price', sortable: false, cellTemplate: sellPriceTemplate },
                { key: 'directCostPercentage', label: 'TDC', sortable: false, cellTemplate: directCostTemplate },
            ],
        }"
        [source]="estimateSummaries">
    </lib-grid-view>


    <ng-template #sellPriceTemplate let-data>
        {{ data.object.sellPrice | currency }}
    </ng-template>

    <ng-template #grossMarginTemplate let-data>
        {{ data.object.grossMarginPercentage | customPercentage }}
    </ng-template>

    <ng-template #creationDateTemplate let-data>
        {{ data.object.creationDate | date: "MM/dd/y hh:mm a" }}
    </ng-template>

    <ng-template #selectedTemplate let-data>
        <lib-badge text="{{ data.object.changeOrderStatusName }}"></lib-badge>
    </ng-template>

    <ng-template #directCostTemplate let-data>
        {{ data.object.directCostPercentage | percent: '1.2-2'  }}
    </ng-template>
</div>