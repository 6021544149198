<lib-generic-type-ahead-multiselect
    [isReadOnly]="isReadOnly"
    [source]="contactItems"
    [selectedItems]="selectedContactItems"
    [config]="{
        defaultText: 'Select',
        itemDisplayKey: 'fullName',
        itemTemplate: contactTemplate
    }"
    removeKey="contactId"
    (textChange)="onContactTextChange($event)"
    (selectedItemsChange)="onSelectedContactChange($event)"
    (removeItemChange)="onRemoveContactChange($event)"
    [isReadOnly]="isReadOnly"
    [action]="addContactAction"
    [placeholder]="isReadOnly && !bindingProperty || !label ? 'Select an Item' : 'Add ' + label"
>
</lib-generic-type-ahead-multiselect>

<ng-template #contactTemplate let-data>
    <div>
        <span [innerHTML]="data.object?.firstName + ' ' + data.object?.lastName | highlightText:data.inputText"></span>
        <div *ngIf="data.object?.companyName">
            <span [innerHTML]="data.object?.companyName | highlightText:data.inputText"></span>
            <span *ngIf="data.object?.isMain"> - Main contact</span>
        </div>
        <div *ngIf="!data.object?.companyName">
            <span *ngIf="!data.object?.companyName">No company assigned</span>
        </div>
    </div>
</ng-template>
