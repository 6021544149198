import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";

import { Chart } from "chart.js/auto";
import { IChartCanvasData } from "./interfaces";

@Component({
    selector: "app-chart-base",
    templateUrl: "./chart-base.component.html",
    styleUrls: ["./chart-base.component.scss"]
})
export class ChartBaseComponent implements OnChanges, OnDestroy {
    @Input()
    chartCanvasData?: IChartCanvasData;

    chart?: Chart;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["chartCanvasData"]) {
            if (this.chart) this.chart.destroy();
            setTimeout(() => {
                this.createChart();
            });
        }
    }

    ngOnDestroy(): void {
        this.chart?.destroy();
    }

    createChart(): void {
        if (!this.chartCanvasData) return;
        const chartElement: any = document.getElementById(this.chartCanvasData?.id);

        this.chart = new Chart(chartElement.getContext("2d"), {
            type: this.chartCanvasData.type,
            data: {
                labels: this.chartCanvasData?.labels,
                datasets: this.chartCanvasData?.datasets
            },
            options: this.chartCanvasData?.options,
            plugins: this.chartCanvasData.plugins
        });
    }
}
