import { Component, Injector, OnInit } from "@angular/core";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { EntityFactory } from "./entity-factory/entity-factory";
import { IComponentEditorEntity } from "./interfaces/editor-entity.interface";
import { IEntity } from "./interfaces/entity.interface";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";

@Component({
    selector: "app-entity-editor",
    templateUrl: "./entity-editor.component.html",
    styleUrls: ["./entity-editor.component.scss"]
})
export class EntityEditorComponent extends EditorComponentBase<unknown> implements OnInit {
    entity!: IComponentEditorEntity<IEntity, unknown>;
    source: IEntity[];
    selectedItems: IEntity[];
    entityType?: EntityTypes;

    constructor(private readonly injector: Injector) {
        super();
        this.source = [];
        this.selectedItems = [];
    }

    async ngOnInit(): Promise<void> {
        if (!this.entityId) return;

        this.entity = EntityFactory.create(this.entityId, this.injector);

        this.entityType = this.additionalInfo?.entityType;

        this.source = await this.entity.getSource(this.entityType);

        if (Array.isArray(this.bindingProperty) && this.bindingProperty?.length) {
            this.selectedItems = this.bindingProperty.map(item => this.entity?.toSource(item));
        }
    }

    onSelectedItemChange() {
        this.bindingProperty = this.selectedItems.length ? this.selectedItems.map(item => this.entity?.toDestination(item)) : [];
        this.validate();
        this.valueChange.emit();
    }
}
