<div class="container">
    <lib-section-header title="Create New Opportunity" [actionsTemplate]="actionsTemplate" [iconName]="'goBack'">
        <ng-template #actionsTemplate>
            <div class="actions">
                <button class="secondary-btn" (click)="cancel()">Cancel</button>
                <button class="primary-btn" (click)="saveOpportunity()">Create</button>
            </div>
        </ng-template>
    </lib-section-header>

    <div class="cardsContainer">
        <app-opportunities-single-overview
            #overview
            [opportunity]="opportunity"
            [isReadOnly]="false"
        ></app-opportunities-single-overview>
    </div>
</div>
