import { Component, Input } from "@angular/core";

type DashboardStatType = "currency" | "customPercentage" | "none";
@Component({
    selector: "app-dashboard-stat",
    templateUrl: "./dashboard-stat.component.html",
    styleUrls: ["./dashboard-stat.component.scss"]
})
export class DashboardStatComponent {
    @Input()
    title: string;

    @Input()
    previousValue?: number;

    @Input()
    currentValue?: number;

    @Input()
    type?: DashboardStatType;

    constructor() {
        this.title = "";
        this.previousValue = 0;
        this.currentValue = 0;
        this.type = "none";
    }
}
