import { NoteUserView, UserView } from "../services/http/clients/api-proxies";
import { ModelBase } from "./ModelBase";

export class NoteUser extends ModelBase implements NoteUserView {
    noteId: number;
    userId: number;
    userEmail: string | undefined;
    userFirstName: string | undefined;
    userLastName: string | undefined;
    userFullName: string | undefined;
    userExternalId: number | undefined;
    constructor() {
        super();
        this.noteId = 0;
        this.userId = 0;
        this.userFirstName = undefined;
        this.userLastName = undefined;
        this.userFullName = undefined;
        this.userEmail = undefined;
        this.userExternalId = undefined;
    }

    static fromInterface(dto: NoteUserView): NoteUser {
        const noteUser = new NoteUser();
        noteUser.mapFromInterface(dto);
        return noteUser;
    }

    static fromUserView(userView: UserView): NoteUser {
        const noteUser = new NoteUser();
        noteUser.userId = userView.id;
        noteUser.userFullName = userView.fullName;
        noteUser.userFirstName = userView.firstName;
        noteUser.userLastName = userView.lastName;
        noteUser.userEmail = userView.email;
        noteUser.userExternalId = userView.externalId;
        return noteUser;
    }
}
