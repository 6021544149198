import { Injectable } from "@angular/core";
import { ActivityLogClient, ActivityLogViewPaginatedResultDto, IActivityLogClient, SearchActivityLogsParameters } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class ActivityLogService {
    private client: IActivityLogClient;
    constructor() {
        this.client = new ActivityLogClient();
    }

    async search(parameters: SearchActivityLogsParameters): Promise<ActivityLogViewPaginatedResultDto> {
        return await this.client.activityLogSearch(parameters);
    }
}
