import { Component, OnInit } from "@angular/core";
import { DivisionView, SearchDivisionsParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { DivisionService } from "projects/app/src/app/services/http/clients/division.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-division-editor",
    templateUrl: "./division-editor.component.html",
    styleUrls: ["./division-editor.component.scss"]
})
export class DivisionEditorComponent extends EditorComponentBase<number | null> implements OnInit {
    subDivisionItems?: DivisionView[];
    selectedSubDivision?: DivisionView;

    constructor(private readonly divisionService: DivisionService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.subDivisionItems = await this.divisionService.search({isActive : this.additionalInfo?.isActive} as SearchDivisionsParameters);
        if (this.bindingProperty) {
            this.selectedSubDivision = this.subDivisionItems?.find(x => x.id === this.bindingProperty);
            if (!this.selectedSubDivision) {
                this.selectedSubDivision = await this.divisionService.getById(this.bindingProperty);
            }
        }
            
    }

    onSubDivisionChange() {
        if (this.selectedSubDivision?.id) {
            this.bindingProperty = this.selectedSubDivision.id;
            this.validate();
        } else {
            this.bindingProperty = null;
        }
        this.valueChange.emit();
    }
}
