import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { ReportExecutionView, SearchReportExecutionsParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { ReportExecutionService } from "projects/app/src/app/services/http/clients/report-execution.service";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig, ModalService } from "projects/ngx-lib/src/public-api";
import { ModalConfirmComponent } from "../../../shared/modals/modal-confirm/modal-confirm.component";
import { IReportExecutionListFilters } from "../report-execution-list-filters/report-execution-list-filters.component";

@Component({
    selector: "app-reports-executions-list",
    templateUrl: "./reports-executions-list.component.html",
    styleUrls: ["./reports-executions-list.component.scss"]
})
export class ReportsExecutionsListComponent implements OnChanges {
    @Input()
    userHasReportFullAccess: boolean;

    @Input()
    filters?: IReportExecutionListFilters;

    @Input()
    allowToSetMultiOrganization?: boolean;

    reportsExecutions: ReportExecutionView[];

    orderConfig?: IGenericGridOrderConfig<ReportExecutionView>;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchReportExecutionsParameters;

    createNewReportLink: string;

    noResults: boolean;

    isLoading: boolean;

    constructor(
        private readonly awaiter: AwaiterService,
        private readonly reportExecutionService: ReportExecutionService,
        private readonly router: Router,
        private readonly modalService: ModalService
    ) {
        this.userHasReportFullAccess = false;

        this.reportsExecutions = [];

        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage
        } as SearchReportExecutionsParameters;

        this.createNewReportLink = "";

        this.noResults = true;
        this.isLoading = true;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (!changes["filters"]?.firstChange && changes["filters"]?.currentValue) {
            this.paginationConfig.currentPage = 1;
            this.reportsExecutions = [];
            await this.refreshGridView();
        }

        if (changes["userHasReportFullAccess"]?.currentValue) {
            this.userHasReportFullAccess = changes["userHasReportFullAccess"].currentValue;
        }

        if (changes["allowToSetMultiOrganization"]?.currentValue) {
            this.allowToSetMultiOrganization = changes["allowToSetMultiOrganization"].currentValue;
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Reports Executions",
            async () => {
                const response = await this.reportExecutionService.search(this.searchConfig);

                const { pageInfo, results } = response;

                this.reportsExecutions = this.reportsExecutions?.concat(results ?? []);

                this.noResults = this.reportsExecutions.length === 0;
                this.createNewReportLink = this.noResults && this.userHasReportFullAccess ? "Create New Report" : "";

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;

        this.reportsExecutions = [];

        await this.refreshGridView();
    }

    onRowClicked(reportExecution: ReportExecutionView) {
        this.router.navigate([`reports-edit/${reportExecution.id}`], { state: { data: { allowToSetMultiOrganization: this.allowToSetMultiOrganization } } });
    }

    async removeItem(reportExecution: ReportExecutionView): Promise<void> {
        if (!this.userHasReportFullAccess) return;

        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Remove",
            cancelCaption: "Cancel",
            content: "Are you sure you want to remove this report from the list?",
            title: "Remove Report"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction(
            "Removing Report Execution",
            async () => {
                if (!reportExecution) return;

                await this.reportExecutionService.delete(reportExecution.id);

                const reportExecutionIndex = this.reportsExecutions.findIndex(report => report.id === reportExecution.id);
                this.reportsExecutions.splice(reportExecutionIndex, 1);

                this.noResults = this.reportsExecutions.length === 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    async runReportExecutionItem(reportExecution: ReportExecutionView): Promise<void> {
        this.router.navigate(["/reports-results"], { state: { data: { reportExecution: reportExecution } } });
    }

    onCreateNewReportExecution() {
        if (!this.userHasReportFullAccess) return;
        this.router.navigate([`/reports-edit`], { state: { data: { allowToSetMultiOrganization: this.allowToSetMultiOrganization } } });
    }

    private buildSearchConfig() {
        this.searchConfig = {
            filterText: this.filters?.searchText,
            creatorUserId: this.filters?.creatorUser?.externalId,
            reportTypeId: this.filters?.reportType?.id,

            pageNumber: this.paginationConfig.currentPage,
            sortDirection: this.orderConfig?.order,
            sortBy: this.orderConfig?.key
        } as SearchReportExecutionsParameters;
    }
}
