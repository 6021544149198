import { ChartTypeRegistry } from "chart.js";

export enum LegendTitle {
    Current = "Current",
    Previous = "Previous"
}
export interface ILegend {
    title?: LegendTitle | string;
    labels: ILegendLabel[];
}

interface ILegendLabel {
    name: string;
    value?: number;
}

export interface IDashboardChart {
    id: string;
    labels: string[];
    legend?: ILegend;
    currentData?: number[];
    previousData?: number[];
}

export interface IChartCanvasData {
    id: string;
    type: keyof ChartTypeRegistry;
    labels: string[];
    options: any;
    datasets: any[];
    plugins?: any[];
}
