export const CHART_COLORS = {
    current: {
        primary: "#0095ff",
        secondary: "#0095ff80"
    },
    previous: {
        primary: "#175c89",
        secondary: "#175c8980"
    },
    tertiary: "#85b1ce",
    ticks: {
        x: "#175c89",
        y: "#98b7d2"
    },
    gridLines: "#e3ecf2",
    emptyState: "#f5f8fa"
};

export const tooltip = {
    backgroundColor: "#fffffff2",
    borderColor: "#e3ecf2",
    borderWidth: 1,
    displayColors: false,
    titleColor: "#175C89",
    callbacks: {
        labelTextColor: function (context: any) {
            return "#175C89";
        }
    }
};
