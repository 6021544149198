import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { BaseFilterComponent } from "../base-filter/base-filter.component";
import { icons } from "../../../assets/icons";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { SearchInputComponent } from "../../search-input/search-input.component";
import { IGenericTypeAheadDropdownConfig } from "../../../interfaces/type-ahead-dropdown-config.interface";

@Component({
    selector: "lib-filter-generic-type-ahead-dropdown",
    templateUrl: "./filter-generic-type-ahead-dropdown.component.html",
    styleUrls: ["./filter-generic-type-ahead-dropdown.component.scss"]
})
export class FilterGenericTypeAheadDropdownComponent<T> extends BaseFilterComponent implements OnInit, OnChanges {
    readonly icons = icons;

    @ViewChild("searchInput")
    searchInput?: SearchInputComponent;

    _selectedItem?: T;

    @Input()
    source?: T[];

    @Input()
    config?: IGenericTypeAheadDropdownConfig<T>;

    @Input()
    disabled: boolean;

    @Input()
    selectedItem?: T;

    @Output()
    selectedItemChange = new EventEmitter<T>();

    @Input()
    text = "";

    @Output()
    textChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    itemClicked: EventEmitter<T> = new EventEmitter<T>();

    @Input()
    isReadOnly: boolean;

    @Input()
    displaySelected?: boolean;

    @Input()
    placeholder?: string;

    @Input()
    noResultsText?: string;

    @Input()
    name?: string;

    @Input()
    key?: keyof T;

    opened: boolean;

    filterActive: boolean;

    override filterChanged: EventEmitter<void>;

    closeIcon?: SafeHtml;

    get displayPlaceholder(): string | undefined {
        return this.placeholder ?? "Select an item";
    }

    constructor(
        private readonly elementRef: ElementRef,
        private readonly sanitizer: DomSanitizer
    ) {
        super();
        this.disabled = false;
        this.text = "";
        this.isReadOnly = false;
        this.displaySelected = true;
        this.noResultsText = "No matches found";
        this.opened = false;
        this.filterActive = true;
        this.filterChanged = new EventEmitter();
    }

    ngOnInit(): void {
        this.filterActive = this.selectedItem !== undefined;
        this.closeIcon = this.sanitizer.bypassSecurityTrustHtml(icons.close);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["selectedItem"] && changes["selectedItem"].currentValue) {
            this._selectedItem = this.selectedItem ? ({ ...this.selectedItem } as T) : undefined;
        }
        if (changes["source"] && changes["source"].currentValue) {
            this.showItems();
        }
    }

    selectItem(item: T): void {
        this._selectedItem = item;
        this.filterActive = false;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.text = "";
        this.toggle();
        this.emitItemChanged(item);
        this.emitFilterChanged();
    }

    onKeyDownSelectItem(e: KeyboardEvent, item: T): void {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            this.selectItem(item);
        }
    }

    getTemplate(): TemplateRef<unknown> | null {
        if (!this.config?.itemTemplate) return null;
        return this.config.itemTemplate;
    }

    showItems(): void {
        this.opened = true;
    }

    activateFilter(event: Event): void {
        if (this.isReadOnly) return;
        event.stopPropagation();
        this.filterActive = true;
    }

    async onTextChanged(): Promise<void> {
        this.showItems();
        this.textChange.emit(this.text);
    }

    toggle(value?: boolean): void {
        if (this.disabled) return;
        this.opened = value ?? !this.opened;
    }

    clearFilter() {
        this._selectedItem = undefined;
        this.filterActive = false;
        this.emitItemChanged(undefined);
        this.emitFilterChanged();
    }

    onKeyDownClearFilter(e: KeyboardEvent) {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            this.clearFilter();
        }
    }

    focusInput() {
        setTimeout(() => {
            this.searchInput?.inputElement?.nativeElement.focus();
        }, 0);
    }

    @HostListener("document:click", ["$event"])
    private onDocumentClicked(event: MouseEvent): void {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            if (this.filterActive) this.filterActive = false;
            this.opened = false;
        }
    }

    private emitItemChanged(item?: T): void {
        this.selectedItemChange.emit(item);
    }

    private emitFilterChanged(): void {
        this.filterChanged.emit();
    }
}
