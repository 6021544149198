import { Injectable } from "@angular/core";
import { IUserOrganizationClient, UserOrganizationClient, UserOrganizationView } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class UserOrganizationService {
    private client: IUserOrganizationClient;
    constructor() {
        this.client = new UserOrganizationClient();
    }

    async userOrganizationGetForCurrentUser(): Promise<UserOrganizationView[]> {
        return await this.client.userOrganizationGetForCurrentUser();
    }
}
