import { Component, OnInit } from "@angular/core";
import { CompanyView } from "projects/app/src/app/services/http/clients/api-proxies";
import { CompanyService } from "projects/app/src/app/services/http/clients/company.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { ModalService, ObjectExtensionsService } from "projects/ngx-lib/src/public-api";

import { ModalConfirmComponent } from "projects/app/src/app/components/shared/modals/modal-confirm/modal-confirm.component";

@Component({
    selector: "app-company-editor",
    templateUrl: "./company-editor.component.html",
    styleUrls: ["./company-editor.component.scss"]
})
export class CompanyEditorComponent extends EditorComponentBase<number | undefined> implements OnInit {
    selectedCompany?: CompanyView;

    editorInfoAllowAction?: boolean;

    editorInfoHasMainContactSet?: boolean;
    
    isActive?: boolean;

    constructor(
        private readonly companyService: CompanyService,
        private readonly modalService: ModalService,
        private readonly objectExtensionService: ObjectExtensionsService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.editorInfoAllowAction = !!this.additionalInfo?.allowAction;
        this.editorInfoHasMainContactSet = !!this.additionalInfo?.hasMainContactSet;
        if (this.additionalInfo)
            this.isActive = this.additionalInfo.isActive;
        if (this.bindingProperty) {
            this.selectedCompany = await this.companyService.getById(this.bindingProperty);
        }
    }

    async onSelectedCompany(company: CompanyView) {
        const setCompanyFn = () => {
            this.selectedCompany = company;
            this.bindingProperty = this.selectedCompany?.id;
            this.valueChange.emit();
        };

        if (this.editorInfoHasMainContactSet) {
            const responseOk = await this.modalService.open(ModalConfirmComponent, {
                acceptCaption: "Remove",
                cancelCaption: "Cancel",
                content: "This user is a primary contact, therefore he/she will also be removed from that section of the company.",
                title: "Removing Company"
            });

            if (responseOk) {
                setCompanyFn();
            } else {
                this.selectedCompany = this.objectExtensionService.clone(this.selectedCompany) as CompanyView;
            }
        } else {
            setCompanyFn();
        }
    }
}
