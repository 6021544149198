<div class="cardsWrapper">
    <app-popup-card-base-layout
        (click)="togglePopup()" tabindex="0" (keyup)="onKeyUp($event)" aria-label="Show card details"
        [highlightColor]="data?.eventType === dueDatetype ? 'blue' : 'yellow'">
        <div class="container">
            <div class="header">
                <lib-badge
                    [text]="data?.eventType === dueDatetype ? 'Due Date' : 'Follow up'"
                    [color]="data?.eventType === dueDatetype ? 'secondary' : 'tertiary'">
                </lib-badge>
                <div class="title">{{ data?.name }}</div>
                <app-user-avatar
                    *ngIf="data?.opportunityUsers?.length && data?.eventType === dueDatetype"
                    [users]="$any(data?.opportunityUsers)"
                    [size]="'small'">
                </app-user-avatar>
                <div *ngIf="data?.contactInfo" class="subtitle">{{ data?.contactInfo }}</div>
            </div>
        </div>
    </app-popup-card-base-layout>

    <app-opportunity-card-expanded
        *ngIf="isExpanded"
        [data]="data"
        [ngClass]="popupPosition"
        (closeClicked)="this.isExpanded = false"
        (goToClicked)="onGoToClicked(data?.id)"
        >
    </app-opportunity-card-expanded>
</div>
