/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, Injector, Type, ViewContainerRef } from "@angular/core";
import mapboxgl from "mapbox-gl";
import { ConfigurationService } from "./http/clients/configuration.service";
import { OpportunityCardExpandedComponent } from "../components/shared/opportunity-card/opportunity-card-expanded/opportunity-card-expanded.component";
import { Router } from "@angular/router";
@Injectable({
    providedIn: "root"
})
export class MapService {
    private map!: mapboxgl.Map;
    private onMoveEndCallback?: () => void;
    private onLoadedCallback?: () => void;
    private popups: mapboxgl.Popup[] = [];
    popupContainer!: ViewContainerRef;
    popupComponent!: Type<any>;

    constructor(
        private readonly configurationService: ConfigurationService,
        private injector: Injector,
        private readonly router: Router
    ) {}

    async initializeMap(containerId: string, center: [number, number], zoom: number, navigationControl = true): Promise<boolean> {
        try {
            const mapboxToken = (await this.configurationService.getMapConfiguration()).accessTokenCrm;

            (mapboxgl as any).accessToken = mapboxToken;

            this.map = new mapboxgl.Map({
                container: containerId,
                style: "mapbox://styles/mapbox/streets-v12",
                center: center,
                zoom: zoom,
                attributionControl: false
            });

            if (navigationControl) this.addNavigationControl();

            this.map.on("load", () => {
                this.addMapListeners();
                this.addCustomMarkerImage();
                if (this.onLoadedCallback) {
                    this.onLoadedCallback();
                }
            });

            return true;
        } catch (error) {
            console.error("Error initializing Mapbox map:", error);
            return false;
        }
    }

    setPopupContainer(viewContainerRef: ViewContainerRef) {
        this.popupContainer = viewContainerRef;
    }

    setPopupContent(popupComponent: Type<any>) {
        this.popupComponent = popupComponent;
    }

    addNavigationControl() {
        const nav = new mapboxgl.NavigationControl({
            visualizePitch: true
        });
        this.map.addControl(nav, "bottom-right");
    }

    fetchData(data: any[], layerId: string) {
        if (!this.isStyleLoaded()) return;
        const sourceId = `${layerId}-source`;
        this.closeAllPopups();

        this.setSource(sourceId, this.createGeoJSONSource(data));
        this.addLayer(layerId, sourceId);
    }

    setSource(sourceId: string, sourceData: mapboxgl.GeoJSONSourceRaw) {
        if (this.map.getSource(sourceId)) {
            (this.map.getSource(sourceId) as mapboxgl.GeoJSONSource).setData(
                sourceData.data as GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
            );
        } else {
            this.map.addSource(sourceId, sourceData);
        }
    }

    addLayer(layerId: string, sourceId: string) {
        if (!this.map.getLayer(layerId)) {
            this.map.addLayer({
                id: layerId,
                type: "symbol",
                source: sourceId,
                layout: {
                    "icon-image": "custom-marker-icon",
                    "icon-size": 0.7
                }
            });

            this.addLayerListeners(layerId);
        }
    }

    setOnMoveEndCallback(callback: () => void) {
        this.onMoveEndCallback = callback;
    }

    setOnLoadedCallback(callback: () => void) {
        this.onLoadedCallback = callback;
    }

    getMapBounds(): mapboxgl.LngLatBounds {
        return this.map.getBounds();
    }

    private addMapListeners() {
        this.map.on("moveend", () => {
            if (this.onMoveEndCallback) {
                this.onMoveEndCallback();
            }
        });
    }

    private createGeoJSONSource(data: any[]): any {
        if (!data?.length) {
            return {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: []
                }
            };
        }

        return {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: data.map(p => ({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [p.longitude, p.latitude]
                    },
                    properties: p
                }))
            }
        };
    }

    private addLayerListeners(layerId: string) {
        this.map.on("click", layerId, e => {
            if (!e.features) return;
            const feature = e.features[0];

            if (!feature || !feature.properties || feature.geometry.type !== "Point") return;

            const longitude = feature.geometry.coordinates[0];
            const latitude = feature.geometry.coordinates[1];

            const data = {
                ...feature.properties,
                ...(feature.properties["opportunityUsers"] && { opportunityUsers: JSON.parse(feature.properties["opportunityUsers"]) })
            };

            this.addPopup([longitude, latitude], data, this.popupContainer, this.popupComponent);
        });

        this.map.on("mouseenter", layerId, () => {
            this.map.getCanvas().style.cursor = "pointer";
        });

        this.map.on("mouseleave", layerId, () => {
            this.map.getCanvas().style.cursor = "";
        });
    }

    private addPopup(lngLat: mapboxgl.LngLatLike, data: any, viewContainerRef: ViewContainerRef, popupComponent: Type<any>) {
        const popupElement = document.createElement("div");

        const popup = new mapboxgl.Popup({ closeButton: false, closeOnMove: true, maxWidth: "275px" })
            .setLngLat(lngLat)
            .setDOMContent(popupElement)
            .addTo(this.map);

        this.popups.push(popup);

        const componentRef = viewContainerRef.createComponent<OpportunityCardExpandedComponent>(popupComponent, { injector: this.injector });
        componentRef.instance.data = data;
        componentRef.instance.showEventInfo = false;
        componentRef.instance.hasBorder = false;
        componentRef.instance.goToClicked.subscribe(() => this.onGoToClicked(data.id));
        componentRef.instance.closeClicked.subscribe(() => popup.remove());

        popupElement.appendChild((componentRef.hostView as any).rootNodes[0]);

        popup.on("close", () => {
            componentRef.destroy();
            this.popups = this.popups.filter(p => p !== popup);
        });
    }

    private onGoToClicked(id?: number): void {
        this.router.navigate([`/opportunities-single/${id}`]);
    }

    private addCustomMarkerImage() {
        this.map.loadImage("../../assets/images/map-marker-point.png", (error, image) => {
            if (error) throw error;
            if (!this.map.hasImage("custom-marker-icon")) {
                this.map.addImage("custom-marker-icon", image as HTMLImageElement);
            }
        });
    }

    private closeAllPopups() {
        this.popups.forEach(popup => popup.remove());
        this.popups = [];
    }

    private isStyleLoaded() {
        return this.map.isStyleLoaded();
    }
}
