import { TagView } from "projects/app/src/app/services/http/clients/api-proxies";
import { IComponentEditorEntity } from "../../interfaces/editor-entity.interface";
import { TagService } from "projects/app/src/app/services/http/clients/tag.service";

export abstract class TagEntity<TEntityDestination> implements IComponentEditorEntity<TagView, TEntityDestination> {
    constructor(readonly tagService: TagService) {}

    abstract toDestination(entity: TagView): TEntityDestination;

    abstract toSource(entity: TEntityDestination): TagView;

    abstract getSource(): Promise<TagView[]>;
}
