import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IChartCanvasData, IDashboardChart } from "../chart-base/interfaces";
import { CHART_COLORS, tooltip } from "../chart-base/config";

@Component({
    selector: "app-doughnut-chart",
    templateUrl: "./doughnut-chart.component.html",
    styleUrls: ["./doughnut-chart.component.scss"]
})
export class DoughnutChartComponent implements OnChanges {
    canvasData?: IChartCanvasData;

    @Input()
    data?: IDashboardChart;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["data"]) {
            this.buildCanvasData();
        }
    }

    commonConfig = {
        borderWidth: 0
    };

    currentConfig = {
        backgroundColor: [CHART_COLORS.current.primary, CHART_COLORS.current.secondary]
    };

    previousConfig = {
        backgroundColor: [CHART_COLORS.previous.primary, CHART_COLORS.previous.secondary]
    };

    buildCanvasData(): void {
        if (!this.data) return;

        const datasets: any[] = [];

        datasets.push({
            data: this.data?.currentData ? this.data?.currentData : this.data?.previousData,
            ...(this.data?.currentData ? this.currentConfig : this.previousConfig),
            ...this.commonConfig
        });

        const plugin = {
            id: "emptyDoughnut",
            afterDraw(chart: { data?: any; chartArea?: any; ctx?: any }, args: any, options: { color: any; width: any; radiusDecrease: any }) {
                const { datasets } = chart.data;
                const { color, width, radiusDecrease } = options;
                let hasData = false;
                let isZero = false;

                for (let i = 0; i < datasets.length; i += 1) {
                    const dataset = datasets[i];
                    hasData ||= dataset.data.length > 0;
                    isZero = dataset.data.every((item: number) => item === 0);
                }

                if (!hasData || isZero) {
                    const {
                        chartArea: { left, top, right, bottom },
                        ctx
                    } = chart;
                    const centerX = (left + right) / 2;
                    const centerY = (top + bottom) / 2;
                    const r = Math.min(right - left, bottom - top) / 2;

                    ctx.beginPath();
                    ctx.lineWidth = width || 40;
                    ctx.strokeStyle = color || CHART_COLORS.emptyState;
                    ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
                    ctx.stroke();
                }
            }
        };

        this.canvasData = {
            id: this.data.id,
            type: "doughnut",
            labels: this.data.labels,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    emptyDoughnut: {
                        color: CHART_COLORS.emptyState,
                        width: 40,
                        radiusDecrease: 20
                    },
                    legend: {
                        display: false
                    },
                    tooltip
                }
            },
            plugins: [plugin],
            datasets
        };
    }
}
