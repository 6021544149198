import { Component, EventEmitter, Output } from "@angular/core";
import { BaseFilterComponent } from "../base-filter/base-filter.component";
import { SlideToggleComponent } from "../../slide-toggle/slide-toggle.component";
import { FilterService, MessageBusService } from "../../../services";

@Component({
    selector: "lib-filter-slide-toggle",
    templateUrl: "../../slide-toggle/slide-toggle.component.html",
    styleUrls: ["../../slide-toggle/slide-toggle.component.scss"]
})
export class FilterSlideToggleComponent extends SlideToggleComponent implements BaseFilterComponent {
    @Output()
    filterChanged: EventEmitter<void>;

    constructor(
        private readonly filterService: FilterService,
        private readonly messageBusService: MessageBusService
    ) {
        super();
        this.filterChanged = new EventEmitter();
    }

    override onToggleChange(): void {
        super.onToggleChange();
        this.filterChanged.emit();
    }
}
