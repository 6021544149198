import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

interface Param {
    name: string;
    value?: string;
}

export interface IGetById {
    getById(value: number): unknown;
}

@Injectable({
    providedIn: "root"
})
export class FilterService {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    async serialize<TModel>(filterObject: TModel, property: keyof TModel): Promise<void> {
        if (!filterObject[property]) await this.updateQueryParam({ name: property as string, value: undefined });
        else await this.updateQueryParam({ name: property as string, value: filterObject[property] as string });
    }

    async serializeTypeAhead<TModel, TObjectModel>(
        property: keyof TModel,
        object: TObjectModel | TObjectModel[] | undefined,
        objectKey: keyof TObjectModel,
        multipleSelection = false
    ): Promise<void> {
        if (multipleSelection) {
            const typesValues = object && (object as TObjectModel[]).length ? (object as TObjectModel[])?.map(x => x[objectKey]) : [];
            await this.updateQueryParam({ name: property as string, value: typesValues.length ? typesValues.join(",") : undefined });
        } else {
            if (!object) this.updateQueryParam({ name: property as string, value: undefined });
            else await this.updateQueryParam({ name: property as string, value: (object as TObjectModel)[objectKey] as string });
        }
    }

    async serializeArray<TModel, TArrayItem>(
        filterObject: TModel,
        property: keyof TModel,
        arrayItemKey: keyof TArrayItem,
        multipleSelection = false
    ): Promise<void> {
        if (multipleSelection) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const typesValues = (filterObject[property] as Array<TArrayItem>)?.map(x => x[arrayItemKey]) ?? [];
            await this.updateQueryParam({ name: property as string, value: typesValues.join(",") });
        } else {
            const typeValue = filterObject[property] ? (filterObject[property] as TArrayItem)[arrayItemKey] : undefined;
            await this.updateQueryParam({ name: property as string, value: typeValue ? (typeValue as string) : undefined });
        }
    }

    async serializeDate<TModel>(filterObject: TModel, property: keyof TModel): Promise<void> {
        await this.updateQueryParam({ name: property as string, value: (filterObject[property] as Date | undefined)?.toISOString() ?? undefined });
    }

    deserialize<TModel>(filterObject: TModel, property: keyof TModel, allowUndefined = false): void {
        if (allowUndefined) (filterObject[property] as string | undefined) = this.getParamValue(property.toString()) ?? undefined;
        else (filterObject[property] as string) = this.getParamValue(property.toString()) ?? "";
    }

    deserializeNumber<TModel>(filterObject: TModel, property: keyof TModel): void {
        const value = this.getParamValue(property.toString());

        if (!value) (filterObject[property] as number | undefined) = undefined;
        else (filterObject[property] as number) = parseInt(value);
    }

    deserializeBoolean<TModel>(filterObject: TModel, property: keyof TModel): void {
        const value = this.getParamValue(property.toString());

        if (!value) (filterObject[property] as boolean | undefined) = undefined;
        else if (value && value === "true") (filterObject[property] as boolean) = true;
        else if (value && value === "false") (filterObject[property] as boolean) = false;
    }

    async deserializeTypeAhead<TModel, TObjectModel>(
        filterObject: TModel,
        property: keyof TModel,
        service: IGetById,
        multipleSelection = false
    ): Promise<void> {
        const paramValue = this.getParamValue(property.toString()) ?? "";
        if (!paramValue) return;

        if (multipleSelection) {
            const values = paramValue.split(",");

            const selectedItems = [];
            if (values.length) {
                for (const value of values) {
                    const item = (await service.getById(Number(value))) as TObjectModel;
                    if (value) selectedItems.push(item);
                }
            }
            (filterObject[property] as TObjectModel[]) = selectedItems;
        } else {
            (filterObject[property] as TObjectModel) = (await service.getById(Number(paramValue))) as TObjectModel;
        }
    }

    deserializeArray<TModel, TArrayItem>(
        filterObject: TModel,
        property: keyof TModel,
        arrayItemKey: keyof TArrayItem,
        sourceArray: TArrayItem[],
        multipleSelection = false
    ): void {
        const paramValue = this.getParamValue(property.toString()) ?? "";
        if (multipleSelection) {
            const values = paramValue.split(",");
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const items = sourceArray.filter(x => values.some(y => y === (x[arrayItemKey] as any).toString()));

            (filterObject[property] as TArrayItem[]) = items;
        } else {
            const selectedStatusItem = sourceArray.find(x => x[arrayItemKey] == paramValue);
            if (selectedStatusItem) (filterObject[property] as TArrayItem) = selectedStatusItem;
        }
    }

    deserializeDate<TModel>(filterObject: TModel, property: keyof TModel, allowUndefined = true): void {
        const paramValue = this.getParamValue(property.toString());
        if (allowUndefined) (filterObject[property] as Date | undefined) = paramValue ? new Date(paramValue) : undefined;
        else (filterObject[property] as Date) = new Date(paramValue ?? "");
    }

    async updateQueryParams(params: Param[]): Promise<void> {
        const queryParams: { [key: string]: string | undefined | null } = {};

        for (const param of params) {
            let paramValue: string | undefined | null = param.value;
            if (!paramValue) {
                paramValue = null;
            }
            queryParams[param.name] = paramValue;
        }
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams,
            queryParamsHandling: "merge",
            replaceUrl: true
        });
    }

    private async updateQueryParam(param: Param): Promise<void> {
        let paramValue: string | undefined | null = param.value;
        if (!paramValue) {
            paramValue = null;
        }
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { [param.name]: paramValue },
            queryParamsHandling: "merge",
            replaceUrl: true
        });
    }

    private getParamValue(paramName: string) {
        return this.activatedRoute.snapshot.queryParamMap.get(paramName);
    }
}
