<div class="container" [ngClass]="{ filtersContainer: reportType !== undefined }">
    <div class="contentTitle">
        <p class="title">{{ reportType?.name }}</p>
    </div>
    <div class="cardsContainer">
        <div class="cardWrapper highlight">
            <div class="cardContainer">
                <div class="cardHeader">
                    <h2>Details</h2>
                </div>
                <div class="cardBody">
                    <div class="cardItem">
                        <div class="editorWrapper">
                            <div class="label">
                                <span>Description</span>
                            </div>
                            <div class="editor">
                                <div class="input-container">
                                    <input
                                        placeholder="Add Description"
                                        type="text"
                                        [(ngModel)]="reportExecution.description"
                                        (ngModelChange)="onDescriptionChange($event)"
                                        [class.error]="!isValid"
                                    />
                                </div>
                                <app-validation-error-message [show]="!isValid" [errors]="fieldValidationErrors"></app-validation-error-message>
                            </div>
                        </div>

                        <div class="editorWrapper" *ngIf="showMultiOrganization">
                            <div class="label">
                                <span>Context</span>
                            </div>

                            <div class="editor">
                                <div class="input-container">
                                    <input
                                        type="checkbox"
                                        id="checkboxEditor"
                                        name="checkboxEditor"
                                        [(ngModel)]="reportExecution.hasMultipleOrganizations"
                                        (ngModelChange)="onCheckboxChange($event)"
                                        [disabled]="disableContextOnEdit"
                                    />
                                    <label for="checkboxEditor">Multi Organization</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <lib-generic-card
            *ngIf="filtersCardConfig.length"
            [title]="'Filters'"
            [factory]="factory"
            [configItems]="filtersCardConfig"
            (valueChange)="emitValueChanged()"
        >
        </lib-generic-card>
    </div>
</div>
