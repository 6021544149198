import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { AddressService } from "../../../services/http/clients/address.service";

@Component({
    selector: "app-static-map",
    templateUrl: "./static-map.component.html",
    styleUrls: ["./static-map.component.scss"]
})
export class StaticMapComponent implements OnChanges {
    staticMap!: string;

    alternativeText!: string;

    @Input()
    latitude?: number;

    @Input()
    longitude?: number;

    @Input()
    zoomLevel?: number;

    @Input()
    width?: number;

    @Input()
    height?: number;

    constructor(private readonly addressService: AddressService) {
        this.staticMap = "";
        this.alternativeText = "";
        this.zoomLevel = 12;
        this.width = 385;
        this.height = 200;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.latitude && this.longitude && changes["latitude"] && changes["longitude"]) {
            this.staticMap = await this.addressService.staticImageContent(this.latitude, this.longitude, this.zoomLevel, this.width, this.height);
            this.alternativeText = `Map with marker at latitude ${this.latitude} and longitude ${this.longitude}`;
        }
    }
}
