<lib-generic-type-ahead-dropdown
    [(selectedItem)]="selectedAddress"
    [placeholder]="'Add address'"
    (selectedItemChange)="onAddressChange($event)"
    [displaySelected]="true"
    [(text)]="searchText"
    [source]="source"
    (textChange)="onAddressTextChanged()"
    [config]="{
        defaultText: 'Add Address',
        itemDisplayKey: 'fullAddress'
    }"
    [isReadOnly]="isReadOnly"
    [error]="error"
>
</lib-generic-type-ahead-dropdown>
