<div class="cardsWrapper">
    <app-popup-card-base-layout
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        (click)="isExpanded = true"
        (keydown)="onKeyDown($event)"
        tabindex="0"
        aria-label="Show card details"
        [highlightColor]="data?.eventType === dueDatetype ? 'blue' : 'yellow'">
        <div class="container">
            <div class="header">
                <lib-badge
                    [text]="data?.eventType === dueDatetype ? 'Due Date' : 'Follow up'"
                    [color]="data?.eventType === dueDatetype ? 'secondary' : 'tertiary'">
                </lib-badge>
                <div class="title">{{ data?.name }}</div>
                <app-user-avatar
                    *ngIf="data?.opportunityUsers?.length && data?.eventType === dueDatetype"
                    [users]="$any(data?.opportunityUsers)"
                    [size]="'small'">
                </app-user-avatar>
                <div *ngIf="data?.contactInfo" class="subtitle">{{ data?.contactInfo }}</div>
            </div>
        </div>
    </app-popup-card-base-layout>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isExpanded"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    (detach)="isExpanded = false"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-custom-backdrop'"
    (backdropClick)="isExpanded = false"
    [cdkConnectedOverlayFlexibleDimensions]="false"
    [cdkConnectedOverlayPush]="true"
    [cdkConnectedOverlayPositions]="[
    {
        originX: 'center',
        originY: 'center',
        overlayX: 'center',
        overlayY: 'center',
    }
    ]"
>
    <app-opportunity-card-expanded
        cdkTrapFocus
        cdkTrapFocusAutoCapture="true"
        [data]="data"
        (closeClicked)="this.isExpanded = false"
        (goToClicked)="onGoToClicked(data?.id)"
    >
    </app-opportunity-card-expanded>
</ng-template>