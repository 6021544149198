import { OpportunityTagView, SearchTagsParameters, TagView } from "projects/app/src/app/services/http/clients/api-proxies";
import { TagEntity } from "./abstract/tag-entity";
import { TagService } from "projects/app/src/app/services/http/clients/tag.service";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";

export class OpportunityTagEntity extends TagEntity<OpportunityTagView> {
    constructor(tagService: TagService) {
        super(tagService);
    }

    toDestination(entity: TagView): OpportunityTagView {
        return { id: 0, tagId: entity.id, entityTypeId: entity.entityTypeId, tagName: entity.name };
    }

    toSource(entity: OpportunityTagView): TagView {
        return { id: entity.tagId, entityTypeId: entity.entityTypeId ?? 0, name: entity.tagName };
    }

    async getSource(): Promise<TagView[]> {
        return await this.tagService.search({
            entityTypeId: EntityTypes.Opportunity
        } as SearchTagsParameters);
    }
}
