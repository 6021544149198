import { Injectable } from "@angular/core";
import {
    IOpportunityDashboardClient,
    OpportunityDashboardClient,
    OpportunityView,
    DashboardComparisonOptionView,
    GetOpportunityStatsByPeriodResultOpportunityTotalsResultDto
} from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class OpportunityDashboardService {
    private client: IOpportunityDashboardClient;
    constructor() {
        this.client = new OpportunityDashboardClient();
    }

    async getNewProjects(): Promise<OpportunityView[]> {
        return await this.client.opportunityDashboardGetNewProjects();
    }

    async getUpcomingBids(): Promise<OpportunityView[]> {
        return await this.client.opportunityDashboardGetUpcomingBids();
    }

    async getComparisonOptions(): Promise<DashboardComparisonOptionView[]> {
        return await this.client.opportunityDashboardGetComparisonOptions();
    }

    async getOpportunityStatsByPeriod(comparisonId: number): Promise<GetOpportunityStatsByPeriodResultOpportunityTotalsResultDto> {
        return await this.client.opportunityDashboardGetOpportunityStatsByPeriod(comparisonId);
    }
}
