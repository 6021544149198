import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { ArchiveEntityOptionView, ReserveEntityOptionView } from "projects/app/src/app/services/http/clients/api-proxies";
import { Condition } from "projects/app/src/app/models/enums/Condition";

export interface ICustomizationFilters {
    condition?: ArchiveEntityOptionView;
    type?: ReserveEntityOptionView;
}

export interface ICustomizationFiltersOptions {
    condition?: ArchiveEntityOptionView[];
    type?: ReserveEntityOptionView[];
}

@Component({
    selector: "app-customization-filters",
    templateUrl: "./customization-filters.component.html",
    styleUrls: ["./customization-filters.component.scss"]
})
export class CustomizationFiltersComponent implements OnChanges {
    @Input()
    customizationFilters: ICustomizationFilters;

    @Input()
    filterOptions?: ICustomizationFiltersOptions;

    @Output()
    customizationFiltersChange: EventEmitter<ICustomizationFilters>;

    constructor() {
        this.customizationFilters = {};
        this.customizationFiltersChange = new EventEmitter<ICustomizationFilters>();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["filterOptions"]?.currentValue) {
            this.customizationFilters = {
                ...(this.filterOptions?.condition?.length && { condition: this.filterOptions.condition.find(c => c.id === Condition.Active) })
            };

            this.customizationFiltersChange.emit(this.customizationFilters);
        }
    }

    async onFilterChanged(): Promise<void> {
        this.customizationFiltersChange.emit(this.customizationFilters);
    }
}
