import { CanActivateFn, Router } from "@angular/router";
import { inject } from "@angular/core";
import { User } from "../models/security/user";
import { BasicUserService, MessageBusService } from "projects/ngx-lib/src/public-api";
import { UserRoles } from "../models/enums/UserRoles";
import { LoginMessage } from "../messages/login.message";
import { Messages } from "../messages/messages";
import { BasicUserOrganizationService } from "projects/ngx-lib/src/lib/services/basic-user-organization.service";
import { UserOrganization } from "../models/security/user-organization";

export function authGuard(role?: UserRoles): CanActivateFn {
    return () => {
        const userService: BasicUserService = inject(BasicUserService);
        const userOrganizationService: BasicUserOrganizationService = inject(BasicUserOrganizationService);
        const messageBusService: MessageBusService = inject(MessageBusService);

        const router: Router = inject(Router);
        const user = userService.getUser<User>();
        const userOrganization = userOrganizationService.getUserOrganization<UserOrganization>();

        if (!user) {
            messageBusService.send<LoginMessage>(Messages.LoggedIn, new LoginMessage(false));
            return router.createUrlTree(["/login"]);
        }

        const roleToCompare = userOrganization ? userOrganization.roleId : user.roleId;

        const isAuthorized = !role || role <= roleToCompare;
        if (!isAuthorized) return router.createUrlTree(["/login"]);

        return isAuthorized;
    };
}
