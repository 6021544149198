import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "ampm"
})
export class AmpmPipe implements PipeTransform {
    transform(value: number): string {
        const period = value < 12 ? "AM" : "PM";
        const hour12 = value % 12 === 0 ? 12 : value % 12;
        return `${hour12} ${period}`;
    }
}
