import { Injectable, Type, ViewContainerRef } from "@angular/core";
import LogService from "./log.service";
import { SnackbarNotificationInstance } from "../classes/snackbar-notification-instance";
import { SnackbarNotificationComponentBase } from "../classes/snackbar-notification-component-base";
import { ISnackbarNotificationParameter } from "../classes/snackbar-notification-parameter.interface";

enum SnackbarNotificationType {
    Info = "info",
    Warning = "warning",
    Success = "success",
    Error = "error"
}

@Injectable({
    providedIn: "root"
})
export class SnackbarNotificationService {
    private snackbarNotificationInstances: SnackbarNotificationInstance[];

    private viewContainer?: ViewContainerRef;

    private defaultSnackbarNotificationComponent?: Type<SnackbarNotificationComponentBase>;

    constructor(private readonly logger: LogService) {
        this.snackbarNotificationInstances = [];
    }

    setViewContainer(container: ViewContainerRef): void {
        this.viewContainer = container;
    }

    setDefaultNotificationComponent(component: Type<SnackbarNotificationComponentBase>): void {
        this.defaultSnackbarNotificationComponent = component;
    }

    async info(message: string): Promise<void> {
        this.logger.info(message);
        if (!this.defaultSnackbarNotificationComponent) return;
        await this.show(this.defaultSnackbarNotificationComponent, {
            type: SnackbarNotificationType.Info as string,
            message
        } as ISnackbarNotificationParameter);
    }

    async warn(message: string): Promise<void> {
        this.logger.warn(message);
        if (!this.defaultSnackbarNotificationComponent) return;
        await this.show(this.defaultSnackbarNotificationComponent, { type: SnackbarNotificationType.Warning, message });
    }

    async success(message: string): Promise<void> {
        this.logger.info(message);
        if (!this.defaultSnackbarNotificationComponent) return;
        await this.show(this.defaultSnackbarNotificationComponent, { type: SnackbarNotificationType.Success, message });
    }

    async error(message: string): Promise<void> {
        this.logger.error(message);
        if (!this.defaultSnackbarNotificationComponent) return;
        await this.show(this.defaultSnackbarNotificationComponent, { type: SnackbarNotificationType.Error, message });
    }

    show(type: Type<SnackbarNotificationComponentBase>, parameters: ISnackbarNotificationParameter): Promise<void> {
        // create Snackbar Notification instance.
        const index = this.snackbarNotificationInstances.length;
        const stackLevel = this.snackbarNotificationInstances.reduce((p, c) => (p < c.stackLevel ? c.stackLevel : p), -1) + 1;
        const snackbarNotificationInstance = new SnackbarNotificationInstance(type, index, stackLevel);
        this.snackbarNotificationInstances.push(snackbarNotificationInstance);

        // setup the Snackbar Notification promise.
        const promise = new Promise<void>((resolve, reject) => {
            snackbarNotificationInstance.resolve = () => {
                resolve();
                this.remove(snackbarNotificationInstance);
            };
            snackbarNotificationInstance.reject = (reason?: string) => {
                reject(reason);
                this.remove(snackbarNotificationInstance);
            };
        });

        if (this.viewContainer) {
            // create the component.
            const componentRef = this.viewContainer.createComponent(type);

            // initialize the Snackbar Notification component
            snackbarNotificationInstance.componentRef = componentRef;
            componentRef.instance.setNotificationService(this);
            componentRef.instance.setNotificationInstance(snackbarNotificationInstance);
            componentRef.instance.setParameters(parameters);

            // insert view on screen.
            this.viewContainer.insert(componentRef.hostView);
        }

        return promise;
    }

    private remove(snackbarNotificationInstance: SnackbarNotificationInstance): void {
        if (!this.viewContainer || !snackbarNotificationInstance?.componentRef) return;
        const index = this.snackbarNotificationInstances.indexOf(snackbarNotificationInstance);
        this.snackbarNotificationInstances.splice(index, 1);
        this.viewContainer.remove(this.viewContainer.indexOf(snackbarNotificationInstance.componentRef.hostView));
    }
}
