import { NoteUserView, NoteView } from "../services/http/clients/api-proxies";
import { ModelBase } from "./ModelBase";

export class Note extends ModelBase implements NoteView {
    title: string | undefined;
    message: string | undefined;
    entityTypeId: number;
    entityId: number;
    creationDate: Date;
    modificationDate: Date | undefined;
    userId: number | undefined;
    userFirstName: string | undefined;
    userLastName: string | undefined;
    userEmail: string | undefined;
    noteUsers: NoteUserView[] | undefined;
    noteKey: string | undefined;    

    constructor() {
        super();
        this.title = "";
        this.message = "";
        this.entityTypeId = 0;
        this.entityId = 0;
        this.creationDate = new Date();
        this.modificationDate = undefined;
        this.userId = undefined;
        this.userFirstName = "";
        this.userLastName = "";
        this.userEmail = "";
        this.noteUsers = undefined;
        this.noteKey = "";
    }

    static fromInterface(dto: NoteView): Note {
        const contact = new Note();
        contact.mapFromInterface(dto);
        return contact;
    }
}
