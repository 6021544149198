import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { icons } from "../../assets/icons";

@Component({
    selector: "lib-no-data",
    templateUrl: "./no-data.component.html",
    styleUrls: ["./no-data.component.scss"]
})
export class NoDataComponent implements OnInit {
    @Input()
    iconName!: keyof typeof icons;

    @Input()
    title: string;

    @Input()
    text: string;

    @Input()
    linkText?: string;

    @Output()
    linkAction: EventEmitter<void>;

    svg?: SafeHtml;

    constructor(private readonly sanitizer: DomSanitizer) {
        this.title = "No data available";
        this.text = "";
        this.linkAction = new EventEmitter<void>();
    }

    ngOnInit(): void {
        this.svg = this.sanitizer.bypassSecurityTrustHtml(icons[this.iconName]);
    }

    onLinkClicked() {
        this.linkAction?.emit();
    }
}
