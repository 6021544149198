import { Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from "@angular/core";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { DivisionView, SearchDivisionsParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { DivisionService } from "projects/app/src/app/services/http/clients/division.service";
import { ObjectExtensionsService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { CustomFieldsItemComponent } from "../../../../shared/custom-fields-item/custom-fields-item.component";
import { ICustomizationFilters, ICustomizationFiltersOptions } from "../customization-filters/customization-filters.component";
import { Condition } from "projects/app/src/app/models/enums/Condition";
import { CustomFieldsFilterType } from "projects/app/src/app/models/enums/CustomFieldsFilterType";

@Component({
    selector: "app-customization-custom-info",
    templateUrl: "./customization-custom-info.component.html",
    styleUrls: ["./customization-custom-info.component.scss"]
})
export class CustomizationCustomInfoComponent implements OnChanges {
    @Input()
    title?: string;

    @Input()
    divisions?: DivisionView[];

    @Output()
    divisionsChange: EventEmitter<DivisionView[]>;

    @Input()
    divisionsInitialState?: DivisionView[];

    @Output()
    divisionsInitialStateChange: EventEmitter<DivisionView[]>;

    @Input()
    filterOptions?: ICustomizationFiltersOptions;

    @Input()
    isModelEqual?: boolean;

    showActions?: boolean;

    filters: ICustomizationFilters;

    searchConfig: SearchDivisionsParameters;

    factory: AppEditorFactory;

    @ViewChildren("customInfoItem")
    customInfoItem?: QueryList<CustomFieldsItemComponent>;

    archiveStatus: Condition;

    reservedFilterType: CustomFieldsFilterType;

    constructor(
        private readonly divisionService: DivisionService,
        private readonly objectExtensionService: ObjectExtensionsService,
        private readonly awaiter: AwaiterService,
        private readonly snackbarNotificationService: SnackbarNotificationService
    ) {
        this.title = "";
        this.searchConfig = {
            isActive: true
        } as SearchDivisionsParameters;
        this.filters = {};
        this.divisions = [];
        this.divisionsChange = new EventEmitter<DivisionView[]>();
        this.divisionsInitialState = [];
        this.divisionsInitialStateChange = new EventEmitter<DivisionView[]>();
        this.factory = new AppEditorFactory();
        this.archiveStatus = Condition.Archived;
        this.reservedFilterType = CustomFieldsFilterType.Reserved;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["isModelEqual"]) {
            this.showActions = this.isModelEqual;
        }
    }

    onAddNewField(): void {
        if (this.filters.condition?.id === Condition.Archived || this.filters.type?.id === CustomFieldsFilterType.Reserved) return;

        this.divisions?.unshift({
            id: 0,
            organizationId: 0,
            isActive: true
        } as DivisionView);
        this.divisionsChange.emit(this.divisions);
    }

    async onToggleCondition(item: DivisionView, index: number, archive: boolean): Promise<void> {
        const action = archive ? "Archiving" : "Restoring";
        const message = archive ? "Division successfully archived." : "Division successfully restored.";
        const serviceAction = archive ? this.divisionService.deactivate(item.id) : this.divisionService.activate(item.id);

        await this.awaiter.awaitAction(`${action} Division`, async () => {
            if (this.divisions && this.divisions[index]) {
                await serviceAction;
                item.isActive = !archive;
                if ((archive && this.filters.condition?.id === Condition.Active) || (!archive && this.filters.condition?.id === Condition.Archived))
                    this.divisions.splice(index, 1);
                this.snackbarNotificationService.success(message);
            }
        });
    }

    onRemove(index: number): void {
        if (this.divisions && this.divisions[index]) {
            if (index > -1) this.divisions.splice(index, 1);
            this.divisionsChange.emit(this.divisions);
        }
    }

    onItemsChange(): void {
        this.divisionsChange.emit(this.divisions);
    }

    async onFiltersChange(): Promise<void> {
        await this.refreshListView();
    }

    private async refreshListView(): Promise<void> {
        this.buildSearchConfig();

        await this.awaiter.awaitAction("Getting Custom Division Info", async () => {
            this.divisions = await this.divisionService.search(this.searchConfig);
            this.divisionsInitialState = this.divisions?.map(item => this.objectExtensionService.clone(item) as DivisionView);
            this.divisionsInitialStateChange.emit(this.divisionsInitialState);
            this.divisionsChange.emit(this.divisions);
        });
    }

    private buildSearchConfig() {
        this.searchConfig = {
            isActive: this.filters.condition?.value,
            isReserved: this.filters.type?.value
        } as SearchDivisionsParameters;
    }
}
