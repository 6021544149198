import { Injectable } from "@angular/core";
import { UserRoleClient, UserRoleView, IUserRoleClient } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class UserRoleService {
    private client: IUserRoleClient;
    constructor() {
        this.client = new UserRoleClient();
    }

    async getAll(): Promise<UserRoleView[]> {
        return await this.client.userRoleGetAll();
    }
}
