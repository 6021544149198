import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseFilterComponent } from "projects/ngx-lib/src/lib/components/filters/base-filter/base-filter.component";
import { IGenericTypeAheadDropdownConfig } from "projects/ngx-lib/src/lib/interfaces/type-ahead-dropdown-config.interface";

export interface ITypeAheadService<T, U> {
    getById(id: number): Promise<T>;
    search(body: U): Promise<T[] | undefined>;
}

@Component({
    selector: "app-generic-type-ahead",
    templateUrl: "./generic-type-ahead.component.html",
    styleUrls: ["./generic-type-ahead.component.scss"]
})
export class GenericTypeAheadComponent<T, U> extends BaseFilterComponent {
    @Input()
    selectedItem?: T;

    @Output()
    selectedItemChange = new EventEmitter<T>();

    @Input()
    selectedItems?: T[];

    @Output()
    selectedItemsChange = new EventEmitter<T[]>();

    @Input()
    filter?: object;

    @Output()
    filterChanged: EventEmitter<void>;

    @Input()
    placeholder?: string;

    @Input()
    service?: ITypeAheadService<T, U>;

    @Input()
    config?: IGenericTypeAheadDropdownConfig<T>;

    @Input()
    isReadOnly: boolean;

    @Input()
    removeKey?: string;

    items?: T[];
    text: string;

    constructor() {
        super();
        this.text = "";
        this.filterChanged = new EventEmitter();
        this.placeholder = "Search";
        this.isReadOnly = false;
    }

    async onTextChanged(): Promise<void> {
        if (this.text) {
            this.items = await this.service?.search({
                filterText: this.text,
                ...this.filter
            } as U);
        } else {
            this.items = [];
        }
    }

    emitFilterChanged(): void {
        this.selectedItemChange.emit(this.selectedItem);
        this.filterChanged.emit();
    }

    emitFiltersChanged(): void {
        this.selectedItemsChange.emit(this.selectedItems);
        this.filterChanged.emit();
    }

    onRemoveItem(item: any) {
        if (this.selectedItems?.length) {
            this.selectedItems = this.selectedItems?.filter((i: any) => i.id !== item.id);
            this.emitFiltersChanged();
        }
    }
}
