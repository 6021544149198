import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root'
})
export class BasicUserService extends UserService {
	constructor(storageService: StorageService) {
		super(storageService);
	}
}
