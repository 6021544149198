import { Injectable } from "@angular/core";
import { NgForm } from "@angular/forms";
import { SnackbarNotificationService } from "projects/ngx-lib/src/lib/services/snackbar-notification.service";
import { BasicUserService } from "projects/ngx-lib/src/lib/services/basic-user.service";
import { UserOrganizationService } from "projects/ngx-lib/src/lib/services/user-organization.service";
import { LoginService } from "./http/clients/login.service";
import { ApiError } from "./http/clients/api-proxies";
import { UserService } from "./http/clients/user.service";
import { AwaiterService } from "./awaiter.service";
import { User } from "../models/security/user";
import { Router } from "@angular/router";
import { MessageBusService } from "projects/ngx-lib/src/lib/services/message-bus.service";
import { Messages } from "../messages/messages";
import { LoginMessage } from "../messages/login.message";
import { UserRoles } from "../models/enums/UserRoles";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    constructor(
        private readonly router: Router,
        private readonly awaiter: AwaiterService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly loginService: LoginService,
        private readonly userClientService: UserService,
        private readonly basicUserService: BasicUserService,
        private readonly userOrganizationService: UserOrganizationService,
        private readonly messageBusService: MessageBusService
    ) {}

    getUserFromStorage(): User | undefined {
        return this.basicUserService.getUser<User>();
    }

    redirectIfAuthenticated(redirectTo = "/dashboard") {
        const user = this.getUserFromStorage();
        if (!user?.token) return;
        this.router.navigate([redirectTo]);
    }

    async login(event: NgForm): Promise<void> {
        await this.awaiter.awaitAction("Logging user", async () => {
            let result;
            try {
                result = await this.loginService.authenticate({
                    email: event.value.email,
                    password: event.value.password,
                    rememberUser: true
                });
            } catch (error) {
                if (error instanceof ApiError) {
                    if (error.status === 403) {
                        this.snackbarNotificationService.error("Email or password Invalid.");
                    } else {
                        this.snackbarNotificationService.error(error.message);
                    }
                } else {
                    this.snackbarNotificationService.error("Error.");
                }
            }

            if (result?.token) {
                if (result.isAuthorized) {
                    this.basicUserService.setUser({ token: result.token });
                    await this.setUserDataOnStorage(result.token);
                    this.messageBusService.send<LoginMessage>(Messages.LoggedIn, new LoginMessage(true));
                    this.router.navigate(["/dashboard"]);
                } else {
                    this.router.navigate(["/access-denied"]);
                }
            }
        });
    }

    async sendPasswordResetEmail(event: NgForm): Promise<void> {
        await this.awaiter.awaitAction("Sending Forgot Password Request", async () => {
            try {
                await this.loginService.sendForgotPasswordRequest({
                    email: event.value.resetEmail
                });
                this.snackbarNotificationService.success("Email successfully sent.");
            } catch (error) {
                if (error instanceof ApiError) {
                    if (error.status === 403) {
                        this.snackbarNotificationService.error("The provided email does not exist");
                    } else {
                        this.snackbarNotificationService.error(error.message);
                    }
                } else {
                    this.snackbarNotificationService.error("Error.");
                }
            }
        });
    }

    hasRestrictedAccess() {
        const userOrganization = this.userOrganizationService.getUserOrganization();
        return !userOrganization?.roleId || userOrganization?.roleId < UserRoles.SalesManager;
    }

    private async setUserDataOnStorage(token: string): Promise<void> {
        await this.awaiter.awaitAction("Getting Authenticated User", async () => {
            try {
                const user = await this.userClientService.getAuthenticatedUser();
                if (user.id && user.userRoleId) {
                    this.basicUserService.setUser(
                        new User(token, user.id, user.firstName ?? "", user.lastName ?? "", user.email ?? "", user.userRoleId, user.userRoleDescription ?? "")
                    );

                    if (user.userOrganizations?.length && user.userOrganizations[0].organizationId && user.userOrganizations[0].userRoleId)
                        this.userOrganizationService.setUserOrganization({
                            id: user.userOrganizations[0].organizationId,
                            roleId: user.userOrganizations[0].userRoleId
                        });
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    if (error.status === 403) {
                        this.basicUserService.removeUser();
                        this.userOrganizationService.removeUserOrganization();
                        this.snackbarNotificationService.error("Forbidden.");
                    } else {
                        this.snackbarNotificationService.error(error.message);
                    }
                } else {
                    this.snackbarNotificationService.error("Error.");
                }
            }
        });
    }
}
