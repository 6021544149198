import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IChartCanvasData, IDashboardChart } from "../chart-base/interfaces";
import { CHART_COLORS, tooltip } from "../chart-base/config";
import ChartDataLabels from "chartjs-plugin-datalabels";

@Component({
    selector: "app-bar-chart",
    templateUrl: "./bar-chart.component.html",
    styleUrls: ["./bar-chart.component.scss"]
})
export class BarChartComponent implements OnChanges {
    canvasData?: IChartCanvasData;

    @Input()
    data?: IDashboardChart;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["data"]) {
            this.buildCanvasData();
        }
    }

    commonConfig = {
        borderWidth: 1,
        borderRadius: 5,
        borderSkipped: false
    };

    currentConfig = {
        backgroundColor: CHART_COLORS.current.primary
    };

    previousConfig = {
        backgroundColor: CHART_COLORS.previous.primary
    };

    dataLabelsConfig = {
        datalabels: {
            anchor: "end",
            align: "end",
            offset: -5
        }
    };

    buildCanvasData(): void {
        if (!this.data) return;

        const datasets: any[] = [];

        if (this.data?.currentData?.length && this.data.previousData?.length) {
            datasets.push({
                data: this.data.currentData,
                ...this.commonConfig,
                ...this.currentConfig,
                ...this.dataLabelsConfig
            });

            datasets.push({
                data: this.data.previousData,
                ...this.commonConfig,
                ...this.previousConfig,
                ...this.dataLabelsConfig
            });
        }

        this.canvasData = {
            id: this.data.id,
            type: "bar",
            labels: this.data.labels,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                        labels: {
                            font: {
                                size: 12
                            }
                        }
                    },
                    datalabels: {
                        font: {
                            weight: "bolder",
                            size: 12,
                            family: "Roboto"
                        },
                        color: (ctx: any) => {
                            const datasetIndex = ctx.datasetIndex;
                            const colorMap = [this.currentConfig.backgroundColor, this.previousConfig.backgroundColor];
                            return colorMap[datasetIndex];
                        },
                        display: (ctx: any) => {
                            // Display data labels only if they are bigger than 0
                            return ctx.dataset.data[ctx.dataIndex] > 0;
                        },
                        formatter: (value: number) => this.formatNumberWithSuffix(value)
                    },
                    tooltip
                },
                scales: {
                    x: {
                        border: {
                            display: false
                        },
                        grid: {
                            display: false
                        },
                        ticks: {
                            font: {
                                size: 12,
                                family: "Roboto"
                            },
                            color: CHART_COLORS.ticks.x
                        }
                    },
                    y: {
                        border: {
                            display: false
                        },
                        grid: {
                            color: CHART_COLORS.gridLines
                        },
                        ticks: {
                            min: 0,
                            precision: 0,
                            color: CHART_COLORS.ticks.y,
                            font: {
                                size: 8
                            },
                            callback: (value: number) => this.formatNumberWithSuffix(value)
                        }
                    }
                }
            },
            datasets,
            plugins: [ChartDataLabels]
        };
    }

    private formatNumberWithSuffix(value: number): string {
        if (value >= 1_000_000) {
            return `${(value / 1_000_000).toFixed(1).replace(/\.0$/, "")} M`;
        } else if (value >= 1000) {
            return `${(value / 1_000).toFixed(1).replace(/\.0$/, "")} k`;
        }
        return value.toString();
    }
}
