export enum OpportunityStatuses {
    Unqualified = 1,
    InProgress,
    InReview,
    Submitted,
    Accepted,
    Canceled,
    OnHold,
    Rejected,
    Ready,
    EstimateRequested
}
