import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { OpportunityEstimateSummaryView, OpportunityEstimateView } from "projects/app/src/app/services/http/clients/api-proxies";
import { EstimateService } from "projects/app/src/app/services/http/clients/estimate.service";

@Component({
    selector: "app-opportunities-single-estimates-summary",
    templateUrl: "./opportunities-single-estimates-summary.component.html",
    styleUrls: ["./opportunities-single-estimates-summary.component.scss"]
})
export class OpportunitiesSingleEstimatesSummaryComponent implements OnChanges {
    finalEstimate?: OpportunityEstimateView;

    baseEstimate?: OpportunityEstimateView[];

    alternateEstimates?: OpportunityEstimateView[];

    estimateSummaries?: OpportunityEstimateSummaryView[];

    @Input()
    estimateId?: number;

    @Output()
    goBackClicked: EventEmitter<boolean>;

    constructor(
        private readonly estimateService: EstimateService,
        private readonly awaiter: AwaiterService
    ) {
        this.baseEstimate = [];
        this.alternateEstimates = [];
        this.estimateId = 0;
        this.goBackClicked = new EventEmitter<boolean>();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes["estimateId"]) {
            await this.awaiter.awaitAction("Loading Final Estimate Overview", async () => {
                if (!this.estimateId) return;
                this.finalEstimate = await this.estimateService.getFinalEstimateOverview(this.estimateId);

                const { alternateEstimateViews, baseEstimateView, opportunityEstimateSummaries } = this.finalEstimate;

                this.baseEstimate = [baseEstimateView];
                this.alternateEstimates = alternateEstimateViews;
                this.estimateSummaries = opportunityEstimateSummaries;
            });
        }
    }

    onRowClicked(opportunityEstimate: OpportunityEstimateView) {
        window.open(opportunityEstimate?.externalUrl, "_blank");
    }

    onGoBackClicked() {
        this.goBackClicked.emit();
    }
}
