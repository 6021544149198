import { Component, OnInit } from "@angular/core";

import { EstimateTypeView } from "projects/app/src/app/services/http/clients/api-proxies";
import { EstimateTypeService } from "projects/app/src/app/services/http/clients/estimate-type.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-estimate-type-editor",
    templateUrl: "./estimate-type-editor.component.html",
    styleUrls: ["./estimate-type-editor.component.scss"]
})
export class EstimateTypeEditorComponent extends EditorComponentBase<number | null> implements OnInit {
    estimateTypes?: EstimateTypeView[];
    selectedEstimateType?: EstimateTypeView;

    constructor(private readonly estimateTypeService: EstimateTypeService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.estimateTypes = await this.estimateTypeService.getAwardableEstimateTypes();
        if (this.bindingProperty) this.selectedEstimateType = this.estimateTypes?.find(x => x.id === this.bindingProperty);
    }

    onEstimateTypeChange() {
        this.bindingProperty = this.selectedEstimateType?.id ?? null;
        this.valueChange.emit();
    }
}
