<div *ngIf="noResults && !isLoading; else showGrid" class="content">
    <lib-no-data
        [iconName]="'empty'"
        [title]="'No change orders available'"
        text="Please check back later or"
        linkText="refresh for updates"
        (linkAction)="refreshPage()">
    </lib-no-data>
</div>

<ng-template #showGrid>
    <div class="container" *ngIf="opportunityChangeOrderEstimates.length >= 0">
        <div class="totals">
            <div class="title">TOTALS</div>
            <div class="info">
                <div class="item">
                    <div class="label">Submitted/On-Hold CO</div>
                    <div class="value">{{ combinedTotals?.submittedOnHoldTotal | currency }}</div>
                </div>
                <div class="item">
                    <div class="label">Rejected/Canceled CO</div>
                    <div class="value">{{ combinedTotals?.rejectedCanceledTotal | currency }}  </div>
                </div>
                <div class="item">
                    <div class="label">Accepted CO</div>
                    <div class="value">{{ combinedTotals?.acceptedTotal | currency }}  </div>
                </div>
                <div class="item">
                    <div class="label">Base Contract</div>
                    <div class="value">{{ combinedTotals?.baseContractTotal| currency }}  </div>
                </div>
                <div class="item">
                    <div class="label">Base Contract + Accepted CO</div>
                    <div class="value">{{ combinedTotals?.baseAcceptedTotal| currency }}  </div>
                </div>
            </div>
        </div>
    </div>

    <div class="content">
        <lib-grid-view
            [gridConfig]="{
                columnsConfig: [
                    { key: 'order', label: '#', sortable: true },
                    { key: 'clientChangeOrderNumber', label: 'Client CO#', sortable: true },
                    { key: 'name', label: 'Name', sortable: true },
                    { key: 'description', label: 'Description', sortable: true },
                    { key: 'creationDate', label: 'Date Created', sortable: true, cellTemplate: creationDateTemplate },
                    { key: 'sellPrice', label: 'Sell Price', sortable: true, cellTemplate: sellPriceTemplate },
                    { key: 'grossMarginPercentage', label: 'Gross Margin', sortable: true, cellTemplate: grossMarginTemplate },
                    { key: 'changeOrderStatusName', label: 'Status', sortable: true, cellTemplate: selectedTemplate }
                ],
            }"
            [source]="opportunityChangeOrderEstimates"
            [(orderConfig)]="orderConfig"
            (orderConfigChange)="onOrderChanged()"
            [(paginationConfig)]="paginationConfig"
            (paginationConfigChange)="refreshGridView()"
            (rowClicked)="onRowClicked($event)"
            [hasExternalLink]="true"
        >

            <ng-template #sellPriceTemplate let-data>
                {{ data.object.sellPrice | currency }}
            </ng-template>

            <ng-template #grossMarginTemplate let-data>
                {{ data.object.grossMarginPercentage | customPercentage }}
            </ng-template>

            <ng-template #creationDateTemplate let-data>
                {{ data.object.creationDate | date: "MM/dd/y hh:mm a" }}
            </ng-template>

            <ng-template #selectedTemplate let-data>
                <lib-badge text="{{ data.object.changeOrderStatusName }}"></lib-badge>
            </ng-template>
        </lib-grid-view>
    </div>
</ng-template>
