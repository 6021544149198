import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from "@angular/core";
import { DateTime } from "luxon";
import { DateTimeService } from "../../../services";
import { DateRanges, IFullViewCalendarEvent } from "../interfaces/full-view-calendar.interface";

type EventsForWeek<T> = {
    [date: string]: {
        [hour: string]: IFullViewCalendarEvent<T>[] | undefined;
    };
};

@Component({
    selector: "lib-week-view-calendar",
    templateUrl: "./week-view-calendar.component.html",
    styleUrls: ["./week-view-calendar.component.scss"]
})
export class WeekViewCalendarComponent<T> implements AfterViewInit, OnChanges {
    @Input()
    events?: IFullViewCalendarEvent<T>[];

    @Input()
    eventTemplate!: TemplateRef<any>;

    @Output()
    dateRangeSelected: EventEmitter<DateRanges>;

    currentWeek?: DateTime[];

    hours: number[];

    eventsForWeek: EventsForWeek<T>;

    selectedDate: DateTime;

    today: DateTime;

    startOfWeek!: DateTime;

    endOfWeek!: DateTime;

    isCurrentWeek: boolean;

    customDateText: string;

    constructor(private readonly dateTimeService: DateTimeService) {
        this.currentWeek = [];
        this.hours = Array.from({ length: 24 }, (_, i) => i);
        this.events = [];
        this.selectedDate = this.dateTimeService.now();
        this.today = this.dateTimeService.now();
        this.isCurrentWeek = false;
        this.eventsForWeek = {};
        this.customDateText = "";
        this.dateRangeSelected = new EventEmitter<DateRanges>();
    }

    ngAfterViewInit(): void {
        this.scrollToCurrentHour();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes["events"].firstChange) {
            this.updateWeek(this.selectedDate);
            this.updateEventsForWeek();
        }
    }

    get dateFrom(): string | null {
        return this.dateTimeService.getStartOfWeek(this.selectedDate).toISO();
    }

    get dateTo(): string | null {
        return this.dateTimeService.getEndOfWeek(this.selectedDate).toISO();
    }

    onDateChange(event: any): void {
        if (!event) {
            this.selectedDate = this.dateTimeService.now();
            this.emitDateRange();
            return;
        }

        this.selectedDate = this.dateTimeService.getDateTimeFromString(event);
        this.emitDateRange();
    }

    isToday(date: DateTime): boolean {
        return this.dateTimeService.isToday(date);
    }

    isWeekend(date: DateTime): boolean {
        return this.dateTimeService.isWeekend(date);
    }

    retreatWeek(): void {
        this.selectedDate = this.selectedDate.minus({ weeks: 1 });
        this.emitDateRange();
    }

    advanceWeek(): void {
        this.selectedDate = this.selectedDate.plus({ weeks: 1 });
        this.emitDateRange();
    }

    goToToday(): void {
        if (this.isToday(this.selectedDate)) return;
        this.selectedDate = this.dateTimeService.now();
        this.emitDateRange();
    }

    updateCustomDateText() {
        this.customDateText = `${this.startOfWeek.toFormat("yyyy")}, ${this.startOfWeek.toFormat("MMMM d")} - ${this.endOfWeek.toFormat("d")}`;
    }

    private updateWeek(date: DateTime): void {
        this.startOfWeek = this.dateTimeService.getStartOfWeek(date);
        this.endOfWeek = this.dateTimeService.getEndOfWeek(date);
        this.currentWeek = Array.from({ length: 7 }, (_, i) => this.startOfWeek?.plus({ days: i }));
        this.isCurrentWeek = this.currentWeek.some(day => day.hasSame(this.today, "day"));
        this.updateCustomDateText();
    }

    private updateEventsForWeek(): void {
        this.eventsForWeek = {};

        this.currentWeek?.forEach(day => {
            const dayKey = day.toISODate() || "";
            this.hours.forEach(hour => {
                const eventsForHour = this.events?.filter(event => {
                    if (event.date?.hasSame(day, "day")) {
                        const eventTime = DateTime.fromFormat(event.startTime ?? "", "HH:mm");
                        return eventTime.hour === hour || (eventTime.hour === hour && eventTime.minute > 0);
                    }
                    return false;
                });

                if (!this.eventsForWeek[dayKey]) {
                    this.eventsForWeek[dayKey] = {};
                }

                this.eventsForWeek[dayKey][hour] = eventsForHour;
            });
        });
    }

    private emitDateRange(): void {
        this.dateRangeSelected.emit({
            dateFrom: this.dateFrom,
            dateTo: this.dateTo
        });
    }

    scrollToCurrentHour() {
        const now = this.dateTimeService.now();
        const hourElement = document.getElementById(`hour-${now.hour}`);

        if (hourElement) {
            hourElement.scrollIntoView({ behavior: "auto", block: "center" });
        }
    }
}
