<div class="filtersContainer">
    <lib-search-input class="filter" [placeholderText]="'Search Report'" [(text)]="filters.searchText" (textChange)="onFilterChanged()"> </lib-search-input>
    <div class="filter">
        <lib-generic-dropdown
            [source]="reportTypes"
            [config]="{
                multiple: false,
                defaultText: 'Report Type',
                itemDisplayKey: 'name'
            }"
            [(selectedItem)]="filters.reportType"
            (selectedItemChange)="onFilterChanged()"
        >
        </lib-generic-dropdown>
    </div>
    <div class="filter">
        <app-generic-type-ahead
            placeholder="Created By"
            [service]="userServiceFilter"
            [config]="{
                defaultText: 'Creator user',
                itemDisplayKey: 'fullName',
                itemTemplate: userTemplate
            }"
            [(selectedItem)]="filters.creatorUser"
            (filterChanged)="onFilterChanged()"
        ></app-generic-type-ahead>
    </div>

    <ng-template #userTemplate let-data>
        <div class="userContainer">
            <p class="userName" [innerHTML]="data.object?.firstName + ' ' + data.object?.lastName | highlightText: data.inputText"></p>
            <p class="userEmail" [innerHTML]="data.object?.email | highlightText: data.inputText"></p>
        </div>
    </ng-template>
</div>
