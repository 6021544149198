import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { EditorComponentBase } from "../../classes/editor-component-base";
import { Overlay, ScrollStrategy } from "@angular/cdk/overlay";
import { IGenericTypeAheadDropdownConfig } from "../../interfaces/type-ahead-dropdown-config.interface";
@Component({
    selector: "lib-generic-dropdown",
    templateUrl: "./generic-dropdown.component.html",
    styleUrls: ["./generic-dropdown.component.scss"]
})
export class GenericDropdownComponent<T> extends EditorComponentBase<T> implements OnInit, OnChanges {
    protected scrollStrategy: ScrollStrategy;

    @Input()
    source?: T[];

    @Input()
    config?: IGenericTypeAheadDropdownConfig<T>;

    @Input()
    disabled: boolean;

    @Input()
    selectedItem?: T;

    @Output()
    selectedItemChange = new EventEmitter<T>();

    @Input()
    override isReadOnly?: boolean;

    @Input()
    noResultsText?: string;

    @Input()
    placeholder?: string;

    @Input()
    error?: boolean;

    menuOpen: boolean;

    displayText: string;

    constructor(
        private readonly elementRef: ElementRef,
        private overlay: Overlay
    ) {
        super();
        this.menuOpen = false;
        this.disabled = false;
        this.noResultsText = "No matches found";
        this.error = false;
        this.displayText = "";
        this.scrollStrategy = this.overlay.scrollStrategies.close();
    }

    ngOnInit(): void {
        this.config = {
            ...this.config,
            clearSelection: this.config?.clearSelection ?? true
        } as IGenericTypeAheadDropdownConfig<T>;
        this.updateDisplayText();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["selectedItem"]?.currentValue) {
            this.selectedItem = changes["selectedItem"]?.currentValue;
            this.updateDisplayText();
        }

        if (changes["source"]?.currentValue && !changes["source"].firstChange) {
            this.source = changes["source"].currentValue;
        }
    }

    selectItem(item: T): void {
        this.selectedItem = item;
        this.emitItemChanged(item);
        this.updateDisplayText();
        this.closePanel();
    }

    selectItemKeydownHandler({ event, item }: { event: KeyboardEvent; item: T }): void {
        if (event.key === "Enter" || event.key === " ") {
            event.preventDefault();
            this.selectItem(item);
        }
    }

    emitItemChanged(item?: T): void {
        this.selectedItemChange.emit(item);
    }

    toggleDropdown(): void {
        if (this.isReadOnly) return;
        this.menuOpen = !this.menuOpen;
    }

    closePanel() {
        this.menuOpen = false;
    }

    private updateDisplayText(): void {
        if (this.selectedItem && this.config && this.selectedItem[this.config.itemDisplayKey] !== "") {
            this.displayText = this.selectedItem[this.config.itemDisplayKey] as unknown as string;
        } else if (!this.selectedItem && this.config && !this.isReadOnly) {
            this.displayText = this.config?.defaultText ?? "Select an item";
        } else if (this.isReadOnly) {
            this.displayText = "";
        }
    }
}
