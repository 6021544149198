import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-cell-favorite",
    templateUrl: "./cell-favorite.component.html",
    styleUrls: ["./cell-favorite.component.scss"]
})
export class CellFavoriteComponent {
    @Input()
    favorite: boolean;

    @Input()
    isHeader: boolean;

    @Output()
    favoriteChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {
        this.favorite = false;
        this.isHeader = false;
    }

    toggleFav(event: Event) {
        if (this.isHeader) return;
        event.stopPropagation();
        this.favoriteChange.emit(!this.favorite);
    }
}
