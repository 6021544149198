export class ModelBase {
    id: number;

    constructor() {
        this.id = 0;
    }

    isNew(): boolean {
        return this.id === 0;
    }

    protected mapFromInterface(dto: object): void {
        const keys = Object.keys(dto);

        for (const key of keys) {
            if (Object.prototype.hasOwnProperty.call(this, key)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (this as any)[key] = (dto as any)[key];
            }
        }
    }
}
