import { Injectable } from "@angular/core";
import { ConfigurationClient, IConfigurationClient, MapClientConfiguration } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class ConfigurationService {
    private client: IConfigurationClient;
    constructor() {
        this.client = new ConfigurationClient();
    }

    async getMapConfiguration(): Promise<MapClientConfiguration> {
        return await this.client.configurationGetMapConfiguration();
    }
}
