import { Injectable } from "@angular/core";
import { INotificationTypeUserConfigurationClient, NotificationTypeUserConfigurationClient, NotificationTypeUserConfigurationView } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class NotificationTypeUserConfigurationService {
    private client: INotificationTypeUserConfigurationClient;
    constructor() {
        this.client = new NotificationTypeUserConfigurationClient();
    }

    async save(body: NotificationTypeUserConfigurationView[]): Promise<NotificationTypeUserConfigurationView[]> {
        return await this.client.notificationTypeUserConfigurationSave(body);
    }

    async getUserConfigurations(): Promise<NotificationTypeUserConfigurationView[]> {
        return await this.client.notificationTypeUserConfigurationGetForCurrentUser();
    }
}
