import { Injectable } from "@angular/core";
import { UserOrganizationService } from "./user-organization.service";
import { StorageService } from "./storage.service";

@Injectable({
    providedIn: "root"
})
export class BasicUserOrganizationService extends UserOrganizationService {
    constructor(storageService: StorageService) {
        super(storageService);
    }
}
