<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="container">
	<ng-container *ngIf="!filterActive">
		<div tabindex="0" (click)="activateFilter($event)" class="selectedItem" [ngClass]="{ itemDisabled: disabled || isReadOnly }">
			<ng-container *ngIf="selectedItem && displaySelected; else displayPlaceholderTemplate">
				<ng-container *ngIf="config?.itemTemplate">
					<ng-container
						*ngTemplateOutlet="
							getTemplate();
							context: {
								$implicit: { object: selectedItem, config }
							}
						">
					</ng-container>
				</ng-container>
				<div *ngIf="!config?.itemTemplate && config && config.itemDisplayKey" class="select">
					<span>{{ selectedItem[config.itemDisplayKey] }}</span>
				</div>

				<button
					*ngIf="selectedItem && !isReadOnly"
					class="clear-filter"
					(click)="clearFilter()"
					(keydown)="onKeyDownClearFilter($event)"
					[innerHTML]="closeIcon"
					tabindex="0"
					aria-label="Clear selection"></button>
			</ng-container>

			<ng-template #displayPlaceholderTemplate>
				<button class="select placeholder" (click)="focusInput()" [ngClass]="{ show: opened }">{{ displayPlaceholder }}</button>
			</ng-template>
		</div>
	</ng-container>
	<lib-search-input
		#searchInput
		*ngIf="filterActive"
		class="select"
		(focus)="showItems()"
		[ngClass]="{ show: opened }"
		tabindex="0"
		[placeholderText]="displayPlaceholder"
		[(text)]="text"
		(textChange)="onTextChanged()">
	</lib-search-input>
	<ul class="itemsContainer" [ngClass]="{ show: opened }">
		<ng-container *ngIf="source && config">
			<li *ngFor="let item of source" (click)="selectItem(item)" (keydown)="onKeyDownSelectItem($event, item)" class="item" tabindex="0">
				<div *ngIf="!config.itemTemplate; else customBlock">
					<span>{{ item[config.itemDisplayKey] }}</span>
				</div>
				<ng-template #customBlock>
						<ng-container
							*ngTemplateOutlet="
								getTemplate();
								context: {
									$implicit: { object: item, config, inputText: text }
								}
							">
						</ng-container>
				</ng-template>
			</li>
		</ng-container>
		<ng-container *ngIf="!source?.length">
			<div class="noResultsText">
				<span>{{ noResultsText }}</span>
			</div>
		</ng-container>
	</ul>
</div>
