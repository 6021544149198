import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { icons } from "../../assets/icons";

@Component({
    selector: "lib-helper-info",
    templateUrl: "./helper-info.component.html",
    styleUrls: ["./helper-info.component.scss"]
})
export class HelperInfoComponent implements OnInit {
    readonly icons = icons;

    @Input()
    message: string;

    svg?: SafeHtml;

    constructor(private readonly sanitizer: DomSanitizer) {
        this.message = "";
    }

    ngOnInit(): void {
        this.svg = this.sanitizer.bypassSecurityTrustHtml(icons.info);
    }
}
