import { ValidatorType } from "../enums/validator-type";
import { Injectable } from "@angular/core";
import { IValidator } from "../interfaces/validator.interface";
import { StringValidator } from "../classes/validators/string-validator";
import { EmailValidator } from "../classes/validators/email-validator";
import { RequiredValidator } from "../classes/validators/required-validator";
import { NumberValidator } from "../classes/validators/number-validator";

@Injectable({
    providedIn: "root"
})
export class ValidatorFactory {
    createValidator(validatorId: string): IValidator<unknown, unknown> {
        switch (validatorId) {
            case ValidatorType.string:
                return new StringValidator();

            case ValidatorType.number:
                return new NumberValidator();

            case ValidatorType.required:
                return new RequiredValidator();

            case ValidatorType.email:
                return new EmailValidator();

            default:
                throw new Error("Validator id not recognized as a valid validator");
        }
    }
}
