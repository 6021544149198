/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from "@angular/core";

import { AddressView, BuildingView } from "projects/app/src/app/services/http/clients/api-proxies";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-address-editor",
    templateUrl: "./address-editor.component.html",
    styleUrls: ["./address-editor.component.scss"]
})
export class AddressEditorComponent extends EditorComponentBase<string> implements OnInit {
    @Input()
    selectedAddress?: AddressView;

    showStaticImageMap?: boolean;

    constructor() {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.showStaticImageMap = !!this.additionalInfo?.staticImageMap;
        if (this.bindingProperty) {
            this.mapBuildingViewToAddressView();
        }
    }

    onSelectedAddress(address: AddressView) {
        this.selectedAddress = address;
        this.bindingProperty = this.selectedAddress?.addressLines ?? "";
        this.mapAddressViewToBuildingView();
        this.validate();
    }

    mapBuildingViewToAddressView() {
        this.selectedAddress = {
            id: (this.bindContext as BuildingView).addressId,
            line1: (this.bindContext as BuildingView).line1,
            line2: (this.bindContext as BuildingView).line2,
            addressLines: (this.bindContext as BuildingView).addressLines,
            zipCode: (this.bindContext as BuildingView).zipCode,
            latitude: (this.bindContext as BuildingView).latitude,
            longitude: (this.bindContext as BuildingView).longitude,
            countryId: (this.bindContext as BuildingView).countryId ?? 0,
            countryName: (this.bindContext as BuildingView).countryName,
            countryCode: (this.bindContext as BuildingView).countryCode,
            stateId: (this.bindContext as BuildingView).stateId ?? 0,
            stateName: (this.bindContext as BuildingView).stateName,
            stateCode: (this.bindContext as BuildingView).stateCode,
            cityId: (this.bindContext as BuildingView).cityId ?? 0,
            cityName: (this.bindContext as BuildingView).cityName,
            fullAddress: (this.bindContext as BuildingView).fullAddress
        };
    }

    mapAddressViewToBuildingView() {
        if (this.selectedAddress && this.bindContext) {
            for (const key in this.selectedAddress) {
                if (key in (this.bindContext as BuildingView) && key !== "id") {
                    (this.bindContext as any)[key] = (this.selectedAddress as any)[key];
                }
            }

            (this.bindContext as BuildingView).addressId = this.selectedAddress.id;
        }
    }
}
