import { Component, Input } from '@angular/core';

export interface IGridAction {
	text: string;
	callback: () => void;
	inverted: boolean;
}

@Component({
	selector: 'lib-grid-actions',
	templateUrl: './grid-actions.component.html',
	styleUrls: ['./grid-actions.component.scss']
})
export class GridActionsComponent {
	@Input()
	actions: IGridAction[] = [];

	buttonClicked(action: IGridAction) {
		action.callback();
	}
}
