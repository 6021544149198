<lib-section-header
    [title]="pageTitle"
    [subTitle]="pageSubtitle"
    [iconName]="'goBack'"
    [goBackAction]="'custom'"
    (goBackActionChange)="onGoBack()"
    [actionsTemplate]="actionsTemplate"
>
    <ng-template #actionsTemplate>
        <div class="actions">
            <button class="primary-btn" [disabled]="isLoading" *ngIf="!reportExecutionId && userHasReportFullAccess" (click)="onCreateReportExecution()">Create</button>
            <div class="editMode" *ngIf="reportExecutionId">
                <lib-helper-info *ngIf="userHasReportFullAccess && filtersHasChanged" message="Unsaved changes"></lib-helper-info>
                <button class="secondary-btn" *ngIf="userHasReportFullAccess && filtersHasChanged" (click)="onUpdateFilters()">Update template</button>
                <button class="primary-btn" (click)="onRunReport()">Run Report</button>
            </div>
        </div>
    </ng-template>
</lib-section-header>

<div class="container">
    <ng-container *ngIf="reportExecutionId; else newReportExecution">
        <!-- Update Report Execution -->
        <app-reports-executions-filters
            [(reportExecution)]="reportExecution"
            (reportExecutionChange)="compareFilters($event)"
            [showMultiOrganization]="allowToSetMultiOrganization"
        ></app-reports-executions-filters>
    </ng-container>
</div>

<ng-template #newReportExecution>
    <div *ngIf="reportTypes.length">
        <lib-tab-view
            [direction]="'column'"
            [separation]="'narrow'"
            [containerBackground]="true"
            [(currentTab)]="currentReportType"
            (currentTabChange)="onReportTypeChange($event)"
        >
            <ng-container *ngFor="let report of reportTypes; let i = index">
                <lib-tab-item [tabName]="report.name" [headerTemplate]="reportType" [bodyTemplate]="reportFilterContent">
                    <ng-template #reportType>
                        <div class="tabItem">
                            <span class="title">{{ report.name }}</span>
                            <span class="subtitle">{{ report.description }}</span>
                        </div>
                    </ng-template>

                    <ng-template #reportFilterContent>
                        <app-reports-executions-filters
                            #reportExecutionFilters
                            [reportType]="reportTypes[i]"
                            [(reportExecution)]="reportExecution"
                            [showMultiOrganization]="allowToSetMultiOrganization"
                        ></app-reports-executions-filters>
                    </ng-template>
                </lib-tab-item>
            </ng-container>
        </lib-tab-view>
    </div>
</ng-template>
