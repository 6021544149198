import { Component } from "@angular/core";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-toggle-editor",
    templateUrl: "./toggle-editor.component.html",
    styleUrls: ["./toggle-editor.component.scss"]
})
export class ToggleEditorComponent extends EditorComponentBase<boolean> {
    constructor() {
        super();
    }

    dataChanged(value: boolean): void {
        this.bindingProperty = value;
        this.valueChange.emit();
    }
}
