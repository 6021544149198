import { Injectable } from "@angular/core";
import { BuildingContactRoleClient, BuildingContactRoleView, IBuildingContactRoleClient } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class BuildingContactRoleService {
    private client: IBuildingContactRoleClient;
    constructor() {
        this.client = new BuildingContactRoleClient();
    }

    async getById(id: number): Promise<BuildingContactRoleView> {
        return await this.client.buildingContactRoleGetById(id);
    }

    async getByUsage(entityTypeId: number, filterText: string | undefined): Promise<BuildingContactRoleView[]> {
        return await this.client.buildingContactRoleGetByUsage(entityTypeId, filterText);
    }
}
