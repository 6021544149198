<div class="container">
    <div class="header">
        <div class="title">{{ title }}</div>
        <div class="actions">
            <button *ngIf="!(filters.condition?.id === archiveStatus || filters.type?.id === reservedFilterType)" class="secondary-btn" (click)="onAddNewField()">+ New field</button>
        </div>
    </div>

    <app-customization-filters
        [(customizationFilters)]="filters"
        [filterOptions]="filterOptions"
        (customizationFiltersChange)="onFiltersChange()"
    ></app-customization-filters>

    <ng-container *ngIf="customFields?.length === 0; else showContent">
        <lib-no-data [iconName]="'empty'" [title]="'You don\'t have custom fields.'" [text]="'Add a custom field to use as a variable.'"></lib-no-data>
    </ng-container>

    <ng-template #showContent>
        <div class="list">
            <div *ngFor="let item of customFields; let i = index">
                <app-custom-fields-item
                    #customFieldsItem
                    libDragAndDrop
                    (valueChange)="onItemsChange()"
                    (toggleCondition)="onToggleCondition(item, i, item.isActive)"
                    [items]="customFields"
                    (itemsChange)="onItemsChange()"
                    [index]="i"
                    [showActions]="item.id !== 0 && showActions"
                    [isReserved]="item.isReserved"
                    [isActive]="item.isActive"
                    [factory]="factory"
                    [configItems]="[
                        {
                            label: 'Field Name',
                            componentId: item.editor ? item.editor : '',
                            fieldName: 'name',
                            bindContext: item,
                            isReadOnly: item.isReserved,
                            validations: [{ type: 'required' }, { type: 'string', args: { maxLength: 30 } }]
                        },
                        {
                            label: 'Description',
                            componentId: item.editor ? item.editor : '',
                            fieldName: 'description',
                            bindContext: item,
                            isReadOnly: item.isReserved,
                            validations: [{ type: 'string', args: { maxLength: 200 } }]
                        }
                    ]"
                >
                </app-custom-fields-item>
            </div>
        </div>
    </ng-template>
</div>
