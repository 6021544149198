<lib-generic-type-ahead-dropdown
    [(selectedItem)]="selectedCompany"
    placeholder="Add Company"
    (selectedItemChange)="onCompanyChange($event)"
    [(text)]="searchText"
    [source]="source"
    (textChange)="onCompanyTextChanged()"
    [config]="{
        defaultText: 'Add Company',
        itemDisplayKey: 'name'
    }"
    [isReadOnly]="isReadOnly"
    [action]="addCompanyAction"
>
</lib-generic-type-ahead-dropdown>
