import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { BaseFilterComponent } from "../filters/base-filter/base-filter.component";
import { ObjectExtensionsService } from "../../services";
import { CdkOverlayOrigin, Overlay, ScrollStrategy } from "@angular/cdk/overlay";
import { IGenericTypeAheadDropdownConfig, ITypeAheadDropdownAction } from "../../interfaces/type-ahead-dropdown-config.interface";
import { SearchInputComponent } from "../search-input/search-input.component";

@Component({
    selector: "lib-generic-type-ahead-multiselect",
    templateUrl: "./generic-type-ahead-multiselect.component.html",
    styleUrls: ["./generic-type-ahead-multiselect.component.scss"]
})
export class GenericTypeAheadMultiselectComponent<T> extends BaseFilterComponent implements OnInit, OnChanges {
    @ViewChild(CdkOverlayOrigin, { static: true }) overlayOrigin!: CdkOverlayOrigin;

    @ViewChild("searchInput")
    searchInput?: SearchInputComponent;

    private _originalItems?: T[];

    protected scrollStrategy: ScrollStrategy;

    @Input()
    source?: T[];

    @Input()
    config?: IGenericTypeAheadDropdownConfig<T>;

    @Input()
    disabled: boolean;

    @Input()
    selectedItems?: T[];

    @Input()
    text = "";

    @Input()
    isReadOnly?: boolean;

    @Input()
    displaySelected?: boolean;

    @Input()
    placeholder?: string;

    @Input()
    noResultsText?: string;

    @Input()
    name?: string;

    @Input()
    key?: keyof T;

    @Input()
    removeKey?: string;

    @Input()
    action?: ITypeAheadDropdownAction;

    @Output()
    textChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    itemClicked: EventEmitter<T> = new EventEmitter<T>();

    @Output()
    selectedItemsChange = new EventEmitter<T[]>();

    @Output()
    removeItemChange = new EventEmitter<T>();

    @Input()
    error?: boolean;

    override filterChanged: EventEmitter<void>;

    opened: boolean;

    filterActive: boolean;

    sourceItems: T[] | undefined;

    focusTrapEnabled: boolean;

    get displayPlaceholder(): string | undefined {
        return this.placeholder ?? "Select an item";
    }

    constructor(
        private readonly objectExtensionsService: ObjectExtensionsService,
        private readonly overlay: Overlay
    ) {
        super();
        this.disabled = false;
        this.text = "";
        this.displaySelected = true;
        this.noResultsText = "No matches found";
        this.opened = false;
        this.filterActive = false;
        this.filterChanged = new EventEmitter();
        this.sourceItems = [];
        this.scrollStrategy = this.overlay.scrollStrategies.close();
        this.focusTrapEnabled = false;
    }

    ngOnInit(): void {
        this.config = {
            ...this.config,
            multiple: this.config?.multiple ?? true
        } as IGenericTypeAheadDropdownConfig<T>;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["source"]?.currentValue && !changes["source"].firstChange) {
            this.updateList();
        }

        if (changes["selectedItems"]?.currentValue && !changes["selectedItems"].firstChange) {
            this.selectedItems = this.selectedItems ? [...this.selectedItems] : undefined;
        }
    }

    updateList(): void {
        if (!this.source) return;

        this.sourceItems = this.source.length > 0 ? this.removeSelectedItems(this.source) : [];
    }

    selectItem(item: T): void {
        if (!this.selectedItems) this.selectedItems = [];

        if (!this.isItemDuplicated(item, this.selectedItems)) {
            this.selectedItems.push(item);
            this.updateList();
            this.focusInput();
            this.getItemsAndEmitChanges();
        }
    }

    getTemplate(): TemplateRef<unknown> | null {
        if (!this.config?.itemTemplate) return null;
        return this.config.itemTemplate;
    }

    openOptionsMenu(event: Event): void {
        if (this.isReadOnly) return;
        event.stopPropagation();
        this.filterActive = true;
        this.focusInput();
        if (this.text.length) this.opened = true;
    }

    optionsMenuKeyDown(event: KeyboardEvent): void {
        if (event.key === "Enter") {
            this.openOptionsMenu(event);
        }
    }

    async onTextChanged(text: string): Promise<void> {
        if (text && typeof text === "string") {
            this.filterActive = true;
            this.textChange.emit(text);
        } else {
            this.sourceItems = [];
        }
    }

    clearFilter() {
        this.selectedItems = undefined;
        this.filterActive = false;
        this.emitItemsChanged([]);
    }

    emitItemsChanged(items: T[]): void {
        this.selectedItemsChange.emit(items);
    }

    removeItem(item: T) {
        const objectIndex = this.selectedItems?.indexOf(item);
        if (objectIndex != undefined && objectIndex >= 0) this.selectedItems?.splice(objectIndex, 1);

        this.sourceItems = this.removeSelectedItems(this.source);
        this.getItemsAndEmitChanges(true);
        this.removeItemChange.emit(item);
    }

    onRunAction() {
        this.filterActive = false;
    }

    focusInput() {
        if (this.filterActive) {
            this.focusTrapEnabled = false;
            setTimeout(() => {
                this.searchInput?.inputElement?.nativeElement.focus();
            }, 0);
        }
    }

    closePanel() {
        this.filterActive = false;
        this.text = "";
    }

    onKeyDown(event: KeyboardEvent): void {
        if (event.key === "Tab" && this.filterActive) {
            this.focusTrapEnabled = true;
        }
    }

    selectItemKeydownHandler({ event, item }: { event: KeyboardEvent; item: T }): void {
        if (event.key === "Enter" || event.key === " ") {
            event.preventDefault();
            this.selectItem(item);
        }
    }

    private isItemDuplicated(item: T, selectedItems: T[]): boolean {
        return selectedItems.some(selectedItem => this.objectExtensionsService.isEqualTo(selectedItem as object, item as object));
    }

    private removeSelectedItems(source: T[] | undefined): T[] | undefined {
        if (!source) return;

        if (this.selectedItems?.length) {
            const sourceItems = source.filter(
                sourceItem =>
                    !this.selectedItems?.some(selectedItem => (selectedItem as any)[this.removeKey ?? "id"] === (sourceItem as any)[this.removeKey ?? "id"])
            );

            return sourceItems;
        } else {
            return source;
        }
    }

    private getItemsAndEmitChanges(isRemove?: boolean): void {
        const items = this.selectedItems ? [...this.selectedItems] : undefined;
        if (this.opened) this._originalItems = this.objectExtensionsService.clone(items) as T[] | undefined;
        if (!this.opened && items) {
            if (this.objectExtensionsService.isEqualTo(items, this._originalItems)) return;
            if (!isRemove) this.emitItemsChanged(items);
        }
    }

    private syncWidth(): void {
        if (!this.overlayOrigin.elementRef) {
            return;
        }
        this.overlayOrigin.elementRef.nativeElement.getBoundingClientRect().width;
    }

    @HostListener("window:resize")
    public onWinResize(): void {
        this.syncWidth();
    }
}
