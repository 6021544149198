<section>
    <button class="resetStyles organizationSelected" (click)="openSwitcher()">
        <div *ngIf="!selectedOrganization" class="wrapper">
            <span>{{ placeholder }}</span>
        </div>
        <div *ngIf="selectedOrganization" class="wrapper">
            <app-organization-item [organization]="selectedOrganization"></app-organization-item>
        </div>
    </button>
    <div libFocusTrap class="panelWrapper" *ngIf="opened" [ngClass]="{ opened: opened }">
        <div class="panelContent">
            <div class="panelHeader">
                <h2>Select Organization</h2>
            </div>
            <div class="panelBody">
                <ul class="organizationItems" *ngFor="let organization of organizations">
                    <li>
                        <button
                            class="organizationItem"
                            [ngClass]="{ selected: organization === selectedOrganization }"
                            (click)="selectItem(organization)"
                            (keyup)="keyup($event, organization)"
                            tabindex="0"
                        >
                            <app-organization-item [organization]="organization"></app-organization-item>
                        </button>
                    </li>
                </ul>

            </div>
            <div class="panelFooter">
                <button class="primary-btn" (click)="applyChanges()">Apply</button>
            </div>
        </div>
    </div>
</section>
