import { Component, ElementRef, EventEmitter, OnChanges, Output, SimpleChanges } from "@angular/core";
import { BaseFilterComponent } from "../base-filter/base-filter.component";
import { DropdownRangeValuesComponent } from "../../dropdown-range-values/dropdown-range-values.component";
import { DomSanitizer } from "@angular/platform-browser";
import { Overlay } from "@angular/cdk/overlay";

@Component({
    selector: "lib-filter-dropdown-range-values",
    templateUrl: "../../dropdown-range-values/dropdown-range-values.component.html",
    styleUrls: ["../../dropdown-range-values/dropdown-range-values.component.scss"]
})
export class FilterDropdownRangeValuesComponent extends DropdownRangeValuesComponent implements BaseFilterComponent, OnChanges {
    @Output()
    filterChanged: EventEmitter<void>;

    constructor(elementRef: ElementRef, sanitizer: DomSanitizer, overlay: Overlay) {
        super(elementRef, sanitizer, overlay);
        this.filterChanged = new EventEmitter<void>();
    }

    override ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
    }

    override emitValueFromChange(valueFrom: number) {
        this.valueFromChange.emit(valueFrom);
        this.filterChanged.emit();
    }

    override emitValueToChange(valueTo: number) {
        this.valueToChange.emit(valueTo);
        this.filterChanged.emit();
    }
}
