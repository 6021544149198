import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "app-configuration",
    templateUrl: "./configuration.component.html",
    styleUrls: ["./configuration.component.scss"]
})
export class ConfigurationComponent implements OnInit {
    constructor(private readonly router: Router) {}

    ngOnInit(): void {
        this.router.navigate(["configuration/customization"]);
    }
}
