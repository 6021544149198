import { Component, OnInit } from "@angular/core";
import { LeadSourceView } from "projects/app/src/app/services/http/clients/api-proxies";
import { LeadSourceService } from "projects/app/src/app/services/http/clients/lead-source.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-lead-source-editor",
    templateUrl: "./lead-source-editor.component.html",
    styleUrls: ["./lead-source-editor.component.scss"]
})
export class LeadSourceEditorComponent extends EditorComponentBase<number | null> implements OnInit {
    leadSourceItems?: LeadSourceView[];
    selectedLeadSource?: LeadSourceView;

    constructor(private readonly leadSourceService: LeadSourceService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.leadSourceItems = await this.leadSourceService.getAll();
        if (this.bindingProperty) this.selectedLeadSource = this.leadSourceItems?.find(x => x.id === this.bindingProperty);
    }

    onLeadSourceChange() {
        if (this.selectedLeadSource?.id) {
            this.bindingProperty = this.selectedLeadSource.id;
        } else {
            this.bindingProperty = null;
        }
        this.valueChange.emit();
    }
}
