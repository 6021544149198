<div class="cardsContainer">
    <lib-generic-card
        [title]="'Main Info'"
        [highlight]="true"
        [factory]="factory"
        [configItems]="mainInfoCardConfig"
        #mainInfoCard>
    </lib-generic-card>

    <lib-generic-card
        [title]="'Company Info'"
        [factory]="factory"
        [configItems]="companyInfoCard">
    </lib-generic-card>

    <lib-generic-card
        *ngIf="customFieldsConfig && customFieldsConfig.length"
        [title]="'Custom Info'"
        [factory]="factory"
        [configItems]="customFieldsConfig">
    </lib-generic-card>
</div>
