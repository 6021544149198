import { Injectable } from "@angular/core";
import { IOrganizationClient, OrganizationClient, OrganizationView } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class OrganizationService {
    private client: IOrganizationClient;
    constructor() {
        this.client = new OrganizationClient();
    }

    async getById(id: number): Promise<OrganizationView> {
        return await this.client.organizationGetById(id);
    }
}
