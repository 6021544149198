import { Component, OnInit } from "@angular/core";
import { BuildingContactRoleView } from "projects/app/src/app/services/http/clients/api-proxies";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { BuildingContactRoleService } from "projects/app/src/app/services/http/clients/building-contact-role.service";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";

@Component({
    selector: "app-building-contact-role-editor",
    templateUrl: "./building-contact-role-editor.component.html",
    styleUrls: ["./building-contact-role-editor.component.scss"]
})
export class BuildingContactRoleEditorComponent extends EditorComponentBase<number | null> implements OnInit {
    buildingContactRoles?: BuildingContactRoleView[];
    selectedRole?: BuildingContactRoleView;

    constructor(private readonly buildingContactRoleService: BuildingContactRoleService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.buildingContactRoles = await this.buildingContactRoleService.getByUsage(EntityTypes.Contact, undefined);
        if (this.bindingProperty) this.selectedRole = this.buildingContactRoles?.find(x => x.id === this.bindingProperty);
    }

    onBuildingContactRoleChange() {
        if (this.selectedRole?.id) {
            this.bindingProperty = this.selectedRole.id;
        } else {
            this.bindingProperty = null;
        }
    }
}
