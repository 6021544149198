<div class="container">
    <lib-section-header
        title="{{ company?.name }}"
        subTitle="Companies"
        [actionsTemplate]="actionsTemplate"
        iconName="goBack"
        [mainAdditionalTemplate]="statusTemplate"
    >
        <ng-template #statusTemplate>
            <lib-status-indicator *ngIf="!isActive" [iconName]="'archived'" [colorType]="'error'" [text]="'Archived'"></lib-status-indicator>
        </ng-template>

        <ng-template #actionsTemplate>
            <div class="actions">
                <button *ngIf="currentTab === 'Overview'" class="primary-btn" (click)="onEditCompany()">Edit</button>
                <app-kebab-menu>
                    <button role="menuitem" *ngIf="isActive" class="resetStyles" (click)="archiveCompany()">Archive</button>
                    <button role="menuitem" *ngIf="!isActive" class="resetStyles" (click)="restoreCompany()">Restore</button>
                </app-kebab-menu>
            </div>
        </ng-template>
    </lib-section-header>

    <div class="tabGroup">
        <lib-tab-view [(currentTab)]="currentTab" alignment="start" separation="wide">
            <lib-tab-item tabName="Overview" [bodyTemplate]="overviewTab">
                <ng-template #overviewTab>
                    <div class="tab">
                        <app-companies-single-overview [company]="company" [isReadOnly]="true"></app-companies-single-overview>
                    </div>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Offices" [bodyTemplate]="officesTab">
                <ng-template #officesTab>
                    <div class="tab">
                        <app-companies-single-offices [companyId]="company?.id" [mainOfficeId]="company?.mainOfficeId"></app-companies-single-offices>
                    </div>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Contacts" [bodyTemplate]="contactsTab">
                <ng-template #contactsTab>
                    <div class="tab">
                        <app-companies-single-contacts [companyId]="company?.id" [mainContactId]="company?.contactId"></app-companies-single-contacts>
                    </div>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Opportunities" [bodyTemplate]="opportunitiesTab">
                <ng-template #opportunitiesTab>
                    <div class="tab">
                        <app-opportunities-grid-single [companyId]="company?.id"></app-opportunities-grid-single>
                    </div>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Notes" [bodyTemplate]="notesTab">
                <ng-template #notesTab>
                    <div class="tab">
                        <app-notes [entityTypeId]="entityTypeId" [entityId]="company?.id"></app-notes>
                    </div>
                </ng-template>
            </lib-tab-item>
        </lib-tab-view>
    </div>
</div>
