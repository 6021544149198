<div class="input-container">
    <input
        [placeholder]="isReadOnly && !bindingProperty || !label ? '' : 'Add ' + label"
        type="number"
        [(ngModel)]="bindingProperty"
        (ngModelChange)="dataChanged()"
        (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
        [class.error]="!isValid"
        [readOnly]="isReadOnly"
    />
    <app-validation-error-message [show]="!isValid" [errors]="validationErrors"></app-validation-error-message>
</div>
