import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
    selector: "app-loading-spinner",
    templateUrl: "./loading-spinner.component.html",
    styleUrls: ["./loading-spinner.component.scss"]
})
export class LoadingSpinnerComponent implements OnChanges {
    @Input()
    placeholder?: string;

    label: string;

    constructor() {
        this.label = "Loading";
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["placeholder"]) {
            this.label = changes["placeholder"].currentValue;
        }
    }
}
