import { Component } from "@angular/core";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-number-input-editor",
    templateUrl: "./number-input-editor.component.html",
    styleUrls: ["./number-input-editor.component.scss"]
})
export class NumberInputEditorComponent extends EditorComponentBase<number> {
    constructor() {
        super();
    }

    dataChanged() {
        this.valueChange.emit();
        this.validate();
    }
}
