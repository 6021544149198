<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="container" [ngClass]="{ readOnly: isReadOnly, error: error }" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <div
        tabindex="0"
        class="selectedItem"
        [ngClass]="{ itemDisabled: disabled || isReadOnly }"
        *ngIf="selectedItems?.length"
        (click)="openOptionsMenu($event)"
        (keydown)="optionsMenuKeyDown($event)"
    >
        <ng-container *ngIf="displaySelected">
            <div *ngIf="!config?.selectedItemTemplate && config?.itemDisplayKey" class="select">
                <div class="item" *ngFor="let item of selectedItems">
                    <lib-badge [item]="item" [key]="config?.itemDisplayKey" [removeButton]="!isReadOnly" (removeItem)="removeItem($event)"></lib-badge>
                </div>
            </div>
            <div *ngIf="config?.selectedItemTemplate" class="selectTemplate">
                <div *ngFor="let item of selectedItems">
                    <ng-container *ngIf="config?.selectedItemTemplate">
                        <ng-container
                            *ngTemplateOutlet="
                                this.config?.selectedItemTemplate ?? null;
                                context: {
                                    $implicit: { object: selectedItems, config }
                                }
                            "
                        >
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="!isReadOnly && (!selectedItems?.length || (selectedItems?.length && filterActive))">
        <lib-search-input
            #searchInput
            class="select"
            [(text)]="text"
            [searchIcon]="false"
            [removeBorder]="true"
            [placeholderText]="displayPlaceholder"
            [clearValueButton]="true"
            [disabled]="disabled"
            (textChange)="onTextChanged($event)"
            (keydown)="onKeyDown($event)"
        >
        </lib-search-input>

        <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="filterActive && !!text"
            [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
            [cdkConnectedOverlayWidth]="trigger.elementRef.nativeElement.offsetWidth"
            (detach)="filterActive = false"
            [cdkConnectedOverlayPositions]="[
                {
                    originX: 'center',
                    originY: 'bottom',
                    overlayX: 'center',
                    overlayY: 'top',
                    offsetY: 0
                }
            ]"
            (overlayOutsideClick)="filterActive = false"
            [cdkConnectedOverlayHasBackdrop]="true"
            [cdkConnectedOverlayBackdropClass]="'cdk-overlay-custom-backdrop'"
            (backdropClick)="filterActive = false"
        >
            <lib-options-menu
                cdkTrapFocus
                [cdkTrapFocusAutoCapture]="focusTrapEnabled"
                [source]="sourceItems"
                [(selectedItems)]="selectedItems"
                [config]="config"
                [menuOpen]="filterActive"
                [noResultsText]="noResultsText"
                [action]="action"
                (runActionEmitter)="onRunAction()"
                (selectedItemChange)="selectItem($event)"
                (listKeyDown)="selectItemKeydownHandler($event)"
            ></lib-options-menu>
        </ng-template>
    </ng-container>
</div>
