<lib-section-sub-header
    *ngIf="opportunityEstimates"
    [title]="finalSelectedEstimate ? 'Selected Estimate' : 'Base Estimate'"
    [subTitle]="baseEstimate?.name"
    [iconName]="finalSelectedEstimate ? 'dollar' : 'flag'"
    [info]="finalSelectedEstimate ? finalSelectedTemplate : baseEstimateTemplate "
    (subHeaderClicked)="navigate()"
    [selected]="!!finalSelectedEstimate"
>
    <ng-template #baseEstimateTemplate>
        <div class="info" [ngClass]="{isBaseEstimate: !finalSelectedEstimate}">
            <div class="item">
                <div class="label">Sell Price</div>
                <div class="value">{{ baseEstimate?.baseEstimateSellPrice | currency }}  </div>
            </div>
            <div class="item">
                <div class="label">Gross Margin</div>
                <div class="value">{{ baseEstimate?.grossMarginPercentage | customPercentage }}</div>
                <div class="value" *ngIf="!baseEstimate?.grossMarginPercentage">-</div>
            </div>
        </div>
    </ng-template>

    <ng-template #finalSelectedTemplate>
        <div class="info" [ngClass]="{isFinalSelected: finalSelectedEstimate}">
            <div class="item">
                <div class="label">Base Estimate</div>
                <div class="value">{{ finalSelectedEstimate?.baseEstimateSellPrice | currency }}  </div>
            </div>
            <div class="mathSymbol">+</div>
            <div class="item">
                <div class="label">Alternate Estimates</div>
                <div class="value">{{ finalSelectedEstimate?.alternateTotalSellPrice | currency }}</div>
                <div class="value" *ngIf="!finalSelectedEstimate?.alternateTotalSellPrice">-</div>
            </div>
                <div class="mathSymbol">+</div>
                <div class="item">
                    <div class="label">Change Orders</div>
                    <div class="value">{{ finalSelectedEstimate?.changeOrderTotalSellPrice | currency }}</div>
                    <div class="value" *ngIf="!finalSelectedEstimate?.changeOrderTotalSellPrice">-</div>
                </div>
            <div class="mathSymbol">=</div>
            <div class="item">
                <div class="label">Grand Total</div>
                <div class="value">{{ grandTotal | currency }}</div>
            </div>
            <div class="item">
                <div class="label">Total Gross Margin</div>
                <div class="value">{{ finalSelectedEstimate?.grossMarginPercentage | customPercentage }}</div>
                <div class="value" *ngIf="!finalSelectedEstimate?.grossMarginPercentage">-</div>
            </div>
        </div>
    </ng-template>
</lib-section-sub-header>