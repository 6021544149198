import { Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { DateRanges, IFullViewCalendarEvent } from "./interfaces/full-view-calendar.interface";
@Component({
    selector: "lib-full-view-calendar",
    templateUrl: "./full-view-calendar.component.html",
    styleUrls: ["./full-view-calendar.component.scss"]
})
export class FullViewCalendarComponent<T> {
    @Input()
    events?: IFullViewCalendarEvent<T>[];

    @Input()
    eventTemplate!: TemplateRef<any>;

    @Output()
    dateRangeSelected: EventEmitter<DateRanges>;

    @Input()
    currentView: "day" | "week" | "month";

    @Input()
    showViewSwitcher: boolean;

    constructor() {
        this.dateRangeSelected = new EventEmitter<DateRanges>();
        this.showViewSwitcher = false;
        this.currentView = "week";
    }

    setView(view: "day" | "week" | "month"): void {
        this.currentView = view;
    }

    onDateRangeSelected(event: DateRanges): void {
        this.dateRangeSelected.emit(event);
    }
}
