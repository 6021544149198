import { Component, OnInit } from "@angular/core";

import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-checkbox-input-editor",
    templateUrl: "./checkbox-input-editor.component.html",
    styleUrls: ["./checkbox-input-editor.component.scss"]
})
export class CheckboxInputEditorComponent extends EditorComponentBase<boolean> implements OnInit {
    checkboxText?: string;

    constructor() {
        super();
        this.checkboxText = "";
    }

    ngOnInit(): void {
        this.checkboxText = this.additionalInfo?.checkboxText ?? this.label;
    }
}
