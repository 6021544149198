import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { EditorItemComponent } from "projects/ngx-lib/src/lib/components/editor-item/editor-item.component";
import { IComponentFactory } from "projects/ngx-lib/src/lib/interfaces/component-factory.interface";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { icons } from "projects/ngx-lib/src/lib/assets/icons";

@Component({
    selector: "app-custom-fields-item",
    templateUrl: "./custom-fields-item.component.html",
    styleUrls: ["./custom-fields-item.component.scss"]
})
export class CustomFieldsItemComponent {
    readonly icons = icons;

    @Input()
    factory?: IComponentFactory;

    @Input()
    configItems: IEditorItemConfig[];

    @Input()
    isActive?: boolean;

    @Input()
    isReserved?: boolean;

    @Input()
    showActions?: boolean;

    @Output()
    valueChange: EventEmitter<boolean>;

    @Output()
    toggleCondition: EventEmitter<boolean>;

    @ViewChildren("editorItem")
    editors?: QueryList<EditorItemComponent>;

    archiveIcon?: SafeHtml;

    restoreIcon?: SafeHtml;

    dragIndicatorIcon?: SafeHtml;

    lockedIcon?: SafeHtml;

    constructor(private readonly sanitizer: DomSanitizer) {
        this.showActions = false;
        this.factory = undefined;
        this.configItems = [];
        this.isActive = true;
        this.isReserved = false;
        this.valueChange = new EventEmitter<boolean>();
        this.toggleCondition = new EventEmitter<boolean>();
        this.archiveIcon = this.sanitizer.bypassSecurityTrustHtml(icons.archive);
        this.restoreIcon = this.sanitizer.bypassSecurityTrustHtml(icons.restore);
        this.dragIndicatorIcon = this.sanitizer.bypassSecurityTrustHtml(icons.dragIndicator);
        this.lockedIcon = this.sanitizer.bypassSecurityTrustHtml(icons.locked);
    }

    emitValueChanged(): void {
        this.valueChange.emit();
    }

    validate(): boolean {
        return this.editors?.map(editorItem => editorItem.validate()).every(editorItem => editorItem) ?? false;
    }

    clearValidationErrors(): void {
        this.editors?.forEach(editorItem => editorItem.clearValidationErrors());
    }

    onToggleCondition(): void {
        this.toggleCondition.emit();
    }
}
