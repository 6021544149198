import { Injectable } from "@angular/core";
import { CountryClient, CountryView, ICountryClient, SearchCountryParameters } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class CountryService {
    private client: ICountryClient;
    constructor() {
        this.client = new CountryClient();
    }

    async search(body: SearchCountryParameters): Promise<CountryView[]> {
        return await this.client.countrySearch(body);
    }

    async getById(id: number): Promise<CountryView> {
        return await this.client.countryGetById(id);
    }
}
