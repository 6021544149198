import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { EstimateTypes } from "projects/app/src/app/models/enums/EstimateTypes";
import { OpportunityEstimateView } from "projects/app/src/app/services/http/clients/api-proxies";

@Component({
    selector: "app-opportunities-estimates-sub-header",
    templateUrl: "./opportunities-estimates-sub-header.component.html",
    styleUrls: ["./opportunities-estimates-sub-header.component.scss"]
})
export class OpportunitiesEstimatesSubHeaderComponent implements OnChanges {
    @Input()
    opportunityEstimates?: OpportunityEstimateView[];

    baseEstimate?: OpportunityEstimateView;

    finalSelectedEstimate?: OpportunityEstimateView;

    grandTotal?: number;

    @Output()
    clicked: EventEmitter<boolean>;

    constructor() {
        this.clicked = new EventEmitter<boolean>();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.opportunityEstimates && changes["opportunityEstimates"]) {
            this.setEstimates(this.opportunityEstimates);
        }
    }

    setEstimates(opportunityEstimate: OpportunityEstimateView[]): void {
        if (opportunityEstimate.length > 1 && opportunityEstimate[1].estimateTypeId === EstimateTypes.Final && opportunityEstimate[1].isSelected) {
            this.finalSelectedEstimate = opportunityEstimate[1];
            this.grandTotal = this.calculateGrandTotal(this.finalSelectedEstimate);
        }
        this.baseEstimate = opportunityEstimate[0];
    }

    calculateGrandTotal(finalSelectedEstimate: OpportunityEstimateView): number {
        return (
            (finalSelectedEstimate.baseEstimateSellPrice ?? 0) +
            (finalSelectedEstimate.alternateTotalSellPrice ?? 0) +
            (finalSelectedEstimate.changeOrderTotalSellPrice ?? 0)
        );
    }

    navigate() {
        if (!this.opportunityEstimates) return;
        this.finalSelectedEstimate ? this.clicked.emit(true) : window.open(this.baseEstimate?.externalUrl, "_blank");
    }
}
