import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Building } from "projects/app/src/app/models/Building";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { BuildingService } from "projects/app/src/app/services/http/clients/building.service";
import { ModalService } from "projects/ngx-lib/src/lib/services/modal.service";
import { SnackbarNotificationService } from "projects/ngx-lib/src/lib/services/snackbar-notification.service";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";
import { ValidatorType } from "projects/ngx-lib/src/lib/enums/validator-type";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { GenericCardComponent } from "projects/ngx-lib/src/lib/components/generic-card/generic-card.component";
import { ModalConfirmComponent } from "projects/app/src/app/components/shared/modals/modal-confirm/modal-confirm.component";
import { EntityTypeCustomFieldService } from "projects/app/src/app/services/http/clients/entity-type-custom-field.service";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";

@Component({
    selector: "app-buildings-edit",
    templateUrl: "./buildings-edit.component.html",
    styleUrls: ["./buildings-edit.component.scss"]
})
export class BuildingsEditComponent implements OnInit {
    building: Building;

    buildingId: number;

    factory: AppEditorFactory;

    mainInfoCardConfig!: IEditorItemConfig[];
    locationCardConfig!: IEditorItemConfig[];
    additionalInfoCardConfig!: IEditorItemConfig[];
    customFieldsConfig!: IEditorItemConfig[];

    @ViewChild("mainInfoCard")
    mainInfoCard?: GenericCardComponent;

    @ViewChild("locationCard")
    locationCard?: GenericCardComponent;

    buttonLabel?: string;

    continueButtonLabel?: string;

    title?: string;

    isReadOnly?: boolean;

    constructor(
        private readonly awaiter: AwaiterService,
        private readonly buildingService: BuildingService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly modalService: ModalService,
        private readonly entityCustomFieldService: EntityTypeCustomFieldService,
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {
        this.building = new Building();
        this.buildingId = parseInt(this.route.snapshot.paramMap.get("id") ?? "0");
        this.factory = new AppEditorFactory();
        this.buttonLabel = "Create";
        this.continueButtonLabel = "Continue creation";
        this.title = "Create New Building";
        this.isReadOnly = false;
    }

    async ngOnInit(): Promise<void> {
        if (this.buildingId) {
            this.buttonLabel = "Save";
            this.continueButtonLabel = "Continue edition";
            this.title = "Edit Building";

            await this.awaiter.awaitAction("Loading Building", async () => {
                const view = await this.buildingService.getById(this.buildingId);

                if (view) {
                    this.building = Building.fromInterface(view);
                }
            });
        }
        this.createCards();
    }

    async createCards(): Promise<void> {
        this.mainInfoCardConfig = [
            {
                label: "Name",
                componentId: EditorType.textInput,
                fieldName: "name",
                bindContext: this.building,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.required }, { type: ValidatorType.string, args: { maxLength: 100 } }]
            },
            {
                label: "Alternate Name",
                componentId: EditorType.multiInput,
                fieldName: "alternateNames",
                bindContext: this.building,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.string, args: { maxLength: 100 } }]
            },
            {
                label: "Class",
                componentId: EditorType.buildingClass,
                fieldName: "buildingBuildingClasses",
                bindContext: this.building,
                isReadOnly: this.isReadOnly
            },
            {
                label: "Website",
                componentId: EditorType.link,
                fieldName: "website",
                bindContext: this.building,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.string, args: { maxLength: 500 } }]
            }
        ];

        this.additionalInfoCardConfig = [
            {
                label: "Property Manager",
                componentId: EditorType.contact,
                fieldName: "propertyManagerContactId",
                bindContext: this.building,
                isReadOnly: this.isReadOnly
            },
            {
                label: "Stories",
                componentId: EditorType.textInput,
                fieldName: "stories",
                bindContext: this.building,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.string, args: { maxLength: 200 } }]
            },
            { label: "Building (sq ft)", componentId: EditorType.numberInput, fieldName: "size", bindContext: this.building, isReadOnly: this.isReadOnly },
            {
                label: "Typical Floor Size (sq ft)",
                componentId: EditorType.numberInput,
                fieldName: "floorSize",
                bindContext: this.building,
                isReadOnly: this.isReadOnly
            }
        ];

        this.locationCardConfig = [
            {
                componentId: EditorType.address,
                fieldName: "addressLines",
                bindContext: this.building,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.required }],
                additionalInfo: {
                    staticImageMap: true
                }
            }
        ];

        if (!this.building.customFieldValues?.length)
            this.building.customFieldValues = await this.entityCustomFieldService.getCustomFieldsByEntityType(EntityTypes.Building);

        this.customFieldsConfig =
            this.building.customFieldValues.map(item => {
                return {
                    label: item.name,
                    componentId: item.editor,
                    bindContext: item,
                    fieldName: "value",
                    isReadOnly: this.isReadOnly
                } as IEditorItemConfig;
            }) ?? [];
    }

    async saveBuilding(): Promise<void> {
        const mainCardInvalid = !this.mainInfoCard?.validate();
        const locationCardInvalid = !this.locationCard?.validate();

        if (mainCardInvalid || locationCardInvalid) return;

        await this.awaiter.awaitAction("Saving Building", async () => {
            await this.buildingService.save(this.building);
            this.snackbarNotificationService.success("Building saved successfully.");
            this.router.navigate(this.buildingId ? [`/buildings-single/${this.buildingId}`] : ["/buildings"]);
        });
    }

    async cancel(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Exit",
            cancelCaption: this.continueButtonLabel,
            content: "Are you sure you want to exit? Your changes will be lost",
            title: "Cancel Save Building"
        });

        if (responseOk) {
            this.router.navigate(this.buildingId ? [`/buildings-single/${this.buildingId}`] : ["/buildings"]);
        }
    }
}
