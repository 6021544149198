<lib-section-header [title]="'Contacts'" [iconName]="'contacts'" [actionsTemplate]="actionsTemplate">
    <ng-template #actionsTemplate>
        <div class="actions">
            <button class="primary-btn" (click)="onCreateNewContactClicked()">+ New Contact</button>
        </div>
    </ng-template>
</lib-section-header>

<div class="filters">
    <lib-grid-filters>
        <lib-search-input *libGridFilter [(text)]="filterValue.searchText" (textChange)="onFilterChanged()" [placeholderText]="'Contact Info'" name="search">
        </lib-search-input>
        <app-generic-type-ahead
            *libGridFilter
            [(selectedItem)]="filterValue.company"
            placeholder="Company"
            (filterChanged)="onFilterChanged()"
            [service]="companyServiceFilter"
            [config]="{
                defaultText: 'Company',
                itemDisplayKey: 'name'
            }"
            [filter]="{ pageSize: 20, isActive: true }"
        >
        </app-generic-type-ahead>
        <lib-generic-dropdown
            *libGridFilter
            [source]="activeStatuses"
            [(selectedItem)]="filterValue.activeStatus"
            (selectedItemChange)="onFilterChanged()"
            [config]="{
                multiple: false,
                defaultText: 'Select Condition',
                itemDisplayKey: 'name'
            }"
        >
        </lib-generic-dropdown>
    </lib-grid-filters>
</div>

<div class="list">
    <app-contacts-list [filters]="filterValue"></app-contacts-list>
</div>
