<app-popup-card-base-layout [shadow]="'strong'" [hasBorder]="hasBorder" (keyup.escape)="onCloseClicked()">
    <div class="container">
        <div class="header">
            <div class="headerContent">
                <div *ngIf="showEventInfo" class="eventInfo">
                    <lib-badge
                        [text]="data?.eventType === dueDatetype ? 'Due Date' : 'Follow up'"
                        [color]="data?.eventType === dueDatetype ? 'secondary' : 'tertiary'"
                    >
                    </lib-badge>
                    <span class="dateInfo" *ngIf="data?.eventDate">{{ data?.eventDate | date: "EE, MMM d h:mm a" }}</span>
                </div>
                <div class="title">{{ data?.name }}</div>
                <div class="code">ID {{ data?.code }}</div>
            </div>
            <button (click)="onCloseClicked()" [innerHTML]="closeIcon" class="resetStyles closeButton" aria-label="Close panel"></button>
        </div>

        <hr />

        <div class="body">
            <ul>
                <li *ngIf="data?.opportunityStatusName">
                    <span class="label">Status</span>
                    <span class="value">{{ data?.opportunityStatusName }}</span>
                </li>
                <ng-container *ngIf="data?.eventType === dueDatetype">
                    <li *ngIf="data?.eventDate">
                        <span class="label">Due Date</span>
                        <span class="value">{{ data?.eventDate | date: "M/d/yyyy" }}</span>
                    </li>
                    <li *ngIf="data?.companyName">
                        <span class="label">Customer</span>
                        <span class="value">{{ data?.companyName }}</span>
                    </li>
                    <li *ngIf="data?.opportunityUsers?.length">
                        <span class="label">Salesperson</span>
                        <span><app-user-avatar [users]="$any(data?.opportunityUsers)" [size]="'small'"></app-user-avatar> </span>
                    </li>
                    <li *ngIf="data?.opportunityValue">
                        <span class="label">Opportunity Value</span>
                        <span class="value">{{ data?.opportunityValue | currency }}</span>
                    </li>
                </ng-container>
                <li *ngIf="data?.eventType === followUpType && data?.contactInfo">
                    <span class="label">Contact</span>
                    <span class="value">{{ data?.contactInfo }}</span>
                </li>
            </ul>
        </div>
        <div class="footer">
            <button class="btn primary-btn" (click)="onGoToClicked()">Go to Opportunity</button>
        </div>
    </div>
</app-popup-card-base-layout>
