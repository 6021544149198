<app-auth-wrapper [title]="'Enter your email to reset password.'">
    <form #forgotPasswordForm="ngForm" novalidate>
        <div class="formGroup">
            <div class="label">Email</div>
            <input
                type="email"
                id="resetEmail"
                name="resetEmail"
                placeholder="example@ats.com"
                class="formControl"
                #resetEmail="ngModel"
                ngModel
                required
                email
                [ngClass]="{ 'invalid': forgotPasswordForm.submitted && resetEmail.invalid }"
            />
            <div *ngIf="forgotPasswordForm.submitted && resetEmail.errors" class="error">
                <div *ngIf="resetEmail.errors?.['email']">Email is invalid</div>
                <div *ngIf="resetEmail.errors?.['required']">Email is required</div>
            </div>
        </div>
        <div class="buttonsContainer">
            <button class="primary-btn" (click)="sendPasswordResetEmail(forgotPasswordForm)">Send password reset link</button>
            <button (click)="onCancel($event)" class="secondary-btn borderless">Cancel</button>
        </div>
    </form>
</app-auth-wrapper>