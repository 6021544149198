<div class="container">
    <lib-section-header title="Customization" [actionsTemplate]="actionsTemplate" iconName="configuration">
        <ng-template #actionsTemplate>
            <div class="actions" *ngIf="!isModelEqual">
                <lib-helper-info message="Unsaved changes"></lib-helper-info>
                <button class="secondary-btn" (click)="onCancel()">Cancel</button>
                <button class="primary-btn" (click)="onSave()">Save</button>
            </div>
        </ng-template>
    </lib-section-header>

    <div class="content">
        <lib-tab-view [direction]="'column'" [separation]="'narrow'" (beforeTabChange)="onBeforeTabChange($event)" [(currentTab)]="currentTab">
            <ng-container *ngFor="let item of configurationItems; let i = index">
                <lib-tab-item [tabName]="item.tabName" [headerTemplate]="configTabItem" [bodyTemplate]="configTabContent" [disabled]="!isModelEqual">
                    <ng-template #configTabItem>
                        <div class="tabItem">
                            <span class="title">{{ item.title }}</span>
                            <span class="subtitle">{{ item.subtitle }}</span>
                        </div>
                    </ng-template>

                    <ng-template #configTabContent>
                        <ng-container *ngIf="item.type === 'customFields'; else customInfoTemplate">
                            <app-customization-custom-fields
                                #customFieldsList
                                [title]="item.title + ' ' + item.subtitle"
                                [entityTypeId]="item.id"
                                [(customFields)]="customFields"
                                [(customFieldsInitialState)]="customFieldsInitialState"
                                (customFieldsChange)="onValueChanged(item.type)"
                                [filterOptions]="filterOptions"
                                [isModelEqual]="isModelEqual"
                            >
                            </app-customization-custom-fields>
                        </ng-container>
                        <ng-template #customInfoTemplate>
                            <app-customization-custom-info
                                #customInfoList
                                [title]="item.title"
                                [(divisions)]="divisions"
                                [(divisionsInitialState)]="divisionsInitialState"
                                (divisionsChange)="onValueChanged(item.type)"
                                [filterOptions]="filterOptions"
                                [isModelEqual]="isModelEqual"
                            ></app-customization-custom-info>
                        </ng-template>
                    </ng-template>
                </lib-tab-item>
            </ng-container>
        </lib-tab-view>
    </div>
</div>
