<div class="filtersContainer">
    <div class="dropdown">
        <button
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            class="resetStyles toggleDropdownButton"
            (click)="toggleDropdown()"
            (keydown)="onKeyPress($event)"
            [ngClass]="{
                opened: opened,
                downArrow: !opened,
                upArrow: opened,
                placeholder: !filtersApplied
            }"
        >
            {{ placeholder }}
        </button>
    </div>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="opened"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    [cdkConnectedOverlayHasBackdrop]="true"
    cdkConnectedOverlayBackdropClass="cdk-overlay-custom-backdrop"
    (backdropClick)="toggleDropdown()"
    (detach)="opened = false"
>
    <div class="itemsContainer" cdkTrapFocus cdkTrapFocusAutoCapture="true">
        <div class="buttonsContainer">
            <button class="secondary-btn" (click)="onClear()" tabindex="0" [disabled]="disableClearBtn">Clear</button>
            <button class="primary-btn" (click)="onApply()" tabindex="0">Apply</button>
        </div>
        <div class="filtersWrapper">
            <div class="filterItem">
                <div class="label">
                    <span>Customer</span>
                </div>
                <div class="filter">
                    <app-generic-type-ahead
                        placeholder="Customer"
                        [service]="companiesServiceFilter"
                        [config]="{
                            multiple: true,
                            defaultText: 'Customer',
                            itemDisplayKey: 'name'
                        }"
                        [filter]="{ pageSize: 20, isActive: true }"
                        [removeKey]="'id'"
                        [(selectedItems)]="additionalFilters.companies"
                        (filterChanged)="onFilterChanged()"
                    ></app-generic-type-ahead>
                </div>
            </div>

            <div class="filterItem">
                <div class="label">
                    <span>Potential Value ($)</span>
                </div>
                <div class="filter">
                    <lib-dropdown-range-values
                        [placeholder]="'Set Potential Value ($)'"
                        [iconType]="'dollar'"
                        [(valueFrom)]="additionalFilters.potentialValueFrom"
                        [(valueTo)]="additionalFilters.potentialValueTo"
                        (valueFromChange)="onFilterChanged()"
                        (valueToChange)="onFilterChanged()"
                    ></lib-dropdown-range-values>
                </div>
            </div>

            <div class="filterItem">
                <div class="label">
                    <span>Proposed GM (%)</span>
                </div>
                <div class="filter">
                    <lib-dropdown-range-values
                        [placeholder]="'Set Proposed GM (%)'"
                        [iconType]="'percentage'"
                        [(valueFrom)]="additionalFilters.proposedGrossMarginFrom"
                        [(valueTo)]="additionalFilters.proposedGrossMarginTo"
                        (valueFromChange)="onFilterChanged()"
                        (valueToChange)="onFilterChanged()"
                    ></lib-dropdown-range-values>
                </div>
            </div>

            <div class="filterItem">
                <div class="label">
                    <span>Division</span>
                </div>
                <div class="filter">
                    <lib-generic-dropdown-multiselect
                        [source]="divisions"
                        [config]="{
                            multiple: true,
                            defaultText: 'Division',
                            itemDisplayKey: 'name'
                        }"
                        [(selectedItems)]="additionalFilters.divisions"
                        (selectedItemsChange)="onFilterChanged()"
                    >
                    </lib-generic-dropdown-multiselect>
                </div>
            </div>

            <div class="filterItem">
                <div class="label">
                    <span>Salesperson</span>
                </div>
                <div class="filter">
                    <app-generic-type-ahead
                        placeholder="Salesperson"
                        [service]="userServiceFilter"
                        [config]="{
                            defaultText: 'Salesperson',
                            itemDisplayKey: 'fullName'
                        }"
                        [filter]="{ roleIds: this.userRoleIds }"
                        [(selectedItem)]="additionalFilters.salesperson"
                        (filterChanged)="onFilterChanged()"
                    ></app-generic-type-ahead>
                </div>
            </div>

            <div class="filterItem">
                <div class="label">
                    <span>Status</span>
                </div>
                <div class="filter">
                    <lib-generic-dropdown-multiselect
                        [source]="statuses"
                        [config]="{
                            multiple: true,
                            defaultText: 'Status',
                            itemDisplayKey: 'name'
                        }"
                        [(selectedItems)]="additionalFilters.statuses"
                        (selectedItemsChange)="onFilterChanged()"
                    >
                    </lib-generic-dropdown-multiselect>
                </div>
            </div>

            <div class="filterItem">
                <div class="label">
                    <span>Probability</span>
                </div>
                <div class="filter">
                    <lib-dropdown-range-values
                        [placeholder]="'Set Probability'"
                        [minValue]="0"
                        [maxValue]="100"
                        [iconType]="'percentage'"
                        [(valueFrom)]="additionalFilters.probabilityFrom"
                        [(valueTo)]="additionalFilters.probabilityTo"
                        (valueFromChange)="onFilterChanged()"
                        (valueToChange)="onFilterChanged()"
                    >
                    </lib-dropdown-range-values>
                </div>
            </div>

            <div class="filterItem">
                <div class="label">
                    <span>Condition</span>
                </div>
                <div class="filter">
                    <lib-generic-dropdown
                        [source]="activeStatuses"
                        [config]="{
                            multiple: false,
                            defaultText: 'Select Condition',
                            itemDisplayKey: 'name'
                        }"
                        [(selectedItem)]="additionalFilters.activeStatus"
                        (selectedItemChange)="onFilterChanged()"
                    >
                    </lib-generic-dropdown>
                </div>
            </div>
        </div>
    </div>
</ng-template>
