import { IUser } from "../interfaces";
import { StorageService } from "./storage.service";

export abstract class UserService {
    private _userKey = "user";

    set userKey(key: string) {
        this._userKey = key;
    }

    constructor(private readonly storageService: StorageService) {}

    getUser<T extends IUser>(): T | undefined {
        return this.storageService.getItem(this._userKey);
    }

    setUser<T extends IUser>(user: T) {
        this.storageService.setItem(this._userKey, user);
    }

    updateToken(newToken: string): void {
        const user = this.getUser();
        if (user) {
            user.token = newToken;
            this.setUser(user);
        }
    }

    removeUser() {
        this.storageService.removeItem(this._userKey);
    }
}
