import { Component, OnDestroy, OnInit } from "@angular/core";
import { NotificationService } from "../../../services/http/clients/notification.service";
import { NotificationView } from "../../../services/http/clients/api-proxies";
import { Router } from "@angular/router";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { icons } from "projects/ngx-lib/src/lib/assets/icons";
import { MessageBusService, RegistrationToken } from "projects/ngx-lib/src/public-api";
import { OrganizationChangedMessage } from "../../../messages/organization-changed.message";
import { Messages } from "../../../messages/messages";

@Component({
    selector: "app-notifications-panel",
    templateUrl: "./notifications-panel.component.html",
    styleUrls: ["./notifications-panel.component.scss"]
})
export class NotificationsPanelComponent implements OnInit, OnDestroy {
    private organizationChangedToken?: RegistrationToken;

    readonly icons = icons;

    hasUnread?: boolean;

    latestNotifications?: NotificationView[];

    notificationIcon?: SafeHtml;

    emptyNotificationIcon?: SafeHtml;

    isLoading: boolean;

    constructor(
        private readonly notificationService: NotificationService,
        private readonly sanitizer: DomSanitizer,
        private readonly router: Router,
        private readonly messageBusService: MessageBusService
    ) {
        this.hasUnread = false;
        this.isLoading = false;
    }

    async ngOnInit(): Promise<void> {
        this.organizationChangedToken = this.messageBusService.register<OrganizationChangedMessage>(Messages.OrganizationChanged, message => {
            message.organizationChanged && this.fetchNotifications();
        });
        this.fetchNotifications();
    }

    ngOnDestroy(): void {
        if (this.organizationChangedToken) this.messageBusService.unregister(this.organizationChangedToken);
    }

    async fetchNotifications(): Promise<void> {
        this.notificationIcon = this.sanitizer.bypassSecurityTrustHtml(icons.notifications);
        this.emptyNotificationIcon = this.sanitizer.bypassSecurityTrustHtml(icons.emptyNotifications);
        this.latestNotifications = await this.notificationService.getLatest();
        this.updateUnreadStatus();
    }

    async markAllAsRead(): Promise<void> {
        if (this.hasUnread) {
            this.isLoading = true;
            await this.notificationService.markAllAsRead();
            this.isLoading = false;

            this.latestNotifications?.forEach(notification => {
                if (!notification.isRead) {
                    notification.isRead = true;
                }
            });
            this.updateUnreadStatus();
        }
    }

    seeAllNotifications(): void {
        if (this.router.url === "/notifications") return;
        this.router.navigate(["/notifications"]);
    }

    updateUnreadStatus(): void {
        this.hasUnread =
            this.latestNotifications && this.latestNotifications.length > 0 && this.latestNotifications?.some(notification => !notification.isRead);
    }
}
