<lib-generic-type-ahead-dropdown
    [(selectedItem)]="selectedUser"
    [placeholder]="(isReadOnly && !bindingProperty) || !label ? 'Select an Item' : 'Add ' + label"
    (selectedItemChange)="onSelectedUser()"
    [(text)]="usersSearchText"
    [source]="usersSearchResult"
    (textChange)="onUsersTextChanged()"
    [config]="{
        defaultText: 'Add User',
        itemDisplayKey: 'fullName'
    }"
>
</lib-generic-type-ahead-dropdown>
