import { Injectable } from "@angular/core";
import {
    FileResponse,
    IReportExecutionClient,
    ReportExecutionClient,
    ReportExecutionView,
    ReportExecutionViewPaginatedResultDto,
    ReportPaginationParameters,
    ReportResultsView,
    SearchReportExecutionsParameters
} from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class ReportExecutionService {
    client: IReportExecutionClient;
    constructor() {
        this.client = new ReportExecutionClient();
    }

    async save(body: ReportExecutionView): Promise<ReportExecutionView> {
        return await this.client.reportExecutionSave(body);
    }

    async getById(id: number): Promise<ReportExecutionView> {
        return await this.client.reportExecutionGetById(id);
    }

    async search(body: SearchReportExecutionsParameters): Promise<ReportExecutionViewPaginatedResultDto> {
        return await this.client.reportExecutionSearch(body);
    }

    async searchResults(id: number, body: ReportPaginationParameters): Promise<ReportResultsView> {
        return await this.client.reportExecutionSearchReportResults(id, body);
    }

    async downloadReportResults(id: number, body: ReportPaginationParameters): Promise<FileResponse> {
        return await this.client.reportExecutionDownloadReportResults(id, body);
    }

    async delete(id: number): Promise<void> {
        return await this.client.reportExecutionDelete(id);
    }
}
