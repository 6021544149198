
<lib-full-view-calendar
    [currentView]="'week'"
    [events]="events"
    [eventTemplate]="opportunityCardTemplate"
    (dateRangeSelected)="onDateRangeSelected($event)">
</lib-full-view-calendar>

<ng-template #opportunityCardTemplate let-events="events" let-position="position">
    <ng-container *ngFor="let event of events">
        <app-opportunity-card-compact *ngIf="event.data" [data]="event.data" [position]="position"></app-opportunity-card-compact>
    </ng-container>
</ng-template>