<ng-container *ngIf="source && config">
	<ul class="menuGroup" *ngFor="let menuGroup of source" [ngClass]="{ expanded: isExpanded }">
		<ng-container *ngIf="menuGroup.isVisible && !menuGroup.isFooter; else footerTemplate">
				<div *ngIf="menuGroup.name" class="menuGroupSeparator">
				</div>
			<li class="menuItem" *ngFor="let menuItem of menuGroup.menuItems">
				<button *ngIf="menuItem.isVisible" tabindex="0" [ngClass]="{ active: isActiveMenuItem(menuItem), expanded: isExpanded }" (click)="selectMenuItem(menuItem)" class="linkContainer">
					<span class="icon" [innerHTML]="menuItem.icon"></span>
					<p *ngIf="isExpanded" class="name">{{ menuItem.name }}</p>
				</button>
			</li>
		</ng-container>
		<ng-template #footerTemplate>
			<div class="menuFooter menuGroup" *ngIf="menuGroup.isVisible">
				<div class="line"></div>
				<li class="menuItem" *ngFor="let menuItem of menuGroup.menuItems">
				<button *ngIf="menuItem.isVisible" class="linkContainer" [ngClass]="{ active: isActiveMenuItem(menuItem), expanded: isExpanded }" (click)="selectMenuItem(menuItem)">
					<span class="icon" [innerHTML]="menuItem.icon"></span>
					<p *ngIf="isExpanded" class="name">{{ menuItem.name }}</p>
				</button>
				</li>
			</div>
		</ng-template>
	</ul>
</ng-container>
