import { GetOpportunityActualValuesResultDto } from "../services/http/clients/api-proxies";
import { ModelBase } from "./ModelBase";

export class OpportunityActualValue extends ModelBase implements GetOpportunityActualValuesResultDto {
    actualValue: number | undefined;
    actualGrossMarginAmount: number | undefined;
    actualGrossMarginPercentage: number | undefined;

    constructor() {
        super();
        this.actualValue = undefined;
        this.actualGrossMarginAmount = undefined;
        this.actualGrossMarginPercentage = undefined;
    }

    static fromInterface(dto: GetOpportunityActualValuesResultDto): OpportunityActualValue {
        const opportunityActualValue = new OpportunityActualValue();
        opportunityActualValue.mapFromInterface(dto);
        return opportunityActualValue;
    }
}
