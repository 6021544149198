<div class="modal-wrapper" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div class="modal-content">
        <div class="modal-header">
            <h2>Create Estimate</h2>
            <div class="bar"></div>

            <button class="close-wrapper" (click)="close(undefined)" aria-label="Close modal">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                    <path
                        d="M6.32812 15.3359L10 11.6641L13.6719 15.3359L15 14.0078L11.3281 10.3359L15 6.66406L13.6719 5.33594L10 9.00781L6.32812 5.33594L5 6.66406L8.67188 10.3359L5 14.0078L6.32812 15.3359ZM10 20.3359C8.62847 20.3359 7.33507 20.0755 6.11979 19.5547C4.90451 19.0339 3.84115 18.3177 2.92969 17.4062C2.01823 16.4948 1.30208 15.4314 0.78125 14.2161C0.260417 13.0009 0 11.7075 0 10.3359C0 8.94705 0.260417 7.64931 0.78125 6.44271C1.30208 5.23611 2.01823 4.17708 2.92969 3.26562C3.84115 2.35417 4.90451 1.63802 6.11979 1.11719C7.33507 0.596354 8.62847 0.335938 10 0.335938C11.3889 0.335938 12.6866 0.596354 13.8932 1.11719C15.0998 1.63802 16.1589 2.35417 17.0703 3.26562C17.9818 4.17708 18.6979 5.23611 19.2188 6.44271C19.7396 7.64931 20 8.94705 20 10.3359C20 11.7075 19.7396 13.0009 19.2188 14.2161C18.6979 15.4314 17.9818 16.4948 17.0703 17.4062C16.1589 18.3177 15.0998 19.0339 13.8932 19.5547C12.6866 20.0755 11.3889 20.3359 10 20.3359Z"
                        fill="white"
                    />
                </svg>
            </button>
        </div>
        <div class="modal-body">
            <p>{{ canCreateBlankEstimate ? "What type of estimate do you need to create?" : "Create estimate from template:" }}</p>

            <div class="radioGroup" *ngIf="canCreateBlankEstimate">
                <label>
                    <input type="radio" name="estimateType" [(ngModel)]="estimateType" value="blank" checked (click)="removeSelectedTemplate()">
                    Blank Estimate
                </label>
                <label>
                    <input type="radio" name="estimateType" [(ngModel)]="estimateType" value="template">
                    Template-based estimate
                </label>
            </div>

            <div [hidden]="canCreateBlankEstimate && estimateType !== 'template'">
                <div class="wrapper">
                    <div class="label">
                        <span>Select Template</span>
                    </div>
                    <div class="editor">
                        <lib-generic-type-ahead-dropdown
                            [(selectedItem)]="selectedTemplate"
                            placeholder="Select"
                            (selectedItemChange)="onTemplateChange($event)"
                            [(text)]="searchText"
                            [source]="source"
                            (textChange)="onTemplateTextChanged()"
                            [config]="{
                                defaultText: 'Select',
                                itemDisplayKey: 'name'
                            }">
                        </lib-generic-type-ahead-dropdown>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <button class="modal-button cancel" (click)="close(undefined)" [disabled]="isLoading">Cancel</button>
            <app-loading-button [isLoading]="isLoading" (clickEvent)="crateEstimate()" text="Create"></app-loading-button>
        </div>
    </div>
</div>
