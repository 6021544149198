<div class="container">
    <div class="header">
        <div class="title">{{ title }}</div>
        <div class="actions">
            <button class="secondary-btn" *ngIf="!(filters.condition?.id === archiveStatus || filters.type?.id === reservedFilterType)" (click)="onAddNewField()">+ New field</button>
        </div>
    </div>

    <app-customization-filters
        [(customizationFilters)]="filters"
        [filterOptions]="filterOptions"
        (customizationFiltersChange)="onFiltersChange()"
    ></app-customization-filters>

    <ng-container *ngIf="divisions?.length === 0; else showContent">
        <lib-no-data [iconName]="'empty'" [title]="'You don\'t have any division.'" [text]="'Please add a new one to use on the workflow.'"></lib-no-data>
    </ng-container>

    <ng-template #showContent>
        <div class="list">
            <ng-container *ngFor="let item of divisions; let i = index">
                <app-custom-fields-item
                    #customInfoItem
                    libDragAndDrop
                    (valueChange)="onItemsChange()"
                    (toggleCondition)="onToggleCondition(item, i, item.isActive)"
                    [items]="divisions"
                    [index]="i"
                    (itemsChange)="onItemsChange()"
                    [isReserved]="item.organizationId === null || item.organizationId === undefined"
                    [showActions]="item.id !== 0 && showActions"
                    [isActive]="item.isActive"
                    [factory]="factory"
                    [configItems]="[
                        {
                            label: 'Description',
                            componentId: 'text-input',
                            fieldName: 'name',
                            bindContext: item,
                            isReadOnly: item.organizationId === null || item.organizationId === undefined,
                            validations: [{ type: 'required' }, { type: 'string', args: { maxLength: 200 } }]
                        }
                    ]"
                >
                </app-custom-fields-item>
            </ng-container>
        </div>
    </ng-template>
</div>
