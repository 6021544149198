import { Component, Input, OnChanges } from "@angular/core";
import { AddressView } from "../../../services/http/clients/api-proxies";

@Component({
    selector: "app-address-display",
    templateUrl: "./address-display.component.html",
    styleUrls: ["./address-display.component.scss"]
})
export class AddressDisplayComponent implements OnChanges {
    @Input()
    name?: string;

    @Input()
    address?: AddressView;

    fullAddress?: string;

    ngOnChanges(): void {
        if (this.address) {
            const { line1, line2, cityName, stateName, countryName, zipCode } = this.address;

            this.fullAddress = [line1, line2, cityName, stateName, countryName, zipCode].filter(part => part).join(", ");
        }
    }
}
