import { Component, OnInit } from "@angular/core";
import { BuildingBuildingClassView, BuildingClassView } from "projects/app/src/app/services/http/clients/api-proxies";
import { BuildingClassService } from "projects/app/src/app/services/http/clients/building-class.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-building-class-editor",
    templateUrl: "./building-class-editor.component.html",
    styleUrls: ["./building-class-editor.component.scss"]
})
export class BuildingClassEditorComponent extends EditorComponentBase<BuildingBuildingClassView[]> implements OnInit {
    buildingClassItems?: BuildingClassView[];
    selectedBuildingClasses?: BuildingClassView[];

    constructor(private readonly buildingClassService: BuildingClassService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.buildingClassItems = await this.buildingClassService.getAll();

        if (this.buildingClassItems?.length && this.bindingProperty) {
            this.selectedBuildingClasses = this.buildingClassItems.filter(x => this.bindingProperty?.some(obj2 => x["id"] === obj2["buildingClassId"]));
        }
    }

    onSelectedProductTypeChange() {
        if (this.selectedBuildingClasses)
            this.bindingProperty = this.selectedBuildingClasses.map(x => ({ buildingClassId: x.id }) as BuildingBuildingClassView);
    }
}
