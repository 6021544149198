<div class="mainContainer" *ngIf="note && index !== undefined">
    <div class="detailsContainer">
        <div>
            <p>Created by</p>
            <span>{{ note.userFirstName + ' ' + note.userLastName }}</span>
        </div>

        <div>
            <p>Created on</p>
            <span>{{ note.creationDate | date }}</span>
        </div>

        <div>
            <p>Last modified</p>
            <span>{{ note.modificationDate| date }}</span>
        </div>

        <div class="teamAssigned" *ngIf="note.noteUsers?.length">
            <p>Team assigned</p>
            <ng-container *ngFor="let user of note?.noteUsers">
                <lib-badge [text]="user.userFullName"></lib-badge>
            </ng-container>
        </div>
    </div>

    <div class="messageContainer">
        <div class="header">
            <p class="title">{{ note.title }}</p>
            <app-kebab-menu *ngIf="canEditNote">
                <button role="menuitem" class="resetStyles" (click)="editNote(index)">Edit</button>
                <button role="menuitem" class="resetStyles" (click)="removeNote(note.id, index)">Remove</button>
            </app-kebab-menu>
        </div>
        <div class="message">{{ note.message }}</div>
    </div>
</div>