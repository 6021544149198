import { Component, EventEmitter, Output } from "@angular/core";
import { BaseFilterComponent } from "../base-filter/base-filter.component";
import { SearchInputComponent } from "../../search-input/search-input.component";

@Component({
    selector: "lib-filter-search-input",
    templateUrl: "../../search-input/search-input.component.html",
    styleUrls: ["../../search-input/search-input.component.scss"]
})
export class FilterSearchInputComponent extends SearchInputComponent implements BaseFilterComponent {
    @Output()
    filterChanged: EventEmitter<void> = new EventEmitter<void>();

    override emitChanges(event: Event): void {
        super.emitChanges(event);
        this.filterChanged.emit();
    }
}
