import { DateTime } from "luxon";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from "@angular/core";
import { IFullViewCalendarEvent } from "./interfaces/full-view-calendar.interface";
import { DateRanges, EventsForPeriod, FullViewCalendarViewType } from "./types/full-view-calendar.types";
import { DateTimeService } from "../../services/date-time.service";

@Component({
    selector: "lib-full-view-calendar",
    templateUrl: "./full-view-calendar.component.html",
    styleUrls: ["./full-view-calendar.component.scss"]
})
export class FullViewCalendarComponent<T> implements OnChanges {
    readonly hours: number[];

    @Input()
    showViewSwitcher: boolean;

    @Input()
    events?: IFullViewCalendarEvent<T>[];

    @Input()
    eventTemplate!: TemplateRef<any>;

    @Output()
    dateRangeSelected: EventEmitter<DateRanges>;

    @Input()
    currentView: FullViewCalendarViewType;

    @Output()
    currentViewChange: EventEmitter<FullViewCalendarViewType>;

    eventsForPeriod: EventsForPeriod<T>;

    today: DateTime;

    selectedDate: DateTime;

    startOfPeriod!: DateTime;

    endOfPeriod!: DateTime;

    currentPeriod?: DateTime[];

    isCurrentPeriod: boolean;

    customDateText: string;

    constructor(private readonly dateTimeService: DateTimeService) {
        this.showViewSwitcher = true;
        this.hours = Array.from({ length: 24 }, (_, i) => i);
        this.selectedDate = this.dateTimeService.now();
        this.today = this.dateTimeService.now();
        this.isCurrentPeriod = false;
        this.currentPeriod = [];
        this.eventsForPeriod = {};
        this.customDateText = "";
        this.currentView = "week";
        this.dateRangeSelected = new EventEmitter<DateRanges>();
        this.currentViewChange = new EventEmitter<FullViewCalendarViewType>();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["currentView"] || changes["events"]) {
            this.updatePeriod(this.selectedDate);
            this.updateEventsForPeriod();
        }
    }

    setView(view: FullViewCalendarViewType): void {
        if (view === this.currentView) return;
        this.currentView = view;
        this.selectedDate = this.dateTimeService.now();
        this.currentViewChange.emit(view);
    }

    retreatPeriod(): void {
        this.selectedDate = this.selectedDate.minus(this.currentView === "day" ? { days: 1 } : { weeks: 1 });
        this.emitDateRange();
    }

    advancePeriod(): void {
        this.selectedDate = this.selectedDate.plus(this.currentView === "day" ? { days: 1 } : { weeks: 1 });
        this.emitDateRange();
    }

    onDatepickerChange(event: any): void {
        if (!event) {
            // Clear button event
            this.selectedDate = this.dateTimeService.now();
            this.emitDateRange();
            return;
        }

        this.selectedDate = this.dateTimeService.getDateTimeFromString(event);
        this.emitDateRange();
    }

    goToToday(): void {
        if (this.dateTimeService.isToday(this.selectedDate)) return;
        this.selectedDate = this.dateTimeService.now();
        this.emitDateRange();
    }

    private updateCustomDateText(): void {
        this.customDateText =
            this.currentView === "day"
                ? this.selectedDate.toFormat("DDDD")
                : `${this.startOfPeriod.toFormat("yyyy")}, ${this.startOfPeriod.toFormat("MMMM d")} - ${this.endOfPeriod.toFormat("d")}`;
    }

    private updatePeriod(date: DateTime): void {
        this.startOfPeriod = this.currentView === "day" ? date : this.dateTimeService.getStartOfWeek(date);
        this.endOfPeriod = this.currentView === "day" ? date : this.dateTimeService.getEndOfWeek(date);
        this.currentPeriod = Array.from({ length: this.currentView === "day" ? 1 : 7 }, (_, i) => this.startOfPeriod?.plus({ days: i }));
        this.isCurrentPeriod = this.currentPeriod.some(day => day.hasSame(this.today, "day"));
        this.updateCustomDateText();
    }

    private updateEventsForPeriod(): void {
        this.eventsForPeriod = {};

        this.currentPeriod?.forEach(day => {
            const dayKey = day.toISODate() || "";
            this.hours.forEach(hour => {
                const eventsForHour = this.events?.filter(event => {
                    if (event.date?.hasSame(day, "day")) {
                        const eventTime = DateTime.fromFormat(event.startTime ?? "", "HH:mm");
                        return eventTime.hour === hour || (eventTime.hour === hour && eventTime.minute > 0);
                    }
                    return false;
                });

                if (!this.eventsForPeriod[dayKey]) {
                    this.eventsForPeriod[dayKey] = {};
                }

                this.eventsForPeriod[dayKey][hour] = eventsForHour;
            });
        });
    }

    private emitDateRange(): void {
        this.dateRangeSelected.emit({
            dateFrom: this.currentView === "day" ? this.selectedDate.toISO() : this.dateTimeService.getStartOfWeek(this.selectedDate).toISO(),
            dateTo: this.currentView === "day" ? this.selectedDate.toISO() : this.dateTimeService.getEndOfWeek(this.selectedDate).toISO()
        });
    }
}
