import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { SafeHtml } from "@angular/platform-browser";

export interface IMenuGroup {
    id: string;
    name?: string;
    isVisible: boolean;
    menuItems: IMenuItem[];
    isFooter?: boolean;
}

interface IMenuItem {
    id: string;
    name: string;
    icon: SafeHtml;
    link?: string;
    isVisible?: boolean;
}

export interface IGenericMenuConfig {
    defaultText: string;
}

@Component({
    selector: "lib-generic-menu",
    templateUrl: "./generic-menu.component.html",
    styleUrls: ["./generic-menu.component.scss"]
})
export class GenericMenuComponent implements OnChanges {
    currentActiveMenuItem?: IMenuItem;

    @Input()
    config?: IGenericMenuConfig;

    @Input()
    source: IMenuGroup[];

    @Input()
    isExpanded: boolean;

    @Output()
    isExpandedChange: EventEmitter<boolean>;

    @Input()
    currentUrl?: string;

    constructor(private router: Router) {
        this.source = [];
        this.isExpanded = false;
        this.isExpandedChange = new EventEmitter<boolean>();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["currentUrl"] && changes["currentUrl"].currentValue !== changes["currentUrl"].previousValue) {
            if (!this.source.length) return;

            const menuItems = this.source.map(s => s.menuItems).flat();

            const menuActive = menuItems.find(menu => changes["currentUrl"].currentValue.includes(menu.link));

            this.currentActiveMenuItem = menuActive ?? this.source[0].menuItems[0];
        }
    }

    selectMenuItem(menuItem: IMenuItem): void {
        if (!this.isActiveMenuItem(menuItem)) this.currentActiveMenuItem = menuItem;

        this.navigate();
        if (this.isExpanded) this.isExpandedChange.emit(false);
    }

    isActiveMenuItem(menuItem: IMenuItem): boolean {
        return this.currentActiveMenuItem === menuItem;
    }

    private navigate(): void {
        if (this.currentActiveMenuItem?.link === undefined) return;
        this.router.navigate(["/", this.currentActiveMenuItem?.link]);
    }
}
