/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "lib-custom-input-with-icon",
    templateUrl: "./custom-input-with-icon.html",
    styleUrls: ["./custom-input-with-icon.scss"]
})
export class CustomInputWithIconComponent {
    @Input()
    type: string;

    @Input()
    value: any;

    @Input()
    svgIcon?: SafeHtml;

    @Input()
    placeholder?: string;

    @Input()
    min?: number;

    @Input()
    max?: number;

    @Output()
    valueChange: EventEmitter<any>;

    @Output()
    ngModelChange: EventEmitter<any>;

    @Output()
    inputError: EventEmitter<boolean>;

    errorMessage: string;

    constructor() {
        this.type = "number";
        this.placeholder = "Add value";
        this.valueChange = new EventEmitter<any>();
        this.ngModelChange = new EventEmitter<any>();
        this.inputError = new EventEmitter<boolean>();
        this.errorMessage = "";
    }

    handleInputValueChange(event: any): void {
        if (event) {
            let newValue: any = event.target.value;
            if (this.type === "number") {
                if (newValue === "") newValue = null;
                else {
                    newValue = parseFloat(newValue);
                    if (!isNaN(newValue)) this.validateMinMaxValues(newValue);
                }
            } else {
                this.errorMessage = "";
                this.inputError.emit(!!this.errorMessage);
            }
            this.value = newValue;
            this.valueChange.emit(newValue);
            this.ngModelChange.emit(newValue);
        } else {
            this.value = null;
        }
    }

    private validateMinMaxValues(value: number) {
        if (this.min !== undefined && value < this.min) {
            this.errorMessage = `Value must be greater than or equal to ${this.min}`;
        } else if (this.max !== undefined && value > this.max) {
            this.errorMessage = `Value must be less than or equal to ${this.max}`;
        } else {
            this.errorMessage = "";
        }

        this.inputError.emit(!!this.errorMessage);
    }
}
