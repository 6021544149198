import { Injectable } from "@angular/core";
import { BuildingClassClient, BuildingClassView, IBuildingClassClient } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class BuildingClassService {
    private client: IBuildingClassClient;
    constructor() {
        this.client = new BuildingClassClient();
    }

    async getAll(): Promise<BuildingClassView[]> {
        return await this.client.buildingClassGetAll();
    }
}
