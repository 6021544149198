import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ReportTypeView, SearchUsersParameters, UserView } from "projects/app/src/app/services/http/clients/api-proxies";
import { ReportTypeService } from "projects/app/src/app/services/http/clients/report-type.service";
import { UserService } from "projects/app/src/app/services/http/clients/user.service";
import { FilterService, IGetById } from "projects/ngx-lib/src/public-api";
import { ITypeAheadService } from "../../../shared/generic-type-ahead/generic-type-ahead.component";

export interface IReportExecutionListFilters {
    searchText?: string;
    reportType?: ReportTypeView;
    creatorUser?: UserView;
}

@Component({
    selector: "app-report-execution-list-filters",
    templateUrl: "./report-execution-list-filters.component.html",
    styleUrls: ["./report-execution-list-filters.component.scss"]
})
export class ReportExecutionListFiltersComponent implements OnInit {
    @Input()
    filters: IReportExecutionListFilters;

    @Output()
    filtersChange: EventEmitter<IReportExecutionListFilters>;

    reportTypes: ReportTypeView[];

    userServiceFilter: ITypeAheadService<UserView, SearchUsersParameters>;

    constructor(
        private readonly reportTypeService: ReportTypeService,
        private readonly userService: UserService,
        private readonly filterService: FilterService
    ) {
        this.filters = {};
        this.filtersChange = new EventEmitter<IReportExecutionListFilters>();
        this.reportTypes = [];
        this.userServiceFilter = userService;
    }

    async ngOnInit(): Promise<void> {
        this.reportTypes = await this.reportTypeService.getAll();
        await this.deserializeUrlParams();
        await this.updateFilterUrlParams();
        this.buildFilters();
        this.filtersChange.emit(this.filters);
    }

    buildFilters(): void {
        this.filters = {
            searchText: this.filters.searchText,
            reportType: this.filters.reportType,
            creatorUser: this.filters.creatorUser
        };
    }

    async onFilterChanged(): Promise<void> {
        await this.updateFilterUrlParams();
        this.buildFilters();
        this.filtersChange.emit(this.filters);
    }

    private async updateFilterUrlParams(): Promise<void> {
        await this.filterService.serialize<IReportExecutionListFilters>(this.filters, "searchText");
        await this.filterService.serializeArray<IReportExecutionListFilters, ReportTypeView>(this.filters, "reportType", "id");
        await this.filterService.serializeTypeAhead<IReportExecutionListFilters, UserView>("creatorUser", this.filters.creatorUser, "externalId");
    }

    private async deserializeUrlParams() {
        this.filterService.deserialize<IReportExecutionListFilters>(this.filters, "searchText");
        this.filterService.deserializeArray<IReportExecutionListFilters, ReportTypeView>(this.filters, "reportType", "id", this.reportTypes ?? []);
        await this.filterService.deserializeTypeAhead<IReportExecutionListFilters, UserView>(this.filters, "creatorUser", this.userService as IGetById);
    }
}
