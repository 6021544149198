import { MarketView } from "projects/app/src/app/services/http/clients/api-proxies";
import { MarketService } from "projects/app/src/app/services/http/clients/market.service";
import { IComponentEditorEntity } from "../../interfaces/editor-entity.interface";

export abstract class MarketEntity<TEntityDestination> implements IComponentEditorEntity<MarketView, TEntityDestination> {
    constructor(private readonly marketService: MarketService) {}

    abstract toDestination(entity: MarketView): TEntityDestination;

    abstract toSource(entity: TEntityDestination): MarketView;

    async getSource(): Promise<MarketView[]> {
        return await this.marketService.getAll();
    }
}
