<button
    class="select"
    #dropdownButton
    id="dropdownButton"
    aria-haspopup="true"
    [attr.aria-expanded]="menuOpen"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    (click)="toggleDropdown()"
    [ngClass]="{
        downArrow: !disabled && !menuOpen && !isReadOnly,
        upArrow: !disabled && menuOpen,
        menuOpen: menuOpen,
        placeholder: !selectedItem,
        readonly: isReadOnly,
        error: error
    }"
>
    {{ displayText }}
</button>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="menuOpen"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    [cdkConnectedOverlayWidth]="trigger.elementRef.nativeElement.offsetWidth"
    (detach)="closePanel()"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-custom-backdrop'"
    (backdropClick)="closePanel()"
    (overlayOutsideClick)="closePanel()"
>
    <lib-options-menu
        cdkTrapFocus
        cdkTrapFocusAutoCapture="true"
        [source]="source"
        [(selectedItem)]="selectedItem"
        [config]="config"
        [menuOpen]="menuOpen"
        [noResultsText]="noResultsText"
        (selectedItemChange)="selectItem($event)"
        (listKeyDown)="selectItemKeydownHandler($event)"
    ></lib-options-menu>
</ng-template>
