import { Injectable } from "@angular/core";
import {
    CreateBaseEstimateParameters,
    IOpportunityEstimateClient,
    OpportunityEstimateClient,
    OpportunityEstimateView,
    OpportunityEstimateViewPaginatedResultDto,
    SearchEstimateTemplatesParameters,
    SearchEstimateTemplatesResultDto,
    SearchOpportunityEstimateParameters
} from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class OpportunityEstimateService {
    private client: IOpportunityEstimateClient;
    constructor() {
        this.client = new OpportunityEstimateClient();
    }

    async createBaseEstimate(body: CreateBaseEstimateParameters | undefined): Promise<OpportunityEstimateView> {
        return await this.client.opportunityEstimateCreateBaseEstimate(body);
    }

    async getEstimatesInfoByOpportunity(opportunityId: number): Promise<OpportunityEstimateView[]> {
        return await this.client.opportunityEstimateGetEstimatesInfoByOpportunity(opportunityId);
    }

    async searchAlternateEstimatesByOpportunity(body: SearchOpportunityEstimateParameters): Promise<OpportunityEstimateViewPaginatedResultDto> {
        return await this.client.opportunityEstimateSearchAlternateEstimatesByOpportunity(body);
    }

    async searchBaseEstimateTemplates(body: SearchEstimateTemplatesParameters): Promise<SearchEstimateTemplatesResultDto[]> {
        return await this.client.opportunityEstimateSearchBaseEstimateTemplates(body);
    }

    async searchFinalEstimatesByOpportunity(body: SearchOpportunityEstimateParameters): Promise<OpportunityEstimateViewPaginatedResultDto> {
        return await this.client.opportunityEstimateSearchFinalEstimatesByOpportunity(body);
    }
}
