export enum CalendarRanges {
    LAST_WEEK = "last-week",
    LAST_MONTH = "last-month",
    LAST_THREE_MONTHS = "last-three-months",
    LAST_YEAR = "last-year",
    CUSTOM = "custom",
    NEXT_WEEK = "next-week",
    NEXT_MONTH = "next-month",
    NEXT_YEAR = "next-year",
    END_OF_CURRENT_WEEK = "end-of-current-week",
    END_OF_CURRENT_MONTH = "end-of-current-month",
    END_OF_CURRENT_QUARTER = "end-of-current-quarter",
    END_OF_JUNE_CURRENT_YEAR = "end-of-june-current-year",
    END_OF_CURRENT_YEAR = "end-of-current-year"
}

export interface CalendarRangesConfig {
    key: CalendarRanges;
    text: string;
    disabled: boolean;
}

export const backwardRanges: CalendarRangesConfig[] = [
    { key: CalendarRanges.LAST_WEEK, text: "Last week", disabled: false },
    { key: CalendarRanges.LAST_MONTH, text: "Last month", disabled: false },
    { key: CalendarRanges.LAST_THREE_MONTHS, text: "Last three months", disabled: false },
    { key: CalendarRanges.LAST_YEAR, text: "Last year", disabled: false }
];

export const forwardRanges: CalendarRangesConfig[] = [
    { key: CalendarRanges.NEXT_WEEK, text: "Next 7 days", disabled: false },
    { key: CalendarRanges.NEXT_MONTH, text: "Next 30 days", disabled: false },
    { key: CalendarRanges.NEXT_YEAR, text: "Next 365 days", disabled: false },
    { key: CalendarRanges.END_OF_CURRENT_WEEK, text: "End of Current Week", disabled: false },
    { key: CalendarRanges.END_OF_CURRENT_MONTH, text: "End of Current Month", disabled: false },
    { key: CalendarRanges.END_OF_CURRENT_QUARTER, text: "End of Current Quarter", disabled: false },
    { key: CalendarRanges.END_OF_JUNE_CURRENT_YEAR, text: "End of June Current Year", disabled: false },
    { key: CalendarRanges.END_OF_CURRENT_YEAR, text: "End of Current Year", disabled: false }
];

export const defaultRanges: CalendarRangesConfig[] = backwardRanges;
