import { Directive, ElementRef, HostListener, Renderer2 } from "@angular/core";

@Directive({
    selector: "[appEnsureHttps]"
})
export class EnsureHttpsDirective {
    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) {}

    @HostListener("click", ["$event"])
    onClick(event: Event) {
        const element = this.elementRef.nativeElement as HTMLAnchorElement;
        let url = element.getAttribute("href");

        if (url && !url.startsWith("https://") && !url.startsWith("http://")) {
            url = `https://${url}`;
            this.renderer.setAttribute(element, "href", url);
        }
    }
}
