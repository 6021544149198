/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import { IGenericGridColumnConfig, IGenericGridConfig } from '../generic-grid/generic-grid.component';

export interface IGridTabViewConfig {
	tabTitle: string;
	source: any[];
	columnsConfig: IGenericGridColumnConfig<any>[];
	config?: IGenericGridConfig;
	disabled: boolean;
}

@Component({
	selector: 'lib-grid-tab-view',
	templateUrl: './grid-tab-view.component.html',
	styleUrls: ['./grid-tab-view.component.scss']
})
export class GridTabViewComponent {
	@Input()
	config?: IGridTabViewConfig[];

	activeTab?: IGridTabViewConfig;

	ngAfterContentInit() {
		if (this.config && this.config.length > 0) this.activeTab = this.config[0];
	}

	activateTab(tab: IGridTabViewConfig) {
		this.activeTab = tab;
	}
}
