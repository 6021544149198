<lib-section-header [title]="reportResultsTitle" [subTitle]="reportResultSubtitle" [iconName]="'goBack'" [actionsTemplate]="actionsTemplate">
    <ng-template #actionsTemplate>
        <div class="actions">
            <button class="primary-btn" [disabled]="noResults" (click)="downloadResults()">Download .csv</button>
        </div>
    </ng-template>
</lib-section-header>

<div class="container">
    <div *ngIf="filtersApplied.length" class="filtersAppliedContainer">
        <span>Filters Applied</span>
        <ng-container *ngFor="let item of filtersApplied">
            <lib-badge [key]="item.key" [label]="item.label" [text]="item.value" [readonly]="true"></lib-badge>
        </ng-container>
    </div>
    <ng-container *ngIf="noResults && !isLoading; else showContent">
        <lib-no-data
            [iconName]="'noResults'"
            [title]="'It seems we could not find any results matching your search.'"
            [text]="'Please check your report terms.'"
        ></lib-no-data>
    </ng-container>
</div>

<ng-template #showContent>
    <lib-dynamic-grid [source]="reportResults" [(paginationConfig)]="paginationConfig" (paginationConfigChange)="refreshGridView()"></lib-dynamic-grid>
</ng-template>
