<ng-container *ngIf="opportunityEstimates?.length === 0 && !isLoading; else showRequested">
    <lib-no-data
        [iconName]="'empty'"
        [title]="'You have not created any estimates yet.'"
        linkText="{{ canCreateEstimate ? 'Please create an estimate to get started' : ''}}"
        (linkAction)="createBaseEstimate()"
    >
    </lib-no-data>
</ng-container>

<ng-template #showRequested>
    <ng-container *ngIf="opportunityEstimates && opportunityEstimates[0].opportunityStatusId === requestedStatus; else showContent">
        <lib-no-data
            [iconName]="'requested'"
            [title]="'You already requested an estimate from a template.'"
            text="We are building it. Please check back later or"
            linkText="refresh for updates."
            (linkAction)="refreshPage()"
        >
        </lib-no-data>
    </ng-container>
</ng-template>

<ng-template #showContent>
    <ng-container *ngIf="showSummary; else estimatesInfo">
        <app-opportunities-single-estimates-summary
            [estimateId]="finalEstimateId"
            (goBackClicked)="this.showSummary = $event"
        ></app-opportunities-single-estimates-summary>
    </ng-container>

    <ng-template #estimatesInfo>
        <ng-container *ngIf="opportunityEstimates?.length !== 0 && !isLoading">
            <lib-tab-view [(currentTab)]="currentTab" direction="column" alignment="start" separation="narrow" [fixedContentHeader]="headerTemplate">
                <ng-template #headerTemplate>
                    <app-opportunities-estimates-sub-header
                        [opportunityEstimates]="opportunityEstimates"
                        (clicked)="this.showSummary = $event"
                    ></app-opportunities-estimates-sub-header>
                </ng-template>

                <lib-tab-item tabName="Final Estimates" [bodyTemplate]="finalEstimatesTab" [icon]="finalEstimatesIcon">
                    <ng-template #finalEstimatesTab>
                        <app-opportunities-single-estimates-grid [opportunityId]="opportunityId" [isFinalEstimate]="true"></app-opportunities-single-estimates-grid>
                    </ng-template>
                </lib-tab-item>
                <lib-tab-item tabName="Alternate Estimates" [bodyTemplate]="alternateEstimatesTab" [icon]="alternateEstimatesIcon">
                    <ng-template #alternateEstimatesTab>
                        <app-opportunities-single-estimates-grid [opportunityId]="opportunityId"></app-opportunities-single-estimates-grid>
                    </ng-template>
                </lib-tab-item>
                <lib-tab-item tabName="Change Order" [bodyTemplate]="changeOrderTab" [icon]="changeOrderIcon">
                    <ng-template #changeOrderTab>
                        <app-opportunities-single-change-order-grid [opportunityId]="opportunityId"></app-opportunities-single-change-order-grid>
                    </ng-template>
                </lib-tab-item>
            </lib-tab-view>
        </ng-container>
    </ng-template>
</ng-template>
