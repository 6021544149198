import { Component, OnInit } from "@angular/core";
import { AwaiterService } from "../../../services/awaiter.service";
import { OpportunityDashboardService } from "../../../services/http/clients/opportunity-dashboard.service";
import { DashboardComparisonOptionView, GetOpportunityStatsByPeriodResultOpportunityTotalsResultDto } from "../../../services/http/clients/api-proxies";
import { DashboardPeriodOptions } from "../../../models/enums/DashboardPeriodOptions";
import { LegendTitle, IDashboardChart } from "../../shared/charts/chart-base/interfaces";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
    comparisonOptions?: DashboardComparisonOptionView[];

    selectedOption?: DashboardComparisonOptionView;

    opportunityStat?: GetOpportunityStatsByPeriodResultOpportunityTotalsResultDto;

    salesFunnelData?: IDashboardChart;

    bidVsAwardedPreviousData?: IDashboardChart;

    bidVsAwardedCurrentData?: IDashboardChart;

    constructor(
        private readonly awaiter: AwaiterService,
        private readonly opportunityDashboardService: OpportunityDashboardService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.awaiter.awaitAction("Getting Dashboard Stats", async () => {
            this.comparisonOptions = await this.opportunityDashboardService.getComparisonOptions();
        });

        if (this.comparisonOptions?.length) {
            this.selectedOption = this.comparisonOptions?.find(x => x.id === DashboardPeriodOptions.Last365Days);
            await this.getOpportunityStat(DashboardPeriodOptions.Last365Days);
        }

        this.buildChartsConfig();
    }

    async getOpportunityStat(comparisonId: number): Promise<GetOpportunityStatsByPeriodResultOpportunityTotalsResultDto> {
        this.opportunityStat = await this.opportunityDashboardService.getOpportunityStatsByPeriod(comparisonId);
        return this.opportunityStat;
    }

    async onSelectedOption(): Promise<void> {
        if (this.selectedOption) {
            await this.getOpportunityStat(this.selectedOption.id);
            this.buildChartsConfig();
        }
    }

    buildChartsConfig() {
        if (!this.opportunityStat) return;

        this.salesFunnelData = {
            id: "salesFunnelChart",
            labels: ["Opportunities", "Bid", "Awarded"],
            legend: {
                labels: [
                    {
                        name: this.opportunityStat.dashboardComparisonOptionView.currentPeriodDescription ?? "Current"
                    },
                    {
                        name: this.opportunityStat.dashboardComparisonOptionView.previousPeriodDescription ?? "Previous"
                    }
                ]
            },
            currentData: [
                this.opportunityStat?.currentResult.opportunitiesAmount,
                this.opportunityStat.currentResult.bidAmount,
                this.opportunityStat.currentResult.awardedAmount
            ],
            previousData: [
                this.opportunityStat?.previousResult.opportunitiesAmount,
                this.opportunityStat.previousResult.bidAmount,
                this.opportunityStat.previousResult.awardedAmount
            ]
        };

        this.bidVsAwardedCurrentData = {
            id: "bidVsAwardedCurrentChart",
            labels: ["Bid", "Awarded"],
            legend: {
                title: LegendTitle.Current,
                labels: [
                    {
                        name: "Bid",
                        value: this.opportunityStat.currentResult.bidCount
                    },
                    {
                        name: "Awarded",
                        value: this.opportunityStat.currentResult.awardedCount
                    }
                ]
            },
            currentData: [this.opportunityStat.currentResult.bidCount, this.opportunityStat.currentResult.awardedCount]
        };

        this.bidVsAwardedPreviousData = {
            id: "bidVsAwardedPreviousChart",
            labels: ["Bid", "Awarded"],
            legend: {
                title: LegendTitle.Previous,
                labels: [
                    {
                        name: "Bid",
                        value: this.opportunityStat.previousResult.bidCount
                    },
                    {
                        name: "Awarded",
                        value: this.opportunityStat.previousResult.awardedCount
                    }
                ]
            },
            previousData: [this.opportunityStat.previousResult.bidCount, this.opportunityStat.previousResult.awardedCount]
        };
    }
}
