import { Component, OnInit } from "@angular/core";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { DateTimeService } from "projects/ngx-lib/src/lib/services/date-time.service";

@Component({
    selector: "app-date-editor",
    templateUrl: "./date-editor.component.html",
    styleUrls: ["./date-editor.component.scss"]
})
export class DateEditorComponent extends EditorComponentBase<Date | null> implements OnInit {
    selectedDate?: Date;
    minDate?: Date;
    selectedTimeChanged?: string;

    timeEnabled: boolean;

    constructor(readonly dateTimeService: DateTimeService) {
        super();
        this.timeEnabled = false;
    }

    ngOnInit(): void {
        this.timeEnabled = this.additionalInfo?.timeEnabled;
        this.minDate = this.additionalInfo?.minDate;
        if (this.bindingProperty) {
            this.selectedDate = this.dateTimeService.formatDateTimeToDate(this.dateTimeService.getDateTimeFromString(this.bindingProperty.toString()));
        }
    }

    onSelectedDateChange() {
        if (this.selectedDate) {
            this.bindingProperty = this.selectedDate;
            this.validate();
        } else {
            this.bindingProperty = null;
        }
        this.valueChange.emit();
    }
}
