import { ModalInstance, ModalService } from "../services";

export class ModalComponentBase<TParameters, TResult> {
    protected modalService?: ModalService;

    protected modalInstance?: ModalInstance<TParameters, TResult | undefined>;

    protected parameters?: TParameters;

    setModalService(modalService: ModalService) {
        this.modalService = modalService;
    }

    setParameters(parameters?: TParameters): void {
        this.parameters = parameters;
    }

    setModalInstance(modalInstance: ModalInstance<TParameters, TResult | undefined>): void {
        this.modalInstance = modalInstance;
    }

    close(result: TResult | undefined): void {
        if (this.modalInstance?.resolve) {
            this.modalInstance?.resolve(result);
        }
    }

    throw(error: string): void {
        if (this.modalInstance?.reject) {
            this.modalInstance?.reject(error);
        }
    }

    onKeydownHandler(event: KeyboardEvent) {
        if (event.key === "Escape") {
            this.close(undefined);
        }
    }
}
