import { Component, HostListener, Input } from "@angular/core";
import { CurrencyPipe } from "@angular/common";
import { FormattedInputEditorComponent } from "../formatted-input-editor.component";

@Component({
    selector: "app-currency-input-editor",
    templateUrl: "../formatted-input-editor.component.html",
    styleUrls: ["../formatted-input-editor.component.scss"]
})
export class CurrencyInputEditorComponent extends FormattedInputEditorComponent {
    @Input()
    currencyCode = "USD";

    constructor(private currencyPipe: CurrencyPipe) {
        super();
    }

    override formatValue(inputValue?: string) {
        return this.currencyPipe.transform(inputValue, this.currencyCode);
    }

    @HostListener("focusout") onFocusout() {
        let value = this.input.nativeElement.value;
        if (value.length === 0 || (value.length === 1 && [",", ".", ""].indexOf(value[0]) > -1)) {
            value = "0";
        }
        this.input.nativeElement.value = this.formatValue(value);
    }
}
