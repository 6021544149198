import { Component, OnInit } from "@angular/core";
import { OpportunityStatusView } from "projects/app/src/app/services/http/clients/api-proxies";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { OpportunityStatusService } from "./../../../../services/http/clients/opportunity-status.service";

@Component({
    selector: "app-opportunity-status-editor",
    templateUrl: "./opportunity-status-editor.component.html",
    styleUrls: ["./opportunity-status-editor.component.scss"]
})
export class OpportunityStatusEditorComponent extends EditorComponentBase<number | null> implements OnInit {
    opportunityStatusItems?: OpportunityStatusView[];
    selectedOpportunityStatus?: OpportunityStatusView;

    constructor(private readonly opportunityStatusService: OpportunityStatusService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.opportunityStatusItems = await this.opportunityStatusService.getAll();
        if (this.bindingProperty) this.selectedOpportunityStatus = this.opportunityStatusItems?.find(x => x.id === this.bindingProperty);
    }

    onOpportunityStatusChange() {
        this.bindingProperty = this.selectedOpportunityStatus?.id ?? null;
        this.valueChange.emit();
    }
}
