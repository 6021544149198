import { Injectable } from "@angular/core";
import { StateClient, StateView, IStateClient, SearchStateParameters } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class StateService {
    private client: IStateClient;
    constructor() {
        this.client = new StateClient();
    }

    async search(body: SearchStateParameters): Promise<StateView[]> {
        return await this.client.stateSearch(body);
    }

    async getById(id: number): Promise<StateView> {
        return await this.client.stateGetById(id);
    }
}
