import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../../services/auth.service";

@Component({
    selector: "app-access-denied",
    templateUrl: "./access-denied.component.html",
    styleUrls: ["./access-denied.component.scss"]
})
export class AccessDeniedComponent implements OnInit {
    constructor(
        private readonly router: Router,
        private readonly authService: AuthService
    ) {}

    ngOnInit(): void {
        this.authService.redirectIfAuthenticated();
    }

    onGoBack(event: Event): void {
        event.preventDefault();
        this.router.navigate(["/login"]);
    }
}
