import { Component, OnInit } from "@angular/core";
import { IDropdownPanel, IDropdownPanelItem } from "projects/ngx-lib/src/lib/components/dropdown-panel/dropdown-panel.component";
import { UserService } from "../../../services/http/clients/user.service";
import { SnackbarNotificationService } from "projects/ngx-lib/src/lib/services/snackbar-notification.service";

@Component({
    selector: "app-user-apps-panel",
    templateUrl: "./user-apps-panel.component.html",
    styleUrls: ["./user-apps-panel.component.scss"]
})
export class UserAppsPanelComponent implements OnInit {
    panel: IDropdownPanel;

    constructor(
        private readonly userService: UserService,
        private readonly snackbarNotificationService: SnackbarNotificationService
    ) {
        this.panel = {
            items: []
        };
    }

    async ngOnInit(): Promise<void> {
        const result = await this.userService.getUserApplications();
        this.panel.items = result.map(
            item =>
                ({
                    id: item.name,
                    name: item.name,
                    action: () => window.open(item.url, "_blank")
                }) as IDropdownPanelItem
        );
    }
}
