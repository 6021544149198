import { Injectable } from "@angular/core";
import { CompanyTypeClient, CompanyTypeView, ICompanyTypeClient } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class CompanyTypeService {
    private client: ICompanyTypeClient;
    constructor() {
        this.client = new CompanyTypeClient();
    }

    async getById(id: number): Promise<CompanyTypeView> {
        return await this.client.companyTypeGetById(id);
    }

    async getAll(): Promise<CompanyTypeView[]> {
        return await this.client.companyTypeGetAll();
    }

    async create(body: CompanyTypeView): Promise<CompanyTypeView> {
        return await this.client.companyTypeSave(body);
    }
}
