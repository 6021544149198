import { OpportunityUserUserRoleView, OpportunityUserView, UserView } from "../services/http/clients/api-proxies";
import { ModelBase } from "./ModelBase";
import { UserRoles } from "./enums/UserRoles";

export class OpportunityUser extends ModelBase implements OpportunityUserView {
    userId: number;
    commissionPercentage: number | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    userFullName: string | undefined;
    email: string | undefined;
    userRoleDescriptions: string | undefined;
    userExternalId: number | undefined;
    opportunityId: number;
    opportunityUserUserRoles: OpportunityUserUserRoleView[] | undefined;

    constructor() {
        super();
        this.opportunityId = 0;
        this.userId = 0;
        this.commissionPercentage = undefined;
        this.firstName = undefined;
        this.lastName = undefined;
        this.userFullName = undefined;
        this.email = undefined;
        this.userRoleDescriptions = undefined;
        this.userExternalId = undefined;
        this.opportunityUserUserRoles = [];
    }

    static fromInterface(dto: OpportunityUserView): OpportunityUser {
        const opportunityUser = new OpportunityUser();
        opportunityUser.mapFromInterface(dto);
        return opportunityUser;
    }

    static fromUserView(userView: UserView): OpportunityUser {
        const opportunityUser = new OpportunityUser();

        opportunityUser.userId = userView.id;
        opportunityUser.firstName = userView.firstName;
        opportunityUser.lastName = userView.lastName;
        opportunityUser.userFullName = userView.fullName;
        opportunityUser.email = userView.email;
        opportunityUser.userExternalId = userView.externalId;

        const hiddenRole = userView.userRoleId === UserRoles.None;
        opportunityUser.opportunityUserUserRoles = (!hiddenRole ? [{ userRoleId: userView?.userRoleId }] : []) as OpportunityUserUserRoleView[];

        return opportunityUser;
    }
}
