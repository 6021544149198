import { UserRoleView } from "projects/app/src/app/services/http/clients/api-proxies";
import { IComponentEditorEntity } from "../../interfaces/editor-entity.interface";
import { UserRoleService } from "./../../../../../../services/http/clients/user-role.service";

export abstract class UserRoleEntity<TEntityDestination> implements IComponentEditorEntity<UserRoleView, TEntityDestination> {
    constructor(readonly userRoleService: UserRoleService) {}

    abstract toDestination(entity: UserRoleView): TEntityDestination;

    abstract toSource(entity: TEntityDestination): UserRoleView;

    abstract getSource(): Promise<UserRoleView[]>;
}
