import { Component, Input } from "@angular/core";
import { IDropdownPanel } from "projects/ngx-lib/src/lib/components/dropdown-panel/dropdown-panel.component";
import { User } from "../../../models/security/user";
import { Router } from "@angular/router";
import { BasicUserService, MessageBusService } from "projects/ngx-lib/src/public-api";
import { LoginMessage } from "../../../messages/login.message";
import { Messages } from "../../../messages/messages";
import { UserOrganizationService } from "projects/ngx-lib/src/lib/services/user-organization.service";

@Component({
    selector: "app-user-panel",
    templateUrl: "./user-panel.component.html",
    styleUrls: ["./user-panel.component.scss"]
})
export class UserPanelComponent {
    @Input()
    user?: User;

    @Input()
    showSubheading?: boolean;

    panel: IDropdownPanel;

    constructor(
        private readonly userService: BasicUserService,
        private readonly userOrganizationService: UserOrganizationService,
        private readonly router: Router,
        private readonly messageBusService: MessageBusService
    ) {
        this.showSubheading = true;
        this.panel = {
            items: [
                {
                    id: "edit-notifications",
                    name: "Edit Notifications",
                    action: () => this.editNotifications()
                },
                {
                    id: "logout",
                    name: "Log Out",
                    action: () => this.logout()
                }
            ]
        };
    }

    logout() {
        this.userService.removeUser();
        this.userOrganizationService.removeUserOrganization();
        this.messageBusService.send<LoginMessage>(Messages.LoggedIn, new LoginMessage(false));
        this.router.navigate(["/login"]);
    }

    editNotifications() {
        this.router.navigate(["/notifications-edit"]);
    }
}
