<lib-generic-dropdown
    name="opportunityStatus"
    id="opportunityStatus"
    [(selectedItem)]="selectedOpportunityStatus"
    (selectedItemChange)="onOpportunityStatusChange()"
    [source]="opportunityStatusItems"
    [config]="{
        defaultText: 'Select ' + label,
        itemDisplayKey: 'name'
    }"
    [isReadOnly]="isReadOnly"
>
    ></lib-generic-dropdown
>
