import { Component, Input } from "@angular/core";
@Component({
    selector: "app-popup-card-base-layout",
    templateUrl: "./popup-card-base-layout.component.html",
    styleUrls: ["./popup-card-base-layout.component.scss"]
})
export class PopupCardBaseLayoutComponent {
    @Input()
    highlightColor?: "blue" | "yellow";

    @Input()
    shadow: "soft" | "strong";

    @Input()
    hasBorder: boolean;

    constructor() {
        this.shadow = "soft";
        this.hasBorder = true;
    }
}
