<div class="container main-padding">

    <div class="input-container">
        <lib-custom-input-with-icon [(value)]="numericValue" [type]="'number'" [min]="0" [svgIcon]="percentageIcon"></lib-custom-input-with-icon>
    </div>

    <lib-dropdown-range-values [placeholder]="'Set Probability'"></lib-dropdown-range-values>
    <!-- Helper info component -->
    <div class="helperInfo">
        <lib-helper-info message="This is a custom information message"></lib-helper-info>
    </div>

    <!-- Slide toggle component -->
    <div>
        <lib-slide-toggle [label]="'Label'"></lib-slide-toggle>
    </div>

    <!-- Notifications component -->
    <div class="notifications">
        <select [(ngModel)]="notificationType">
            <option value="info">Info</option>
            <option value="error">Error</option>
            <option value="warn">Warn</option>
            <option value="success">Success</option>
        </select>
        <button (click)="showNotification()">Show Notification</button>
    </div>

    <!-- Generic Card component -->
    <div class="generic-card">
        <lib-generic-card [title]="'Main Info'" [highlight]="true" [factory]="factory" [configItems]="testConfigCard"> </lib-generic-card>

        <ng-template #nameTemplate>
            <div>{{ items[0].name }}</div>
        </ng-template>

        <ng-template #lastNameTemplate>
            <div>{{ items[0].surname }}</div>
        </ng-template>

        <ng-template #onlyTemplate>
            <input type="text" placeholder="Phone" />
        </ng-template>
    </div>

    <div class="list-view">
        <!-- List View With Templates -->
        <lib-list-view
            [source]="items"
            [itemTemplate]="listViewItem"
            [headerTemplate]="listViewHeader"
            [footerTemplate]="listViewFooter"
            [(selectedItem)]="listViewSelectedItem"
        >
            <ng-template #listViewHeader>
                <div class="list-view-header">
                    <div class="column">Name</div>
                    <div class="column">Description</div>
                </div>
            </ng-template>
            <div class="description">
                <ng-template #listViewItem let-data>
                    <app-user-avatar [users]="[user]"></app-user-avatar>
                </ng-template>
            </div>
            <ng-template #listViewFooter>
                <div class="list-view-footer">
                    <div class="column">Quantity:</div>
                    <div class="column">{{ itemsTotalNum }}</div>
                </div>
            </ng-template>
        </lib-list-view>

        <!-- List View Without Templates -->
        <lib-list-view [source]="items" [valueProperty]="'name'" [(selectedItem)]="listViewSelectedItem"> </lib-list-view>
    </div>

    <!-- Section Header -->
    <div>
        <lib-section-header [title]="page" [iconName]="'opportunities'"></lib-section-header>
    </div>

    <!-- Dropdown Panel -->
    <lib-dropdown-panel [textContent]="'Custom text'" [source]="panel"></lib-dropdown-panel>

    <!-- Cell User Component -->
    <div class="cellUser">
        <app-user-avatar [users]="[user]"></app-user-avatar>
    </div>

    <!-- Grid With No Rows -->
    <div class="gridNoRows">
        <lib-generic-grid
            [source]="noRowItems"
            [(selectedItems)]="currentNoRowSelectedItems"
            (selectedItemsChange)="selectedItemsChanged($event)"
            [columnsConfig]="[
                { key: 'name', label: 'Name', sortable: true },
                { key: 'surname', label: 'Surname', sortable: true, cellTemplate: customCell }
            ]"
            [config]="{ allowSelection: true, noRowsTemplate: noRowsCustom }"
            (rowClicked)="onRowClicked($event)"
            [(orderConfig)]="orderConfig"
            (orderConfigChange)="gridOrderChanged()"
        >
        </lib-generic-grid>

        <ng-template #customCell let-data>
            <div class="surname">
                {{ data.object.surname }}
            </div>
        </ng-template>

        <ng-template #noRowsCustom> This is a custom template for no rows </ng-template>
    </div>

    <!-- Grid With Pagination -->
    <div>
        <lib-generic-grid
            [source]="pagedItems"
            [(selectedItems)]="currentSelectedItems"
            (selectedItemsChange)="selectedItemsChanged($event)"
            [columnsConfig]="[
                { key: 'isFavorite', headerTemplate: headerFavorite, sortable: true, cellTemplate: cellFavorite, customClasses: ['small-grid'] },
                { key: 'name', label: 'Name', sortable: true },
                { key: 'surname', label: 'Surname', sortable: true, cellTemplate: customCell },
                { key: 'role', label: 'Role', sortable: true }
            ]"
            [config]="{ allowSelection: false }"
            (rowClicked)="onRowClicked($event)"
            [(orderConfig)]="orderConfig"
            [(paginationConfig)]="paginationConfig"
            (paginationConfigChange)="updatePage()"
        >
        </lib-generic-grid>
    </div>

    <!-- Grid With Infinite Scroll And Favorites -->
    <div>
        <lib-generic-grid
            [source]="infiniteScrollItems"
            [(selectedItems)]="currentSelectedItems"
            (selectedItemsChange)="selectedItemsChanged($event)"
            [columnsConfig]="[
                { key: 'isFavorite', headerTemplate: headerFavorite, sortable: true, cellTemplate: cellFavorite, customClasses: ['small-grid'] },
                { key: 'name', label: 'Name', sortable: true },
                { key: 'surname', label: 'Surname', sortable: true, cellTemplate: customCell },
                { key: 'role', label: 'Role', sortable: true }
            ]"
            [config]="{ allowSelection: false }"
            (rowClicked)="onRowClicked($event)"
            [(orderConfig)]="orderConfig"
            (orderConfigChange)="gridOrderChanged()"
            [(paginationConfig)]="paginationConfigAutoLoad"
            (paginationConfigChange)="loadMore()"
            (rowClicked)="onRowClicked($event)"
        >
        </lib-generic-grid>

        <ng-template #headerFavorite>
            <app-cell-favorite [isHeader]="true"></app-cell-favorite>
        </ng-template>

        <ng-template #cellFavorite let-data>
            <app-cell-favorite [favorite]="data.object.isFavorite" (favoriteChange)="onFavoriteChange($event, data.object)"></app-cell-favorite>
        </ng-template>
    </div>
</div>
