import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface ISelectedSummaryConfig {
	itemDescriptionHeader: string;
	totalHeader: string;
	selectedHeader: string;
	totalSummaryText: string;
}

export interface ISelectedSummaryItem<T> {
	key: T;
	description: string;
	total: number;
	selected: number;
}

@Component({
	selector: 'lib-selected-summary',
	templateUrl: './selected-summary.component.html',
	styleUrls: ['./selected-summary.component.scss']
})
export class SelectedSummaryComponent<T> {
	@Input()
	config?: ISelectedSummaryConfig;

	@Input()
	items: ISelectedSummaryItem<T>[] = [];

	@Input()
	selectedItem?: ISelectedSummaryItem<T>;

	@Output()
	selectedItemChange: EventEmitter<ISelectedSummaryItem<T>> = new EventEmitter<ISelectedSummaryItem<T>>();

	onItemClicked(item: ISelectedSummaryItem<T>) {
		this.selectedItemChange.emit(item);
	}

	getTotal(): number {
		return this.items.reduce((accumulator, currentValue) => {
			return accumulator + currentValue.selected;
		}, 0);
	}
}
