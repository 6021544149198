<ng-container *ngIf="user">
    <lib-dropdown-panel [buttonTemplateRef]="userPanel" [source]="panel"></lib-dropdown-panel>
    <ng-template #userPanel>
            <app-user-avatar [users]="[user]" [showSubheading]="showSubheading"></app-user-avatar>
        <span class="open-close" [ngClass]="{ noSubHeading: !showSubheading }">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                <path
                    d="M5.15244 6.82526C5.62099 7.29223 6.38193 7.29223 6.85048 6.82526L11.6485 2.04347C11.9933 1.69978 12.0945 1.18798 11.9071 0.739683C11.7197 0.29139 11.2849 0 10.7976 0L1.20159 0.003736C0.718038 0.003736 0.279471 0.295126 0.0920492 0.743419C-0.0953726 1.19171 0.00958361 1.70351 0.350691 2.0472L5.14869 6.82899L5.15244 6.82526Z"
                    fill="#175C89"
                />
            </svg>
        </span>
    </ng-template>
</ng-container>

