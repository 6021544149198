    <div class="container">
        <div class="header">
            <p class="title">{{ title }}</p>
            <lib-trend-indicator [previousValue]="previousValue" [currentValue]="currentValue"></lib-trend-indicator>
        </div>
        <div class="statValue">
            <span *ngIf="type === 'currency'">{{ currentValue | currency : 'USD' : 'symbol' : '1.0-0' }}</span>
            <span *ngIf="type === 'customPercentage'">{{ currentValue === 0 ? '@0.00%' : currentValue | customPercentage }}</span>
            <span *ngIf="type === 'none'">{{ currentValue }}</span>
        </div>
    </div>