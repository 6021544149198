<ng-container *ngIf="noResults && !isLoading; else showContent">
    <lib-no-data [iconName]="'emptyData'" [title]="'No Companies available.'"> </lib-no-data>
</ng-container>

<ng-template #showContent>
    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'name', label: 'Company Name', sortable: true },
                { key: 'fullAddress', label: 'Main Office Address', sortable: false },
                { key: 'contactFirstName', label: 'Main Contact', sortable: true, cellTemplate: contactFullName },
                { key: 'companyTypeName', label: 'Type', sortable: true },
                { key: 'markets', label: 'Markets', sortable: true },
                { key: 'isActive', label: 'Condition', sortable: false, cellTemplate: cellIsActive }
            ]
        }"
        [source]="companies"
        [(orderConfig)]="orderConfig"
        (orderConfigChange)="onOrderChanged()"
        [(paginationConfig)]="paginationConfig"
        (paginationConfigChange)="refreshGridView()"
        (rowClicked)="onRowClicked($event)"
    >
        <ng-template #contactFullName let-data>
            <div>{{ data.object.contactFirstName }} {{ data.object.contactLastName }}</div>
        </ng-template>

        <ng-template #cellIsActive let-data>
            {{ data.object.isActive ? "Active" : "Archived" }}
        </ng-template>
    </lib-grid-view>
</ng-template>
