<div class="container">
    <div class="card">
        <div class="header">
            <svg xmlns="http://www.w3.org/2000/svg" class="logo" width="122" height="57" viewBox="0 0 122 57" fill="none">
                <path d="M9.03711 26.7656V39.5212H12.5262V30.2656H19.1355V26.7656H9.03711Z" fill="#8AACC1"/>
                <path d="M33.2917 30.2663V17.5107H29.8026V26.7663H23.1934V30.2663H33.2917Z" fill="#136392"/>
                <path d="M12.525 35.6318V39.5207H9.0359V35.7252C8.03682 36.3552 7.43737 37.4518 7.42969 38.6263C7.42969 39.5363 7.79089 40.4074 8.43645 41.0529C9.08201 41.6985 9.95044 42.0563 10.8573 42.0563C12.7479 42.0563 14.2849 40.5163 14.2849 38.6263C14.2849 37.3818 13.6086 36.2385 12.525 35.6318Z" fill="#8AACC1"/>
                <path d="M29.8048 21.3991V17.5102H33.2939V21.3057C34.293 20.6757 34.8925 19.5791 34.9001 18.4046C34.9001 16.5068 33.3631 14.9746 31.4725 14.9746C29.582 14.9746 28.0449 16.5146 28.0449 18.4046C28.0449 19.6491 28.7212 20.7924 29.8048 21.3991Z" fill="#136392"/>
                <path d="M31.4909 21.748C33.4221 21.748 34.9877 20.181 34.9877 18.248C34.9877 16.3151 33.4221 14.748 31.4909 14.748C29.5597 14.748 27.9941 16.3151 27.9941 18.248C27.9941 20.181 29.5597 21.748 31.4909 21.748Z" fill="#136392"/>
                <path d="M21.1699 0.5V56.5L41.4589 48.24V8.77556L21.1699 0.5ZM23.2296 5.09667L37.9852 11.1167V45.8911L23.2296 51.9033V5.09667Z" fill="#324A5E"/>
                <path d="M21.1718 0.5V56.5L0.882812 48.24V8.77556L21.1718 0.5ZM19.1122 5.09667L4.35653 11.1167V45.8911L19.1122 51.9033V5.09667Z" fill="#A5AEB5"/>
                <path d="M64.4245 38.2764C64.4245 38.9841 64.1017 39.5675 63.4485 40.0186C62.8644 40.4308 62.2035 40.6408 61.4811 40.6408H53.3809V16.2575H60.6588C61.4734 16.2575 61.996 16.3119 62.2266 16.4286C63.6944 16.8175 64.4245 17.8052 64.4245 19.3841V38.2764ZM62.703 37.5452V19.9675C62.703 18.6919 62.019 18.0541 60.6588 18.0541H55.1177V38.9375H60.751C62.0498 38.9375 62.703 38.4708 62.703 37.5452ZM70.6726 40.6175H68.9127V16.273H70.6726V40.6175ZM86.8731 16.2575L81.0477 40.6019H79.6336L73.5008 16.2575H75.2607L80.4021 36.0364L85.1439 16.2575H86.8807H86.8731ZM107.631 40.6797H95.0424V38.9608L104.956 24.2686C105.502 23.4597 105.779 22.5964 105.779 21.6941C105.779 21.033 105.625 20.403 105.318 19.7964L104.856 19.0652C104.526 18.793 104.242 18.5986 103.996 18.4819C103.204 18.093 102.259 17.8986 101.152 17.8986C98.9389 17.8986 97.4556 19.1897 96.7178 21.7641H94.981C95.811 18.0386 97.8629 16.1797 101.137 16.1797C103.097 16.1797 104.626 16.6464 105.725 17.5875C106.885 18.583 107.462 20.0297 107.462 21.9352C107.462 22.9308 107.247 23.8252 106.809 24.6264L97.202 38.8986H107.631V40.6797ZM121.118 33.0808C121.118 36.5497 119.797 38.8597 117.145 40.0186C116.238 40.4152 115.331 40.6175 114.409 40.6175H110.436V38.8986L114.355 38.883C115.962 38.8675 117.222 38.2919 118.137 37.1408C119.005 36.0441 119.435 34.6908 119.435 33.0808C119.435 31.8675 119.12 30.833 118.498 29.9697C117.583 28.7175 116.138 28.0875 114.179 28.0719L110.682 28.0564V16.2652H120.711V18.023H112.442V26.3375H114.87C116.784 26.3375 118.306 26.9908 119.458 28.2897C120.565 29.5264 121.118 31.1286 121.118 33.0886V33.0808Z" fill="#136392"/>
            </svg>
            <h2 class="title">{{ title }} </h2>
        </div>
        <ng-content></ng-content>
    </div>
</div>