export class Messages {
    /**
     * Message thrown when the user is Logged In or Logged Out
     */
    static readonly LoggedIn = "LoggedIn";

    /**
     * Message for Loading state
     */
    static readonly Loading = "Loading";

    /**
     * Message thrown when an Organization has changed
     */
    static readonly OrganizationChanged = "OrganizationChanged";
}
