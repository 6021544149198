import { UserOrganizationView } from "../services/http/clients/api-proxies";
import { ModelBase } from "./ModelBase";

export class UserOrganization extends ModelBase implements UserOrganizationView {
    userId: number;
    userFullName: string | undefined;
    userRoleId: number;
    userRoleCode: string | undefined;
    userRoleDescription: string | undefined;
    organizationId: number;
    organizationName: string | undefined;
    organizationCode: string | undefined;
    organizationImageUrl: string | undefined;

    constructor() {
        super();
        this.organizationId = 0;
        this.userId = 0;
        this.userFullName = "";
        this.userRoleId = 0;
        this.userRoleCode = "";
        this.userRoleDescription = "";
        this.organizationName = "";
        this.organizationImageUrl = "";
    }

    static fromInterface(dto: UserOrganizationView): UserOrganization {
        const userOrganization = new UserOrganization();
        userOrganization.mapFromInterface(dto);
        return userOrganization;
    }
}
