import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { BuildingService } from "projects/app/src/app/services/http/clients/building.service";
import { BuildingView, SearchBuildingsParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { IFilterValueBuildings } from "../buildings-main/buildings-main.component";
import { Router } from "@angular/router";
import { FilterService } from "projects/ngx-lib/src/lib/services";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";

@Component({
    selector: "app-buildings-list",
    templateUrl: "./buildings-list.component.html",
    styleUrls: ["./buildings-list.component.scss"]
})
export class BuildingsListComponent implements OnChanges {
    @Input()
    filters?: IFilterValueBuildings;

    buildings: BuildingView[];

    orderConfig?: IGenericGridOrderConfig<BuildingView>;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchBuildingsParameters;

    noResults: boolean;

    isLoading: boolean;

    constructor(
        private readonly buildingService: BuildingService,
        private readonly router: Router,
        private readonly awaiter: AwaiterService,
        private readonly filterService: FilterService
    ) {
        this.buildings = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage
        } as SearchBuildingsParameters;
        this.noResults = true;
        this.isLoading = true;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (!changes["filters"].firstChange) {
            this.paginationConfig.currentPage = 1;
            this.buildings = [];
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Buildings",
            async () => {
                const response = await this.buildingService.searchBuilding(this.searchConfig);
                const { pageInfo, results } = response;
                this.buildings = this.buildings?.concat(results ?? []);

                this.noResults = this.buildings.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;
        this.buildings = [];

        await this.refreshGridView();
    }

    onRowClicked(building: BuildingView) {
        this.router.navigate([`/buildings-single/${building.id}`]);
    }

    private buildSearchConfig() {
        this.searchConfig.pageNumber = this.paginationConfig.currentPage ?? 1;

        this.searchConfig.filterText = this.filters?.searchText;
        this.searchConfig.stateId = this.filters?.state?.id;
        this.searchConfig.countryId = this.filters?.country?.id;
        this.searchConfig.cityId = this.filters?.city?.id;
        this.searchConfig.isActive = this.filters?.activeStatus?.value;
    }
}
