import { Component, Input } from "@angular/core";
import { ILegend } from "../chart-base/interfaces";

@Component({
    selector: "app-chart-legend",
    templateUrl: "./chart-legend.component.html",
    styleUrls: ["./chart-legend.component.scss"]
})
export class ChartLegendComponent {
    @Input()
    legend?: ILegend;
}
