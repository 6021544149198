import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "projects/app/src/app/services/auth.service";
import { IReportExecutionListFilters } from "../report-execution-list-filters/report-execution-list-filters.component";
import { UserOrganizationService } from "projects/app/src/app/services/http/clients/user-organization.service";

@Component({
    selector: "app-reports-executions-main",
    templateUrl: "./reports-executions-main.component.html",
    styleUrls: ["./reports-executions-main.component.scss"]
})
export class ReportsExecutionsMainComponent implements OnInit {
    userHasReportFullAccess: boolean;

    listFilters: IReportExecutionListFilters;

    allowToSetMultiOrganization?: boolean;

    constructor(
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly userOrganizationService: UserOrganizationService
    ) {
        this.userHasReportFullAccess = false;
        this.listFilters = {};
    }

    async ngOnInit(): Promise<void> {
        this.userHasReportFullAccess = !this.authService.hasRestrictedAccess();

        await this.checkUserOrganizations();
    }

    onCreateNewReportExecution() {
        if (!this.userHasReportFullAccess) return;
        this.router.navigate([`/reports-edit`], { state: { data: { allowToSetMultiOrganization: this.allowToSetMultiOrganization } } });
    }

    private async checkUserOrganizations(): Promise<void> {
        try {
            const userOrganizations = await this.userOrganizationService.userOrganizationGetForCurrentUser();
            this.allowToSetMultiOrganization = userOrganizations.length > 1;
        } catch (error) {
            this.allowToSetMultiOrganization = false;
        }
    }
}
