<div class="cardWrapper" [ngClass]="{ highlight: highlight }">
    <div class="cardContainer">
        <div class="cardHeader">
            <h2>{{ title }}</h2>
        </div>
        <div class="cardBody">
            <div class="cardItem" *ngFor="let configItem of configItems">
                <lib-editor-item #editorItem [configItem]="configItem" [factory]="factory" (valueChange)="emitValueChanged()"></lib-editor-item>
            </div>
            <ng-content></ng-content>
        </div>
    </div>
</div>
