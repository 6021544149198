import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
    selector: "lib-search-input",
    templateUrl: "./search-input.component.html",
    styleUrls: ["./search-input.component.scss"]
})
export class SearchInputComponent {
    @ViewChild("inputElement")
    inputElement?: ElementRef;

    @Input()
    placeholderText?: string;

    @Input()
    text?: string;

    @Input()
    searchIcon?: boolean;

    @Input()
    removeBorder?: boolean;

    @Input()
    clearValueButton?: boolean;

    @Output()
    textChange: EventEmitter<string> = new EventEmitter<string>();

    @Input()
    disabled?: boolean;

    @Input()
    error?: boolean;

    private specialKeys: Array<string> = [
        "Tab",
        "ArrowLeft",
        "ArrowUp",
        "ArrowDown",
        "ArrowRight",
        "Shift",
        "Control",
        "Alt",
        "CapsLock",
        "Escape",
        "Meta",
        "PageUp",
        "PageDown",
        "End",
        "Home"
    ];

    constructor() {
        this.text = "";
        this.searchIcon = true;
        this.removeBorder = false;
        this.placeholderText = "";
        this.clearValueButton = false;
        this.disabled = false;
        this.error = false;
    }

    protected timeOut?: number;

    keyUp(event: KeyboardEvent): void {
        if (this.specialKeys.includes(event.key)) return;

        if (this.timeOut) clearTimeout(this.timeOut);
        this.timeOut = window.setTimeout(() => {
            this.emitChanges(event);
        }, 500);
    }

    emitChanges(event: Event): void {
        this.textChange.emit((event.target as HTMLInputElement).value);
    }

    clearValue(): void {
        this.text = "";
        this.textChange.emit(this.text);
    }
}
