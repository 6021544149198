import { Injectable } from "@angular/core";
import { IOpportunityProbabilityClient, OpportunityProbabilityClient, OpportunityProbabilityView } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class OpportunityProbabilityService {
    private client: IOpportunityProbabilityClient;
    constructor() {
        this.client = new OpportunityProbabilityClient();
    }

    async getAll(): Promise<OpportunityProbabilityView[]> {
        return await this.client.opportunityProbabilityGetAll();
    }
}
