import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { icons } from "projects/ngx-lib/src/lib/assets/tabIcons";
import { FilterValueOpportunities } from "../../../shared/opportunities-filters/opportunities-filters.component";

@Component({
    selector: "app-opportunities-main",
    templateUrl: "./opportunities-main.component.html",
    styleUrls: ["./opportunities-main.component.scss"]
})
export class OpportunitiesMainComponent implements OnInit {
    readonly icons = icons;

    currentTab: string;

    filterValue: FilterValueOpportunities;

    kanbanIcon?: SafeHtml;
    listIcon?: SafeHtml;
    mapIcon?: SafeHtml;
    calendarIcon?: SafeHtml;

    constructor(
        private readonly router: Router,
        private readonly sanitizer: DomSanitizer
    ) {
        this.currentTab = "List";
        this.filterValue = {};
    }

    ngOnInit(): void {
        this.kanbanIcon = this.sanitizer.bypassSecurityTrustHtml(icons.kanban);
        this.listIcon = this.sanitizer.bypassSecurityTrustHtml(icons.list);
        this.mapIcon = this.sanitizer.bypassSecurityTrustHtml(icons.map);
        this.calendarIcon = this.sanitizer.bypassSecurityTrustHtml(icons.calendar);
    }

    onCreateNewOpportunityClicked() {
        this.router.navigate([`/opportunities-create`]);
    }
}
