import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ActivityLogService } from "../../../services/http/clients/activity-log.service";
import { AwaiterService } from "../../../services/awaiter.service";
import { ActivityLogView, SearchActivityLogsParameters, SearchUsersParameters, UserView } from "../../../services/http/clients/api-proxies";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { UserService } from "../../../services/http/clients/user.service";
import { ITypeAheadService } from "../generic-type-ahead/generic-type-ahead.component";

interface IFilterValueActivityLog {
    searchText: string;
    user?: UserView;
    dateFrom?: Date;
    dateTo?: Date;
}

@Component({
    selector: "app-activity-log-grid",
    templateUrl: "./activity-log-grid.component.html",
    styleUrls: ["./activity-log-grid.component.scss"]
})
export class ActivityLogGridComponent implements OnChanges {
    @Input()
    entityId?: number;

    @Input()
    entityTypeId?: number;

    searchConfig: SearchActivityLogsParameters;

    paginationConfig: IGenericGridPaginationConfig;

    activityLogs?: ActivityLogView[];

    orderConfig?: IGenericGridOrderConfig<ActivityLogView>;

    userServiceFilter: ITypeAheadService<UserView, SearchUsersParameters>;

    filterValue: IFilterValueActivityLog;

    noResults: boolean;

    isLoading: boolean;

    constructor(
        private readonly activityLogService: ActivityLogService,
        private readonly awaiter: AwaiterService,
        private readonly userService: UserService
    ) {
        this.activityLogs = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage
        } as SearchActivityLogsParameters;
        this.userServiceFilter = userService;
        this.noResults = true;
        this.isLoading = true;

        this.filterValue = {
            searchText: ""
        };
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes["entityId"] || changes["entityTypeId"]) {
            if (this.entityId) {
                this.refreshGridView();
            }
        }
    }

    async refreshGridView(): Promise<void> {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Activity logs",
            async () => {
                const response = await this.activityLogService.search(this.searchConfig);
                const { pageInfo, results } = response;
                this.activityLogs = this.activityLogs?.concat(results ?? []);

                this.noResults = this.activityLogs?.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    buildSearchConfig(): void {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage ?? 1,
            filterText: this.filterValue.searchText,
            entityId: this.entityId,
            entityTypeId: this.entityTypeId,
            dateFrom: this.filterValue.dateFrom,
            dateTo: this.filterValue.dateTo,
            sortDirection: this.orderConfig?.order,
            userId: this.filterValue.user?.id,
            sortBy: this.orderConfig?.key
        } as SearchActivityLogsParameters;
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.resetGrid();
        await this.refreshGridView();
    }

    async onFilterChanged(): Promise<void> {
        this.buildFilters();
        this.resetGrid();
        await this.refreshGridView();
    }

    resetGrid(): void {
        this.paginationConfig.currentPage = 1;
        this.activityLogs = [];
    }

    buildFilters(): void {
        this.filterValue = {
            user: this.filterValue?.user,
            dateFrom: this.filterValue.dateFrom,
            dateTo: this.filterValue.dateTo,
            searchText: this.filterValue.searchText
        };
    }
}
