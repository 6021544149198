<ul class="itemsContainer" tabindex="-1">
    <ng-container *ngIf="source && config">
        <li class="item" *ngFor="let item of source" tabindex="0" (click)="onSelectItem(item)" (keydown)="onListKeyDown($event, item)">
            <div class="resetStyles" *ngIf="!config.itemTemplate; else customBlock">
                <span [innerHTML]="item[config.itemDisplayKey] | highlightText: text"></span>
            </div>
            <ng-template #customBlock>
                <ng-container
                    *ngTemplateOutlet="
                        getTemplate();
                        context: {
                            $implicit: { object: item, config, inputText: text }
                        }
                    "
                >
                </ng-container>
            </ng-template>
        </li>
        <li *ngIf="config?.clearSelection" class="clearSelection">
            <button class="resetStyles action" (click)="onClearSelectionClicked()" [disabled]="!selectedItem">Clear selection</button>
        </li>
    </ng-container>
    <ng-container *ngIf="!source?.length && menuOpen">
        <div class="noResultsText">
            <span>{{ noResultsText }}</span>
        </div>
    </ng-container>
    <ng-container *ngIf="action?.includeAction">
        <div class="actionContainer">
            <button class="resetStyles action" (click)="runAction(action?.action)">{{ action?.name }}</button>
        </div>
    </ng-container>
</ul>
