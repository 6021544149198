/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AddressService } from "../../../services/http/clients/address.service";
import { AddressView } from "../../../services/http/clients/api-proxies";

@Component({
    selector: "app-type-ahead-addresses",
    templateUrl: "./type-ahead-addresses.component.html",
    styleUrls: ["./type-ahead-addresses.component.scss"]
})
export class TypeAheadAddressesComponent {
    @Input()
    selectedAddress?: AddressView;

    @Input()
    isReadOnly?: boolean;

    @Input()
    error?: boolean;

    @Output()
    selectedAddressChange = new EventEmitter<AddressView>();

    searchText: string;

    source?: AddressView[];

    constructor(private readonly addressService: AddressService) {
        this.searchText = "";
        this.isReadOnly = false;
    }

    async onAddressTextChanged(): Promise<void> {
        if (this.searchText?.length > 2) {
            const limit = 10;
            this.source = await this.addressService.searchPlaces(this.searchText, limit);
        } else {
            this.source = [];
        }
    }

    onAddressChange(itemSelected: any): void {
        this.selectedAddress = itemSelected;
        this.selectedAddressChange.emit(itemSelected);
        this.source = [];
    }
}
