import { IBaseValidatorArgs, IValidator, IValidatorProp, ValidationError } from "../../interfaces/validator.interface";

export class EmailValidator implements IValidator<string | string[], IBaseValidatorArgs> {
    private emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    private errorMessage = "*Invalid email";

    validate(validatorProp: IValidatorProp<string | string[], IBaseValidatorArgs>): ValidationError | null {
        if (Array.isArray(validatorProp.value)) {
            const errors: { index: number; message: string }[] = [];
            validatorProp.value.forEach((email, index) => {
                if (email && !this.emailRegex.test(email)) {
                    errors.push({ index, message: validatorProp?.args?.customMessage ?? this.errorMessage });
                }
            });

            if (errors.length === 0) return null;

            return { errors };
        } else {
            if (!this.emailRegex.test(validatorProp.value)) return { errorMessage: validatorProp?.args?.customMessage ?? this.errorMessage };
        }

        return null;
    }
}
