import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { AuthService } from "../../../services/auth.service";
import { Router } from "@angular/router";

@Component({
    selector: "app-forgot-password",
    templateUrl: "./forgot-password.component.html",
    styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent implements OnInit {
    constructor(
        private readonly authService: AuthService,
        private readonly router: Router
    ) {}

    ngOnInit(): void {
        this.authService.redirectIfAuthenticated();
    }

    async sendPasswordResetEmail(event: NgForm): Promise<void> {
        if (event.invalid) {
            return;
        }
        await this.authService.sendPasswordResetEmail(event);
    }

    onCancel(event: Event): void {
        event.preventDefault();
        this.router.navigate(["/login"]);
    }
}
