import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NotificationView } from "projects/app/src/app/services/http/clients/api-proxies";

export interface INotificationListPaginationConfig {
    currentPage: number;
    pagesCount: number;
    totalCount: number;
    pageSize?: number;
}

@Component({
    selector: "app-notifications-list",
    templateUrl: "./notifications-list.component.html",
    styleUrls: ["./notifications-list.component.scss"]
})
export class NotificationsListComponent {
    @Input()
    notifications?: NotificationView[];

    @Output()
    notificationChange: EventEmitter<void>;

    @Input()
    paginationConfig?: INotificationListPaginationConfig;

    @Output()
    paginationConfigChange: EventEmitter<INotificationListPaginationConfig> = new EventEmitter<INotificationListPaginationConfig>();

    @Input()
    isPanel?: boolean;

    constructor() {
        this.notificationChange = new EventEmitter<void>();
        this.isPanel = false;
    }

    onNotificationChange() {
        this.notificationChange.emit();
    }

    goNext() {
        if (!this.canGoNext()) return;
        this.changePage(this.paginationConfig!.currentPage + 1);
    }

    canGoNext() {
        if (!this.paginationConfig?.currentPage || !this.paginationConfig?.pagesCount) return false;
        return this.paginationConfig?.currentPage < this.paginationConfig?.pagesCount;
    }

    private changePage(page: number) {
        if (!this.paginationConfig) return;
        this.paginationConfigChange.emit({ ...this.paginationConfig, currentPage: page });
    }
}
