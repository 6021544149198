import { Component, OnInit } from "@angular/core";
import { OpportunityContact } from "projects/app/src/app/models/OpportunityContact";
import { ContactPositions } from "projects/app/src/app/models/enums/ContactPositions";
import {
    ContactView,
    OpportunityContactBuildingContactRoleView,
    OpportunityContactView,
    SearchAvailableOpportunityContactsParameters
} from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityContactService } from "projects/app/src/app/services/http/clients/opportunity-contact.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { ITypeAheadDropdownAction } from "projects/ngx-lib/src/lib/interfaces/type-ahead-dropdown-config.interface";
import { ModalService, ObjectExtensionsService } from "projects/ngx-lib/src/public-api";
import { ModalAddContactComponent } from "../../modals/modal-add-contact/modal-add-contact.component";

@Component({
    selector: "app-opportunity-contact-editor",
    templateUrl: "./opportunity-contact-editor.component.html",
    styleUrls: ["./opportunity-contact-editor.component.scss"]
})
export class OpportunityContactEditorComponent extends EditorComponentBase<OpportunityContactView[]> implements OnInit {
    contactItems: OpportunityContact[];

    selectedContactItems?: OpportunityContact[];

    searchText: string;

    contactPosition?: ContactPositions;

    addContactAction!: ITypeAheadDropdownAction;

    constructor(
        private readonly opportunityContactService: OpportunityContactService,
        private readonly objectExtensionService: ObjectExtensionsService,
        private readonly modalService: ModalService
    ) {
        super();
        this.isReadOnly = !!this.bindContext;
        this.searchText = "";
        this.contactItems = [];
        this.contactPosition = undefined;
    }

    ngOnInit(): void {
        this.contactPosition = this.additionalInfo as ContactPositions;

        this.addContactAction = {
            includeAction: true,
            name: "+ Add new contact",
            action: () => this.openModal()
        };

        if (this.bindingProperty) this.filterContactsByRole();
    }

    async onContactTextChange(event: string) {
        const results = await this.opportunityContactService.searchAvailable({
            filterText: event
        } as SearchAvailableOpportunityContactsParameters);
        this.contactItems = results.map(x => OpportunityContact.fromContactView(x));
    }

    onSelectedContactChange(event: OpportunityContact[]) {
        this.selectedContactItems = event;
        const clonedBindingProperty = this.objectExtensionService.clone(this.bindingProperty) as OpportunityContactView[];

        event.forEach(currentContact => {
            const existingContact = clonedBindingProperty.find(contact => contact.contactId === currentContact.contactId);
            if (existingContact) {
                if (!existingContact.opportunityContactBuildingContactRoles?.some(x => x.buildingContactRoleId === this.contactPosition)) {
                    existingContact.opportunityContactBuildingContactRoles?.push({
                        buildingContactRoleId: this.contactPosition
                    } as OpportunityContactBuildingContactRoleView);
                }
            } else {
                clonedBindingProperty?.push(this.mapToOpportunityContact(currentContact));
            }

            this.bindingProperty = clonedBindingProperty;

            this.valueChange.emit();
        });
    }

    onRemoveContactChange(event: OpportunityContact) {
        if (this.bindingProperty) {
            const clonedBindingProperty = this.objectExtensionService.clone(this.bindingProperty) as OpportunityContactView[];

            const contactIndex = clonedBindingProperty?.findIndex(contact => contact.contactId === event.contactId);
            if (contactIndex !== undefined && contactIndex !== -1) {
                const contact = clonedBindingProperty[contactIndex];

                const positionIndex = contact.opportunityContactBuildingContactRoles?.findIndex(contactRoles => {
                    return contactRoles.buildingContactRoleId === this.contactPosition;
                });

                if (positionIndex !== undefined && positionIndex !== -1) {
                    clonedBindingProperty[contactIndex].opportunityContactBuildingContactRoles?.splice(positionIndex, 1);

                    if (contact.opportunityContactBuildingContactRoles?.length === 0) {
                        clonedBindingProperty.splice(contactIndex, 1);
                    }
                    this.bindingProperty = clonedBindingProperty;
                }
                this.valueChange.emit();
            }
        }
    }

    async openModal(): Promise<void> {
        const contact = await this.modalService.open(ModalAddContactComponent, {
            setCompany: true
        });
        if (contact) {
            const clonedBindingProperty = this.objectExtensionService.clone(this.bindingProperty) as OpportunityContactView[];

            clonedBindingProperty?.push(this.mapToOpportunityContact(contact));

            this.bindingProperty = clonedBindingProperty;

            this.filterContactsByRole();

            this.valueChange.emit();
        }
    }

    filterContactsByRole() {
        const mappedResults = this.bindingProperty?.map(x => OpportunityContact.fromInterface(x));
        this.selectedContactItems = mappedResults?.filter(
            contact => contact.opportunityContactBuildingContactRoles?.some(x => x.buildingContactRoleId === this.contactPosition)
        );
    }

    mapToOpportunityContact(contact: OpportunityContact | ContactView): OpportunityContact {
        return OpportunityContact.fromInterface({
            contactId: contact.id,
            companyId: contact.companyId,
            firstName: contact.firstName,
            lastName: contact.lastName,
            fullName: contact.fullName,
            phone: contact.phone,
            email: contact.email,
            opportunityContactBuildingContactRoles: [{ buildingContactRoleId: this.contactPosition }]
        } as OpportunityContactView);
    }
}
