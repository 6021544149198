<div class="container">
    <lib-generic-dropdown
        name="subDivision"
        id="subDivision"
        [(selectedItem)]="selectedSubDivision"
        (selectedItemChange)="onSubDivisionChange()"
        [source]="subDivisionItems"
        [config]="{
            defaultText: 'Select ' + label,
            itemDisplayKey: 'name'
        }"
        [isReadOnly]="isReadOnly"
        [error]="!isValid"
    >
        ></lib-generic-dropdown
    >
    <app-validation-error-message [show]="!isValid" [errors]="validationErrors"></app-validation-error-message>
</div>
