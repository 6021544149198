<div class="grid-wrapper">
    <div class="grid-container">
        <div class="grid-header">
            <div *ngFor="let column of columnsHeader">
                <div>
                    <span>{{ column.title }}</span>
                </div>
            </div>
        </div>
        <div class="grid-body" *ngIf="rowGridContent && rowGridContent.length > 0">
            <div *ngFor="let inner of rowGridContent" class="grid-body-item">
                <div *ngFor="let item of inner">
                    {{ item.value }}
                </div>
            </div>
        </div>
    </div>
    <div class="scroll-ref" *ngIf="paginationConfig?.autoLoad" libIntersectionListener (intersectionListener)="goNext()"></div>
</div>
