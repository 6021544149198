<div class="cardsContainer">
    <lib-generic-card
        [title]="'Main Info'"
        [highlight]="true"
        [factory]="factory"
        [configItems]="mainInfoCardConfig"
        #mainInfoCard>
    </lib-generic-card>
    <lib-generic-card
        [title]="'Additional Info'"
        [factory]="factory"
        [configItems]="additionalInfoCardConfig"
    >
    </lib-generic-card>

    <lib-generic-card
        [title]="'Location'"
        [factory]="factory"
        [configItems]="locationCardConfig"
        #locationCard
    >
    </lib-generic-card>

    <lib-generic-card
        *ngIf="customFieldsConfig && customFieldsConfig.length"
        [title]="'Custom Info'"
        [factory]="factory"
        [configItems]="customFieldsConfig"
    >
    </lib-generic-card>
</div>
