import { Injectable } from "@angular/core";
import { BidCategoryClient, BidCategoryView, IBidCategoryClient, QuickSearchBidCategoriesParameters } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class BidCategoryService {
    private client: IBidCategoryClient;
    constructor() {
        this.client = new BidCategoryClient();
    }

    async quickSearch(parameters?: QuickSearchBidCategoriesParameters | undefined): Promise<BidCategoryView[]> {
        return await this.client.bidCategoryQuickSearch(parameters);
    }
}
