<div class="calendarContainer">
    <table class="calendarTable">
        <thead>
            <tr>
                <th></th>
                <th *ngFor="let day of currentPeriod" [ngClass]="{'today': isToday(day), 'weekend': isWeekend(day)}">
                    <div class="dayContainer">
                        <span class="dayName">{{ day.toFormat("EEE") }}</span>
                        <span class="dayNumber">{{ day.toFormat("d") }}</span>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let hour of hours; let hourIndex = index">
                <td class="hour" [id]="'hour-' + hourIndex">{{ hour | ampm }}</td>
                <td *ngFor="let day of currentPeriod; let dayIndex = index" class="dayCell" [ngClass]="{'weekend': isWeekend(day)}">
                    <div class="events">
                        <ng-container
                            *ngIf="eventTemplate; else defaultTemplate"
                            [ngTemplateOutlet]="eventTemplate"
                            [ngTemplateOutletContext]="{events: eventsForPeriod[day.toISODate() || ''][hour], position: { row: hourIndex, col: dayIndex }}">
                        </ng-container>
                        <ng-template #defaultTemplate>
                            <div *ngFor="let event of eventsForPeriod[day.toISODate() || ''][hour]">
                                <div class="eventCard" tabindex="0">
                                    <div *ngIf="event.title" class="title">{{ event.title }}</div>
                                    <div *ngIf="event.description" class="description">{{ event.description }}</div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>