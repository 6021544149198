<div class="container">
    <lib-section-header title="{{ title }}" [actionsTemplate]="actionsTemplate" [iconName]="'goBack'">
        <ng-template #actionsTemplate>
            <div class="actions">
                <button class="secondary-btn" (click)="cancel()">Cancel</button>
                <button class="primary-btn" (click)="saveBuilding()">{{ buttonLabel }}</button>
            </div>
        </ng-template>
    </lib-section-header>

    <div class="cardsContainer">
        <lib-generic-card [title]="'Main Info'" [highlight]="true" [factory]="factory" [configItems]="mainInfoCardConfig" #mainInfoCard> </lib-generic-card>
        <lib-generic-card [title]="'Additional Info'" [factory]="factory" [configItems]="additionalInfoCardConfig"> </lib-generic-card>
        <lib-generic-card [title]="'Location'" [factory]="factory" [configItems]="locationCardConfig" #locationCard> </lib-generic-card>
        <lib-generic-card *ngIf="customFieldsConfig && customFieldsConfig.length" [title]="'Custom Info'" [factory]="factory" [configItems]="customFieldsConfig"></lib-generic-card>
    </div>
</div>
