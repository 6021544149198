import { CurrencyPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ReportFilterTypes } from "projects/app/src/app/models/enums/ReportFilterTypes";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { DownloadService } from "projects/app/src/app/services/download.service";
import {
    ReportExecutionView,
    ReportPaginationParameters,
    ReportResultsView,
    ReportTypeFilterView
} from "projects/app/src/app/services/http/clients/api-proxies";
import { ReportExecutionService } from "projects/app/src/app/services/http/clients/report-execution.service";
import { IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";

@Component({
    selector: "app-reports-results",
    templateUrl: "./reports-results.component.html",
    styleUrls: ["./reports-results.component.scss"]
})
export class ReportsResultsComponent implements OnInit {
    reportResults?: any;

    reportExecution?: ReportExecutionView;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: ReportPaginationParameters;

    noResults: boolean;

    isLoading: boolean;

    reportResultsTitle: string;

    reportResultSubtitle: string;

    filtersApplied: { key: string; label: string; value: string }[];

    constructor(
        private readonly router: Router,
        private readonly awaiter: AwaiterService,
        private readonly reportExecutionService: ReportExecutionService,
        private readonly downloadService: DownloadService,
        private currencyPipe: CurrencyPipe
    ) {
        this.reportResults = [];

        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            pageSize: 20,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage
        } as ReportPaginationParameters;

        this.noResults = true;

        this.isLoading = true;

        this.reportResultsTitle = "";

        this.reportResultSubtitle = "";

        this.filtersApplied = [];
    }

    async ngOnInit(): Promise<void> {
        const stateObj = this.router.lastSuccessfulNavigation?.extras.state;
        if (stateObj) {
            this.paginationConfig.currentPage = 1;

            this.reportExecution = stateObj["data"].reportExecution;

            this.reportResultsTitle = this.reportExecution?.description ?? "";

            const dateExecutionData = this.reportExecution?.executionDate ? ` - ${new Date(this.reportExecution?.executionDate).toLocaleString("en-US")}` : "";

            this.reportResultSubtitle = `${this.reportExecution?.reportTypeName} ${dateExecutionData}`;

            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Reports Results",
            async () => {
                const response = await this.searchResults();

                if (response) {
                    const { paginationInfo } = response;
                    this.reportResults = response;

                    if (!this.filtersApplied.length) {
                        const filtersApplied = response.filtersApplied?.filter((f: ReportTypeFilterView) => f.filterValue && f.filterDisplayValue) ?? [];
                        this.buildFiltersApplied(filtersApplied);
                    }

                    this.noResults = this.reportResults.rows.length === 0;

                    this.paginationConfig.pagesCount = paginationInfo?.totalPages ?? 0;
                    this.paginationConfig.currentPage = paginationInfo?.pageNumber ?? 1;
                    this.paginationConfig.totalCount = paginationInfo?.itemsCount ?? 0;
                } else {
                    this.noResults = true;
                }
            },
            loading => (this.isLoading = loading)
        );
    }

    async downloadResults(): Promise<void> {
        const reportExecutionId = this.reportExecution?.id ?? 0;

        if (reportExecutionId) {
            await this.awaiter.awaitAction(
                "Downloading Reports Results",
                async () => {
                    const fileResponse = await this.reportExecutionService.downloadReportResults(reportExecutionId, this.searchConfig);
                    if (fileResponse) {
                        const fileName = fileResponse.fileName ?? `report-${this.reportExecution?.description}.csv`;
                        this.downloadService.downloadFile(fileResponse.data, fileName);
                    }
                },
                loading => (this.isLoading = loading)
            );
        }
    }

    private buildSearchConfig() {
        this.searchConfig.pageNumber = this.paginationConfig.currentPage ?? 1;
        this.searchConfig.pageSize = this.paginationConfig.pageSize ?? 20;
        if (this.reportExecution?.reportExecutionFilters?.length) this.searchConfig.reportExecutionFilters = this.reportExecution?.reportExecutionFilters;
    }

    private async searchResults(): Promise<ReportResultsView | undefined> {
        const reportExecutionId = this.reportExecution?.id ?? 0;
        return reportExecutionId ? await this.reportExecutionService.searchResults(reportExecutionId, this.searchConfig) : undefined;
    }

    private formatFilterApplied(filtersApplied: ReportTypeFilterView[]) {
        for (const item of filtersApplied) {
            switch (item.reportFilterTypeId) {
                case ReportFilterTypes.Text:
                case ReportFilterTypes.Number:
                case ReportFilterTypes.Company:
                case ReportFilterTypes.Division:
                case ReportFilterTypes.Salesperson:
                case ReportFilterTypes.EstimateType:
                case ReportFilterTypes.OpportunityStatus:
                    this.filtersApplied.push({
                        key: item.reportFilterName ?? "",
                        label: item.reportFilterDisplayName ?? "",
                        value: item.filterDisplayValue
                    });
                    break;

                case ReportFilterTypes.Currency:
                    this.filtersApplied.push({
                        key: item.reportFilterName ?? "",
                        label: item.reportFilterDisplayName ?? "",
                        value: this.currencyPipe.transform(item.filterDisplayValue, "USD") ?? ""
                    });
                    break;

                case ReportFilterTypes.Date:
                    this.filtersApplied.push({
                        key: item.reportFilterName ?? "",
                        label: item.reportFilterDisplayName ?? "",
                        value: new Date(item.filterDisplayValue).toLocaleDateString("en-US")
                    });
                    break;

                default:
                    break;
            }
        }
    }

    private buildFiltersApplied(filtersApplied: ReportTypeFilterView[]) {
        if (!filtersApplied.length) this.filtersApplied = [];
        else this.formatFilterApplied(filtersApplied);
    }
}
