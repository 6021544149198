import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ChangeOrderStatuses } from "projects/app/src/app/models/enums/ChangeOrderStatuses";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import {
    GetChangeOrderCostsResult,
    OpportunityChangeOrderEstimateView,
    SearchOpportunityEstimateParameters
} from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityChangeOrderEstimateService } from "projects/app/src/app/services/http/clients/opportunity-change-order-estimate.service";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";

interface CombinedTotals {
    submittedOnHoldTotal: number;
    rejectedCanceledTotal: number;
    acceptedTotal: number;
    baseContractTotal: number;
    baseAcceptedTotal: number;
}

@Component({
    selector: "app-opportunities-single-change-order-grid",
    templateUrl: "./opportunities-single-change-order-grid.component.html",
    styleUrls: ["./opportunities-single-change-order-grid.component.scss"]
})
export class OpportunitiesSingleChangeOrderGridComponent implements OnChanges {
    @Input()
    opportunityId?: number;

    opportunityChangeOrderEstimates: OpportunityChangeOrderEstimateView[];

    searchConfig: SearchOpportunityEstimateParameters;

    paginationConfig: IGenericGridPaginationConfig;

    orderConfig?: IGenericGridOrderConfig<OpportunityChangeOrderEstimateView>;

    combinedTotals?: CombinedTotals;

    noResults: boolean;

    isLoading: boolean;

    constructor(
        private readonly awaiter: AwaiterService,
        private readonly opportunityChangeOrderEstimateService: OpportunityChangeOrderEstimateService
    ) {
        this.opportunityChangeOrderEstimates = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            opportunityId: this.opportunityId
        } as SearchOpportunityEstimateParameters;

        this.noResults = true;
        this.isLoading = true;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.opportunityId && changes["opportunityId"]) {
            this.searchConfig.opportunityId = this.opportunityId;

            await this.awaiter.awaitAction(
                "Loading Change Order Costs",
                async () => {
                    if (this.opportunityId) {
                        const changeOrderCostsResult = await this.opportunityChangeOrderEstimateService.costsOverview(this.opportunityId);
                        this.calculateCombinedTotals(changeOrderCostsResult);
                    }
                },
                loading => (this.isLoading = loading)
            );
            await this.refreshGridView();
        }
    }

    calculateCombinedTotals(changeOrderCostsResult: GetChangeOrderCostsResult) {
        this.combinedTotals = {
            submittedOnHoldTotal: 0,
            rejectedCanceledTotal: 0,
            acceptedTotal: 0,
            baseAcceptedTotal: 0,
            baseContractTotal: 0
        };

        if (!changeOrderCostsResult.changeOrderCosts || !changeOrderCostsResult.finalEstimateSellPrice) return;

        for (const item of changeOrderCostsResult.changeOrderCosts) {
            switch (item.changeOrderStatusId) {
                case ChangeOrderStatuses.Submitted:
                case ChangeOrderStatuses.OnHold:
                    this.combinedTotals.submittedOnHoldTotal += item.total;
                    break;
                case ChangeOrderStatuses.Rejected:
                case ChangeOrderStatuses.Canceled:
                    this.combinedTotals.rejectedCanceledTotal += item.total;
                    break;
                case ChangeOrderStatuses.Accepted:
                    this.combinedTotals.acceptedTotal = item.total;
                    break;
            }

            this.combinedTotals.baseContractTotal = changeOrderCostsResult.finalEstimateSellPrice;
            this.combinedTotals.baseAcceptedTotal = this.combinedTotals.baseContractTotal + this.combinedTotals.acceptedTotal;
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Opportunity Change Order",
            async () => {
                const response = await this.opportunityChangeOrderEstimateService.searchChangeOrdersByOpportunity(this.searchConfig);

                const { pageInfo, results } = response;

                this.opportunityChangeOrderEstimates = this.opportunityChangeOrderEstimates?.concat(results ?? []);

                this.noResults = this.opportunityChangeOrderEstimates.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    buildSearchConfig() {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            sortDirection: this.orderConfig?.order,
            sortBy: this.orderConfig?.key,
            opportunityId: this.opportunityId
        } as SearchOpportunityEstimateParameters;
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;

        this.opportunityChangeOrderEstimates = [];

        await this.refreshGridView();
    }

    onRowClicked(opportunityChangeOrderEstimates: OpportunityChangeOrderEstimateView) {
        window.open(opportunityChangeOrderEstimates?.externalUrl, "_blank");
    }

    refreshPage() {
        window.location.reload();
    }
}
