<div class="calendarHeader">
    <div class="mainControls">
        <button class="resetStyles todayButton" (click)="goToToday()">Today</button>
        <div class="dateControls">
            <div class="navigation">
                <button class="resetStyles arrowButton left" (click)="retreatPeriod()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="8" viewBox="0 0 4 8" fill="none">
                        <path d="M4 8L0 4L4 0V8Z" fill="#98B7D2"/>
                    </svg>
                </button>
                <button class="resetStyles arrowButton right" (click)="advancePeriod()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="8" viewBox="0 0 4 8" fill="none">
                        <path d="M0 8V0L4 4L0 8Z" fill="#98B7D2"/>
                    </svg>
                </button>
            </div>
            <lib-datepicker-dropdown
                [dateRangeEnabled]="false"
                (selectedDateChange)="onDatepickerChange($event)"
                [defaultTitle]="customDateText"
        >
        </lib-datepicker-dropdown>
        </div>

        <span *ngIf="isCurrentPeriod" class="currentIndicator">Current {{ currentView }}</span>
    </div>

    <div class="viewSwitcher" *ngIf="showViewSwitcher">
        <button (click)="setView('day')" class="resetStyles viewButton" [ngClass]="{ selected: currentView === 'day' }">Day</button>
        <button (click)="setView('week')" class="resetStyles viewButton" [ngClass]="{ selected: currentView === 'week' }">Week</button>
        <!-- <button (click)="setView('month')" class="resetStyles viewButton" [ngClass]="{ selected: currentView === 'month' }">Month</button> -->
    </div>
</div>

<lib-day-week-view-calendar
    *ngIf="currentView === 'day'"
    [currentPeriod]="currentPeriod"
    [eventsForPeriod]="eventsForPeriod"
    [eventTemplate]="eventTemplate">
</lib-day-week-view-calendar>

<lib-day-week-view-calendar
    *ngIf="currentView === 'week'"
    [currentPeriod]="currentPeriod"
    [eventsForPeriod]="eventsForPeriod"
    [eventTemplate]="eventTemplate">
</lib-day-week-view-calendar>
