import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class MessageBusService {
	private handlers: { [key: string]: MessageBusHandler[] };

	constructor() {
		this.handlers = {};
	}

	isRegistered(messageType: string): boolean {
		return this.handlers[messageType] !== null && this.handlers[messageType] !== undefined;
	}

	register<T>(messageType: string, handler: (x: T) => void): RegistrationToken {
		if (!this.isRegistered(messageType)) {
			this.handlers[messageType] = [];
		}

		const messageBusHandler = new MessageBusHandler(messageType, handler);
		this.handlers[messageType].push(messageBusHandler);

		return messageBusHandler.token;
	}

	unregister(token: RegistrationToken): void {
		const messageType = token.messageType;

		if (!this.isRegistered(messageType)) {
			return;
		}

		const handler = this.handlers[messageType];

		for (let i = 0; i < handler.length; i++) {
			if (token.id === handler[i].token.id) {
				delete handler[i];
				handler.splice(i, 1);
				break;
			}
		}

		if (handler.length === 0) {
			delete this.handlers[messageType];
		}
	}

	send<T>(messageType: string, message?: T): boolean {
		if (!this.isRegistered(messageType)) {
			return false;
		}

		const handlers = this.handlers[messageType];
		let accepted = false;

		for (let i = handlers.length - 1; i >= 0; i--) {
			const handler = handlers[i];

			if (handler === null || handler === undefined) {
				continue;
			}

			accepted = true;
			handler.handler(message);
		}

		return accepted;
	}
}

export class MessageBusHandler {
	private innerToken: RegistrationToken;

	// eslint-disable-next-line @typescript-eslint/ban-types
	private innerHandler: Function;

	get token(): RegistrationToken {
		return this.innerToken;
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	get handler(): Function {
		return this.innerHandler;
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	constructor(messageType: string, handler: Function) {
		this.innerHandler = handler;
		this.innerToken = new RegistrationToken(messageType);
	}
}

export class RegistrationToken {
	private static id = 0;

	private innerMessageType: string;

	private innerId: number;

	get messageType(): string {
		return this.innerMessageType;
	}

	get id(): number {
		return this.innerId;
	}

	constructor(messageType: string) {
		this.innerMessageType = messageType;
		this.innerId = RegistrationToken.id++;
	}
}
