import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { ContactView, SearchContactsForCompanyParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { ContactService } from "projects/app/src/app/services/http/clients/contact.service";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { ModalService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { ModalConfirmComponent } from "projects/app/src/app/components/shared/modals/modal-confirm/modal-confirm.component";

@Component({
    selector: "app-companies-single-contacts",
    templateUrl: "./companies-single-contacts.component.html",
    styleUrls: ["./companies-single-contacts.component.scss"]
})
export class CompaniesSingleContactsComponent implements OnChanges {
    @Input()
    companyId?: number;

    @Input()
    mainContactId?: number;

    contacts: ContactView[];

    orderConfig?: IGenericGridOrderConfig<ContactView>;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchContactsForCompanyParameters;

    contact?: ContactView;

    noResults: boolean;

    isLoading: boolean;

    constructor(
        private readonly contactService: ContactService,
        private readonly awaiter: AwaiterService,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly snackbarNotificationService: SnackbarNotificationService
    ) {
        this.contacts = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            companyId: this.companyId
        } as SearchContactsForCompanyParameters;

        this.noResults = true;
        this.isLoading = true;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.companyId && changes["companyId"]) {
            this.searchConfig.companyId = this.companyId;
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Contacts",
            async () => {
                const response = await this.contactService.searchContactsForCompany(this.searchConfig);

                const { pageInfo, results } = response;

                this.contacts = this.contacts?.concat(results ?? []);

                this.noResults = this.contacts.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    buildSearchConfig() {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            sortDirection: this.orderConfig?.order,
            sortBy: this.orderConfig?.key,
            companyId: this.companyId
        } as SearchContactsForCompanyParameters;
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;

        this.contacts = [];
        await this.refreshGridView();
    }

    onRowClicked(contact: ContactView) {
        this.router.navigate([`/contacts-single/${contact.id}`]);
    }

    onMouseWheelClicked(contact: ContactView) {
        if (contact?.id && contact.id > 0) {
            window.open(`/contacts-single/${contact.id}`, "_blank");
        }
    }

    async onSelectedContact(contact: ContactView): Promise<void> {
        if (!contact || !this.companyId) return;

        contact.companyId = this.companyId;

        try {
            const contactSaved = await this.contactService.save(contact);

            if (contactSaved) {
                this.contacts.unshift(contactSaved);
                this.noResults = false;
            }
        } catch (error) {
            this.snackbarNotificationService.error("An error occurred while trying to save the contact");
        }
    }

    async removeItem(contact: ContactView): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Remove contact",
            cancelCaption: "Cancel",
            content: "Are you sure you want to remove this contact from the contact list?",
            title: "Remove contact"
        });

        if (!responseOk) return;

        if (!contact) return;
        contact.companyId = undefined;

        await this.awaiter.awaitAction(
            "Removing contact",
            async () => {
                await this.contactService.save(contact);
                const contactIndex = this.contacts.findIndex(c => c.id === contact.id);
                this.contacts.splice(contactIndex, 1);
                this.noResults = this.contacts.length === 0;
            },
            loading => (this.isLoading = loading)
        );
    }
}
