import { MarketService } from "projects/app/src/app/services/http/clients/market.service";
import { MarketEntity } from "./abstract/market-entity";
import { MarketView, OpportunityMarketView } from "projects/app/src/app/services/http/clients/api-proxies";

export class OpportunityMarketEntity extends MarketEntity<OpportunityMarketView> {
    constructor(marketService: MarketService) {
        super(marketService);
    }

    toDestination(entity: MarketView): OpportunityMarketView {
        return { marketId: entity.id, marketName: entity.name, id: 0 };
    }

    toSource(entity: OpportunityMarketView): MarketView {
        return { id: entity.marketId, name: entity.marketName };
    }
}
