<div class="container">
    <lib-section-header
        title="Edit Notifications"
        subTitle="Edit Notifications for the current Organization"
        [actionsTemplate]="actionsTemplate"
        [iconName]="'goBack'"
        [goBackAction]="'base'"
        >
        <ng-template #actionsTemplate>
            <div class="actions" *ngIf="!isModelEqual">
                <lib-helper-info message="Unsaved changes"></lib-helper-info>
                <button class="secondary-btn" (click)="onCancel()">Cancel</button>
                <button class="primary-btn" (click)="onSave()">Save</button>
            </div>
        </ng-template>
    </lib-section-header>

    <div class="cardsContainer">
        <lib-generic-card [title]="'In-app notifications'">
            <div class="cardItem" *ngFor="let item of notificationTypeUserConfiguration">
                <lib-editor-item
                    [configItem]="
                        {
                            label: item.notificationTypeName,
                            componentId: 'toggle',
                            fieldName: 'includeInAppNotification',
                            bindContext: item,
                        }"
                    [factory]="factory"
                    (valueChange)="compareChanges()">
                </lib-editor-item>
                <hr>
            </div>
        </lib-generic-card>

        <lib-generic-card [title]="'Email notifications'">
            <div class="cardItem" *ngFor="let item of notificationTypeUserConfiguration">
                <lib-editor-item
                    [configItem]="
                        {
                            label: item.notificationTypeName,
                            componentId: 'toggle',
                            fieldName: 'includeEmailNotification',
                            bindContext: item,
                        }"
                    [factory]="factory"
                    (valueChange)="compareChanges()">
                </lib-editor-item>
                <hr>
            </div>
        </lib-generic-card>
    </div>
</div>