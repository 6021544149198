import { BidCategoryView, OpportunityBidCategoryView } from "projects/app/src/app/services/http/clients/api-proxies";
import { BidCategoryEntity } from "./abstract/bid-category-entity";
import { BidCategoryService } from "projects/app/src/app/services/http/clients/bid-category.service";

export class OpportunityBidCategoryEntity extends BidCategoryEntity<OpportunityBidCategoryView> {
    constructor(bidCategoryService: BidCategoryService) {
        super(bidCategoryService);
    }

    toDestination(entity: BidCategoryView): OpportunityBidCategoryView {
        return {
            id: 0,
            bidCategoryId: entity.id,
            bidCategoryName: entity.name
        };
    }

    toSource(entity: OpportunityBidCategoryView): BidCategoryView {
        return {
            id: entity.bidCategoryId,
            name: entity.bidCategoryName
        };
    }
}
