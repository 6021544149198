<div class="container">
    <div class="dropdown">
        <div *ngIf="svgIcon && rangeValuesText && rangeValuesText !== dropdownPlaceholder" class="iconContainer" [innerHTML]="svgIcon"></div>
        <button
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            class="resetStyles toggleDropdownButton"
            (click)="toggleDropdown()"
            (keydown)="onKeyPress($event)"
            [ngClass]="{
                placeholder: !rangeValuesText || (rangeValuesText === dropdownPlaceholder),
                icon: svgIcon && rangeValuesText && (rangeValuesText !== dropdownPlaceholder)
            }"
        >
            {{ rangeValuesText ?? dropdownPlaceholder }}
            <span class="arrow" [ngClass]="{ up: opened }">
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                    <path d="M3.43496 5.15834C3.74733 5.46966 4.25462 5.46966 4.56699 5.15834L7.76565 1.97048C7.99556 1.74135 8.06303 1.40015 7.93808 1.10129C7.81313 0.80243 7.52325 0.60817 7.19839 0.60817L0.801058 0.61066C0.478692 0.61066 0.186314 0.80492 0.0613662 1.10378C-0.0635817 1.40264 0.00638908 1.74384 0.233794 1.97297L3.43246 5.16083L3.43496 5.15834Z" fill="#175C89"/>
                </svg>
            </span>
        </button>
    </div>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="opened"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    (detach)="opened = false"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-custom-backdrop'"
    (backdropClick)="opened = false"
    (overlayOutsideClick)="opened = false"
>
<div class="itemsContainer" cdkTrapFocus cdkTrapFocusAutoCapture="true">
        <div class="rangeContainer">
            <div class="valuesContainer">
                <div class="valueWrapper">
                    <div class="label">
                        <span>From</span>
                    </div>
                    <div class="inputValue">
                        <lib-custom-input-with-icon
                            [(value)]="valueFrom"
                            (ngModelChange)="handleFromValueChange($event)"
                            [type]="'number'"
                            [min]="minValue"
                            [max]="maxValue"
                            [svgIcon]="svgIcon"
                            (inputError)="handleInputError($event)"
                        ></lib-custom-input-with-icon>
                        <div *ngIf="fromErrorMessage" class="errorMessage">{{ fromErrorMessage }}</div>
                    </div>
                </div>
                <div class="valueWrapper">
                    <div class="label">
                        <span>To</span>
                    </div>
                    <div class="inputValue">
                        <lib-custom-input-with-icon
                            [(value)]="valueTo"
                            (ngModelChange)="handleToValueChange($event)"
                            [type]="'number'"
                            [min]="minValue"
                            [max]="maxValue"
                            [svgIcon]="svgIcon"
                            (inputError)="handleInputError($event)"
                        ></lib-custom-input-with-icon>
                        <div *ngIf="toErrorMessage" class="errorMessage">{{ toErrorMessage }}</div>
                    </div>
                </div>
            </div>

            <div class="rangeButtons">
                <button class="secondary-btn" (click)="onClear()" tabindex="0" [disabled]="isDisableButton">Clear</button>
                <button class="primary-btn" (click)="onApply()" tabindex="0" [disabled]="isDisableButton">Apply</button>
            </div>
        </div>
    </div>
</ng-template>
