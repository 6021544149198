import { Component, ElementRef, EventEmitter, HostListener, Input, Output, TemplateRef } from "@angular/core";

export interface IDropdownPanelItem {
    id: string;
    name: string;
    action?: () => void;
}

export interface IDropdownPanel {
    items: IDropdownPanelItem[];
}

@Component({
    selector: "lib-dropdown-panel",
    templateUrl: "./dropdown-panel.component.html",
    styleUrls: ["./dropdown-panel.component.scss"]
})
export class DropdownPanelComponent {
    @Input()
    buttonTemplateRef!: TemplateRef<unknown>;

    @Input()
    contentTemplateRef!: TemplateRef<unknown>;

    @Input()
    textContent!: string;

    @Input()
    source: IDropdownPanel;

    opened: boolean;

    @Output()
    openEvent: EventEmitter<void>;

    constructor(private readonly elementRef: ElementRef) {
        this.opened = false;
        this.source = {
            items: []
        };
        this.openEvent = new EventEmitter<void>();
    }

    openClose(event: Event) {
        event.stopPropagation();
        this.opened = !this.opened;
        if (this.opened) {
            this.openEvent.emit();
        }
    }

    action(callback: () => void | undefined, event: Event) {
        event.stopPropagation();
        callback();
    }

    @HostListener("document:click", ["$event"])
    private onDocumentClicked(event: MouseEvent): void {
        if (!this.elementRef.nativeElement.contains(event.target) && this.opened) {
            this.opened = false;
        }
    }
}
