import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { OpportunityCalendarEvent } from "projects/app/src/app/models/enums/OpportunityCalendarEvent";
import { SearchOpportunitiesCalendarResult } from "projects/app/src/app/services/http/clients/api-proxies";

@Component({
    selector: "app-opportunity-card-compact",
    templateUrl: "./opportunity-card-compact.component.html",
    styleUrls: ["./opportunity-card-compact.component.scss"]
})
export class OpportunityCardCompactComponent {
    isExpanded: boolean;

    @Input()
    data?: SearchOpportunitiesCalendarResult;

    @Input()
    position?: { row: number; col: number };

    popupPosition: "top-right" | "top-left" | "bottom-right" | "bottom-left";

    dueDatetype: OpportunityCalendarEvent;
    followUpType: OpportunityCalendarEvent;

    constructor(private readonly router: Router) {
        this.isExpanded = false;
        this.dueDatetype = OpportunityCalendarEvent.DueDate;
        this.followUpType = OpportunityCalendarEvent.FollowUpContactDate;
        this.popupPosition = "top-right";
    }

    togglePopup(): void {
        this.isExpanded = !this.isExpanded;

        if (this.position) {
            const { col, row } = this.position;
            const totalRows = 24;

            const isTopEdge = row < 2;
            const isBottomEdge = row >= totalRows - 2;
            const isLeftEdge = col < 2;

            if (isTopEdge) {
                this.popupPosition = isLeftEdge ? "top-right" : "top-left";
            } else if (isBottomEdge) {
                this.popupPosition = isLeftEdge ? "bottom-right" : "bottom-left";
            } else {
                this.popupPosition = isLeftEdge ? "top-right" : "top-left";
            }
        }
    }

    onGoToClicked(id?: number): void {
        this.router.navigate([`/opportunities-single/${id}`]);
    }

    onKeyUp(e: KeyboardEvent) {
        if (e.key === "Enter" || e.key === "Escape") {
            this.togglePopup();
        }
    }
}
