import { Component, Input } from "@angular/core";
import { User } from "../../models/security/user";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"]
})
export class HeaderComponent {
    @Input()
    isLoggedIn?: boolean;

    @Input()
    user?: User;
}
