<ng-container *ngIf="config?.multiple; else singleSelection">
    <lib-generic-type-ahead-multiselect
        [placeholder]="placeholder"
        [source]="items"
        [(text)]="text"
        (textChange)="onTextChanged()"
        [config]="config"
        [(selectedItems)]="selectedItems"
        (selectedItemsChange)="emitFiltersChanged()"
        [isReadOnly]="isReadOnly"
        [removeKey]="removeKey"
        (removeItemChange)="onRemoveItem($event)"
    >
    </lib-generic-type-ahead-multiselect>
</ng-container>

<ng-template #singleSelection>
    <lib-generic-type-ahead-dropdown
        [placeholder]="placeholder"
        [source]="items"
        [(text)]="text"
        (textChange)="onTextChanged()"
        [config]="config"
        [(selectedItem)]="selectedItem"
        (selectedItemChange)="emitFilterChanged()"
        [isReadOnly]="isReadOnly"
    >
    </lib-generic-type-ahead-dropdown>
</ng-template>
