<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="container" tabindex="-1" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="filterActive && !!text"
        [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
        [cdkConnectedOverlayWidth]="trigger.elementRef.nativeElement.offsetWidth"
        (detach)="closePanel()"
        (overlayOutsideClick)="closePanel()"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayBackdropClass]="'cdk-overlay-custom-backdrop'"
        (backdropClick)="closePanel()"
    >
        <lib-options-menu
            cdkTrapFocus
            [cdkTrapFocusAutoCapture]="true"
            [source]="source"
            [(selectedItem)]="selectedItem"
            [config]="config"
            [menuOpen]="filterActive"
            [text]="text"
            [noResultsText]="noResultsText"
            [action]="action"
            (runActionEmitter)="onRunAction()"
            (selectedItemChange)="selectItem($event)"
            (listKeyDown)="onKeyDownSelectItem($event)"
        ></lib-options-menu>
    </ng-template>

    <ng-container *ngIf="filterActive; else placeholderContent">
        <lib-search-input
            #searchInput
            class="select"
            [(text)]="text"
            [removeBorder]="true"
            [placeholderText]="displayPlaceholder"
            [clearValueButton]="true"
            [disabled]="disabled"
            (click)="activateFilter($event)"
            (textChange)="onTextChanged()"
        >
        </lib-search-input>
    </ng-container>

    <ng-template #placeholderContent>
        <div
            class="selectedItem"
            tabindex="0"
            (click)="activateFilter($event)"
            (keydown)="onKeyDownActivateFilter($event)"
            [ngClass]="{ itemDisabled: disabled || isReadOnly, error: error }"
        >
            <ng-container *ngIf="selectedItem && displaySelected; else displayPlaceholderTemplate">
                <ng-container *ngIf="config?.itemTemplate && opened; else displaySelectedItem">
                    <ng-container
                        *ngTemplateOutlet="
                            getTemplate();
                            context: {
                                $implicit: { object: selectedItem, config, inputText: text }
                            }
                        "
                    >
                    </ng-container>
                </ng-container>

                <ng-template #displaySelectedItem>
                    <span *ngIf="config && config.itemDisplayKey">{{ selectedItem[config.itemDisplayKey] }}</span>
                </ng-template>

                <button
                    *ngIf="selectedItem && !isReadOnly"
                    class="clear-filter"
                    (click)="clearFilter()"
                    (keydown)="onKeyDownClearFilter($event)"
                    [innerHTML]="closeIcon"
                    tabindex="0"
                    aria-label="Clear selection"
                ></button>
            </ng-container>

            <ng-template #displayPlaceholderTemplate>
                <button class="select placeholder" (click)="focusInput()">
                    {{ isReadOnly && !selectedItem ? "" : displayPlaceholder }}
                </button>
            </ng-template>
        </div>
    </ng-template>
</div>
