<div class="container">
    <lib-generic-type-ahead-multiselect
        [isReadOnly]="false"
        [source]="userItems"
        [selectedItems]="selectedUsers"
        (selectedItemsChange)="onSelectedUserChange($event)"
        (removeItemChange)="onRemoveUserChange()"
        [config]="{
            defaultText: 'Add User',
            itemDisplayKey: 'userFullName'
        }"
        [placeholder]="isReadOnly && !bindingProperty || !label ? 'Select an Item' : 'Add ' + label"
        (textChange)="onUserTextChange($event)"
        removeKey="userExternalId"
        [isReadOnly]="isReadOnly"
        [error]="!isValid"
    >
    </lib-generic-type-ahead-multiselect>
</div>

