import { Component, Input } from "@angular/core";
import { UserOrganizationView } from "../../../services/http/clients/api-proxies";

@Component({
    selector: "app-organization-item",
    templateUrl: "./organization-item.component.html",
    styleUrls: ["./organization-item.component.scss"]
})
export class OrganizationItemComponent {
    @Input()
    organization?: UserOrganizationView;
}
