import { ValidatorFactory } from "projects/ngx-lib/src/lib/factories/validator-factory";
import { EventEmitter } from "@angular/core";
import { IEditorItemConfig } from "../interfaces/editor-item-config.interface";
import { ValidationError } from "../interfaces/validator.interface";

/* eslint-disable @typescript-eslint/no-explicit-any */

export class EditorComponentBase<TPropertyType> {
    protected componentId?: string;
    protected bindContext?: object;
    protected fieldName?: string;
    protected additionalInfo?: any;
    protected label?: string;
    protected entityId?: string;
    protected isReadOnly?: boolean;
    protected isValid: boolean;
    valueChange: EventEmitter<boolean>;
    protected validationErrors?: ValidationError[];

    // Validations
    protected readonly validatorFactory: ValidatorFactory;
    protected validations: any[];

    get bindingProperty(): TPropertyType | undefined {
        if (!this.bindContext || !this.fieldName) return;
        return (this.bindContext as any)[this.fieldName];
    }

    set bindingProperty(value: TPropertyType) {
        if (!this.bindContext || !this.fieldName) return;
        (this.bindContext as any)[this.fieldName] = value;
    }

    constructor() {
        this.validatorFactory = new ValidatorFactory();
        this.validations = [];
        this.valueChange = new EventEmitter();
        this.isValid = true;
    }

    initialize({ componentId, bindContext, fieldName, label, additionalInfo, validations, entityId, isReadOnly }: IEditorItemConfig) {
        this.componentId = componentId;
        this.bindContext = bindContext;
        this.fieldName = fieldName;
        this.additionalInfo = additionalInfo;
        this.label = label;
        this.entityId = entityId;
        this.isReadOnly = isReadOnly;

        if (validations?.length) {
            for (const validation of validations) {
                const validator = { ...validation, apply: validation.apply ?? true, type: this.validatorFactory.createValidator(validation.type) };
                this.validations.push(validator);
            }
        }
    }

    validate(): boolean {
        const validationErrors: ValidationError[] = [];
        if (this.validations && this.validations?.length) {
            for (const validator of this.validations) {
                if (validator.apply) {
                    const errors = validator.type.validate({ ...validator, value: this.bindingProperty });

                    if (errors) {
                        validationErrors.push(errors);
                    }

                    if (validationErrors.length > 0) {
                        this.isValid = false;
                        this.validationErrors = validationErrors;
                    } else {
                        this.isValid = true;
                        this.validationErrors = undefined;
                    }
                }
            }
        }
        return this.isValid;
    }

    clearValidationErrors(): void {
        this.isValid = true;
    }
}
