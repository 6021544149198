<div class="cardContainer"
    [ngClass]="
    {
        hasBorder: hasBorder,
        highlightBlue: highlightColor === 'blue',
        highlightYellow: highlightColor === 'yellow',
        softShadow: shadow === 'soft',
        strongShadow: shadow === 'strong'
    }">
    <ng-content></ng-content>
</div>