<div class="container">
    <lib-generic-dropdown
        name="probability"
        id="probability"
        [(selectedItem)]="selectedProbability"
        (selectedItemChange)="onProbabilityChange()"
        [source]="probabilityItems"
        [config]="{
            defaultText: 'Select ' + label,
            itemDisplayKey: 'name'
        }"
        [isReadOnly]="isReadOnly"
        [error]="!isValid"
    ></lib-generic-dropdown>
    <app-validation-error-message [show]="!isValid" [errors]="validationErrors"></app-validation-error-message>
</div>
