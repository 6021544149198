<div class="calendarHeader">
    <div class="mainControls">
        <button class="resetStyles todayButton" (click)="goToToday()">Today</button>
        <div class="dateControls">
            <div class="navigation">
                <button class="resetStyles arrowButton left" (click)="retreatWeek()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="8" viewBox="0 0 4 8" fill="none">
                        <path d="M4 8L0 4L4 0V8Z" fill="#98B7D2"/>
                    </svg>
                </button>
                <button class="resetStyles arrowButton right" (click)="advanceWeek()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="8" viewBox="0 0 4 8" fill="none">
                        <path d="M0 8V0L4 4L0 8Z" fill="#98B7D2"/>
                    </svg>
                </button>
            </div>
            <lib-datepicker-dropdown
                [dateRangeEnabled]="false"
                (selectedDateChange)="onDateChange($event)"
                [defaultTitle]="customDateText"
        >
        </lib-datepicker-dropdown>
        </div>
        <span *ngIf="isCurrentWeek" class="currentWeekIndicator">Current Week</span>
    </div>
    <div class="horizontalLine"></div>
</div>

<div class="calendarContainer">
    <table class="calendarTable">
        <thead>
            <tr>
                <th></th>
                <th *ngFor="let day of currentWeek" [ngClass]="{'today': isToday(day), 'weekend': isWeekend(day)}">
                    <div class="dayContainer">
                        <span class="dayName">{{ day.toFormat("EEE") }}</span>
                        <span class="dayNumber">{{ day.toFormat("d") }}</span>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let hour of hours; let hourIndex = index">
                <td class="hour" [id]="'hour-' + hourIndex">{{ hour | ampm }}</td>
                <td *ngFor="let day of currentWeek; let dayIndex = index" class="dayCell" [ngClass]="{'weekend': isWeekend(day)}">
                    <div class="events">
                        <ng-container
                            *ngIf="eventTemplate; else defaultTemplate"
                            [ngTemplateOutlet]="eventTemplate"
                            [ngTemplateOutletContext]="{events: eventsForWeek[day.toISODate() || ''][hour], position: { row: hourIndex, col: dayIndex }}">
                        </ng-container>
                        <ng-template #defaultTemplate>
                            <div *ngFor="let event of eventsForWeek[day.toISODate() || ''][hour]">
                                <div class="eventCard" tabindex="0">
                                    <div *ngIf="event.title" class="title">{{ event.title }}</div>
                                    <div *ngIf="event.description" class="description">{{ event.description }}</div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>