import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
    selector: "lib-trend-indicator",
    templateUrl: "./trend-indicator.component.html",
    styleUrls: ["./trend-indicator.component.scss"]
})
export class TrendIndicatorComponent implements OnChanges {
    displayValue?: string;
    trendClass?: string;

    @Input()
    previousValue?: number;

    @Input()
    currentValue?: number;

    constructor() {
        this.previousValue = 0;
        this.currentValue = 0;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["previousValue"] || changes["currentValue"]) {
            this.calculatePercentageChange();
        }
    }

    calculatePercentageChange(): void {
        if (typeof this.currentValue === "undefined" || typeof this.previousValue === "undefined") {
            this.displayValue = "0%";
            this.trendClass = "neutral";
            return;
        }

        if (this.previousValue === 0) {
            if (this.currentValue === 0) {
                this.displayValue = "0%";
                this.trendClass = "neutral";
            } else {
                this.displayValue = "-%";
                this.trendClass = this.currentValue > 0 ? "positive" : "negative";
            }
            return;
        }

        const percentageChange = ((this.currentValue - this.previousValue) / Math.abs(this.previousValue)) * 100;

        if (percentageChange === 0) {
            this.displayValue = "0%";
            this.trendClass = "neutral";
        } else {
            this.displayValue = `${Math.abs(percentageChange).toFixed(2)}%`;
            this.trendClass = percentageChange >= 0 ? "positive" : "negative";
        }
    }
}
