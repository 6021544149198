import { Injectable } from "@angular/core";
import { EntityCustomFieldValueView, EntityTypeCustomFieldClient, EntityTypeCustomFieldView, IEntityTypeCustomFieldClient, SearchEntityTypeCustomFieldParameters } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class EntityTypeCustomFieldService {
    private client: IEntityTypeCustomFieldClient;
    constructor() {
        this.client = new EntityTypeCustomFieldClient();
    }

    async search(body: SearchEntityTypeCustomFieldParameters): Promise<EntityTypeCustomFieldView[]> {
        return await this.client.entityTypeCustomFieldSearch(body);
    }

    async save(body: EntityTypeCustomFieldView[]): Promise<EntityTypeCustomFieldView[]> {
        return await this.client.entityTypeCustomFieldSave(body);
    }

    async getCustomFieldsByEntityType(entityTypeId: number): Promise<EntityCustomFieldValueView[]> {
        return await this.client.entityTypeCustomFieldGetCustomFieldsByEntityType(entityTypeId);
    }

    async activate(id :number): Promise<void> {
        return await this.client.entityTypeCustomFieldActivate(id);
    }

    async deactivate(id :number): Promise<void> {
        return await this.client.entityTypeCustomFieldDeactivate(id);
    }
}
