<lib-dropdown-panel [buttonTemplateRef]="notificationsPanel" [source]="panel"></lib-dropdown-panel>

<ng-template #notificationsPanel>
    <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 17 17" fill="none">
            <path d="M2.72727 15.0001C4.2335 15.0001 5.45455 13.779 5.45455 12.2728C5.45455 10.7666 4.2335 9.54553 2.72727 9.54553C1.22104 9.54553 0 10.7666 0 12.2728C0 13.779 1.22104 15.0001 2.72727 15.0001Z" fill="black"/>
            <path d="M2.72727 5.45455C4.2335 5.45455 5.45455 4.2335 5.45455 2.72727C5.45455 1.22104 4.2335 0 2.72727 0C1.22104 0 0 1.22104 0 2.72727C0 4.2335 1.22104 5.45455 2.72727 5.45455Z" fill="black"/>
            <path d="M12.2732 15.0001C13.7794 15.0001 15.0004 13.779 15.0004 12.2728C15.0004 10.7666 13.7794 9.54553 12.2732 9.54553C10.7669 9.54553 9.5459 10.7666 9.5459 12.2728C9.5459 13.779 10.7669 15.0001 12.2732 15.0001Z" fill="black"/>
            <path d="M12.2732 5.45455C13.7794 5.45455 15.0004 4.2335 15.0004 2.72727C15.0004 1.22104 13.7794 0 12.2732 0C10.7669 0 9.5459 1.22104 9.5459 2.72727C9.5459 4.2335 10.7669 5.45455 12.2732 5.45455Z" fill="black"/>
        </svg>
    </span>
</ng-template>