import { Injectable } from "@angular/core";
import { EstimateTypeClient, EstimateTypeView, IEstimateTypeClient } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class EstimateTypeService {
    private client: IEstimateTypeClient;
    constructor() {
        this.client = new EstimateTypeClient();
    }

    async getAll(): Promise<EstimateTypeView[]> {
        return await this.client.estimateTypeGetAll();
    }

    async getAwardableEstimateTypes(): Promise<EstimateTypeView[]> {
        return await this.client.estimateTypeGetAwardablesEstimateTypes();
    }
}
