import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
	selector: 'lib-list-view',
	templateUrl: './list-view.component.html',
	styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent<TItem = unknown, THeader = unknown, TFooter = unknown> {
	@Input()
	source?: Array<TItem>;

	@Input()
	itemTemplate?: TemplateRef<{ $implicit: { object: TItem } }>;

	@Input()
	valueProperty?: keyof TItem;

	@Input()
	headerTemplate?: TemplateRef<THeader>;

	@Input()
	footerTemplate?: TemplateRef<TFooter>;

	@Input()
	selectedItem?: TItem;

	@Output()
	selectedItemChange: EventEmitter<TItem>;

	constructor() {
		this.selectedItemChange = new EventEmitter<TItem>();
	}

	onItemClicked(item: TItem): void {
		this.selectedItem = item;
		this.selectedItemChange.emit(item);
	}
}
