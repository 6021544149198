import { Injectable } from "@angular/core";
import { IReportTypeClient, ReportTypeClient, ReportTypeColumnView, ReportTypeFilterView, ReportTypeView, SearchReportTypeParameters } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class ReportTypeService {
    client: IReportTypeClient;
    constructor() {
        this.client = new ReportTypeClient();
    }

    async getAll(): Promise<ReportTypeView[]> {
        return await this.client.reportTypeGetAll();
    }

    async search(body: SearchReportTypeParameters): Promise<ReportTypeView[]> {
        return await this.client.reportTypeSearch(body);
    }

    async getColumnsByReportType(reportTypeId: number): Promise<ReportTypeColumnView[]> {
        return await this.client.reportTypeGetColumnsByReportType(reportTypeId);
    }

    async getFiltersByReportType(reportTypeId: number): Promise<ReportTypeFilterView[]> {
        return await this.client.reportTypeGetFiltersByReportType(reportTypeId);
    }
}
