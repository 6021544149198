import { ProductTypeView } from "projects/app/src/app/services/http/clients/api-proxies";
import { IComponentEditorEntity } from "../../interfaces/editor-entity.interface";
import { ProductTypeService } from "projects/app/src/app/services/http/clients/product-type.service";

export abstract class ProductTypeEntity<TEntityDestination> implements IComponentEditorEntity<ProductTypeView, TEntityDestination> {
    constructor(private readonly productTypeService: ProductTypeService) {}

    abstract toDestination(entity: ProductTypeView): TEntityDestination;

    abstract toSource(entity: TEntityDestination): ProductTypeView;

    async getSource(): Promise<ProductTypeView[]> {
        return await this.productTypeService.getAll();
    }
}
