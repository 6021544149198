<lib-section-header title="Notifications" [actionsTemplate]="actionsTemplate" [iconName]="'notifications'">
    <ng-template #actionsTemplate>
        <div class="actions">
            <button class="primary-btn" (click)="markAllAsRead()" [disabled]="!hasUnread">Mark all as read</button>
            <app-kebab-menu>
                <button role="menuitem" class="resetStyles" (click)="onEdit()">Edit</button>
            </app-kebab-menu>
        </div>
    </ng-template>
</lib-section-header>

<div class="notificationsContainer">
    <ng-container #noResults *ngIf="noResults; else showContent">
        <lib-no-data [iconName]="'emptyNotifications'" [title]="'No notifications yet.'" [text]="'Notifications about your activity will show up here.'"></lib-no-data>
    </ng-container>

    <ng-template #showContent>
        <app-notifications-list
            [notifications]="notifications"
            [(paginationConfig)]="paginationConfig"
            (paginationConfigChange)="refreshListView()"
            [isPanel]="false">
        </app-notifications-list>
    </ng-template>
</div>