import { Injectable } from "@angular/core";
import {
    ContactView,
    IOpportunityContactClient,
    OpportunityContactClient,
    OpportunityContactViewPaginatedResultDto,
    SearchAvailableOpportunityContactsParameters,
    SearchContactsForOpportunityParameters
} from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class OpportunityContactService {
    private client: IOpportunityContactClient;
    constructor() {
        this.client = new OpportunityContactClient();
    }

    async search(body: SearchContactsForOpportunityParameters): Promise<OpportunityContactViewPaginatedResultDto> {
        return await this.client.opportunityContactSearchForOpportunity(body);
    }

    async searchAvailable(body: SearchAvailableOpportunityContactsParameters): Promise<ContactView[]> {
        return await this.client.opportunityContactSearchAvailable(body);
    }

    async delete(id: number): Promise<void> {
        return await this.client.opportunityContactDelete(id);
    }

    async opportunityContactUpdateCompany(id: number) {
        return await this.client.opportunityContactUpdateContactCompany(id)
    }
}
