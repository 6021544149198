import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { OpportunityView } from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityDashboardService } from "projects/app/src/app/services/http/clients/opportunity-dashboard.service";

type DashboardOpportunitiesGrid = "newProjects" | "upcomingBids";

@Component({
    selector: "app-dashboard-opportunities-grid",
    templateUrl: "./dashboard-opportunities-grid.component.html",
    styleUrls: ["./dashboard-opportunities-grid.component.scss"]
})
export class DashboardOpportunitiesGridComponent implements OnInit {
    @Input()
    type: DashboardOpportunitiesGrid;

    title: string;

    dashboardOpportunities?: OpportunityView[];

    constructor(
        private readonly awaiter: AwaiterService,
        private readonly opportunityDashboardService: OpportunityDashboardService,
        private readonly router: Router
    ) {
        this.type = "newProjects";
        this.title = "";
    }

    async ngOnInit(): Promise<void> {
        await this.awaiter.awaitAction("Loading Dashboard Opportunities", async () => {
            if (this.type === "newProjects") {
                this.title = "New Projects";
                this.dashboardOpportunities = await this.opportunityDashboardService.getNewProjects();
            } else {
                this.title = "Upcoming Bids";
                this.dashboardOpportunities = await this.opportunityDashboardService.getUpcomingBids();
            }
        });
    }

    onRowClicked(opportunity: OpportunityView) {
        this.router.navigate([`/opportunities-single/${opportunity.id}`]);
    }
}
