import { Injectable } from "@angular/core";
import { EntityTypeClient, IEntityTypeClient, ReserveEntityOptionView, SearchMainEntitiesParameters, SearchMainEntitiesResultDto } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class EntityTypeService {
    private client: IEntityTypeClient;
    constructor() {
        this.client = new EntityTypeClient();
    }

    async searchMainEntities(body: SearchMainEntitiesParameters): Promise<SearchMainEntitiesResultDto[]> {
        return await this.client.entityTypeSearchMainEntities(body);
    }

    async getReservedOptions(): Promise<ReserveEntityOptionView[]> {
        return await this.client.entityTypeGetReserveOptions();
    }
}
