import { Component, OnInit } from "@angular/core";
import { CompanyTypeView } from "projects/app/src/app/services/http/clients/api-proxies";
import { CompanyTypeService } from "projects/app/src/app/services/http/clients/company-type.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-company-type-editor",
    templateUrl: "./company-type-editor.component.html",
    styleUrls: ["./company-type-editor.component.scss"]
})
export class CompanyTypeEditorComponent extends EditorComponentBase<number | null> implements OnInit {
    companyTypeItems?: CompanyTypeView[];
    selectedCompanyType?: CompanyTypeView;

    constructor(private readonly companyTypeService: CompanyTypeService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.companyTypeItems = await this.companyTypeService.getAll();
        if (this.bindingProperty) this.selectedCompanyType = this.companyTypeItems?.find(x => x.id === this.bindingProperty);
    }

    onCompanyTypeChange() {
        if (this.selectedCompanyType?.id) {
            this.bindingProperty = this.selectedCompanyType.id;
        } else {
            this.bindingProperty = null;
        }
    }
}
