import { Injectable } from "@angular/core";
import {
    INotificationClient,
    NotificationClient,
    NotificationView,
    NotificationViewPaginatedResultDto,
    SearchNotificationsParameters
} from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class NotificationService {
    private client: INotificationClient;
    constructor() {
        this.client = new NotificationClient();
    }

    async search(body: SearchNotificationsParameters): Promise<NotificationViewPaginatedResultDto> {
        return await this.client.notificationSearch(body);
    }

    async getLatest(): Promise<NotificationView[]> {
        return await this.client.notificationGetLatestForCurrentUser();
    }

    async markAsRead(id: number): Promise<void> {
        await this.client.notificationMarkAsRead(id);
    }

    async markAllAsRead(): Promise<void> {
        await this.client.notificationMarkAllAsRead();
    }
}
