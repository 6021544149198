import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { icons } from "../../assets/icons";

@Component({
    selector: "lib-section-sub-header",
    templateUrl: "./section-sub-header.component.html",
    styleUrls: ["./section-sub-header.component.scss"]
})
export class SectionSubHeaderComponent implements OnInit {
    @Input()
    title?: string;

    @Input()
    subTitle?: string;

    @Input()
    iconName!: keyof typeof icons;

    @Input()
    info!: TemplateRef<unknown>;

    @Input()
    selected?: boolean;

    @Output()
    subHeaderClicked: EventEmitter<void>;

    svg?: SafeHtml;

    constructor(private readonly sanitizer: DomSanitizer) {
        this.selected = false;
        this.subHeaderClicked = new EventEmitter<void>();
    }

    ngOnInit(): void {
        this.svg = this.sanitizer.bypassSecurityTrustHtml(icons[this.iconName]);
    }

    onClick() {
        this.subHeaderClicked.emit();
    }
}
