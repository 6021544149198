import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { EstimateTypes } from "projects/app/src/app/models/enums/EstimateTypes";
import { OpportunityAction } from "projects/app/src/app/models/enums/OpportunityAction";
import { OpportunityStatuses } from "projects/app/src/app/models/enums/OpportunityStatuses";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { OpportunityEstimateView } from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityEstimateService } from "projects/app/src/app/services/http/clients/opportunity-estimate.service";
import { OpportunityService } from "projects/app/src/app/services/http/clients/opportunity.service";
import { icons } from "projects/ngx-lib/src/lib/assets/tabIcons";

@Component({
    selector: "app-opportunities-single-estimates",
    templateUrl: "./opportunities-single-estimates.component.html",
    styleUrls: ["./opportunities-single-estimates.component.scss"]
})
export class OpportunitiesSingleEstimatesComponent implements OnInit, OnChanges {
    @Input()
    opportunityId!: number;

    @Input()
    opportunityEstimates?: OpportunityEstimateView[];

    @Output()
    validate: EventEmitter<void>;

    canCreateEstimate: boolean;

    currentTab: string;

    finalEstimateId?: number;

    showSummary: boolean;

    alternateEstimatesIcon?: SafeHtml;

    finalEstimatesIcon?: SafeHtml;

    changeOrderIcon?: SafeHtml;

    isLoading: boolean;

    requestedStatus: OpportunityStatuses;

    constructor(
        private readonly opportunityEstimateService: OpportunityEstimateService,
        private readonly opportunityService: OpportunityService,
        private readonly sanitizer: DomSanitizer,
        private readonly awaiter: AwaiterService
    ) {
        this.currentTab = "Final Estimates";
        this.showSummary = false;
        this.validate = new EventEmitter<void>();
        this.isLoading = true;
        this.requestedStatus = OpportunityStatuses.EstimateRequested;
        this.canCreateEstimate = false;
    }

    async ngOnInit(): Promise<void> {
        this.alternateEstimatesIcon = this.sanitizer.bypassSecurityTrustHtml(icons.alternateEstimates);
        this.finalEstimatesIcon = this.sanitizer.bypassSecurityTrustHtml(icons.finalEstimates);
        this.changeOrderIcon = this.sanitizer.bypassSecurityTrustHtml(icons.changeOrder);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.opportunityId && changes["opportunityId"]) {
            const availableActions = await this.opportunityService.availableActions(this.opportunityId);
            this.canCreateEstimate = availableActions.includes(OpportunityAction.CreateEstimate);
            await this.awaiter.awaitAction(
                "Loading Estimates Info",
                async () => {
                    this.opportunityEstimates = await this.opportunityEstimateService.getEstimatesInfoByOpportunity(this.opportunityId);
                    this.finalEstimateId = this.getFinalEstimateId(this.opportunityEstimates);
                },
                loading => (this.isLoading = loading)
            );
        }
    }

    getFinalEstimateId(opportunityEstimates: OpportunityEstimateView[]) {
        if (opportunityEstimates.length > 1 && opportunityEstimates[1].estimateTypeId === EstimateTypes.Final && opportunityEstimates[1].isSelected) {
            return opportunityEstimates[1].estimateId;
        } else return 0;
    }

    async createBaseEstimate() {
        if (!this.canCreateEstimate) return;
        this.validate.emit();
    }

    refreshPage() {
        window.location.reload();
    }
}
