<lib-generic-dropdown
    name="estimateType"
    id="estimateType"
    [(selectedItem)]="selectedEstimateType"
    (selectedItemChange)="onEstimateTypeChange()"
    [source]="estimateTypes"
    [config]="{
        defaultText: 'Select ' + label,
        itemDisplayKey: 'name'
    }"
    [isReadOnly]="isReadOnly"
>
    ></lib-generic-dropdown
>
