<div class="viewSwitcher" *ngIf="showViewSwitcher">
    <button (click)="setView('day')" class="primary-btn small">Day</button>
    <button (click)="setView('week')" class="primary-btn small">Week</button>
    <button (click)="setView('month')"class="primary-btn small">Month</button>
</div>

<lib-week-view-calendar
    *ngIf="currentView === 'week'"
    [events]="events" [eventTemplate]="eventTemplate"
    (dateRangeSelected)="onDateRangeSelected($event)">
</lib-week-view-calendar>