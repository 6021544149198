import { IBaseValidatorArgs, IValidator, IValidatorProp, ValidationError } from "../../interfaces/validator.interface";

const isObject = (obj: unknown) => obj && obj.constructor && obj.constructor === Object;
export class RequiredValidator implements IValidator<unknown, IBaseValidatorArgs> {
    validate(validatorProp: IValidatorProp<unknown, IBaseValidatorArgs>): ValidationError | null {
        if (
            !validatorProp.value ||
            (validatorProp.value instanceof Array && !validatorProp.value.length) ||
            (isObject(validatorProp.value) && !Object.keys(validatorProp.value).length)
        )
            return { errorMessage: validatorProp?.args?.customMessage ?? "*Field required" };

        return null;
    }
}
