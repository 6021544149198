import { Injectable } from "@angular/core";
import { CityClient, CityView, ICityClient, SearchCityParameters } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class CityService {
    private client: ICityClient;
    constructor() {
        this.client = new CityClient();
    }

    async search(body: SearchCityParameters): Promise<CityView[]> {
        return await this.client.citySearch(body);
    }

    async getById(id: number): Promise<CityView> {
        return await this.client.cityGetById(id);
    }
}
