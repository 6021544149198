<div class="container">
    <lib-generic-dropdown-multiselect
        name="entity"
        id="entity"
        [source]="source"
        [(selectedItems)]="selectedItems"
        (selectedItemsChange)="onSelectedItemChange()"
        [config]="{
            multiple: true,
            defaultText: 'Select ' + label,
            itemDisplayKey: additionalInfo?.itemDisplayKey ?? 'name'
        }"
        [isReadOnly]="isReadOnly"
        [error]="!isValid"
    >
    </lib-generic-dropdown-multiselect>
    <app-validation-error-message [show]="!isValid" [errors]="validationErrors"></app-validation-error-message>
</div>
