import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, TemplateRef } from "@angular/core";
import { TabItemComponent } from "./tab-item/tab-item.component";

export interface ITabPaginationConfig {
    currentPage: number;
    pagesCount: number;
    totalCount: number;
    pageSize?: number;
}
export class TabChangeArgs {
    canChange?: Promise<boolean>;

    constructor(
        public readonly currentTabIndex: number,
        public readonly futureTabIndex: number,
        public readonly currentTabName?: string,
        public readonly futureTabName?: string
    ) {
        this.canChange = undefined;
    }
}

@Component({
    selector: "lib-tab-view",
    templateUrl: "./tab-view.component.html",
    styleUrls: ["./tab-view.component.scss"]
})
export class TabViewComponent implements OnChanges, AfterContentInit {
    _activeComponent!: TabItemComponent;

    @ContentChildren(TabItemComponent)
    tabs!: QueryList<TabItemComponent>;

    @Input()
    currentTab?: string;

    // Fixed content that doesn't re render regardless current tab position.
    @Input()
    fixedContentHeader?: TemplateRef<unknown>;

    @Output()
    currentTabChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    beforeTabChange: EventEmitter<TabChangeArgs> = new EventEmitter<TabChangeArgs>();

    @Input()
    hasSteps?: boolean;

    @Input()
    hasQuantity?: boolean;

    @Input()
    direction: "row" | "column" = "row";

    @Input()
    alignment?: "start" | "center" | "end" = "start";

    @Input()
    separation?: "narrow" | "normal" | "wide" = "normal";

    @Input()
    renderAll = false;

    @Input()
    paginationConfig?: ITabPaginationConfig;

    @Input()
    containerBackground?: boolean;

    @Output()
    paginationConfigChange: EventEmitter<ITabPaginationConfig> = new EventEmitter<ITabPaginationConfig>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["currentTab"] && changes["currentTab"].currentValue && this.tabs) {
            const tab = this.tabs.find(x => x.tabName === this.currentTab);
            if (tab) this.setActiveComponent(tab);
        }
    }

    ngAfterContentInit(): void {
        if (this.currentTab) {
            const tab = this.tabs.find(x => x.tabName === this.currentTab);
            if (tab) this.setActiveComponent(tab);
        } else this.setActiveComponent(this.tabs.first);
    }

    getActiveComponent(): TabItemComponent {
        return this._activeComponent;
    }

    async setActiveComponent(value: TabItemComponent): Promise<void> {
        if (this._activeComponent === value) return;
        const currentTabIndex = this.getCurrentTabIndex();
        const currentTabName = this._activeComponent?.tabName;
        const futureTabIndex = this.getTabIndex(value);
        const futureTabName = value?.tabName;

        // we send and event informing that the tab change will happen, but let the parent control
        // decide if that change can happen or not.
        const eventArgs = new TabChangeArgs(currentTabIndex, futureTabIndex, currentTabName, futureTabName);
        this.beforeTabChange.emit(eventArgs);

        if (eventArgs.canChange) {
            const result = await eventArgs.canChange;
            if (!result) return;
        }

        this._activeComponent = value;
        this.currentTabChange.emit(futureTabName);
    }

    nextTab(): void {
        const currentIndex = this.getCurrentTabIndex();
        // Current shouldn't be the last one
        if (currentIndex >= 0 && currentIndex <= this.tabs.length - 2) {
            this.setActiveComponentByIndex(currentIndex + 1);
        }
    }

    previousTab(): void {
        const currentIndex = this.getCurrentTabIndex();
        // At least 1 to be able to go back to first tab
        if (currentIndex >= 1) {
            this.setActiveComponentByIndex(currentIndex - 1);
        }
    }

    goNext() {
        if (!this.canGoNext()) return;
        this.changePage(this.paginationConfig!.currentPage + 1);
    }

    private setActiveComponentByIndex(futureTabIndex: number): void {
        const activeTab = this.getTabByIndex(futureTabIndex);
        if (activeTab && !activeTab.disabled) {
            this.setActiveComponent(activeTab);
        }
    }

    private getTabByIndex(index: number): TabItemComponent | undefined {
        return this.tabs.get(index);
    }

    private getTabIndex(tab: TabItemComponent): number {
        return this.tabs.toArray().indexOf(tab);
    }

    private getCurrentTabIndex(): number {
        return this.getTabIndex(this._activeComponent);
    }

    private canGoNext() {
        if (!this.paginationConfig?.currentPage || !this.paginationConfig?.pagesCount) return false;
        return this.paginationConfig?.currentPage < this.paginationConfig?.pagesCount;
    }

    private changePage(page: number) {
        if (!this.paginationConfig) return;
        this.paginationConfigChange.emit({ ...this.paginationConfig, currentPage: page });
    }
}
