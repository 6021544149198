<section class="container">
    <article class="header" *ngIf="title">
        <h2 class="title">{{ title }}</h2>
        <lib-grid-actions [actions]="actions"></lib-grid-actions>
    </article>
    <article class="filters" *ngIf="filterComponents && !readOnly">
        <ng-container *ngFor="let child of filterComponents">
            <ng-container *ngTemplateOutlet="child.templateRef"></ng-container>
        </ng-container>
    </article>
    <article>
        <lib-generic-grid
            *ngIf="gridConfig"
            [source]="source"
            (rowClicked)="onRowClicked($event)"
            [(selectedItems)]="_selectedItems"
            (selectedItemsChange)="selectedItemsChanged()"
            [columnsConfig]="gridConfig.columnsConfig"
            [config]="gridConfig.config"
            [(orderConfig)]="orderConfig"
            (orderConfigChange)="orderConfigChanged()"
            [(paginationConfig)]="paginationConfig"
            (paginationConfigChange)="pageChanged()"
            [isReadOnly]="readOnly"
            [hasExternalLink]="hasExternalLink"
        >
        </lib-generic-grid>
    </article>
</section>
