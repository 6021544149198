<div class="wrapper">
    <div class="addContact">
        <app-type-ahead-contacts
            [setCompany]="allowSetCompany"
            [searchParameters]="typeAheadSearchByEntityParameters"
            (selectedContactChange)="onSelectedContact($event)"
        >
        </app-type-ahead-contacts>
    </div>
</div>

<ng-container *ngIf="noResults && !isLoading; else showContent">
    <lib-no-data [iconName]="'emptyData'" [title]="'No Contacts available.'"> </lib-no-data>
</ng-container>

<ng-template #showContent>
    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'contactFullName', label: 'Name', sortable: true },
                { key: 'phone', label: 'Phone', sortable: false, cellTemplate: formatPhoneArray },
                { key: 'email', label: 'Email', sortable: false, cellTemplate: formatEmailArray },
                { key: 'buildingContactRoleName', label: 'Role', sortable: true },
                { key: 'companyName', label: 'Company', sortable: true, cellTemplate: companyNameTemplate },
                { key: 'id', sortable: false, cellTemplate: kebabMenu, customClasses: ['small-grid', 'action-grid'] }
            ]
        }"
        [source]="contacts"
        [(orderConfig)]="orderConfig"
        (orderConfigChange)="onOrderChanged()"
        [(paginationConfig)]="paginationConfig"
        (paginationConfigChange)="refreshGridView()"
        (rowClicked)="onRowClicked($event)"
    >
        <ng-template #kebabMenu let-data>
            <app-kebab-menu>
                <button role="menuitem" class="resetStyles" (click)="editItem(data.object)">Edit</button>
                <button role="menuitem" class="resetStyles" (click)="removeItem(data.object)">Remove</button>
                <button
                    role="menuitem"
                    *ngIf="data.object.companyId !== data.object.currentCompanyId"
                    class="resetStyles"
                    (click)="updateContactFreezedData(data.object)"
                    >
                        Update Data
                </button>
            </app-kebab-menu>
        </ng-template>

        <ng-template #formatEmailArray let-data>
            {{ data.object.email | commaSeparated }}
        </ng-template>

        <ng-template #formatPhoneArray let-data>
            {{ data.object.phone | commaSeparated }}
        </ng-template>

        <ng-template #companyNameTemplate let-data>
            <app-descriptive-item
                *ngIf="data.object.companyId !== data.object.currentCompanyId"
                [text]="data.object.companyName"
                [tooltip]="'This information is not updated. You can update it in the menu at the end of this row'"
            ></app-descriptive-item>
            <div *ngIf="data.object.companyId === data.object.currentCompanyId">{{ data.object.companyName }}</div>
        </ng-template>
    </lib-grid-view>
</ng-template>
