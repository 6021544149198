import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { EditorComponentBase } from "../../classes/editor-component-base";
import { ObjectExtensionsService } from "../../services/object-extensions.service";
import { Overlay, ScrollStrategy } from "@angular/cdk/overlay";
import { IGenericTypeAheadDropdownConfig } from "../../interfaces/type-ahead-dropdown-config.interface";
import { SearchInputComponent } from "../search-input/search-input.component";

@Component({
    selector: "lib-generic-dropdown-multiselect",
    templateUrl: "./generic-dropdown-multiselect.component.html",
    styleUrls: ["./generic-dropdown-multiselect.component.scss"]
})
export class GenericDropdownMultiselectComponent<T> extends EditorComponentBase<T> implements OnInit, OnChanges {
    private _originalItems?: T[];
    private _selectedItems?: T[];

    protected scrollStrategy: ScrollStrategy;

    @ViewChild("searchInput")
    searchInput?: SearchInputComponent;

    @Input()
    source?: T[];

    @Input()
    config?: IGenericTypeAheadDropdownConfig<T>;

    @Input()
    disabled: boolean;

    @Input()
    selectedItems?: T[];

    @Output()
    selectedItemsChange = new EventEmitter<T[]>();

    @Output()
    removeItemChange = new EventEmitter<T>();

    @Input()
    override isReadOnly?: boolean;

    @Input()
    disabledInputTypeText?: boolean;

    @Input()
    removeKey?: string;

    @Input()
    noResultsText?: string;

    @Input()
    placeholder?: string;

    @Input()
    text: string;

    @Input()
    error?: boolean;

    sourceItems?: T[];
    menuOpen: boolean;

    focusTrapEnabled?: boolean;

    constructor(
        private readonly objectExtensionsService: ObjectExtensionsService,
        private readonly overlay: Overlay
    ) {
        super();
        this.menuOpen = false;
        this.disabled = false;
        this.text = "";
        this.noResultsText = "No matches found";
        this.sourceItems = [];
        this.disabledInputTypeText = false;
        this.error = false;
        this.scrollStrategy = this.overlay.scrollStrategies.close();
        this.focusTrapEnabled = false;
    }

    ngOnInit(): void {
        this.config = {
            ...this.config,
            multiple: this.config?.multiple ?? true
        } as IGenericTypeAheadDropdownConfig<T>;
        if (!this.selectedItems) this.selectedItems = [];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["selectedItems"]?.currentValue) {
            this.selectedItems = this.selectedItems ? [...this.selectedItems] : undefined;
        }
        if (changes["source"]?.currentValue && changes["source"]?.firstChange) {
            if (changes["source"].firstChange) {
                this._originalItems = this.objectExtensionsService.clone(this.source) as T[] | undefined;
                this.sourceItems = this.source;
            }
        }
    }

    /**
     * Set the selected item
     * @param item Item clicked
     */
    selectItem(item: T): void {
        if (!this.isSelected(item)) {
            (this.selectedItems as T[]).push(item);
            this.sourceItems = this.updateList(this.sourceItems);
            if (this.selectedItems?.length) this.emitItemsChanged(this.selectedItems);
            this.menuOpen = false;
        }
    }

    updateList(list: T[] | undefined): T[] | undefined {
        if (!list || list.length === 0) return [];

        if (list.length > 0) return this.removeSelectedItems(list);
        return [];
    }

    isSelected(item: T): boolean {
        return !!this._selectedItems?.includes(item);
    }

    emitItemsChanged(items: T[]): void {
        this.selectedItemsChange.emit(items);
    }

    selectItemKeydownHandler({ event, item }: { event: KeyboardEvent; item: T }): void {
        if (event.key === "Enter" || event.key === " ") {
            event.preventDefault();
            this.selectItem(item);
        }
    }

    onKeyPress(e: KeyboardEvent) {
        if (e.key === "Enter") {
            this.toggleDropdown();
        }

        if (e.key === "Tab" && this.menuOpen) {
            this.focusTrapEnabled = true;
        }

        if (e.key === "Escape") {
            this.menuOpen = false;
        }
    }

    private getItemsAndEmitChanges(): void {
        const items = this.selectedItems ? [...this.selectedItems] : undefined;
        if (this.menuOpen) this._originalItems = this.objectExtensionsService.clone(items) as T[] | undefined;
        this.emitItemsChanged(items || []);
        if (items) if (this.objectExtensionsService.isEqualTo(items, this._originalItems)) return;
    }

    removeItem(item: T) {
        const objectIndex = this.selectedItems?.indexOf(item);
        if (objectIndex != undefined && objectIndex >= 0) this.selectedItems?.splice(objectIndex, 1);

        this.sourceItems = this.removeSelectedItems(this.source);
        this.getItemsAndEmitChanges();
        this.removeItemChange.emit(item);
        if (this.selectedItems?.length === 0) this.menuOpen = false;
    }

    private removeSelectedItems(source: T[] | undefined): T[] | undefined {
        if (!source) return;

        if (this.selectedItems?.length !== undefined) {
            return source.filter(
                sourceItem =>
                    !this.selectedItems?.some(selectedItem => (selectedItem as any)[this.removeKey ?? "id"] === (sourceItem as any)[this.removeKey ?? "id"])
            );
        } else {
            return source;
        }
    }

    async onTextChanged(event: string): Promise<void> {
        if (event === "") {
            this.sourceItems = this.source;
        }

        this.sourceItems = this.updateList(
            this.source?.filter(item => (item[this.config?.itemDisplayKey as keyof T] as string).toLowerCase().includes(event.toLowerCase()))
        );
    }

    openOptionsMenu(): void {
        if (this.isReadOnly) return;
        this.sourceItems = this.updateList(this.source);
        this.menuOpen = true;
        this.focusInput();
    }

    toggleDropdown(): void {
        this.menuOpen = !this.menuOpen;
        this.focusInput();
    }

    getTemplate(): TemplateRef<unknown> | null {
        if (!this.config?.itemTemplate) return null;
        return this.config.itemTemplate;
    }

    closePanel() {
        this.menuOpen = false;
        this.text = "";
    }

    focusInput() {
        if (this.menuOpen) {
            this.focusTrapEnabled = false;
            setTimeout(() => {
                this.searchInput?.inputElement?.nativeElement.focus();
            }, 0);
        }
    }
}
