<div class="container">
    <div class="filterItem">
        <div class="filter">
            <lib-generic-dropdown
                [source]="filterOptions?.condition"
                [config]="{
                    multiple: false,
                    defaultText: 'Select Condition',
                    itemDisplayKey: 'name'
                }"
                [(selectedItem)]="customizationFilters.condition"
                (selectedItemChange)="onFilterChanged()"
            >
            </lib-generic-dropdown>
        </div>
    </div>

    <div class="filterItem">
        <div class="filter">
            <lib-generic-dropdown
                [source]="filterOptions?.type"
                [config]="{
                    multiple: false,
                    defaultText: 'Select Type',
                    itemDisplayKey: 'name'
                }"
                [(selectedItem)]="customizationFilters.type"
                (selectedItemChange)="onFilterChanged()"
            >
            </lib-generic-dropdown>
        </div>
    </div>
</div>
