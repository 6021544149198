import { Component, OnInit } from "@angular/core";
import { NotificationView, SearchNotificationsParameters } from "../../../services/http/clients/api-proxies";
import { NotificationService } from "../../../services/http/clients/notification.service";
import { AwaiterService } from "../../../services/awaiter.service";
import { INotificationListPaginationConfig } from "../../header/notifications-panel/notifications-list/notifications-list.component";
import { Router } from "@angular/router";

@Component({
    selector: "app-notifications",
    templateUrl: "./notifications.component.html",
    styleUrls: ["./notifications.component.scss"]
})
export class NotificationsComponent implements OnInit {
    notifications?: NotificationView[];

    searchConfig: SearchNotificationsParameters;

    paginationConfig: INotificationListPaginationConfig;

    hasUnread?: boolean;

    noResults: boolean;

    constructor(
        private readonly notificationService: NotificationService,
        private readonly awaiter: AwaiterService,
        private readonly router: Router
    ) {
        this.notifications = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage
        } as SearchNotificationsParameters;
        this.noResults = false;
    }

    async ngOnInit(): Promise<void> {
        await this.refreshListView();
        this.updateUnreadStatus();
    }

    async refreshListView(): Promise<void> {
        await this.awaiter.awaitAction("Loading Notifications", async () => {
            this.searchConfig.pageNumber = this.paginationConfig.currentPage ?? 1;
            const response = await this.notificationService.search(this.searchConfig);
            const { pageInfo, results } = response;
            this.notifications = this.notifications?.concat(results ?? []);

            this.noResults = this.notifications?.length === 0;

            this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
            this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
            this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
        });
    }

    async markAllAsRead(): Promise<void> {
        await this.awaiter.awaitAction("Updating Notifications", async () => {
            if (this.hasUnread) await this.notificationService.markAllAsRead();
        });

        this.notifications?.forEach(notification => {
            if (!notification.isRead) {
                notification.isRead = true;
            }
        });
        this.updateUnreadStatus();
    }

    updateUnreadStatus(): void {
        this.hasUnread = this.notifications && this.notifications.length > 0 && this.notifications?.some(notification => !notification.isRead);
    }

    onEdit(): void {
        this.router.navigate(["/notifications-edit"]);
    }
}
