import { ILogProvider } from '../../interfaces/log-provider.interface';
import { LogType } from '../../services/log.service';

export default class ConsoleLogProvider implements ILogProvider {
	/**
	 * Adds a trace log.
	 * @param message the message to be logged.
	 * @param [optionalParams = null] the optional parameters array.
	 */
	trace(message: string, optionalParams?: Array<unknown>): void {
		message = this.formatMessage(LogType.Trace, message);
		optionalParams ? console.trace(message, optionalParams) : console.trace(message);
	}

	/**
	 * Adds a debug log.
	 * @param message the message to be logged.
	 * @param [optionalParams = null] the optional parameters array.
	 */
	debug(message: string, optionalParams?: Array<unknown>): void {
		message = this.formatMessage(LogType.Debug, message);
		optionalParams ? console.debug(message, optionalParams) : console.debug(message);
	}

	/**
	 * Adds an information log.
	 * @param message the message to be logged.
	 * @param [optionalParams = null] the optional parameters array.
	 */
	info(message: string, optionalParams?: Array<unknown>): void {
		message = this.formatMessage(LogType.Info, message);
		optionalParams ? console.info(message, optionalParams) : console.info(message);
	}

	/**
	 * Adds a warning log.
	 * @param message the message to be logged.
	 * @param [optionalParams = null] the optional parameters array.
	 */
	warn(message: string, optionalParams?: Array<unknown>): void {
		message = this.formatMessage(LogType.Warning, message);
		optionalParams ? console.warn(message, optionalParams) : console.warn(message);
	}

	/**
	 * Adds an error log.
	 * @param message the message to be logged.
	 * @param [optionalParams = null] the optional parameters array.
	 */
	error(message: string, optionalParams?: Array<unknown>): void {
		message = this.formatMessage(LogType.Error, message);
		optionalParams ? console.error(message, optionalParams) : console.error(message);
	}

	private formatMessage(type: LogType, message: string): string {
		return `[${new Date().toJSON()}][${LogType[type].toUpperCase()}] - ${message}`;
	}
}
