<div class="container">
    <div class="leftSection">
        <button *ngIf="iconName === 'goBack'; else nonClickableIcon" (click)="goBack()" [attr.aria-label]="'Go back'">
            <span class="icon" [innerHTML]="svg"></span>
        </button>

        <ng-template #nonClickableIcon>
            <span *ngIf="iconName" class="icon" [innerHTML]="svg"></span>
        </ng-template>

        <div class="mainInfo">
            <p
                class="title"
                [ngClass]="
                {
                    primary: titleStyle === 'primary',
                    secondary: titleStyle === 'secondary',
                    tertiary: titleStyle === 'tertiary'
                }">
                {{ title }}
            </p>
            <p *ngIf="!!subTitle" class="subtitle">{{ subTitle }}</p>
        </div>
        <ng-container *ngTemplateOutlet="mainAdditionalTemplate"></ng-container>
    </div>

    <div *ngIf="infoTemplate || actionsTemplate" class="rightSection" [ngClass]="{ info: infoTemplate, actions: actionsTemplate }">
        <ng-container *ngTemplateOutlet="infoTemplate"></ng-container>
        <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
    </div>
</div>
