import { AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";
import { SnackbarNotificationComponent } from "../snackbar-notification/snackbar-notification.component";
import { SnackbarNotificationService } from "../../../services/snackbar-notification.service";

@Component({
    selector: "lib-snackbar-notifications-container",
    templateUrl: "./snackbar-notifications-container.component.html",
    styleUrls: ["./snackbar-notifications-container.component.scss"]
})
export class SnackbarNotificationsContainerComponent implements AfterViewInit {
    constructor(private snackbarNotificationService: SnackbarNotificationService) {}

    @ViewChild("container", { read: ViewContainerRef })
    viewContainerRef!: ViewContainerRef;

    ngAfterViewInit(): void {
        this.snackbarNotificationService.setViewContainer(this.viewContainerRef);
        this.snackbarNotificationService.setDefaultNotificationComponent(SnackbarNotificationComponent);
    }
}
