import { Component } from "@angular/core";
import { FormattedInputEditorComponent } from "../formatted-input-editor.component";
import { CustomPercentagePipe } from "projects/app/src/app/pipes/custom-percentage.pipe";

@Component({
    selector: "app-percentage-input-editor",
    templateUrl: "../formatted-input-editor.component.html",
    styleUrls: ["../formatted-input-editor.component.scss"]
})
export class PercentageInputEditorComponent extends FormattedInputEditorComponent {
    constructor(private readonly customPercentagePipe: CustomPercentagePipe) {
        super();
    }

    override formatValue(inputValue: number) {
        return this.customPercentagePipe.transform(inputValue);
    }

    override onBlur(): void {
        const amount = this.reverseFormatNumber(this.input.nativeElement.value).replace(/^[^0-9]{2}..*/g, "");
        this.bindingProperty = amount / 100;
        this.input.nativeElement.value = this.formatValue(this.bindingProperty);
        this.valueChange.emit();
        this.validate();
    }
}
