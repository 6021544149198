<lib-generic-dropdown
    name="legalStatus"
    id="legalStatus"
    [(selectedItem)]="selectedLegalStatus"
    (selectedItemChange)="onLegalStatusChange()"
    [source]="legalStatusItems"
    [config]="{
        defaultText: 'Select Legal Status',
        itemDisplayKey: 'name'
    }"
    [isReadOnly]="isReadOnly"
></lib-generic-dropdown>
