import { SnackbarNotificationService } from "../services/snackbar-notification.service";
import { ISnackbarNotificationParameter } from "./snackbar-notification-parameter.interface";
import { SnackbarNotificationInstance } from "./snackbar-notification-instance";

export class SnackbarNotificationComponentBase {
    protected snackbarNotificationService?: SnackbarNotificationService;

    protected snackbarNotificationInstance?: SnackbarNotificationInstance;

    protected parameters?: ISnackbarNotificationParameter;

    setNotificationService(notificationService: SnackbarNotificationService) {
        this.snackbarNotificationService = notificationService;
    }

    setParameters(parameters: ISnackbarNotificationParameter): void {
        this.parameters = parameters;
    }

    setNotificationInstance(notificationInstance: SnackbarNotificationInstance): void {
        this.snackbarNotificationInstance = notificationInstance;
    }

    close(): void {
        this.snackbarNotificationInstance?.close();
    }

    throw(error: string): void {
        this.snackbarNotificationInstance?.throw(error);
    }
}
