<div class="cardsContainer">
    <lib-generic-card
        [title]="'Main Info'"
        [highlight]="true"
        [factory]="factory"
        [configItems]="mainInfoCardConfig"
        (valueChange)="emitValueChanged()"
        #mainInfoCard
    >
    </lib-generic-card>

    <lib-generic-card
        [title]="'Organization'"
        [highlight]="true"
        [factory]="factory"
        [configItems]="organizationCardConfig"
        (valueChange)="emitValueChanged()"
        #organizationCard
    >
    </lib-generic-card>

    <lib-generic-card
        [title]="'Building'"
        [factory]="factory"
        [configItems]="buildingCardConfig"
        (valueChange)="emitValueChanged()"
        #buildingCard
    >
    </lib-generic-card>

    <lib-generic-card
        [title]="'Opportunity Details'"
        [factory]="factory"
        [configItems]="detailsCardConfig"
        (valueChange)="emitValueChanged()"
        #detailsCard
    >
    </lib-generic-card>

    <lib-generic-card
        [title]="'Customer'"
        [factory]="factory"
        [configItems]="customerCardConfig"
        (valueChange)="emitValueChanged()"
    >
    </lib-generic-card>

    <lib-generic-card
        [title]="'Contracting Team'"
        [factory]="factory"
        [configItems]="contractingTeamCardConfig"
        (valueChange)="emitValueChanged()"
    >
    </lib-generic-card>

    <lib-generic-card
        [title]="'Bid Info'"
        [factory]="factory"
        [configItems]="bidInfoCardConfig"
        (valueChange)="emitValueChanged()"
    >
    </lib-generic-card>

    <lib-generic-card
        *ngIf="customFieldsConfig && customFieldsConfig.length"
        [title]="'Custom Info'"
        [factory]="factory"
        [configItems]="customFieldsConfig"
        (valueChange)="emitValueChanged()">
    </lib-generic-card>
</div>
