import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "customPercentage"
})
export class CustomPercentagePipe implements PipeTransform {
    transform(value?: number | string): string | null {
        const numericValue = typeof value === "string" ? parseFloat(value) : value;

        if ((numericValue && isNaN(numericValue)) || !numericValue) return null;

        const formattedNumber = (numericValue * 100).toFixed(2);
        return `@${formattedNumber}%`;
    }
}
