import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { NoteView } from "projects/app/src/app/services/http/clients/api-proxies";
import { NoteService } from "projects/app/src/app/services/http/clients/note.service";
import { ModalEditNoteComponent } from "../../modals/modal-edit-note/modal-edit-note.component";
import { ModalService } from "projects/ngx-lib/src/lib/services/modal.service";
import { ModalConfirmComponent } from "../../modals/modal-confirm/modal-confirm.component";
import { AuthService } from "projects/app/src/app/services/auth.service";

export interface INoteEdit {
    index: number;
    editedNote?: NoteView;
}

@Component({
    selector: "app-note-content",
    templateUrl: "./note-content.component.html",
    styleUrls: ["./note-content.component.scss"]
})
export class NoteContentComponent implements OnChanges {
    @Input()
    noteId?: number;

    @Input()
    index?: number;

    note?: NoteView;

    @Output()
    noteChanged: EventEmitter<INoteEdit>;

    canEditNote: boolean;

    constructor(
        private readonly noteService: NoteService,
        private readonly modalService: ModalService,
        private readonly authService: AuthService
    ) {
        this.noteChanged = new EventEmitter<INoteEdit>();
        this.canEditNote = false;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes["noteId"] && changes["index"] && this.noteId) {
            this.note = await this.noteService.getById(this.noteId);
            this.canEditNote =
                !this.authService.hasRestrictedAccess() ||
                (this.authService.hasRestrictedAccess() && this.authService.getUserFromStorage()?.id === this.note?.userId);
        }
    }

    async editNote(index: number) {
        if (this.note) {
            const editedNote = await this.modalService.open(ModalEditNoteComponent, {
                note: this.note,
                entityId: this.note.entityId,
                entityTypeId: this.note.entityTypeId
            });

            if (!editedNote) return;

            this.note = editedNote;
            this.noteChanged.emit({
                index,
                editedNote
            });
        }
    }

    async removeNote(id: number, index: number) {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Remove",
            cancelCaption: "Cancel",
            content: "Are you sure you want to remove this note?",
            title: "Remove note"
        });

        if (!responseOk) return;

        await this.noteService.delete(id);
        this.noteChanged.emit({
            index
        });
    }
}
