import { IStringValidatorArgs, IValidator, IValidatorProp, ValidationError } from "../../interfaces/validator.interface";

export class StringValidator implements IValidator<string | string[], IStringValidatorArgs> {
    validate(validatorProp: IValidatorProp<string | string[], IStringValidatorArgs>): ValidationError | null {
        const errors: { index: number; message: string }[] = [];
        const minLengthMessage = `*Field is shorter than the minimum length of ${validatorProp.args.minLength} characters`;
        const maxLengthMessage = `*Field exceeds the maximum length of ${validatorProp.args.maxLength} characters`;

        if (Array.isArray(validatorProp.value)) {
            validatorProp?.value?.forEach((item, index) => {
                if (validatorProp?.args?.minLength && item.length < validatorProp.args.minLength) {
                    errors.push({ index, message: validatorProp?.args?.customMessage ?? minLengthMessage });
                } else if (validatorProp?.args?.maxLength && item.length > validatorProp.args.maxLength) {
                    errors.push({ index, message: validatorProp?.args?.customMessage ?? maxLengthMessage });
                }
            });

            if (errors.length === 0) return null;
            return { errors };
        } else {
            if (validatorProp?.args?.minLength && validatorProp?.value?.length < validatorProp.args.minLength) {
                return { errorMessage: validatorProp?.args?.customMessage ?? minLengthMessage };
            }

            if (validatorProp?.args?.maxLength && validatorProp?.value?.length > validatorProp.args.maxLength) {
                return { errorMessage: validatorProp?.args?.customMessage ?? maxLengthMessage };
            }
        }

        return null;
    }
}
