export enum ReportFilterTypes {
    Text = 1,
    Number,
    Bool,
    Currency,
    Date,
    Company,
    Division,
    Salesperson,
    EstimateType,
    OpportunityStatus
}
