import { Component, Input } from "@angular/core";

@Component({
    selector: "app-location-details",
    templateUrl: "./location-details.component.html",
    styleUrls: ["./location-details.component.scss"]
})
export class LocationDetailsComponent {
    @Input()
    location?: any;
}
