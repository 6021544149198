<div
    *ngIf="notification"
    class="container"
    [ngClass]="{ unread: !notification.isRead, panel: isPanel }"
    tabindex="0"
    (keyup.enter)="onClick(notification.id)"
    (click)="onClick(notification.id)">
    <div class="contentWrapper">
        <div class="icon" [innerHTML]="svg"></div>

        <div class="textWrapper">
            <span class="time timePanel">{{ timeAgoDate }}</span>
            <div class="title">
                {{ notification.title }}
            </div>
            <div class="message">
                {{ notification.message }}
            </div>
        </div>
    </div>
    <div class="statusWrapper">
        <span class="time timePage">{{ timeAgoDate }}</span>
        <div class="unreadDotContainer">
            <div *ngIf="!notification.isRead" class="unreadDot"></div>
        </div>
    </div>
</div>