<lib-generic-dropdown-multiselect
    [source]="buildingClassItems"
    [(selectedItems)]="selectedBuildingClasses"
    (selectedItemsChange)="onSelectedProductTypeChange()"
    [config]="{
        multiple: true,
        defaultText: 'Add Class',
        itemDisplayKey: 'name'
    }"
    [isReadOnly]="isReadOnly"
>
</lib-generic-dropdown-multiselect>
