<article class="container">
    <section class="imageSection">
		<img src="../../../../assets/images/login-side-image.png" alt="Image showing Building" />
	</section>

	<section class="loginSection">
		<svg xmlns="http://www.w3.org/2000/svg" width="156" height="73" viewBox="0 0 156 73" fill="none" class="logo">
			<path d="M10.5273 33.8984V50.3609H15.0304V38.4156H23.5604V33.8984H10.5273Z" fill="#8AACC1"/>
			<path d="M41.828 38.4166V21.9541H37.325V33.8994H28.7949V38.4166H41.828Z" fill="#136392"/>
			<path d="M15.0253 45.3408V50.3599H10.5222V45.4613C9.23279 46.2744 8.45914 47.6897 8.44922 49.2055C8.44922 50.3799 8.91539 51.5042 9.74856 52.3374C10.5817 53.1705 11.7025 53.6323 12.8729 53.6323C15.3129 53.6323 17.2966 51.6447 17.2966 49.2055C17.2966 47.5994 16.4238 46.1238 15.0253 45.3408Z" fill="#8AACC1"/>
			<path d="M37.3261 26.9721V21.9531H41.8291V26.8517C43.1185 26.0386 43.8922 24.6232 43.9021 23.1075C43.9021 20.6582 41.9184 18.6807 39.4784 18.6807C37.0384 18.6807 35.0547 20.6682 35.0547 23.1075C35.0547 24.7136 35.9275 26.1892 37.3261 26.9721Z" fill="#136392"/>
			<path d="M39.5032 27.423C41.9957 27.423 44.0162 25.4006 44.0162 22.9058C44.0162 20.4111 41.9957 18.3887 39.5032 18.3887C37.0108 18.3887 34.9902 20.4111 34.9902 22.9058C34.9902 25.4006 37.0108 27.423 39.5032 27.423Z" fill="#136392"/>
			<path d="M26.1836 0V72.2743L52.3688 61.6138V10.6805L26.1836 0ZM28.8418 5.93251L47.8856 13.702V58.5823L28.8418 66.3418V5.93251Z" fill="#324A5E"/>
			<path d="M26.1852 0V72.2743L0 61.6138V10.6805L26.1852 0ZM23.527 5.93251L4.48323 13.702V58.5823L23.527 66.3418V5.93251Z" fill="#A5AEB5"/>
			<path d="M82.007 48.7536C82.007 49.667 81.5904 50.4199 80.7473 51.0021C79.9935 51.5341 79.1405 51.8052 78.2082 51.8052H67.7539V20.3357H77.1469C78.1982 20.3357 78.8727 20.406 79.1703 20.5566C81.0647 21.0585 82.007 22.3333 82.007 24.371V48.7536ZM79.7852 47.81V25.1239C79.7852 23.4777 78.9025 22.6545 77.1469 22.6545H69.9955V49.6068H77.2659C78.9421 49.6068 79.7852 49.0045 79.7852 47.81ZM90.0709 51.7751H87.7995V20.3558H90.0709V51.7751ZM110.979 20.3357L103.461 51.755H101.636L93.7209 20.3357H95.9923L102.628 45.8626L108.748 20.3357H110.989H110.979ZM137.77 51.8554H121.523V49.6369L134.318 30.675C135.022 29.631 135.379 28.5168 135.379 27.3524C135.379 26.4991 135.181 25.686 134.784 24.9031L134.189 23.9595C133.762 23.6082 133.395 23.3572 133.078 23.2066C132.056 22.7047 130.836 22.4538 129.408 22.4538C126.552 22.4538 124.637 24.1201 123.685 27.4427H121.444C122.515 22.6345 125.163 20.2354 129.388 20.2354C131.918 20.2354 133.891 20.8376 135.31 22.0522C136.807 23.3371 137.551 25.2042 137.551 27.6635C137.551 28.9484 137.274 30.1028 136.708 31.1367L124.31 49.5566H137.77V51.8554ZM155.177 42.0481C155.177 46.5251 153.471 49.5064 150.049 51.0021C148.878 51.5141 147.708 51.7751 146.518 51.7751H141.39V49.5566L146.448 49.5366C148.521 49.5165 150.148 48.7737 151.328 47.288C152.449 45.8726 153.005 44.126 153.005 42.0481C153.005 40.4822 152.598 39.1471 151.795 38.0329C150.614 36.4168 148.75 35.6037 146.22 35.5836L141.707 35.5635V20.3458H154.651V22.6144H143.979V33.3451H147.113C149.583 33.3451 151.547 34.1883 153.034 35.8647C154.463 37.4607 155.177 39.5286 155.177 42.0582V42.0481Z" fill="#136392"/>
		</svg>
		<div class="greeting">Hello,</div>
		<div class="welcomeMessage">Welcome</div>
		<div class="signInPrompt">Please sign in to continue</div>
		<button class="primary-btn loginButton" (click)="redirectToLogin()" tabindex="0">
			<div class="buttonContent">
				Sign in
				<svg xmlns="http://www.w3.org/2000/svg" width="103" height="16" viewBox="0 0 103 16" fill="none">
					<path d="M1 7C0.447715 7 -4.82823e-08 7.44772 0 8C4.82823e-08 8.55228 0.447715 9 1 9L1 7ZM102.707 8.7071C103.098 8.31657 103.098 7.68341 102.707 7.29288L96.3431 0.928924C95.9526 0.5384 95.3195 0.5384 94.9289 0.928924C94.5384 1.31945 94.5384 1.95261 94.9289 2.34314L100.586 7.99999L94.9289 13.6568C94.5384 14.0474 94.5384 14.6805 94.9289 15.0711C95.3195 15.4616 95.9526 15.4616 96.3431 15.0711L102.707 8.7071ZM1 9L102 8.99999L102 6.99999L1 7L1 9Z" fill="white"/>
				</svg>
			</div>
		</button>
	</section>
</article>