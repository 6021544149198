<div class="container" *ngIf="bindingProperty && fieldName">
    <ng-container *ngFor="let item of bindingProperty; let i = index; trackBy: trackByFn">
        <div class="inputWrapper">
            <input
                type="text"
                [id]="fieldName + i"
                placeholder="Add {{ label }}"
                [(ngModel)]="bindingProperty[i]"
                (ngModelChange)="dataChanged()"
                [name]="fieldName + i"
                [readOnly]="isReadOnly"
                [ngClass]="{ error: errorIndexes.hasOwnProperty(i) }"
            />
            <app-validation-error-message [show]="errorIndexes.hasOwnProperty(i)" [errors]="[{ errorMessage: errorIndexes[i] }]"></app-validation-error-message>
            <button *ngIf="!isReadOnly" class="removeIcon resetStyles" (click)="removeField(i)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <mask id="mask0_7349_53193" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                        <rect width="16" height="16" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_7349_53193)">
                        <path
                            d="M4.33464 8.57925H11.668V7.4214H4.33464V8.57925ZM8.00259 15.3337C6.98833 15.3337 6.03497 15.1412 5.14251 14.7563C4.25004 14.3713 3.47372 13.8489 2.81355 13.189C2.15337 12.5292 1.63073 11.7532 1.24562 10.8611C0.860521 9.96905 0.667969 9.01588 0.667969 8.00162C0.667969 6.98735 0.860437 6.03399 1.24537 5.14153C1.63031 4.24906 2.15272 3.47275 2.81259 2.81258C3.47247 2.1524 4.24845 1.62975 5.14052 1.24465C6.03258 0.859545 6.98574 0.666992 8.00001 0.666992C9.01428 0.666992 9.96764 0.859461 10.8601 1.2444C11.7526 1.62933 12.5289 2.15174 13.189 2.81161C13.8492 3.4715 14.3719 4.24748 14.757 5.13955C15.1421 6.0316 15.3346 6.98477 15.3346 7.99903C15.3346 9.0133 15.1422 9.96666 14.7572 10.8591C14.3723 11.7516 13.8499 12.5279 13.19 13.1881C12.5301 13.8483 11.7542 14.3709 10.8621 14.756C9.97002 15.1411 9.01686 15.3337 8.00259 15.3337ZM8.0013 14.1758C9.72528 14.1758 11.1855 13.5775 12.382 12.381C13.5785 11.1845 14.1768 9.72431 14.1768 8.00033C14.1768 6.27634 13.5785 4.81611 12.382 3.61961C11.1855 2.42312 9.72528 1.82487 8.0013 1.82487C6.27732 1.82487 4.81708 2.42312 3.62059 3.61961C2.42409 4.81611 1.82585 6.27634 1.82585 8.00033C1.82585 9.72431 2.42409 11.1845 3.62059 12.381C4.81708 13.5775 6.27732 14.1758 8.0013 14.1758Z"
                            fill="#175C89"
                        />
                    </g>
                </svg>
            </button>
        </div>
    </ng-container>
    <button *ngIf="!isReadOnly" class="action resetStyles" (click)="addNewField()">+ Add {{ label }}</button>
</div>
