import { Injectable } from '@angular/core';
import { IStorageProvider } from '../../interfaces/storage-provider.interface';

@Injectable({
	providedIn: 'root'
})
export class LocalStorageProvider implements IStorageProvider {
	private keyPrefix: string = '';

	constructor() {}

	setKeyPrefix(prefix: string) {
		this.keyPrefix = prefix;
	}

	getKeyPrefix(): string {
		return this.keyPrefix;
	}

	setItem<V>(key: string, value: V) {
		const prefixKey = this.getPrefixedKey(key);
		if (value) {
			localStorage.setItem(prefixKey, JSON.stringify(value));
		}
	}

	getItem<V>(key: string): V | undefined {
		const prefixKey = this.getPrefixedKey(key);
		try {
			const jsonValue = localStorage.getItem(prefixKey);
			if (jsonValue && jsonValue != 'undefined') {
				const value = JSON.parse(jsonValue) as V;
				return value;
			} else {
				return;
			}
		} catch (err) {
			console.error(err);
			localStorage.removeItem(prefixKey);
			return;
		}
	}

	removeItem(key: string): void {
		const prefixKey = this.getPrefixedKey(key);
		localStorage.removeItem(prefixKey);
	}

	removeAllItems(): void {
		const localStorageItems = { ...localStorage };

		Object.keys(localStorageItems).forEach((item) => {
			if (item.startsWith(this.keyPrefix)) {
				localStorage.removeItem(item);
			}
		});
	}

	private getPrefixedKey(original: string) {
		return this.keyPrefix + original;
	}
}
