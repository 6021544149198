<div class="legendContainer"
[ngClass]="
{
    current: legend?.title === 'Current',
    previous: legend?.title === 'Previous',
    default: legend?.title !== 'Current' || legend?.title !== 'Previous'
}"
*ngIf="legend?.labels?.length">
    <div *ngIf="legend?.title" class="title">{{ legend?.title}}</div>
    <ng-container *ngFor="let item of legend?.labels; let i = index">
        <div
        class="label"
        [ngClass]="{ primary: i === 0, secondary: i === 1 }"
            >
            <span class="dot"></span>{{ item.name }} <span class="value">{{ item.value }}</span>
        </div>
    </ng-container>
</div>