import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Opportunity } from "projects/app/src/app/models/Opportunity";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { OpportunityService } from "projects/app/src/app/services/http/clients/opportunity.service";
import { ModalService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { OpportunitiesSingleOverviewComponent } from "../opportunities-single/opportunities-single-overview/opportunities-single-overview.component";
import { ModalConfirmComponent } from "projects/app/src/app/components/shared/modals/modal-confirm/modal-confirm.component";
import { EntityTypeCustomFieldService } from "projects/app/src/app/services/http/clients/entity-type-custom-field.service";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";

@Component({
    selector: "app-opportunities-create",
    templateUrl: "./opportunities-create.component.html",
    styleUrls: ["./opportunities-create.component.scss"]
})
export class OpportunitiesCreateComponent implements OnInit {
    opportunity: Opportunity;

    @ViewChild("overview")
    overview?: OpportunitiesSingleOverviewComponent;

    constructor(
        private readonly opportunityService: OpportunityService,
        private readonly entityTypeCustomFieldService: EntityTypeCustomFieldService,
        private readonly awaiter: AwaiterService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly router: Router,
        private readonly modalService: ModalService
    ) {
        this.opportunity = new Opportunity();
    }

    async ngOnInit(): Promise<void> {
        const opportunitiesCustomFields = await this.entityTypeCustomFieldService.getCustomFieldsByEntityType(EntityTypes.Opportunity);
        if (opportunitiesCustomFields.length) {
            this.opportunity.customFieldValues = opportunitiesCustomFields;
        }
    }

    async saveOpportunity(): Promise<void> {
        const areAllCardsValid = this.overview?.validate();
        if (areAllCardsValid) {
            await this.awaiter.awaitAction("Saving Opportunity", async () => {
                await this.opportunityService.save(this.opportunity);
                this.snackbarNotificationService.success("Opportunity created successfully.");
                this.router.navigate(["/opportunities"]);
            });
        }
    }

    async cancel(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Exit",
            cancelCaption: "Continue creation",
            content: "Are you sure you want to exit? Your changes will be lost",
            title: "Cancel Opportunity"
        });

        if (responseOk) this.router.navigate(["/opportunities"]);
    }
}
