<div class="container">
    <lib-section-header
        title="{{ contact?.fullName }}"
        subTitle="Contacts"
        [actionsTemplate]="actionsTemplate"
        [iconName]="'goBack'"
        [mainAdditionalTemplate]="statusTemplate"
    >
        <ng-template #statusTemplate>
            <lib-status-indicator *ngIf="!isActive" [iconName]="'archived'" [colorType]="'error'" [text]="'Archived'"></lib-status-indicator>
        </ng-template>

        <ng-template #actionsTemplate>
            <div class="actions">
                <button *ngIf="currentTab === 'Overview'" class="primary-btn" (click)="onEditContact()">Edit</button>
                <app-kebab-menu>
                    <button role="menuitem" *ngIf="isActive" class="resetStyles" (click)="archiveContact()">Archive</button>
                    <button role="menuitem" *ngIf="!isActive" class="resetStyles" (click)="restoreContact()">Restore</button>
                </app-kebab-menu>
            </div>
        </ng-template>
    </lib-section-header>

    <div class="tabGroup">
        <lib-tab-view [(currentTab)]="currentTab" alignment="start" separation="wide">
            <lib-tab-item tabName="Overview" [bodyTemplate]="overviewTab">
                <ng-template #overviewTab>
                    <div class="tab">
                        <app-contacts-single-overview [contact]="contact" [isReadOnly]="true" #overview></app-contacts-single-overview>
                    </div>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Opportunities" [bodyTemplate]="opportunitiesTab">
                <ng-template #opportunitiesTab>
                    <div class="tab">
                        <app-opportunities-grid-single [contactId]="contact?.id"></app-opportunities-grid-single>
                    </div>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Buildings" [bodyTemplate]="buildingsTab">
                <ng-template #buildingsTab>
                    <div class="tab">
                        <app-contacts-single-buildings [contactId]="contact?.id"></app-contacts-single-buildings>
                    </div>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Notes" [bodyTemplate]="notesTab">
                <ng-template #notesTab>
                    <div class="tab">
                        <app-notes [entityTypeId]="entityTypeId" [entityId]="contact?.id"></app-notes>
                    </div>
                </ng-template>
            </lib-tab-item>
        </lib-tab-view>
    </div>
</div>
