/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from "@angular/core";

interface User {
    firstName: string;
    lastName: string;
    roleName?: string;
}
@Component({
    selector: "app-user-avatar",
    templateUrl: "./user-avatar.component.html",
    styleUrls: ["./user-avatar.component.scss"]
})
export class UserAvatarComponent implements OnInit {
    @Input()
    users: User[] | undefined;

    @Input()
    showSubheading?: boolean;

    @Input()
    size: "small" | "normal";

    avatar: string;

    heading: string;

    subheading: string;

    tooltip: string;

    constructor() {
        this.users = [];
        this.showSubheading = true;
        this.avatar = "";
        this.heading = "";
        this.subheading = "";
        this.tooltip = "";
        this.size = "normal";
    }

    ngOnInit() {
        this.renderUsers();
    }

    renderUsers() {
        if (this.users?.length) {
            this.heading = this.getHeading(this.users[0]);
            this.avatar = this.getAvatar(this.users[0]);
            this.subheading = this.getSubheading(this.users);
            this.tooltip = this.getTooltip(this.users);
        }
    }

    getAvatar(user: User): string {
        const heading = this.getHeading(user);
        const headingArray = heading.split(" ");
        const acronym = headingArray.map(word => word[0].replace(/[^a-zA-Z]/g, "")).join("");
        return acronym.substring(0, 3);
    }

    getHeading(user: User): string {
        return `${user.firstName} ${user.lastName}`;
    }

    getSubheading(users: User[]): string {
        if (users.length === 1) return users[0].roleName ?? "";

        return `+${users.length - 1} Person${users.length - 1 > 1 ? "s" : ""}`;
    }

    getTooltip(users: User[]): string {
        const [, ...rest] = users;
        const restUsersData = rest.map(u => `${u.firstName} ${u.lastName}`);
        return restUsersData.join(", ");
    }
}
