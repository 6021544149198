import { Component, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { ReportTypeService } from "./../../../../services/http/clients/report-type.service";
import { ReportExecutionView, ReportTypeView, SearchReportTypeParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { IReportExecutionParams, ReportsExecutionsFiltersComponent } from "../reports-executions-filters/reports-executions-filters.component";
import { ReportExecutionService } from "projects/app/src/app/services/http/clients/report-execution.service";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { ObjectExtensionsService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { AuthService } from "projects/app/src/app/services/auth.service";
import { ModalService } from "./../../../../../../../ngx-lib/src/lib/services/modal.service";
import { ModalConfirmComponent } from "../../../shared/modals/modal-confirm/modal-confirm.component";

@Component({
    selector: "app-reports-executions-edit",
    templateUrl: "./reports-executions-edit.component.html",
    styleUrls: ["./reports-executions-edit.component.scss"]
})
export class ReportsExecutionsEditComponent implements OnInit {
    searchConfig: SearchReportTypeParameters;

    reportTypes: ReportTypeView[];

    reportExecutionFetchedData?: ReportExecutionView;

    filteredReportExecutionFetchedData?: Partial<ReportExecutionView>;

    currentReportType?: string;

    reportExecutionId: number;

    reportExecution: IReportExecutionParams;

    pageTitle: string;

    pageSubtitle?: string;

    userHasReportFullAccess: boolean;

    filtersHasChanged: boolean;

    isLoading: boolean;

    allowToSetMultiOrganization?: boolean;

    @ViewChild("reportExecutionFilters")
    reportExecutionFilters?: ReportsExecutionsFiltersComponent;

    constructor(
        private readonly reportTypeService: ReportTypeService,
        private readonly reportExecutionService: ReportExecutionService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly awaiter: AwaiterService,
        private readonly objectExtensionService: ObjectExtensionsService,
        private readonly authService: AuthService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly location: Location,
        private readonly modalService: ModalService
    ) {
        this.reportTypes = [];
        this.searchConfig = {} as SearchReportTypeParameters;
        this.reportExecutionId = parseInt(this.route.snapshot.paramMap.get("id") ?? "0");
        this.reportExecution = {
            reportTypeId: undefined,
            description: "",
            hasMultipleOrganizations: false,
            reportExecutionFilters: []
        };
        this.pageTitle = "Create New Report";
        this.userHasReportFullAccess = false;
        this.filtersHasChanged = false;
        this.isLoading = false;
    }

    async ngOnInit(): Promise<void> {
        this.userHasReportFullAccess = !this.authService.hasRestrictedAccess();

        const stateObj = this.router.lastSuccessfulNavigation?.extras.state;
        this.allowToSetMultiOrganization = stateObj ? stateObj["data"].allowToSetMultiOrganization : false;

        if (this.reportExecutionId) {
            await this.initializeReportOnEdit();
        } else {
            this.reportTypes = await this.reportTypeService.search(this.searchConfig);
            this.currentReportType = this.reportTypes?.length ? this.reportTypes[0].name : "";
        }
    }

    onReportTypeChange(reportTypeName: string) {
        this.currentReportType = reportTypeName;
        this.reportExecution = {
            description: "",
            hasMultipleOrganizations: false,
            reportExecutionFilters: []
        };
    }

    async compareFilters(reportExecution: IReportExecutionParams): Promise<void> {
        if (!this.reportExecutionId) {
            this.filtersHasChanged = false;
        } else {
            this.filtersHasChanged = !this.objectExtensionService.isEqualTo(
                this.filteredReportExecutionFetchedData,
                reportExecution,
                ["id", "reportTypeId"],
                false
            );
        }
    }

    async onUpdateFilters(): Promise<void> {
        if (!this.reportExecutionId) return;

        await this.awaiter.awaitAction(
            "Updating Report",
            async () => {
                const reportExecutionSaved = await this.reportExecutionService.save({
                    reportTypeId: this.reportExecution.reportTypeId,
                    id: this.reportExecutionId,
                    description: this.reportExecution.description,
                    hasMultipleOrganizations: this.reportExecution.hasMultipleOrganizations,
                    reportExecutionFilters: this.reportExecution.reportExecutionFilters
                } as ReportExecutionView);

                if (reportExecutionSaved) {
                    this.snackbarNotificationService.success(`Report has been successfully updated.`);
                    this.pageTitle = this.reportExecution.description;
                    this.filtersHasChanged = false;
                }
            },
            loading => (this.isLoading = loading)
        );
    }

    async onRunReport(): Promise<void> {
        const reportToRun = {
            id: this.reportExecutionFetchedData?.id,
            reportTypeId: this.reportExecutionFetchedData?.reportTypeId,
            reportTypeName: this.reportExecutionFetchedData?.reportTypeName,
            description: this.reportExecution.description,
            hasMultipleOrganizations: this.reportExecution.hasMultipleOrganizations,
            reportExecutionFilters: this.reportExecution.reportExecutionFilters
        } as ReportExecutionView;

        this.router.navigate(["/reports-results"], { state: { data: { reportExecution: reportToRun } } });
    }

    async onCreateReportExecution(): Promise<void> {
        if (!this.reportExecutionFilters?.validateField(this.reportExecution.description)) return;

        await this.awaiter.awaitAction(
            "Creating Report",
            async () => {
                this.isLoading = true;
                const reportExecutionSaved = await this.reportExecutionService.save({
                    reportTypeId: this.reportExecution.reportTypeId,
                    description: this.reportExecution.description,
                    hasMultipleOrganizations: this.reportExecution.hasMultipleOrganizations,
                    reportExecutionFilters: this.reportExecution.reportExecutionFilters
                } as ReportExecutionView);

                if (reportExecutionSaved) {
                    this.snackbarNotificationService.success(`Report created successfully!`);
                    this.router.navigate(["/reports"]);
                }
            },
            loading => (this.isLoading = loading)
        );
    }

    async onGoBack(): Promise<void> {
        if (this.reportExecutionId && this.userHasReportFullAccess && this.filtersHasChanged) {
            const responseOk = await this.modalService.open(ModalConfirmComponent, {
                acceptCaption: "Save and close",
                cancelCaption: "Discard",
                content: "Are you sure you want to leave without saving changes? Changes you made to this form will be lost.",
                title: "Unsaved Changes"
            });

            if (responseOk) await this.onUpdateFilters();
        }
        this.location.back();
    }

    private async initializeReportOnEdit(): Promise<void> {
        await this.awaiter.awaitAction("Loading Report Execution", async () => {
            const fetchedData = await this.reportExecutionService.getById(this.reportExecutionId);
            this.reportExecutionFetchedData = this.objectExtensionService.clone(fetchedData) as ReportExecutionView;

            this.pageTitle = fetchedData.description ?? "";

            this.pageSubtitle = `${fetchedData?.reportTypeName} - ${
                fetchedData?.executionDate ? new Date(fetchedData?.executionDate).toLocaleString("en-US") : ""
            }`;

            if (fetchedData && Object.keys(fetchedData).length > 0) {
                this.reportExecution = {
                    reportTypeId: fetchedData.reportTypeId,
                    id: this.reportExecutionId,
                    description: fetchedData.description ?? "",
                    hasMultipleOrganizations: fetchedData.hasMultipleOrganizations ?? false,
                    reportExecutionFilters: fetchedData.reportExecutionFilters ?? []
                };

                this.filteredReportExecutionFetchedData = this.objectExtensionService.pickProperties(
                    this.reportExecutionFetchedData,
                    Object.keys(this.reportExecution)
                );
            }
        });
    }
}
