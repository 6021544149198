import { Component, OnInit } from "@angular/core";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-multi-input-editor",
    templateUrl: "./multi-input-editor.component.html",
    styleUrls: ["./multi-input-editor.component.scss"]
})
export class MultiInputEditorComponent extends EditorComponentBase<Array<string>> implements OnInit {
    errorIndexes: { [index: number]: string } = {};

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (!this.isReadOnly && !this.bindingProperty?.length) this.addNewField();
    }

    dataChanged(): void {
        this.validate();
        this.extractErrorIndexes();
    }

    addNewField(): void {
        this.bindingProperty?.push("");
        this.dataChanged();
    }

    removeField(index: number): void {
        this.bindingProperty?.splice(index, 1);
        this.dataChanged();
    }

    trackByFn(index: number): number {
        return index;
    }

    private extractErrorIndexes(): void {
        this.errorIndexes = {};
        this.validationErrors?.forEach(validationError => {
            validationError?.errors?.forEach(error => {
                this.errorIndexes[error.index] = error.message;
            });
        });
    }
}
