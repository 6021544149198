import { OpportunityUserUserRoleView, UserRoleView } from "projects/app/src/app/services/http/clients/api-proxies";
import { UserRoleEntity } from "./abstract/user-role-entity";
import { UserRoleService } from "projects/app/src/app/services/http/clients/user-role.service";

export class OpportunityUserRoleEntity extends UserRoleEntity<OpportunityUserUserRoleView> {
    constructor(userRoleService: UserRoleService) {
        super(userRoleService);
    }

    toDestination(entity: UserRoleView): OpportunityUserUserRoleView {
        return { id: 0, opportunityUserId: 0, userRoleId: entity.id, userRoleCode: entity.code, userRoleDescription: entity.description };
    }

    toSource(entity: OpportunityUserUserRoleView): UserRoleView {
        return { id: entity.userRoleId, code: entity.userRoleCode, description: entity.userRoleDescription, userOrganizations: [], users: [] };
    }

    async getSource(): Promise<UserRoleView[]> {
        return await this.userRoleService.getAll();
    }
}
