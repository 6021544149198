import { Component, OnInit } from "@angular/core";
import { LegalStatusView } from "projects/app/src/app/services/http/clients/api-proxies";
import { LegalStatusService } from "projects/app/src/app/services/http/clients/legal-status.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-legal-status-editor",
    templateUrl: "./legal-status-editor.component.html",
    styleUrls: ["./legal-status-editor.component.scss"]
})
export class LegalStatusEditorComponent extends EditorComponentBase<number | null> implements OnInit {
    legalStatusItems?: LegalStatusView[];
    selectedLegalStatus?: LegalStatusView;

    constructor(private readonly legalStatusService: LegalStatusService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.legalStatusItems = await this.legalStatusService.getAll();
        if (this.bindingProperty) this.selectedLegalStatus = this.legalStatusItems?.find(x => x.id === this.bindingProperty);
    }

    onLegalStatusChange() {
        if (this.selectedLegalStatus?.id) {
            this.bindingProperty = this.selectedLegalStatus.id;
        } else {
            this.bindingProperty = null;
        }
    }
}
