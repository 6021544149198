import { Injectable } from '@angular/core';
import { ILogProvider } from '../interfaces/log-provider.interface';
import ConsoleLogProvider from '../providers/log/console-log.provider';

export enum LogType {
	Trace = 0,
	Debug,
	Info,
	Warning,
	Error
}

@Injectable({
	providedIn: 'root'
})
export default class LogService implements ILogProvider {
	private provider: ILogProvider;

	constructor() {
		this.provider = new ConsoleLogProvider();
	}

	/**
	 * Set the log provider.
	 * @param provider the log provider.
	 */
	setLogProvider(provider: ILogProvider) {
		this.provider = provider;
	}

	/**
	 * Returns the log provider.
	 * @returns Returns a derived @see LogProvider
	 */
	getLogProvider(): ILogProvider {
		return this.provider;
	}

	/**
	 * Adds a trace log.
	 * @param message the message to be logged.
	 * @param [optionalParams = null] the optional parameters array.
	 */
	trace(message: string, optionalParams?: Array<unknown>): void {
		this.provider?.trace(message, optionalParams);
	}

	/**
	 * Adds a debug log.
	 * @param message the message to be logged.
	 * @param [optionalParams = null] the optional parameters array.
	 */
	debug(message: string, optionalParams?: Array<unknown>): void {
		this.provider?.debug(message, optionalParams);
	}

	/**
	 * Adds an information log.
	 * @param message the message to be logged.
	 * @param [optionalParams = null] the optional parameters array.
	 */
	info(message: string, optionalParams?: Array<unknown>): void {
		this.provider.info(message, optionalParams);
	}

	/**
	 * Adds a warning log.
	 * @param message the message to be logged.
	 * @param [optionalParams = null] the optional parameters array.
	 */
	warn(message: string, optionalParams?: Array<unknown>): void {
		this.provider.warn(message, optionalParams);
	}

	/**
	 * Adds an error log.
	 * @param message the message to be logged.
	 * @param [optionalParams = null] the optional parameters array.
	 */
	error(message: string | unknown, optionalParams?: Array<unknown>): void {
		if (message instanceof Error) {
			this.provider.error(`${message.message} (${this.serializeError(message)})`, optionalParams);
		} else if (message?.['toString']) {
			this.provider.error(message.toString(), optionalParams);
		} else {
			this.provider.error(`Unexpected error: ${this.serializeError(message)}`, optionalParams);
		}
	}

	/**
	 * Tries to serialize an error or another unknown object into a string.
	 * @param error Error object.
	 * @returns a string representation of the serialized error.
	 */
	private serializeError(error: unknown): string {
		try {
			return JSON.stringify(error, Object.getOwnPropertyNames(error), 4);
		} catch {
			return 'Unknown error (unable to serialize)';
		}
	}
}
