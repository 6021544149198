import { Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { IGenericTypeAheadDropdownConfig, ITypeAheadDropdownAction } from "../../interfaces/type-ahead-dropdown-config.interface";

@Component({
    selector: "lib-options-menu",
    templateUrl: "./options-menu.component.html",
    styleUrls: ["./options-menu.component.scss"]
})
export class OptionsMenuComponent<T> {
    @Input() source?: T[];
    @Input() menuOpen?: boolean;
    @Input() config?: IGenericTypeAheadDropdownConfig<T>;
    @Input() selectedItem?: T;
    @Input() selectedItems?: T[];
    @Input() text: string;
    @Input() noResultsText?: string;
    @Input() action?: ITypeAheadDropdownAction;

    @Output() selectedItemChange: EventEmitter<T>;
    @Output() selectedItemsChange = new EventEmitter<T[]>();
    @Output() listKeyDown = new EventEmitter<{
        event: KeyboardEvent;
        item: T;
    }>();
    @Output() runActionEmitter = new EventEmitter<void>();

    constructor() {
        this.text = "";
        this.selectedItem = undefined;
        this.selectedItemChange = new EventEmitter<T>();
    }

    onSelectItem(item: T) {
        this.selectedItemChange.emit(item);
    }

    onListKeyDown(event: KeyboardEvent, item: T) {
        this.listKeyDown.emit({ event, item });
    }

    onClearSelectionClicked() {
        if (!this.config?.clearSelection || this.config?.multiple) return;
        this.selectedItemChange.emit();
    }

    runAction(callback?: () => Promise<void>) {
        if (!callback) return;
        this.runActionEmitter.emit();
        callback();
    }

    getTemplate(): TemplateRef<unknown> | null {
        if (!this.config?.itemTemplate) return null;
        return this.config.itemTemplate;
    }
}
