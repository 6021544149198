<button
    #kebabButton
    id="menubutton"
    aria-haspopup="true"
    aria-controls="menu"
    aria-label="Open and close menu button"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    class="kebabButton noNavigate"
    [ngClass]="{ panelOpen: panelOpen }"
    (click)="panelOpen = !panelOpen">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" class="noNavigate">
        <path
            class="noNavigate"
            d="M8.75 4.5C7.7835 4.5 7 3.7165 7 2.75C7 1.7835 7.7835 1 8.75 1C9.7165 1 10.5 1.7835 10.5 2.75C10.5 3.7165 9.7165 4.5 8.75 4.5ZM8.75 10.5C7.7835 10.5 7 9.7165 7 8.75C7 7.7835 7.7835 7 8.75 7C9.7165 7 10.5 7.7835 10.5 8.75C10.5 9.7165 9.7165 10.5 8.75 10.5ZM7 14.75C7 15.7165 7.7835 16.5 8.75 16.5C9.7165 16.5 10.5 15.7165 10.5 14.75C10.5 13.7835 9.7165 13 8.75 13C7.7835 13 7 13.7835 7 14.75Z"
            fill="#175C89"
        />
    </svg>
</button>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="panelOpen"
    (overlayOutsideClick)="panelOpen = false"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    (detach)="panelOpen = false"
    [cdkConnectedOverlayPositions]="[{
        originX: 'start',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'top',
        offsetX: -5
    }, {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'bottom',
        offsetX: -5
    }]">
    <section id="menu" role="menu" aria-labelledby="menubutton" class="kebabMenu" cdkTrapFocus cdkTrapFocusAutoCapture="true">
        <div role="menubar" tabindex="-1" cdkFocusInitial>
            <ng-content></ng-content>
        </div>
    </section>
</ng-template>