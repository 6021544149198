import { Component, OnInit } from "@angular/core";

import { UserRoles } from "projects/app/src/app/models/enums/UserRoles";
import { SearchUsersParameters, UserView } from "projects/app/src/app/services/http/clients/api-proxies";
import { UserService } from "projects/app/src/app/services/http/clients/user.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-user-editor",
    templateUrl: "./user-editor.component.html",
    styleUrls: ["./user-editor.component.scss"]
})
export class UserEditorComponent extends EditorComponentBase<number | undefined> implements OnInit {
    selectedUser?: UserView;

    usersSearchResult?: UserView[];

    usersSearchText: string;

    userRoleIds: UserRoles[];

    constructor(private readonly userService: UserService) {
        super();
        this.userRoleIds = [UserRoles.SalesPerson];
        this.usersSearchText = "";
    }

    async ngOnInit(): Promise<void> {
        this.userRoleIds = this.additionalInfo?.roles ?? [UserRoles.SalesPerson];
        if (this.bindingProperty) {
            this.selectedUser = await this.userService.getById(this.bindingProperty);
        }
    }

    async onUsersTextChanged(): Promise<void> {
        if (!this.usersSearchText) return;

        this.usersSearchResult = await this.userService.search({
            filterText: this.usersSearchText,
            roleIds: this.userRoleIds
        } as SearchUsersParameters);
    }

    onSelectedUser() {
        this.bindingProperty = this.selectedUser?.id;
        this.valueChange.emit();
    }
}
