import { IUser } from "projects/ngx-lib/src/public-api";
import { UserRoles } from "../enums/UserRoles";

export class User implements IUser {
    constructor(
        public token: string,
        public id: number,
        public firstName: string,
        public lastName: string,
        public email: string,
        public roleId: UserRoles,
        public roleName: string
    ) {}
}
