<div class="container">
    <input
        [ngClass]="{isReadOnly: isReadOnly, error: !isValid }"
        #input
        type="text"
        (focus)="onFocus()"
        (blur)="onBlur()"
        [readOnly]="isReadOnly"
        [placeholder]="isReadOnly && !bindingProperty || !label ? '' : 'Add ' + label"
    />
    <app-validation-error-message [show]="!isValid" [errors]="validationErrors"></app-validation-error-message>
</div>

