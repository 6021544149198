<div class="container">
    <ul *ngIf="location">
        <li>
            <div class="label">Address</div>
            <div class="value">{{ location.addressLines }}</div>
        </li>
        <li>
            <div class="label">Country</div>
            <div class="value">{{ location.countryName }}</div>
        </li>
        <li>
            <div class="label">State</div>
            <div class="value">{{ location.stateName }}</div>
        </li>
        <li>
            <div class="label">City</div>
            <div class="value">{{ location.cityName }}</div>
        </li>
        <li *ngIf="location.zipCode">
            <div class="label">Zip Code</div>
            <div class="value">{{ location.zipCode }}</div>
        </li>
    </ul>
</div>
