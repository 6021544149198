import { Injectable } from "@angular/core";
import { IProjectTypeClient, ProjectTypeClient, ProjectTypeView } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class ProjectTypeService {
    private client: IProjectTypeClient;
    constructor() {
        this.client = new ProjectTypeClient();
    }

    async getAll(): Promise<ProjectTypeView[]> {
        return await this.client.projectTypeGetAll();
    }
}
