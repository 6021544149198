import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { IUserOrganization } from "../interfaces/user-organization.interface";

@Injectable({
    providedIn: "root"
})
export class UserOrganizationService {
    private _userOrganizationKey = "organization";

    set userOrganizationKey(key: string) {
        this._userOrganizationKey = key;
    }

    constructor(private readonly storageService: StorageService) {}

    getUserOrganization<T extends IUserOrganization>(): T | undefined {
        return this.storageService.getItem(this._userOrganizationKey);
    }

    setUserOrganization<T extends IUserOrganization>(organization: T) {
        this.storageService.setItem(this._userOrganizationKey, organization);
    }

    removeUserOrganization() {
        this.storageService.removeItem(this._userOrganizationKey);
    }
}
