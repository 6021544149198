import { Component, Input } from "@angular/core";

@Component({
    selector: "app-list-item",
    templateUrl: "./list-item.component.html",
    styleUrls: ["./list-item.component.scss"]
})
export class ListItemComponent {
    @Input()
    title?: string;

    @Input()
    line1?: string;

    @Input()
    line2?: string;
}
