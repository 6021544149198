<div class="container">
    <lib-generic-dropdown
        name="projectType"
        id="projectType"
        [(selectedItem)]="selectedProjectType"
        (selectedItemChange)="onProjectTypeChange()"
        [source]="projectTypeItems"
        [config]="{
            defaultText: 'Select ' + label,
            itemDisplayKey: 'name'
        }"
        [isReadOnly]="isReadOnly"
        [error]="!isValid"
    ></lib-generic-dropdown>
    <app-validation-error-message [show]="!isValid" [errors]="validationErrors"></app-validation-error-message>
</div>
