<div class="container">
    <button (click)="openClose($event)" [ngClass]="{ show: opened }" aria-label="Open and close menu button">
        <ng-container *ngIf="buttonTemplateRef; else defaultButtonTemplate" [ngTemplateOutlet]="buttonTemplateRef"></ng-container>
        <ng-template #defaultButtonTemplate>{{ textContent }}</ng-template>
    </button>

    <div class="content" [ngClass]="{ show: opened }">

        <ng-container *ngIf="contentTemplateRef; else defaultContentTemplate" [ngTemplateOutlet]="contentTemplateRef"></ng-container>

        <ng-template #defaultContentTemplate>
            <ng-container *ngIf="source && source.items">
                <div *ngFor="let item of source.items">
                    <button *ngIf="item.action" class="resetStyles" (click)="action(item.action, $event)">{{ item.name }}</button>
                </div>
            </ng-container>
        </ng-template>
    </div>
</div>
