/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { IAdditionalFiltersOpportunities, IAdditionalFiltersApplied } from "../opportunities-filters.component";

@Component({
    selector: "app-opportunities-additional-filters-applied",
    templateUrl: "./opportunities-additional-filters-applied.component.html",
    styleUrls: ["./opportunities-additional-filters-applied.component.scss"]
})
export class OpportunitiesAdditionalFiltersAppliedComponent implements OnChanges {
    @Input()
    additionalFilters?: IAdditionalFiltersOpportunities;

    @Output()
    additionalFiltersChange: EventEmitter<IAdditionalFiltersOpportunities>;

    @Output()
    removeFilterTag: EventEmitter<IAdditionalFiltersApplied>;

    additionalFiltersApplied: IAdditionalFiltersApplied[];

    constructor() {
        this.additionalFiltersApplied = [];
        this.additionalFiltersChange = new EventEmitter<IAdditionalFiltersOpportunities>();
        this.removeFilterTag = new EventEmitter<IAdditionalFiltersApplied>();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["additionalFilters"]?.currentValue) {
            this.buildFiltersApplied();
        }
    }

    removeItem(filter: IAdditionalFiltersApplied) {
        // Remove item from Additional Filters applied
        this.additionalFiltersApplied = this.additionalFiltersApplied.filter(applied => applied.value !== filter.value);

        // Remove filter in additional filter dropdown
        this.removeFilterTag.emit(filter);
    }

    private buildFiltersApplied() {
        this.additionalFiltersApplied = [];
        const additionalFiltersArray = Object.entries(this.additionalFilters ?? [])
            .map(([key, value]) => ({ key, value }))
            .filter(af => af.value);

        if (additionalFiltersArray.length) {
            const filtersAppliedRange = additionalFiltersArray.filter(af => af.key.toLowerCase().includes("from") || af.key.toLowerCase().includes("to"));
            if (filtersAppliedRange.length) this.buildFiltersAppliedRange(filtersAppliedRange);

            const filtersAppliedNoRange = additionalFiltersArray.filter(af => !af.key.toLowerCase().includes("from") && !af.key.toLowerCase().includes("to"));
            if (filtersAppliedNoRange.length) this.buildFiltersAppliedNoRange(filtersAppliedNoRange);
        }
    }

    private buildFiltersAppliedRange(
        rangeFiltersArray: {
            key: string;
            value: any;
        }[]
    ) {
        if (!rangeFiltersArray.length) return;

        const probabilityFilterApplied = rangeFiltersArray.filter(af => af.key.includes("probability"));
        if (probabilityFilterApplied.length) {
            const probabilityRange = this.rangeFilterApplied(probabilityFilterApplied, "Probability", 7);
            if (probabilityRange) this.additionalFiltersApplied.push(probabilityRange);
        }

        const potentialValueFilterApplied = rangeFiltersArray.filter(af => af.key.includes("potentialValue"));
        if (potentialValueFilterApplied.length) {
            const potentialValueRange = this.rangeFilterApplied(potentialValueFilterApplied, "Potential Value ($)", 2, "potentialValue");
            if (potentialValueRange) this.additionalFiltersApplied.push(potentialValueRange);
        }

        const proposedGMFilterApplied = rangeFiltersArray.filter(af => af.key.includes("proposedGrossMargin"));
        if (proposedGMFilterApplied.length) {
            const proposedGMRange = this.rangeFilterApplied(proposedGMFilterApplied, "Proposed GM (%)", 3, "proposedGrossMargin");
            if (proposedGMRange) this.additionalFiltersApplied.push(proposedGMRange);
        }
    }

    private buildFiltersAppliedNoRange(
        noRangeFiltersArray: {
            key: string;
            value: any;
        }[]
    ) {
        if (!noRangeFiltersArray.length) return;

        for (const filter of noRangeFiltersArray) {
            const { key, value } = filter;

            if (key === "companies" && value.length) {
                for (const item of value) {
                    const companyApplied = this.getFilterApplied(key, item, "name", "Customer", 1);
                    this.additionalFiltersApplied.push(companyApplied);
                }
            } else if (key === "salesperson") {
                const salesPersonApplied = this.getFilterApplied(key, value, "fullName", "Salesperson", 5);
                this.additionalFiltersApplied.push(salesPersonApplied);
            } else if (key === "divisions" || key === "statuses") {
                const order = key === "divisions" ? 4 : 6;
                const label = key === "divisions" ? "Division" : "Status";
                for (const item of value) {
                    const f = this.getFilterApplied(key, item, "name", label, order);
                    this.additionalFiltersApplied.push(f);
                }
            } else if (key === "activeStatus") {
                this.additionalFiltersApplied.push({ key: key, name: "Condition", value: value.name, order: 8 });
            }
        }
    }

    private getFilterApplied(key: string, value: any, propertyAsValue: string, label: string, order: number): IAdditionalFiltersApplied {
        return { key, name: label, value: value[propertyAsValue], order };
    }

    private rangeFilterApplied(list: any, label: string, order: number, key?: string): IAdditionalFiltersApplied | undefined {
        if (list.length === 1) {
            const item = list[0];
            return { key: item.key, name: label, value: `${item.key.substring(key?.length ?? label.length)} ${item.value}`, order };
        } else if (list.length === 2) {
            const rangeValues = [];
            for (const item of list) {
                rangeValues.push(item.value);
            }
            return { key: key ?? label.toLowerCase(), name: `${label}`, value: `${rangeValues[0]} - ${rangeValues[1]}`, order };
        } else return;
    }
}
