import { BuildingContactRoleView, OpportunityBuildingContactRoleView } from "projects/app/src/app/services/http/clients/api-proxies";
import { BuildingContactRoleEntity } from "./abstract/building-contact-role-entity";
import { BuildingContactRoleService } from "projects/app/src/app/services/http/clients/building-contact-role.service";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
export class OpportunityBuildingContactRoleEntity extends BuildingContactRoleEntity<OpportunityBuildingContactRoleView> {
    constructor(buildingContactRoleService: BuildingContactRoleService) {
        super(buildingContactRoleService);
    }

    toDestination(entity: BuildingContactRoleView): OpportunityBuildingContactRoleView {
        return { id: 0, buildingContactRoleId: entity.id, buildingContactRoleName: entity.name };
    }

    toSource(entity: OpportunityBuildingContactRoleView): BuildingContactRoleView {
        return {
            id: entity.buildingContactRoleId,
            name: entity.buildingContactRoleName
        };
    }

    async getSource(entityType: EntityTypes): Promise<BuildingContactRoleView[]> {
        return await this.buildingContactRoleService.getByUsage(entityType, undefined);
    }
}
