export enum UserRoles {
    None = 1,
    Estimator,
    SalesPerson,
    SalesManager,
    Manager,
    OrganizationAdmin,
    UserAdmin,
    GlobalAdmin
}
