<lib-generic-dropdown
    name="companyType"
    id="companyType"
    [(selectedItem)]="selectedCompanyType"
    (selectedItemChange)="onCompanyTypeChange()"
    [source]="companyTypeItems"
    [config]="{
        defaultText: 'Select Company Type',
        itemDisplayKey: 'name'
    }"
    [isReadOnly]="isReadOnly"
></lib-generic-dropdown>
