import { ContactView, OpportunityContactBuildingContactRoleView, OpportunityContactView } from "../services/http/clients/api-proxies";
import { ModelBase } from "./ModelBase";

export class OpportunityContact extends ModelBase implements OpportunityContactView {
    opportunityId: number;
    contactId: number;
    companyId: number | undefined;
    currentCompanyId: number | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    fullName: string | undefined;
    email: string[] | undefined;
    phone: string[] | undefined;
    opportunityName: string | undefined;
    lastContactDate: Date | undefined;
    followUpDate: Date | undefined;
    buildingContactRoleNames: string | undefined;
    buildingContactRolesCount: number | undefined;
    companyName: string | undefined;
    currentCompanyName: string | undefined;
    isMain: boolean | undefined;
    opportunityContactBuildingContactRoles: OpportunityContactBuildingContactRoleView[] | undefined;
    opportunityBaseEstimateId: number | undefined;

    constructor() {
        super();
        this.opportunityId = 0;
        this.contactId = 0;
        this.companyId = undefined;
        this.currentCompanyId = undefined;
        this.firstName = "";
        this.lastName = "";
        this.fullName = "";
        this.phone = undefined;
        this.email = undefined;
        this.opportunityName = "";
        this.lastContactDate = undefined;
        this.followUpDate = undefined;
        this.buildingContactRoleNames = "";
        this.buildingContactRolesCount = undefined;
        this.companyName = "";
        this.currentCompanyName = "";
        this.isMain = undefined;
        this.opportunityContactBuildingContactRoles = [];
        this.opportunityBaseEstimateId = undefined;
    }    

    static fromInterface(dto: OpportunityContactView): OpportunityContact {
        const opportunityContact = new OpportunityContact();
        opportunityContact.mapFromInterface(dto);
        return opportunityContact;
    }

    static fromContactView(contactView: ContactView): OpportunityContact {
        const opportunityContact = new OpportunityContact();

        opportunityContact.mapFromInterface(contactView);
        opportunityContact.contactId = contactView.id;
        return opportunityContact;
    }
}
