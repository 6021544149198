import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import {
    OpportunityUserView,
    SearchAvailableOpportunityUsersParameters,
    SearchUsersForOpportunityParameters,
    UserView
} from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityUserService } from "projects/app/src/app/services/http/clients/opportunity-user.service";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { OpportunityService } from "projects/app/src/app/services/http/clients/opportunity.service";
import { UserRoles } from "projects/app/src/app/models/enums/UserRoles";
import { ModalService } from "projects/ngx-lib/src/public-api";
import { ModalEditOpportunityTeamMemberComponent } from "../../../../shared/modals/modal-edit-opportunity-team-member/modal-edit-opportunity-team-member.component";
import { IUserOrganization } from "projects/ngx-lib/src/lib/interfaces/user-organization.interface";
import { UserOrganizationService } from "projects/ngx-lib/src/lib/services/user-organization.service";
import { OpportunityAction } from "projects/app/src/app/models/enums/OpportunityAction";
import { ModalConfirmComponent } from "projects/app/src/app/components/shared/modals/modal-confirm/modal-confirm.component";

export interface OpportunityUsersChange {
    opportunityUsers?: OpportunityUserView[];
    opportunityActions?: number[];
}
@Component({
    selector: "app-opportunities-single-team",
    templateUrl: "./opportunities-single-team.component.html",
    styleUrls: ["./opportunities-single-team.component.scss"]
})
export class OpportunitiesSingleTeamComponent implements OnChanges {
    @Input()
    opportunityId?: number;

    @Output()
    opportunityUsersChange: EventEmitter<OpportunityUsersChange>;

    userOrganization?: IUserOrganization;

    opportunityUsers: OpportunityUserView[];

    orderConfig?: IGenericGridOrderConfig<OpportunityUserView>;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchUsersForOpportunityParameters;

    usersSearchResult?: UserView[];

    usersSearchText: string;

    selectedUser?: UserView;

    opportunityUser?: OpportunityUserView;

    availableActions?: number[];

    isActionAllowed: boolean;

    noResults: boolean;

    isLoading: boolean;

    constructor(
        private readonly userOrganizationService: UserOrganizationService,
        private readonly opportunityUserService: OpportunityUserService,
        private readonly opportunityService: OpportunityService,
        private readonly awaiter: AwaiterService,
        private readonly modalService: ModalService
    ) {
        this.userOrganization = this.userOrganizationService.getUserOrganization();
        this.isActionAllowed = false;

        this.opportunityUsers = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            opportunityId: this.opportunityId
        } as SearchUsersForOpportunityParameters;

        this.opportunityUser = undefined;
        this.usersSearchText = "";

        this.opportunityUsersChange = new EventEmitter<OpportunityUsersChange>();

        this.noResults = true;
        this.isLoading = true;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.opportunityId && changes["opportunityId"]) {
            this.searchConfig.opportunityId = this.opportunityId;
            this.validateActions();
            await this.refreshGridView();
        }
    }

    async validateActions(): Promise<void> {
        if (this.opportunityId) {
            this.availableActions = await this.opportunityService.availableActions(this.opportunityId);
            this.isActionAllowed = this.availableActions.includes(OpportunityAction.EditUser);
        }
    }

    async refreshGridView(): Promise<void> {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Opportunity Users",
            async () => {
                const response = await this.opportunityUserService.search(this.searchConfig);

                const { pageInfo, results } = response;

                this.opportunityUsers = this.opportunityUsers?.concat(results ?? []);

                this.noResults = this.opportunityUsers.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;

        this.opportunityUsers = [];

        await this.refreshGridView();
    }

    async onUsersTextChanged() {
        if (this.opportunityId) {
            this.usersSearchResult = [];

            this.usersSearchResult = await this.opportunityUserService.quickSearch({
                filterText: this.usersSearchText,
                opportunityId: this.opportunityId
            } as SearchAvailableOpportunityUsersParameters);
        }
    }

    async onSelectedUser() {
        if (this.selectedUser?.id && this.opportunityId) {
            const hiddenRole = this.selectedUser.userRoleId === UserRoles.None;

            await this.awaiter.awaitAction("Saving User", async () => {
                const userSaved = await this.opportunityService.saveUser({
                    userId: this.selectedUser?.id,
                    email : this.selectedUser?.email,
                    userExternalId: this.selectedUser?.externalId,
                    opportunityId: this.opportunityId,
                    opportunityUserUserRoles: !hiddenRole ? [{ userRoleId: this.selectedUser?.userRoleId }] : []
                } as any as OpportunityUserView);

                if (userSaved) {
                    this.opportunityUsers.unshift(userSaved);
                    this.noResults = false;
                    this.opportunityUsersChange.emit({
                        opportunityUsers: this.opportunityUsers
                    });
                }
            });
        }
    }

    async editUser(opportunityUser: OpportunityUserView): Promise<void> {
        if (!this.isActionAllowed) return;

        const userUpdated = await this.modalService.open(ModalEditOpportunityTeamMemberComponent, {
            user: opportunityUser
        });

        if (userUpdated) {
            const userIndex = this.opportunityUsers.findIndex(user => user.id === opportunityUser?.id);
            this.opportunityUsers.splice(userIndex, 1, userUpdated);
            this.opportunityUsersChange.emit({
                opportunityUsers: this.opportunityUsers
            });
        }
    }

    async removeUser(opportunityUser: OpportunityUserView): Promise<void> {
        if (!this.isActionAllowed) return;

        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Remove",
            cancelCaption: "Cancel",
            content: "Are you sure you want to remove this team member?",
            title: "Remove team member"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction(
            "Removing contact",
            async () => {
                if (!opportunityUser) return;
                await this.opportunityUserService.delete(opportunityUser?.id);

                const userIndex = this.opportunityUsers.findIndex(user => user.id === opportunityUser?.id);
                this.opportunityUsers.splice(userIndex, 1);
                this.noResults = this.opportunityUsers.length === 0;
                await this.validateActions();
                this.opportunityUsersChange.emit({
                    opportunityUsers: this.opportunityUsers,
                    opportunityActions: this.availableActions
                });
            },
            loading => (this.isLoading = loading)
        );
    }

    private buildSearchConfig() {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage ?? 1,
            sortDirection: this.orderConfig?.order,
            sortBy: this.orderConfig?.key,
            opportunityId: this.opportunityId
        } as SearchUsersForOpportunityParameters;
    }
}
