import { Injectable } from "@angular/core";
import { AddressClient, AddressView, IAddressClient } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class AddressService {
    private client: IAddressClient;
    constructor() {
        this.client = new AddressClient();
    }

    async searchPlaces(query: string, limit: number | undefined): Promise<AddressView[]> {
        return await this.client.addressSearchPlaces(query, limit);
    }

    async staticImageContentById(id: number, zoomLevel: number | undefined, width: number | undefined, height: number | undefined): Promise<string> {
        return await this.client.addressStaticImageById(id, zoomLevel, width, height);
    }

    async staticImageContent(
        latitude: number,
        longitude: number,
        zoomLevel: number | undefined,
        width: number | undefined,
        height: number | undefined
    ): Promise<string> {
        return await this.client.addressStaticImage(latitude, longitude, zoomLevel, width, height);
    }
}
