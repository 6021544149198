import {
    BuildingContactView,
    CompanyBuildingView,
    CompanyMarketView,
    CompanyView,
    ContactView,
    EntityCustomFieldValueView
} from "../services/http/clients/api-proxies";
import { ModelBase } from "./ModelBase";

export class Company extends ModelBase implements CompanyView {
    organizationId: number | undefined;
    name: string | undefined;
    abbreviation: string | undefined;
    webSite: string | undefined;
    linkedin: string | undefined;
    createdByUserId: number | undefined;
    companyTypeId: number | undefined;
    companyTypeName: string | undefined;
    legalStatusId: number | undefined;
    legalStatusName: string | undefined;
    line1: string | undefined;
    line2: string | undefined;
    zipCode: string | undefined;
    countryId: number | undefined;
    countryName: string | undefined;
    stateId: number | undefined;
    stateName: string | undefined;
    cityId: number | undefined;
    cityName: string | undefined;
    fullAddress: string | undefined;
    markets: string | undefined;
    organizationName: string | undefined;
    contactFirstName: string | undefined;
    contactLastName: string | undefined;
    contactPhone: string[] | undefined;
    contactId: number | undefined;
    mainOfficeId: number | undefined;
    isActive: boolean;
    buildingContacts: BuildingContactView[] | undefined;
    companyBuildings: CompanyBuildingView[] | undefined;
    companyMarkets: CompanyMarketView[] | undefined;
    contacts: ContactView[] | undefined;
    countryCode: string | undefined;
    stateCode: string | undefined;
    addressLines: string | undefined;
    customFieldValues: EntityCustomFieldValueView[] | undefined;

    constructor() {
        super();
        this.organizationId = undefined;
        this.name = "";
        this.abbreviation = "";
        this.webSite = "";
        this.linkedin = "";
        this.companyTypeId = undefined;
        this.companyTypeName = "";
        this.legalStatusId = undefined;
        this.legalStatusName = "";
        this.line1 = "";
        this.line2 = "";
        this.zipCode = "";
        this.countryId = undefined;
        this.countryName = "";
        this.stateId = undefined;
        this.stateName = "";
        this.cityId = undefined;
        this.cityName = "";
        this.fullAddress = "";
        this.markets = "";
        this.organizationName = "";
        this.contactFirstName = "";
        this.contactLastName = "";
        this.contactPhone = [];
        this.contactId = undefined;
        this.mainOfficeId = undefined;
        this.isActive = true;
        this.buildingContacts = [];
        this.companyBuildings = [];
        this.companyMarkets = [];
        this.contacts = [];
        this.customFieldValues = [];
    }

    static fromInterface(dto: CompanyView): Company {
        const contact = new Company();
        contact.mapFromInterface(dto);
        return contact;
    }
}
