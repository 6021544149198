export enum ContactPositions {
    PropertyManager = 1,
    Owner,
    Tenant,
    Architect,
    ConsultingEngineer,
    GeneralContractor,
    PrimeContractor,
    ContractWith,
    CompetingBidder,
    BidRecipient
}
