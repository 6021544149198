import { Component, OnInit } from "@angular/core";
import { ArchiveEntityOptionView, CompanyView, SearchCompaniesParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { ITypeAheadService } from "../../../shared/generic-type-ahead/generic-type-ahead.component";
import { CompanyService } from "projects/app/src/app/services/http/clients/company.service";
import { Router } from "@angular/router";
import { FilterService, IGetById } from "projects/ngx-lib/src/public-api";
import { ArchiveEntityService } from "projects/app/src/app/services/http/clients/archive-entity.service";

export interface IFilterValueContacts {
    searchText: string;
    company?: CompanyView;
    activeStatus?: ArchiveEntityOptionView;
}

@Component({
    selector: "app-contacts-main",
    templateUrl: "./contacts-main.component.html",
    styleUrls: ["./contacts-main.component.scss"]
})
export class ContactsMainComponent implements OnInit {
    filterValue: IFilterValueContacts;

    companyServiceFilter: ITypeAheadService<CompanyView, SearchCompaniesParameters>;

    activeStatuses?: ArchiveEntityOptionView[];

    constructor(
        private readonly companyService: CompanyService,
        private readonly router: Router,
        private readonly filterService: FilterService,
        private readonly archiveEntityService: ArchiveEntityService
    ) {
        this.filterValue = {
            searchText: ""
        };
        this.companyServiceFilter = companyService;
    }

    async ngOnInit(): Promise<void> {
        this.activeStatuses = await this.archiveEntityService.getArchiveOptions();
        await this.deserializeUrlParams();
        await this.updateFilterUrlParams();
        this.buildFilters();
    }

    buildFilters(): void {
        this.filterValue = {
            searchText: this.filterValue.searchText,
            company: this.filterValue.company,
            activeStatus: this.filterValue.activeStatus
        };
    }

    async onFilterChanged(): Promise<void> {
        await this.updateFilterUrlParams();
        this.buildFilters();
    }

    private async updateFilterUrlParams(): Promise<void> {
        await this.filterService.serialize<IFilterValueContacts>(this.filterValue, "searchText");
        await this.filterService.serializeTypeAhead<IFilterValueContacts, CompanyView>("company", this.filterValue.company, "id");
        await this.filterService.serializeArray<IFilterValueContacts, ArchiveEntityOptionView>(this.filterValue, "activeStatus", "id");
    }

    private async deserializeUrlParams() {
        this.filterService.deserialize<IFilterValueContacts>(this.filterValue, "searchText");
        await this.filterService.deserializeTypeAhead<IFilterValueContacts, CompanyView>(this.filterValue, "company", this.companyService as IGetById);
        this.filterService.deserializeArray<IFilterValueContacts, ArchiveEntityOptionView>(this.filterValue, "activeStatus", "id", this.activeStatuses ?? []);
    }

    onCreateNewContactClicked() {
        this.router.navigate([`/contacts-edit`]);
    }
}
