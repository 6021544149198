import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class DownloadService {
    constructor() {}

    downloadFile(data: any, fileName: string): void {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(data);
            link.setAttribute("href", url);
            link.setAttribute("download", fileName);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
