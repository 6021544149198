import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { icons } from "projects/ngx-lib/src/lib/assets/icons";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
@Component({
    selector: "lib-section-header",
    templateUrl: "./section-header.component.html",
    styleUrls: ["./section-header.component.scss"]
})
export class SectionHeaderComponent implements OnInit {
    @Input()
    title?: string;

    @Input()
    subTitle?: string;

    @Input()
    mainAdditionalTemplate!: TemplateRef<unknown>;

    @Input()
    iconName!: keyof typeof icons;

    @Input()
    infoTemplate!: TemplateRef<unknown>;

    @Input()
    actionsTemplate!: TemplateRef<unknown>;

    @Input()
    isReadOnly?: boolean;

    @Input()
    titleStyle: "primary" | "secondary" | "tertiary";

    @Input()
    goBackAction: "default" | "base" | "custom";

    @Output()
    goBackActionChange: EventEmitter<void>;

    svg?: SafeHtml;

    constructor(
        private readonly sanitizer: DomSanitizer,
        private readonly location: Location,
        private readonly router: Router
    ) {
        this.isReadOnly = true;
        this.titleStyle = "primary";
        this.goBackAction = "default";
        this.goBackActionChange = new EventEmitter<void>();
    }

    ngOnInit(): void {
        this.svg = this.sanitizer.bypassSecurityTrustHtml(icons[this.iconName]);
    }

    async goBack(): Promise<void> {
        if (this.goBackAction === "base") {
            const currentPath = this.location.path();
            if (currentPath.length) {
                const baseUrl = currentPath.split("-");
                if (baseUrl.length) this.router.navigateByUrl(baseUrl[0]);
            }
        } else if (this.goBackAction === "custom") {
            this.goBackActionChange.emit();
        } else {
            this.location.back();
        }
    }
}
