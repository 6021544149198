import { ProductTypeService } from "projects/app/src/app/services/http/clients/product-type.service";
import { ProductTypeEntity } from "./abstract/product-type-entity";
import { OpportunityProductTypeView, ProductTypeView } from "projects/app/src/app/services/http/clients/api-proxies";

export class OpportunityProductTypeEntity extends ProductTypeEntity<OpportunityProductTypeView> {
    constructor(productTypeService: ProductTypeService) {
        super(productTypeService);
    }

    toDestination(entity: ProductTypeView): OpportunityProductTypeView {
        return { id: 0, productTypeId: entity.id, productTypeName: entity.name };
    }

    toSource(entity: OpportunityProductTypeView): ProductTypeView {
        return { id: entity.productTypeId, name: entity.productTypeName };
    }
}
