import { DateTime } from "luxon";
import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from "@angular/core";
import { DateTimeService } from "../../../services";
import { EventsForPeriod } from "../types/full-view-calendar.types";
@Component({
    selector: "lib-day-week-view-calendar",
    templateUrl: "./day-week-view-calendar.component.html",
    styleUrls: ["./day-week-view-calendar.component.scss"]
})
export class DayWeekViewCalendarComponent<T> implements OnChanges {
    readonly hours?: number[];

    @Input()
    currentPeriod?: DateTime[];

    @Input()
    eventsForPeriod: EventsForPeriod<T>;

    @Input()
    eventTemplate!: TemplateRef<any>;

    constructor(private readonly dateTimeService: DateTimeService) {
        this.hours = Array.from({ length: 24 }, (_, i) => i);
        this.eventsForPeriod = {};
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes["currentPeriod"].firstChange) {
            this.scrollToCurrentHour();
        }
    }

    isToday(date: DateTime): boolean {
        return this.dateTimeService.isToday(date);
    }

    isWeekend(date: DateTime): boolean {
        return this.dateTimeService.isWeekend(date);
    }

    private scrollToCurrentHour(): void {
        const now = this.dateTimeService.now();
        const hourElement = document.getElementById(`hour-${now.hour}`);

        if (hourElement) {
            hourElement.scrollIntoView({ behavior: "auto", block: "center" });
        }
    }
}
