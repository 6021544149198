<div class="notificationsWrapper">
    <ng-container *ngFor="let notification of notifications">
        <app-notification-item [notification]="notification" (notificationChange)="onNotificationChange()" [isPanel]="isPanel"></app-notification-item>
    </ng-container>
    <div *ngIf="paginationConfig && !canGoNext()" class="allLoaded">
        <div class="content">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24Z" fill="#477A9C"/>
            <path d="M15 34.6666V13H25.7948L26.3281 15.6667H33.6666V27.6666H25.5385L25.0051 24.9999H17V34.6666H15ZM27.2 25.6666H31.6666V17.6666H24.6666L24.1333 15H17V23H26.6666L27.2 25.6666Z" fill="white"/>
            </svg>
            <div class="text">
                That’s all your notifications
                from the last 30 days.
            </div>
        </div>
    </div>
    <div class="scrollRef" *ngIf="paginationConfig" libIntersectionListener (intersectionListener)="goNext()"></div>
</div>