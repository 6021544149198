import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, Renderer2 } from "@angular/core";

@Directive({
    selector: "[libDragAndDrop]"
})
export class DragAndDropDirective {
    @HostBinding("draggable") draggable = true;

    @Input()
    items?: any[];

    @Input()
    index: number | null;

    @Output()
    itemsChange: EventEmitter<any[]>;

    private draggedItemIndex: number | null;
    private dropIndicator: HTMLElement;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) {
        this.items = [];
        this.index = null;
        this.draggedItemIndex = null;
        this.itemsChange = new EventEmitter<any[]>();
        this.dropIndicator = this.renderer.createElement("div");
        this.renderer.addClass(this.dropIndicator, "drop-indicator");
        this.renderer.setStyle(this.dropIndicator, "background-color", "#0095ff");
        this.renderer.setStyle(this.dropIndicator, "height", "3px");
    }

    @HostListener("dragstart", ["$event"])
    onDragStart(event: DragEvent) {
        this.draggedItemIndex = this.index;
        event.dataTransfer?.setData("text/plain", JSON.stringify(this.index));
    }

    @HostListener("dragover", ["$event"])
    onDragOver(event: DragEvent) {
        event.preventDefault();

        const target = this.elementRef.nativeElement;
        const parent = target.parentNode;

        if (target && parent === this.elementRef.nativeElement.parentNode) {
            const boundingRect = target.getBoundingClientRect();
            const offset = event.clientY - boundingRect.top;

            if (this.renderer.parentNode(this.dropIndicator) === parent) {
                this.renderer.removeChild(parent, this.dropIndicator);
            }

            if (offset < boundingRect.height / 2) {
                this.renderer.insertBefore(parent, this.dropIndicator, target);
            } else {
                this.renderer.insertBefore(parent, this.dropIndicator, target.nextSibling);
            }
        }
    }

    @HostListener("dragend", ["$event"])
    onDragEnd() {
        const parent = this.elementRef.nativeElement.parentNode;
        this.removeDropIndicator(parent);
    }

    @HostListener("dragleave", ["$event"])
    onDragLeave() {
        const parent = this.elementRef.nativeElement.parentNode;
        this.removeDropIndicator(parent);
    }

    @HostListener("drop", ["$event"])
    onDrop(event: DragEvent) {
        event.preventDefault();
        const droppedIndex = this.index;

        const draggedIndex = event.dataTransfer?.getData("text/plain") ?? null;
        if (draggedIndex !== null) {
            this.draggedItemIndex = parseInt(draggedIndex);
        }

        if (this.items && this.draggedItemIndex !== null && droppedIndex !== null && this.draggedItemIndex !== droppedIndex) {
            const draggedItem = this.items[this.draggedItemIndex];
            this.items.splice(this.draggedItemIndex, 1);
            this.items.splice(droppedIndex, 0, draggedItem);
            this.itemsChange.emit(this.items);
        }
        this.draggedItemIndex = null;

        const parent = this.elementRef.nativeElement.parentNode;
        this.removeDropIndicator(parent);
    }

    removeDropIndicator(parent: any) {
        if (this.dropIndicator.parentNode === parent) {
            this.renderer.removeChild(parent, this.dropIndicator);
        }
    }
}
