<div class="content" *ngIf="noResults && !isLoading; else showGrid">
    <lib-no-data
        [iconName]="'empty'"
        [title]="isFinalEstimate ? 'No final estimates available' : 'No alternates estimates available'"
        text="Please check back later or"
        linkText="refresh for updates"
        (linkAction)="refreshPage()">
    </lib-no-data>
</div>

<ng-template #showGrid>
    <div class="content">
        <lib-grid-view
            [gridConfig]="
            isFinalEstimate ?
            {
                columnsConfig: [
                    { key: 'name', label: 'Name', sortable: true },
                    { key: 'description', label: 'Description', sortable: false },
                    { key: 'sellPrice', label: 'Sell Price', sortable: false, cellTemplate: sellPriceTemplate },
                    { key: 'grossMarginPercentage', label: 'Gross Margin', sortable: false, cellTemplate: grossMarginTemplate },
                    { key: 'creationDate', label: 'Date Created', sortable: false, cellTemplate: creationDateTemplate },
                    { key: 'modificationDate', label: 'Last Modified', sortable: false, cellTemplate: modificationDateTemplate },
                    { key: 'isSelected', label: 'Status', sortable: false, cellTemplate: selectedTemplate }
                ],
            } : {
                columnsConfig: [
                    { key: 'name', label: 'Name', sortable: true },
                    { key: 'description', label: 'Description', sortable: false },
                    { key: 'sellPrice', label: 'Sell Price', sortable: false, cellTemplate: sellPriceTemplate },
                    { key: 'grossMarginPercentage', label: 'Gross Margin', sortable: false, cellTemplate: grossMarginTemplate },
                    { key: 'creationDate', label: 'Date Created', sortable: false, cellTemplate: creationDateTemplate },
                    { key: 'modificationDate', label: 'Last Modified', sortable: false, cellTemplate: modificationDateTemplate },
            ],
            }"
            [source]="opportunityEstimates"
            [(orderConfig)]="orderConfig"
            (orderConfigChange)="onOrderChanged()"
            [(paginationConfig)]="paginationConfig"
            (paginationConfigChange)="refreshGridView()"
            (rowClicked)="onRowClicked($event)"
            [hasExternalLink]="true"
        >

            <ng-template #sellPriceTemplate let-data>
                {{ data.object.sellPrice | currency }}
            </ng-template>

            <ng-template #grossMarginTemplate let-data>
                {{ data.object.grossMarginPercentage | customPercentage }}
            </ng-template>

            <ng-template #creationDateTemplate let-data>
                {{ data.object.creationDate | date: "MM/dd/y hh:mm a" }}
            </ng-template>

            <ng-template #modificationDateTemplate let-data>
                {{ data.object.modificationDate | date: "MM/dd/y hh:mm a" }}
            </ng-template>

            <ng-template #selectedTemplate let-data>
                <lib-badge *ngIf="data.object.isSelected" text="Selected" [color]="'secondary'"></lib-badge>
            </ng-template>
        </lib-grid-view>
    </div>
</ng-template>
