import { ComponentRef, Type } from "@angular/core";
import { SnackbarNotificationComponentBase } from "./snackbar-notification-component-base";

export class SnackbarNotificationInstance {
    resolve?: () => void;

    reject?: (reason?: string) => void;

    componentRef?: ComponentRef<SnackbarNotificationComponentBase>;

    constructor(
        public readonly type: Type<SnackbarNotificationComponentBase>,
        public readonly index: number,
        public readonly stackLevel: number
    ) {}

    close(): void {
        if (this.resolve) this.resolve();
    }

    throw(error: string): void {
        if (this.reject) this.reject(error);
    }
}
