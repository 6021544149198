import { ComponentRef, Injectable, Type, ViewContainerRef } from "@angular/core";
import { ModalComponentBase } from "../classes/modal-component-base";

@Injectable({
    providedIn: "root"
})
export class ModalService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private modalInstances: ModalInstance<any, any>[];

    private viewContainer?: ViewContainerRef;

    constructor() {
        this.modalInstances = [];
    }

    setViewContainer(container: ViewContainerRef): void {
        this.viewContainer = container;
    }

    open<TParameters, TResult>(type: Type<ModalComponentBase<TParameters, TResult | undefined>>, parameters?: TParameters): Promise<TResult | undefined> {
        // create modal instance.
        const modalInstance = new ModalInstance<TParameters, TResult | undefined>(type);
        this.modalInstances.push(modalInstance);

        // setup the modal promise.
        const promise = new Promise<TResult | undefined>((resolve, reject) => {
            modalInstance.resolve = (result: TResult | undefined) => {
                resolve(result);
                this.remove(modalInstance);
            };
            modalInstance.reject = (reason?: string) => {
                reject(reason);
                this.remove(modalInstance);
            };
        });

        // create the component.
        const componentRef = this.viewContainer?.createComponent(type);

        // initialize the modal and component.
        modalInstance.componentRef = componentRef;
        componentRef?.instance.setModalService(this);
        componentRef?.instance.setModalInstance(modalInstance);
        componentRef?.instance.setParameters(parameters);

        // insert view on screen.
        if (componentRef?.hostView) {
            this.viewContainer?.insert(componentRef.hostView);
        }

        // return the promise.
        return promise;
    }

    private remove<TParameters, TResult>(modalInstance: ModalInstance<TParameters, TResult>): void {
        const index = this.modalInstances.indexOf(modalInstance);
        if (index >= 0) {
            // prevent double remove
            this.modalInstances.splice(index, 1);
            if (modalInstance?.componentRef?.hostView) this.viewContainer?.remove(this.viewContainer?.indexOf(modalInstance?.componentRef?.hostView));
        }
    }

    public dismissAll(): void {
        while (this.modalInstances.length > 0) {
            this.modalInstances[this.modalInstances.length - 1].resolve!(undefined);
        }
    }
}

export class ModalInstance<TParameters, TResult> {
    resolve?: (result: TResult) => void;

    reject?: (reason: string) => void;

    componentRef?: ComponentRef<ModalComponentBase<TParameters, TResult | undefined>>;

    constructor(public readonly type: Type<ModalComponentBase<TParameters, TResult | undefined>>) {}
}
