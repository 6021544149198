<lib-generic-type-ahead-dropdown
    [(selectedItem)]="selectedBuilding"
    [placeholder]="placeholder"
    (selectedItemChange)="onBuildingChange($event)"
    [displaySelected]="displaySelected"
    [(text)]="searchText"
    [source]="source"
    (textChange)="onBuildingTextChanged()"
    [config]="{
        defaultText: 'Add Building',
        itemDisplayKey: 'name',
        itemTemplate: buildingTemplate
    }"
    [isReadOnly]="isReadOnly"
    [action]="addBuildingAction"
    [error]="error"
>
</lib-generic-type-ahead-dropdown>

<ng-template #buildingTemplate let-data>
    <div *ngIf="!data.object.alternateName">
        <div class="title" [innerHTML]="data.object?.name | highlightText:data.inputText"></div>
    </div>
    <div *ngIf="data.object?.alternateName">
        <div class="title" [innerHTML]="data.object?.alternateName | highlightText:data.inputText"></div>
        <div class="subtitle" [innerHTML]="data.object?.name | highlightText:data.inputText"></div>
    </div>
</ng-template>