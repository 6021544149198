<div class="topSection">

    <div class="container">
        <div class="dropdown">
            <span class="text">Overview of the</span>
            <lib-generic-dropdown
                [source]="comparisonOptions"
                [(selectedItem)]="selectedOption"
                (selectedItemChange)="onSelectedOption()"
                [config]="{
                    multiple: false,
                    defaultText: 'Select',
                    itemDisplayKey: 'name',
                    clearSelection: false
                }">
            </lib-generic-dropdown>
        </div>

        <div class="stats">
            <lib-dashboard-card>
                <app-dashboard-stat
                    [title]="'New Opportunities'"
                    [previousValue]="opportunityStat?.previousResult?.opportunitiesCount"
                    [currentValue]="opportunityStat?.currentResult?.opportunitiesCount">
            </app-dashboard-stat>
            </lib-dashboard-card>
            <lib-dashboard-card>
                <app-dashboard-stat
                    [title]="'Bid Amount'"
                    [previousValue]="opportunityStat?.previousResult?.bidAmount"
                    [currentValue]="opportunityStat?.currentResult?.bidAmount"
                    [type]="'currency'">
            </app-dashboard-stat>
            </lib-dashboard-card>
            <lib-dashboard-card>
                <app-dashboard-stat
                    [title]="'Awarded Amount'"
                    [previousValue]="opportunityStat?.previousResult?.awardedAmount"
                    [currentValue]="opportunityStat?.currentResult?.awardedAmount"
                    [type]="'currency'">
            </app-dashboard-stat>
            </lib-dashboard-card>
            <lib-dashboard-card>
                <app-dashboard-stat
                    [title]="'Total Gross Margin'"
                    [previousValue]="opportunityStat?.previousResult?.totalGrossMarginPercentage"
                    [currentValue]="opportunityStat?.currentResult?.totalGrossMarginPercentage"
                    [type]="'customPercentage'">
                </app-dashboard-stat>
            </lib-dashboard-card>
        </div>

        <div class="charts">
            <lib-dashboard-card>
                <div class="chartContainer">
                    <div class="title">Sales Funnel</div>
                    <app-bar-chart [data]="salesFunnelData"></app-bar-chart>
                </div>
            </lib-dashboard-card>
            <lib-dashboard-card>
                <div class="chartContainer">
                    <div class="title">Bid vs. Awarded </div>
                    <div class="doughnutCharts">
                        <app-doughnut-chart [data]="bidVsAwardedCurrentData"></app-doughnut-chart>
                        <span class="line" *ngIf="bidVsAwardedCurrentData && bidVsAwardedPreviousData"></span>
                        <app-doughnut-chart [data]="bidVsAwardedPreviousData"></app-doughnut-chart>
                    </div>
                </div>
            </lib-dashboard-card>
        </div>
    </div>
</div>

<div class="bottomSection">
    <div class="grids">
        <app-dashboard-opportunities-grid [type]="'upcomingBids'"></app-dashboard-opportunities-grid>
        <app-dashboard-opportunities-grid [type]="'newProjects'"></app-dashboard-opportunities-grid>
    </div>
</div>