import { INumberValidatorArgs, IValidator, IValidatorProp, ValidationError } from "../../interfaces/validator.interface";

export class NumberValidator implements IValidator<number | bigint | number[] | bigint[], INumberValidatorArgs> {
    validate(validatorProp: IValidatorProp<number | bigint | number[] | bigint[], INumberValidatorArgs>): ValidationError | null {
        const errors: { index: number; message: string }[] = [];
        const minValueMessage = `*Input is less than the minimum value of ${validatorProp.args.minValue}`;
        const maxValueMessage = `*Input exceeds the maximum value of ${validatorProp.args.maxValue}`;

        if (Array.isArray(validatorProp.value)) {
            validatorProp?.value?.forEach((item, index) => {
                if (validatorProp?.args?.minValue && item < validatorProp.args.minValue) {
                    errors.push({ index, message: validatorProp?.args?.customMessage ?? minValueMessage });
                } else if (validatorProp?.args?.maxValue && item > validatorProp.args.maxValue) {
                    errors.push({ index, message: validatorProp?.args?.customMessage ?? maxValueMessage });
                }
            });

            if (errors.length === 0) return null;
            return { errors };
        } else {
            if (validatorProp?.args?.minValue && validatorProp?.value < validatorProp.args.minValue) {
                return { errorMessage: validatorProp?.args?.customMessage ?? minValueMessage };
            }

            if (validatorProp?.args?.maxValue && validatorProp?.value > validatorProp.args.maxValue) {
                return { errorMessage: validatorProp?.args?.customMessage ?? maxValueMessage };
            }
        }

        return null;
    }
}
