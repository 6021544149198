import { Injectable } from "@angular/core";
import {
    ChangeOrderCostsView,
    GetChangeOrderCostsResult,
    IOpportunityChangeOrderEstimateClient,
    OpportunityChangeOrderEstimateClient,
    OpportunityChangeOrderEstimateViewPaginatedResultDto,
    SearchOpportunityEstimateParameters
} from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class OpportunityChangeOrderEstimateService {
    private client: IOpportunityChangeOrderEstimateClient;
    constructor() {
        this.client = new OpportunityChangeOrderEstimateClient();
    }

    async costsOverview(opportunityId: number): Promise<GetChangeOrderCostsResult> {
        return await this.client.opportunityChangeOrderEstimateGetCostsOverview(opportunityId);
    }

    async searchChangeOrdersByOpportunity(body: SearchOpportunityEstimateParameters): Promise<OpportunityChangeOrderEstimateViewPaginatedResultDto> {
        return await this.client.opportunityChangeOrderEstimateSearchChangeOrdersByOpportunity(body);
    }
}
