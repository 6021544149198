import { Component, OnInit, ViewChild } from "@angular/core";
import { DivisionView, EntityTypeCustomFieldView } from "projects/app/src/app/services/http/clients/api-proxies";
import { CustomizationCustomFieldsComponent } from "./customization-custom-fields/customization-custom-fields.component";
import { CustomizationCustomInfoComponent } from "./customization-custom-info/customization-custom-info.component";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { EntityTypeCustomFieldService } from "projects/app/src/app/services/http/clients/entity-type-custom-field.service";
import { DivisionService } from "projects/app/src/app/services/http/clients/division.service";
import { ModalService, ObjectExtensionsService, SnackbarNotificationService, TabChangeArgs } from "projects/ngx-lib/src/public-api";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { ModalConfirmComponent } from "../../../shared/modals/modal-confirm/modal-confirm.component";
import { ArchiveEntityService } from "projects/app/src/app/services/http/clients/archive-entity.service";
import { EntityTypeService } from "projects/app/src/app/services/http/clients/entity-type.service";
import { ICustomizationFiltersOptions } from "./customization-filters/customization-filters.component";

interface ICustomizationItems {
    id: number;
    tabName: string;
    title?: string;
    subtitle?: string;
    type: "customFields" | "customInfo";
}

@Component({
    selector: "app-customization",
    templateUrl: "./customization.component.html",
    styleUrls: ["./customization.component.scss"]
})
export class CustomizationComponent implements OnInit {
    currentTab: string;

    configurationItems: ICustomizationItems[];

    customFields?: EntityTypeCustomFieldView[];

    customFieldsInitialState?: EntityTypeCustomFieldView[];

    divisions?: DivisionView[];

    divisionsInitialState?: DivisionView[];

    isModelEqual: boolean;

    filterOptions?: ICustomizationFiltersOptions;

    @ViewChild("customFieldsList")
    customFieldsList?: CustomizationCustomFieldsComponent;

    @ViewChild("customInfoList")
    customInfoList?: CustomizationCustomInfoComponent;

    constructor(
        private readonly awaiter: AwaiterService,
        private readonly entityTypeCustomFieldService: EntityTypeCustomFieldService,
        private readonly divisionsService: DivisionService,
        private readonly objectExtensionService: ObjectExtensionsService,
        private readonly modalService: ModalService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly archiveEntityService: ArchiveEntityService,
        private readonly entityTypeService: EntityTypeService
    ) {
        this.currentTab = "Opportunities";
        this.isModelEqual = true;

        this.configurationItems = [
            {
                id: EntityTypes.Opportunity,
                tabName: "Opportunities",
                title: "Opportunities",
                subtitle: "Custom Fields",
                type: "customFields"
            },
            {
                id: EntityTypes.Contact,
                tabName: "Contacts",
                title: "Contacts",
                subtitle: "Custom Fields",
                type: "customFields"
            },
            {
                id: EntityTypes.Building,
                tabName: "Buildings",
                title: "Buildings",
                subtitle: "Custom Fields",
                type: "customFields"
            },
            {
                id: EntityTypes.Company,
                tabName: "Companies",
                title: "Companies",
                subtitle: "Custom Fields",
                type: "customFields"
            },
            {
                id: EntityTypes.Division,
                tabName: "Divisions",
                title: "Divisions",
                subtitle: "Edit divisions",
                type: "customInfo"
            }
        ];
    }

    async ngOnInit(): Promise<void> {
        const conditionOptions = await this.archiveEntityService.getArchiveOptions();
        const typeOptions = await this.entityTypeService.getReservedOptions();

        this.filterOptions = {
            condition: conditionOptions,
            type: typeOptions
        };
    }

    async onSave(): Promise<void> {
        this.saveCustomFields();
        this.saveDivisions();
    }

    onValueChanged(customizationType: string): void {
        this.isModelEqual =
            customizationType === "customFields"
                ? this.objectExtensionService.isEqualTo(this.customFields, this.customFieldsInitialState)
                : this.objectExtensionService.isEqualTo(this.divisions, this.divisionsInitialState);
    }

    async onCancel(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Exit",
            cancelCaption: "Continue editing",
            content: "Are you sure you want to move on? Your changes will be lost",
            title: "Unsaved changes"
        });

        if (!responseOk) return;

        this.reset();
    }

    async onBeforeTabChange(event: TabChangeArgs): Promise<void> {
        if (!this.isModelEqual) {
            event.canChange = Promise.resolve(false);
        }
    }

    private async saveCustomFields(): Promise<void> {
        const customFieldItemValid = this.customFieldsList?.customFieldsItems
            ?.map(customFieldsItem => customFieldsItem.validate())
            .every(customFieldsItem => customFieldsItem);

        if (!customFieldItemValid) return;

        await this.awaiter.awaitAction("Saving Custom Fields", async () => {
            if (this.customFields) {
                this.customFields = await this.entityTypeCustomFieldService.save(this.customFields);
                this.snackbarNotificationService.success("Custom Fields saved successfully.");
                this.customFieldsInitialState = this.customFields?.map(item => this.objectExtensionService.clone(item) as EntityTypeCustomFieldView);
                this.onValueChanged("customFields");
            }
        });
    }

    private async saveDivisions(): Promise<void> {
        const divisionsValid = this.customInfoList?.customInfoItem?.map(customInfoItem => customInfoItem.validate()).every(customInfo => customInfo);

        if (!divisionsValid) return;

        await this.awaiter.awaitAction("Saving Divisions", async () => {
            if (this.divisions) {
                this.divisions = await this.divisionsService.save(this.divisions);
                this.snackbarNotificationService.success("Divisions saved successfully.");
                this.divisionsInitialState = this.divisions?.map(item => this.objectExtensionService.clone(item) as DivisionView);
                this.onValueChanged("customInfo");
            }
        });
    }

    private resetCustomFields() {
        if (!this.objectExtensionService.isEqualTo(this.customFields, this.customFieldsInitialState)) {
            this.customFields = this.customFieldsInitialState?.map(item => this.objectExtensionService.clone(item) as EntityTypeCustomFieldView);
            this.onValueChanged("customFields");
        }
    }

    private resetDivisions() {
        if (!this.objectExtensionService.isEqualTo(this.divisions, this.divisionsInitialState)) {
            this.divisions = this.divisionsInitialState?.map(item => this.objectExtensionService.clone(item) as DivisionView);
            this.onValueChanged("customInfo");
        }
    }

    private reset() {
        this.resetCustomFields();
        this.resetDivisions();
    }
}
