export function html(strings: TemplateStringsArray, ...values: unknown[]): string {
	for (const index in values) {
		const value = values[index];

		if (Array.isArray(value)) {
			values[index] = value.join('');
		}

		if (!value) {
			values[index] = '';
		}
	}

	return String.raw({ raw: strings }, ...values);
}
