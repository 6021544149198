import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from "@angular/core";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-formatted-input-editor",
    templateUrl: "./formatted-input-editor.component.html",
    styleUrls: ["./formatted-input-editor.component.scss"]
})
export class FormattedInputEditorComponent extends EditorComponentBase<number> implements AfterViewInit {
    @ViewChild("input", { static: true }) input!: ElementRef;

    @Input()
    allowNegative: boolean;

    private regex = {
        positiveDecimal: new RegExp(/^\d*[.|,]?\d*$/g),
        allDecimal: new RegExp(/^-?\d*[.|,]?\d*$/g)
    };

    private specialKeys: Array<string> = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Del", "Tab"];

    constructor() {
        super();
        this.allowNegative = false;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.input.nativeElement.value = this.formatValue(this.bindingProperty);
        }, 0);
    }

    inputChanged(value: string): number {
        return parseFloat(value);
    }

    formatValue(inputValue?: unknown) {
        return inputValue;
    }

    @HostListener("focus") onFocus() {
        if (this.isReadOnly) return;
        this.input.nativeElement.value = this.input.nativeElement.value.replace(/[^\d.,-]/g, "");
        this.input.nativeElement.select();
    }

    @HostListener("keydown", ["$event"])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }

        const matches = this.allowNegative ? event.key.match(this.regex.allDecimal) : event.key.match(this.regex.positiveDecimal);
        if (!matches || event.key === ",") {
            event.preventDefault();
        }
    }

    @HostListener("blur") onBlur() {
        const amount = this.reverseFormatNumber(this.input.nativeElement.value).replace(/^[^0-9]{2}..*/g, "");
        this.bindingProperty = parseFloat(amount);
        this.input.nativeElement.value = amount;
        this.validate();
        this.valueChange.emit();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reverseFormatNumber(value: any) {
        if (!value) return "0";

        const group = ",";
        const decimal = ".";
        let reversedCurrencyValue = value.replace(new RegExp("\\" + group, "g"), "");
        reversedCurrencyValue = reversedCurrencyValue.replace(new RegExp("\\" + decimal, "g"), ".");
        const reversedValue = reversedCurrencyValue.replace(/[^\d.-]/g, "");
        return Number.isNaN(reversedValue) ? 0 : reversedValue;
    }
}
