import { Injectable } from "@angular/core";
import {
    IOpportunityUserClient,
    OpportunityUserClient,
    OpportunityUserViewPaginatedResultDto,
    SearchAvailableOpportunityUsersParameters,
    SearchUsersForOpportunityParameters,
    UserView
} from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class OpportunityUserService {
    private client: IOpportunityUserClient;
    constructor() {
        this.client = new OpportunityUserClient();
    }

    async search(body: SearchUsersForOpportunityParameters): Promise<OpportunityUserViewPaginatedResultDto> {
        return await this.client.opportunityUserSearchForOpportunity(body);
    }

    async delete(id: number): Promise<void> {
        return await this.client.opportunityUserDelete(id);
    }

    async quickSearch(body: SearchAvailableOpportunityUsersParameters): Promise<UserView[]> {
        return await this.client.opportunityUserSearchAvailable(body);
    }
}
