import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
    ContactView,
    QuickSearchContactsParameters,
    SearchAvailableBuildingContactsParameters,
    SearchAvailableOpportunityContactsParameters
} from "../../../services/http/clients/api-proxies";
import { ContactService } from "../../../services/http/clients/contact.service";
import { ModalService } from "projects/ngx-lib/src/lib/services/modal.service";
import { ModalAddContactComponent } from "../modals/modal-add-contact/modal-add-contact.component";
import { OpportunityContactService } from "../../../services/http/clients/opportunity-contact.service";
import { EntityTypes } from "../../../models/enums/EntityTypes";
import { BuildingContactService } from "../../../services/http/clients/building-contact.service";
import { ITypeAheadDropdownEntityParameters } from "../../../interfaces/type-ahead-dropdown-entity-parameters.interface";
import { ITypeAheadDropdownAction } from "projects/ngx-lib/src/lib/interfaces/type-ahead-dropdown-config.interface";

@Component({
    selector: "app-type-ahead-contacts",
    templateUrl: "./type-ahead-contacts.component.html",
    styleUrls: ["./type-ahead-contacts.component.scss"]
})
export class TypeAheadContactsComponent implements OnInit {
    @Input()
    selectedContact?: ContactView;

    @Input()
    isReadOnly?: boolean;

    @Input()
    searchParameters!: ITypeAheadDropdownEntityParameters;

    @Input()
    companyId?: number;

    @Input()
    setCompany?: boolean;

    @Input()
    searchAllCompanies?: boolean;

    @Input()
    displaySelected?: boolean;

    @Output()
    selectedContactChange = new EventEmitter<ContactView>();

    searchText: string;

    source?: ContactView[];

    addContactAction!: ITypeAheadDropdownAction;

    constructor(
        private readonly contactService: ContactService,
        private readonly opportunityContactService: OpportunityContactService,
        private readonly buildingContactService: BuildingContactService,
        private readonly modalService: ModalService
    ) {
        this.searchText = "";
        this.isReadOnly = false;
        this.setCompany = false;
        this.displaySelected = false;
    }

    async ngOnInit(): Promise<void> {
        this.addContactAction = {
            includeAction: true,
            name: "+ Add new contact",
            action: () => this.openModal()
        };
    }

    async searchByEntity(entityId?: EntityTypes): Promise<ContactView[]> {
        const searchConfig: unknown = {
            filterText: this.searchText,
            ...this.searchParameters?.entity,
            ...(this.companyId && { companyId: this.companyId }),
            ...(this.searchAllCompanies && { allCompanies: true })
        };

        this.source = [];

        switch (entityId) {
            case EntityTypes.Opportunity:
                return await this.opportunityContactService.searchAvailable(searchConfig as SearchAvailableOpportunityContactsParameters);
            case EntityTypes.Building:
                return await this.buildingContactService.buildingContactSearchAvailable(searchConfig as SearchAvailableBuildingContactsParameters);
            default:
                // company and company main contact editor
                return await this.contactService.search(searchConfig as QuickSearchContactsParameters);
        }
    }

    async onContactTextChanged(): Promise<void> {
        if (!this.searchText) return;

        this.source = await this.searchByEntity(this.searchParameters?.entityKeyName);
    }

    onContactChange(itemSelected: ContactView): void {
        this.selectedContact = itemSelected;
        this.selectedContactChange.emit(itemSelected);
    }

    async openModal(): Promise<void> {
        this.selectedContact = await this.modalService.open(ModalAddContactComponent, {
            setCompany: this.setCompany
        });
        if (this.selectedContact) {
            this.selectedContactChange.emit(this.selectedContact);
        }
    }
}
