import { Injector } from "@angular/core";
import { IComponentEditorEntity } from "../interfaces/editor-entity.interface";
import { IEntity } from "../interfaces/entity.interface";
import { CompanyMarketEntity } from "../entities-classes/company-market-entity";

import { MarketService } from "projects/app/src/app/services/http/clients/market.service";
import { TagService } from "projects/app/src/app/services/http/clients/tag.service";
import { OpportunityMarketEntity } from "../entities-classes/opportunity-market-entity";
import { OpportunityTagEntity } from "../entities-classes/opportunity-tag-entity";
import { ProductTypeService } from "projects/app/src/app/services/http/clients/product-type.service";
import { OpportunityProductTypeEntity } from "../entities-classes/opportunity-product-type-entity";
import { OpportunityBuildingContactRoleEntity } from "../entities-classes/opportunity-building-contact-role-entity";
import { BuildingContactRoleService } from "projects/app/src/app/services/http/clients/building-contact-role.service";
import { OpportunityBidCategoryEntity } from "../entities-classes/opportunity-bid-category-entity";
import { BidCategoryService } from "projects/app/src/app/services/http/clients/bid-category.service";
import { UserRoleService } from "projects/app/src/app/services/http/clients/user-role.service";
import { OpportunityUserRoleEntity } from "../entities-classes/opportunity-user-role-entity";

export enum Entity {
    opportunityMarket = "opportunityMarket",
    companyMarket = "companyMarket",
    opportunityTag = "opportunityTag",
    productType = "productType",
    opportunityBuildingContactRole = "opportunityBuildingContactRole",
    opportunityBidCategory = "opportunityBidCategory",
    opportunityUserRole = "opportunityUserRole"
}

export class EntityFactory {
    static create(entityId: string, injector: Injector): IComponentEditorEntity<IEntity, unknown> {
        switch (entityId) {
            case Entity.companyMarket:
                return new CompanyMarketEntity(injector.get(MarketService));

            case Entity.opportunityMarket:
                return new OpportunityMarketEntity(injector.get(MarketService));

            case Entity.opportunityTag:
                return new OpportunityTagEntity(injector.get(TagService));

            case Entity.productType:
                return new OpportunityProductTypeEntity(injector.get(ProductTypeService));

            case Entity.opportunityBuildingContactRole:
                return new OpportunityBuildingContactRoleEntity(injector.get(BuildingContactRoleService));

            case Entity.opportunityBidCategory:
                return new OpportunityBidCategoryEntity(injector.get(BidCategoryService));

            case Entity.opportunityUserRole:
                return new OpportunityUserRoleEntity(injector.get(UserRoleService));

            default:
                throw new Error("Entity id not recognized as a valid entity type");
        }
    }
}
