import { Component, OnInit } from "@angular/core";
import { SnackbarNotificationComponentBase } from "projects/ngx-lib/src/lib/classes/snackbar-notification-component-base";

const SnackbarNotificationHeight = 44;
const Gap = 10;

@Component({
    selector: "lib-snackbar-notification",
    templateUrl: "./snackbar-notification.component.html",
    styleUrls: ["./snackbar-notification.component.scss"]
})
export class SnackbarNotificationComponent extends SnackbarNotificationComponentBase implements OnInit {
    showNotification: boolean;

    constructor() {
        super();
        this.showNotification = false;
    }

    ngOnInit(): void {
        this.showNotification = true;
        setTimeout(() => {
            this.showNotification = false;
            // This must be executed after the animation ended, otherwise the transition won't work
            setTimeout(() => {
                this.close();
            }, 600);
        }, 4000);
    }

    closeWithAnimation() {
        setTimeout(() => {
            this.showNotification = false;
            // This must be executed after the animation ended, otherwise the transition won't work
            setTimeout(() => {
                this.close();
            }, 500);
        });
    }

    getPosition(): number {
        if (!this.snackbarNotificationInstance) return 0;
        return this.snackbarNotificationInstance.stackLevel * (SnackbarNotificationHeight + Gap);
    }
}
