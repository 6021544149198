import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { OpportunityCalendarEvent } from "projects/app/src/app/models/enums/OpportunityCalendarEvent";
import { OpportunityUserView } from "projects/app/src/app/services/http/clients/api-proxies";
import { icons } from "projects/ngx-lib/src/lib/assets/icons";

export interface IOpportunityCardDetails {
    id?: number;
    name?: string;
    contactInfo?: string;
    eventDate?: Date;
    code?: string;
    opportunityStatusName?: string;
    companyName?: string;
    opportunityValue?: number;
    eventType?: number;
    opportunityUsers?: OpportunityUserView[];
    latitude?: number;
    longitude?: number;
}

@Component({
    selector: "app-opportunity-card-expanded",
    templateUrl: "./opportunity-card-expanded.component.html",
    styleUrls: ["./opportunity-card-expanded.component.scss"]
})
export class OpportunityCardExpandedComponent {
    readonly icons = icons;

    @Input()
    data?: IOpportunityCardDetails;

    @Input()
    hasBorder: boolean;

    @Input()
    showEventInfo?: boolean;

    @Output()
    closeClicked: EventEmitter<void>;

    @Output()
    goToClicked: EventEmitter<void>;

    closeIcon?: SafeHtml;

    dueDatetype?: OpportunityCalendarEvent;
    followUpType?: OpportunityCalendarEvent;

    constructor(private readonly sanitizer: DomSanitizer) {
        this.closeIcon = this.sanitizer.bypassSecurityTrustHtml(icons.close);
        this.showEventInfo = true;
        this.hasBorder = true;
        this.dueDatetype = OpportunityCalendarEvent.DueDate;
        this.followUpType = OpportunityCalendarEvent.FollowUpContactDate;
        this.closeClicked = new EventEmitter<void>();
        this.goToClicked = new EventEmitter<void>();
    }

    onCloseClicked(): void {
        this.closeClicked.emit();
    }

    onGoToClicked(): void {
        this.goToClicked.emit();
    }
}
