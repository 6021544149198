import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { OpportunityEstimateView, SearchOpportunityEstimateParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityEstimateService } from "projects/app/src/app/services/http/clients/opportunity-estimate.service";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";

@Component({
    selector: "app-opportunities-single-estimates-grid",
    templateUrl: "./opportunities-single-estimates-grid.component.html",
    styleUrls: ["./opportunities-single-estimates-grid.component.scss"]
})
export class OpportunitiesSingleEstimatesGridComponent implements OnChanges {
    @Input()
    opportunityId?: number;

    opportunityEstimates: OpportunityEstimateView[];

    searchConfig: SearchOpportunityEstimateParameters;

    paginationConfig: IGenericGridPaginationConfig;

    orderConfig?: IGenericGridOrderConfig<OpportunityEstimateView>;

    @Input()
    isFinalEstimate: boolean;

    noResults: boolean;

    isLoading: boolean;

    constructor(
        private readonly awaiter: AwaiterService,
        private readonly opportunityEstimateService: OpportunityEstimateService
    ) {
        this.opportunityEstimates = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            opportunityId: this.opportunityId
        } as SearchOpportunityEstimateParameters;

        this.isFinalEstimate = false;
        this.noResults = true;
        this.isLoading = true;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.opportunityId && changes["opportunityId"]) {
            this.searchConfig.opportunityId = this.opportunityId;
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Opportunity Estimates",
            async () => {
                let response;
                if (this.isFinalEstimate) {
                    response = await this.opportunityEstimateService.searchFinalEstimatesByOpportunity(this.searchConfig);
                } else {
                    response = await this.opportunityEstimateService.searchAlternateEstimatesByOpportunity(this.searchConfig);
                }

                const { pageInfo, results } = response;

                this.opportunityEstimates = this.opportunityEstimates?.concat(results ?? []);

                this.noResults = this.opportunityEstimates?.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    buildSearchConfig() {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            sortDirection: this.orderConfig?.order,
            sortBy: this.orderConfig?.key,
            opportunityId: this.opportunityId
        } as SearchOpportunityEstimateParameters;
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;

        this.opportunityEstimates = [];

        await this.refreshGridView();
    }

    onRowClicked(opportunityEstimate: OpportunityEstimateView) {
        window.open(opportunityEstimate?.externalUrl, "_blank");
    }

    refreshPage() {
        window.location.reload();
    }
}
