import { Component, OnInit } from "@angular/core";
import { OpportunityUser } from "projects/app/src/app/models/OpportunityUser";
import { OpportunityUserView, SearchUsersParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { UserService } from "projects/app/src/app/services/http/clients/user.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-opportunity-user-editor",
    templateUrl: "./opportunity-user-editor.component.html",
    styleUrls: ["./opportunity-user-editor.component.scss"]
})
export class OpportunityUserEditorComponent extends EditorComponentBase<OpportunityUserView[]> implements OnInit {
    userItems?: OpportunityUser[];

    selectedUsers?: OpportunityUser[];

    constructor(private readonly userService: UserService) {
        super();
    }

    ngOnInit(): void {
        if (this.bindingProperty) {
            const mappedResults = this.bindingProperty.map(x => OpportunityUser.fromInterface(x));
            this.selectedUsers = mappedResults?.filter(user => user.userExternalId);
        }
    }

    async onUserTextChange(event: string) {
        const results = await this.userService?.search({
            filterText: event
        } as SearchUsersParameters);

        this.userItems = results.map(x => OpportunityUser.fromUserView(x));
    }

    onSelectedUserChange(event: OpportunityUser[]) {
        this.bindingProperty = this.selectedUsers = event;
        this.valueChange.emit();
        this.validate();
    }

    onRemoveUserChange() {
        if (this.bindingProperty && this.selectedUsers) {
            this.bindingProperty = this.selectedUsers;
            this.valueChange.emit();
            this.validate();
        }
    }
}
