import { Directive, ElementRef, Input, OnDestroy, Output, EventEmitter, HostListener, OnChanges, AfterContentInit } from '@angular/core';

@Directive({
	selector: '[libFocusTrap]'
})
export class FocusTrapDirective implements OnChanges, OnDestroy, AfterContentInit {
	@Input() libFocusTrap?: string;

	@Output() focusTrapEscape = new EventEmitter<void>();

	private elementRef: ElementRef<HTMLElement>;
	private focusableElements: HTMLElement[] = [];
	private firstElement: HTMLElement | null = null;
	private lastElement: HTMLElement | null = null;

	constructor(elementRef: ElementRef<HTMLElement>) {
		this.elementRef = elementRef;
	}

	ngAfterContentInit() {
		this.updateFocusableElements();
	}

	ngOnChanges() {
		if (!this.libFocusTrap) this.libFocusTrap = 'a[href], button:not([disabled]), textarea, input, select';
	}

	ngOnDestroy() {
		document.removeEventListener('keydown', this.keyDownHandler);
	}

	@HostListener('keydown', ['$event'])
	keyDownHandler(event: KeyboardEvent) {
		if (event.key !== 'Tab') return;

		if (!this.focusInComponent(document.activeElement as HTMLElement)) {
			if (document.activeElement === this.lastElement) {
				this.lastElement = this.focusableElements[0] || null;
			}
			this.firstElement?.focus();
			event.preventDefault();
			this.focusTrapEscape.emit();
		} else if (!event.shiftKey && document.activeElement === this.lastElement) {
			this.firstElement?.focus();
			event.preventDefault();
		} else if (event.shiftKey && document.activeElement === this.firstElement) {
			this.lastElement?.focus();
			event.preventDefault();
		}
	}

	updateFocusableElements() {
		if (!this.libFocusTrap) return;
		this.focusableElements = Array.from(this.elementRef.nativeElement.querySelectorAll(this.libFocusTrap));
		this.focusableElements = this.focusableElements.filter((el) => el.tabIndex === 0);
		this.firstElement = this.focusableElements[0] || null;
		this.lastElement = this.focusableElements[this.focusableElements.length - 1] || null;

		this.firstElement.focus(); //focus first element on open
	}

	focusInComponent(activeElement: HTMLElement) {
		return this.focusableElements.includes(activeElement);
	}
}
