import { Component, OnInit } from "@angular/core";
import { ProjectTypeView } from "projects/app/src/app/services/http/clients/api-proxies";
import { ProjectTypeService } from "projects/app/src/app/services/http/clients/project-type.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-project-type-editor",
    templateUrl: "./project-type-editor.component.html",
    styleUrls: ["./project-type-editor.component.scss"]
})
export class ProjectTypeEditorComponent extends EditorComponentBase<number | null> implements OnInit {
    projectTypeItems?: ProjectTypeView[];
    selectedProjectType?: ProjectTypeView;

    constructor(private readonly projectTypeService: ProjectTypeService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.projectTypeItems = await this.projectTypeService.getAll();

        if (this.bindingProperty) {
            this.selectedProjectType = this.projectTypeItems?.find(x => x.id === this.bindingProperty);
        }
    }

    onProjectTypeChange() {
        if (this.selectedProjectType?.id) {
            this.bindingProperty = this.selectedProjectType.id;
            this.validate();
        } else {
            this.bindingProperty = null;
        }
        this.valueChange.emit();
    }
}
