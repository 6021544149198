/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "sort"
})
export class SortPipe implements PipeTransform {
    transform(list: any[], field: string, asc = true): any[] {
        if (!list || !field) {
            return list;
        }

        list.sort((a, b) => {
            if (a[field] < b[field]) {
                return asc ? -1 : 1;
            } else if (a[field] > b[field]) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        });

        return list;
    }
}
