import { Component, OnInit } from "@angular/core";
import { BuildingView } from "projects/app/src/app/services/http/clients/api-proxies";
import { BuildingService } from "projects/app/src/app/services/http/clients/building.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-building-editor",
    templateUrl: "./building-editor.component.html",
    styleUrls: ["./building-editor.component.scss"]
})
export class BuildingEditorComponent extends EditorComponentBase<number | undefined> implements OnInit {
    selectedBuilding?: BuildingView;

    displaySelectedBuilding: boolean;

    hideSearchInput: boolean;

    constructor(private readonly buildingService: BuildingService) {
        super();
        this.displaySelectedBuilding = true;
        this.hideSearchInput = false;
    }

    async ngOnInit(): Promise<void> {
        this.hideSearchInput = this.additionalInfo?.hideSearchInput;
        if (this.bindingProperty) {
            this.selectedBuilding = await this.buildingService.getById(this.bindingProperty);
        }
    }

    onSelectedBuilding(building: BuildingView) {
        this.selectedBuilding = building;
        this.bindingProperty = this.selectedBuilding?.id;
        this.validate();
        this.valueChange.emit();
    }
}
