<div class="filters">
    <lib-grid-filters>
        <lib-search-input *libGridFilter [(text)]="filterValue.searchText" (textChange)="onFilterChanged()" [placeholderText]="'Search notes'">
        </lib-search-input>
        <lib-datepicker-dropdown
            *libGridFilter
            defaultTitle="Created/Modified on"
            [(dateFrom)]="filterValue.dateFrom"
            [(dateTo)]="filterValue.dateTo"
            (filterChanged)="onFilterChanged()"
            [dateRangeEnabled]="true"
        >
        </lib-datepicker-dropdown>
        <app-generic-type-ahead
            *libGridFilter
            [(selectedItem)]="filterValue.user"
            placeholder="Created by"
            (filterChanged)="onFilterChanged()"
            [service]="userServiceFilter"
            [config]="{
                defaultText: 'User',
                itemDisplayKey: 'firstName',
                itemTemplate: userTemplate
            }"
        >
        </app-generic-type-ahead>
        <app-generic-type-ahead
            *libGridFilter
            [(selectedItem)]="filterValue.noteUser"
            placeholder="Team assigned"
            (filterChanged)="onFilterChanged()"
            [service]="userServiceFilter"
            [config]="{
                defaultText: 'User',
                itemDisplayKey: 'firstName',
                itemTemplate: userTemplate
            }"
        >
        </app-generic-type-ahead>
        <ng-template #userTemplate let-data>
            <span>{{ data.object?.firstName + " " + data.object?.lastName }}</span>
        </ng-template>
    </lib-grid-filters>
</div>

<div class="actions">
    <button class="secondary-btn small newNoteButton" (click)="createNote()">+ New Note</button>
</div>

<ng-container *ngIf="notes?.length === 0; else showContent">
    <lib-no-data [iconName]="'empty'" [title]="'There are no related notes yet!'" linkText="Create New Note" (linkAction)="createNote()"> </lib-no-data>
</ng-container>

<ng-template #showContent>
    <lib-tab-view
        *ngIf="notes"
        [direction]="'column'"
        [separation]="'narrow'"
        [(currentTab)]="currentTab"
        [(paginationConfig)]="paginationConfig"
        (paginationConfigChange)="refreshTabView()"
    >
        <ng-container *ngFor="let note of notes; let i = index">
            <lib-tab-item [tabName]="note.noteKey" [headerTemplate]="noteTabItem" [bodyTemplate]="noteTabContent">
                <ng-template #noteTabItem>
                    <div class="tabItem">
                        <span class="subtitle">{{ note.modificationDate | date }}</span>
                        <span class="title">{{ note.title }}</span>
                    </div>
                </ng-template>

                <ng-template #noteTabContent>
                    <app-note-content [noteId]="note.id" [index]="i" (noteChanged)="onNoteChanged($event)"></app-note-content>
                </ng-template>
            </lib-tab-item>
        </ng-container>
    </lib-tab-view>
</ng-template>
