import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Contact } from "projects/app/src/app/models/Contact";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { ContactService } from "projects/app/src/app/services/http/clients/contact.service";
import { ModalService, SnackbarNotificationService, ObjectExtensionsService } from "projects/ngx-lib/src/public-api";
import { CompanyView } from "projects/app/src/app/services/http/clients/api-proxies";
import { ContactsSingleOverviewComponent } from "../contacts-single/contacts-single-overview/contacts-single-overview.component";
import { ModalConfirmComponent } from "projects/app/src/app/components/shared/modals/modal-confirm/modal-confirm.component";

@Component({
    selector: "app-contacts-edit",
    templateUrl: "./contacts-edit.component.html",
    styleUrls: ["./contacts-edit.component.scss"]
})
export class ContactsEditComponent implements OnInit {
    contact: Contact;

    contactId: number;

    buttonLabel?: string;

    continueButtonLabel?: string;

    title?: string;

    selectedCompany?: CompanyView;

    @ViewChild("overview")
    overview?: ContactsSingleOverviewComponent;

    contactInitialState!: Contact;

    constructor(
        private readonly awaiter: AwaiterService,
        private readonly contactService: ContactService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly modalService: ModalService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly objectExtensionService: ObjectExtensionsService
    ) {
        this.contact = new Contact();
        this.contactId = parseInt(this.route.snapshot.paramMap.get("id") ?? "0");
        this.buttonLabel = "Create";
        this.continueButtonLabel = "Continue creation";
        this.title = "Create New Contact";
    }

    async ngOnInit(): Promise<void> {
        if (this.contactId) {
            this.buttonLabel = "Save";
            this.continueButtonLabel = "Continue edition";
            this.title = "Edit Contact";

            await this.awaiter.awaitAction("Loading Contact", async () => {
                const view = await this.contactService.getById(this.contactId);
                if (view) {
                    this.contact = Contact.fromInterface(view);
                    this.contactInitialState = this.objectExtensionService.clone(this.contact) as Contact;
                }
            });
        }
    }

    async checkSavingData(): Promise<void> {
        if (!this.overview?.mainInfoCard?.validate()) return;

        if (this.contactId && this.contactInitialState.companyId !== this.contact.companyId) {
            const responseOk = await this.modalService.open(ModalConfirmComponent, {
                acceptCaption: "Continue saving",
                cancelCaption: "Cancel",
                content: "Updates in Company will not be reflected in the Opportunities and Buildings previously linked",
                title: "Company Update"
            });

            if (!responseOk) return;
        }

        await this.saveContact();
    }

    private async saveContact(): Promise<void> {
        await this.awaiter.awaitAction("Saving Contact", async () => {
            await this.contactService.save(this.contact);
            this.snackbarNotificationService.success("Contact saved successfully.");
            this.router.navigate(["/contacts"]);
        });
    }

    async cancel(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Exit",
            cancelCaption: this.continueButtonLabel,
            content: "Are you sure you want to exit? Your changes will be lost",
            title: "Cancel Save Contact"
        });

        if (responseOk) this.router.navigate(this.contactId ? [`/contacts-single/${this.contactId}`] : ["/contacts"]);
    }
}
