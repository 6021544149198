<lib-generic-type-ahead-dropdown
    [(selectedItem)]="selectedContact"
    placeholder="Add Contact"
    (selectedItemChange)="onContactChange($event)"
    [(text)]="searchText"
    [source]="source"
    [displaySelected]="displaySelected"
    (textChange)="onContactTextChanged()"
    [config]="{
        defaultText: 'Contact',
        itemDisplayKey: 'fullName',
        itemTemplate: contactTemplate
    }"
    [isReadOnly]="isReadOnly"
    [action]="addContactAction"
>
</lib-generic-type-ahead-dropdown>

<ng-template #contactTemplate let-data>
    <div class="contactContainer" *ngIf="!isReadOnly">
        <span class="contactName" [innerHTML]="data.object?.firstName + ' ' + data.object?.lastName | highlightText:data.inputText"></span>
        <div class="companyContainer">
            <span class="company" *ngIf="data.object?.companyName" [innerHTML]="data.object?.companyName | highlightText:data.inputText"></span>
            <span class="company" *ngIf="!data.object?.companyName">No company assigned</span>
        </div>
    </div>
</ng-template>
