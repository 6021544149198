<lib-generic-dropdown
    name="buildingContactRole"
    id="buildingContactRole"
    [(selectedItem)]="selectedRole"
    (selectedItemChange)="onBuildingContactRoleChange()"
    [source]="buildingContactRoles"
    [config]="{
        defaultText: 'Select Role',
        itemDisplayKey: 'name'
    }"
    [isReadOnly]="isReadOnly"
></lib-generic-dropdown>
