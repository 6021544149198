<div class="wrapper" [ngClass]="{ locked: isReserved }">
    <div class="container">
        <div class="body">
            <button class="resetStyles dragIndicator">
                <span [innerHTML]="dragIndicatorIcon"></span>
            </button>
            <div class="editors" *ngFor="let configItem of configItems">
                <lib-editor-item #editorItem [configItem]="configItem" [factory]="factory" (valueChange)="emitValueChanged()"></lib-editor-item>
            </div>
        </div>
        <div class="actions" *ngIf="showActions">
            <button *ngIf="!isReserved && isActive" class="resetStyles" (click)="onToggleCondition()" libTooltip [tooltip]="'Archive'">
                <span [innerHTML]="archiveIcon"></span>
            </button>
            <button *ngIf="!isReserved && !isActive" class="resetStyles" (click)="onToggleCondition()" libTooltip [tooltip]="'Restore'">
                <span [innerHTML]="restoreIcon"></span>
            </button>
        </div>
        <span *ngIf="isReserved" [innerHTML]="lockedIcon"></span>
    </div>
</div>
