<lib-section-header [title]="'Reports'" [iconName]="'reports'" [actionsTemplate]="actionsTemplate">
    <ng-template #actionsTemplate>
        <div class="actions">
            <button class="primary-btn" *ngIf="userHasReportFullAccess" (click)="onCreateNewReportExecution()">Create New Report</button>
        </div>
    </ng-template>
</lib-section-header>

<div class="container">
    <app-report-execution-list-filters [(filters)]="listFilters"></app-report-execution-list-filters>

    <app-reports-executions-list
        [userHasReportFullAccess]="userHasReportFullAccess"
        [filters]="listFilters"
        [allowToSetMultiOrganization]="allowToSetMultiOrganization"
    ></app-reports-executions-list>
</div>
