<div class="container">
    <textarea
        [ngClass]="{ readonly: isReadOnly && !bindingProperty }"
        [placeholder]="isReadOnly && !bindingProperty || !label ? '' : 'Add ' + label"
        [(ngModel)]="bindingProperty"
        (ngModelChange)="dataChanged()"
        [readOnly]="isReadOnly"
        [class.error]="!isValid"
    ></textarea>
    <app-validation-error-message [show]="!isValid" [errors]="validationErrors"></app-validation-error-message>
</div>