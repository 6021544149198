import { CompanyMarketView, MarketView } from "projects/app/src/app/services/http/clients/api-proxies";
import { MarketEntity } from "./abstract/market-entity";
import { MarketService } from "projects/app/src/app/services/http/clients/market.service";

export class CompanyMarketEntity extends MarketEntity<CompanyMarketView> {
    constructor(marketService: MarketService) {
        super(marketService);
    }

    toDestination(entity: MarketView): CompanyMarketView {
        return { marketId: entity.id, marketName: entity.name, companyId: 0, id: 0, companyName: undefined };
    }

    toSource(entity: CompanyMarketView): MarketView {
        return { id: entity.marketId, name: entity.marketName };
    }
}
