import { Injectable } from "@angular/core";
import { IMarketClient, MarketClient, MarketView } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class MarketService {
    private client: IMarketClient;
    constructor() {
        this.client = new MarketClient();
    }

    async getById(id: number): Promise<MarketView> {
        return await this.client.marketGetById(id);
    }

    async getAll(): Promise<MarketView[]> {
        return await this.client.marketGetAll();
    }
}
