import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { NotificationTypes } from "projects/app/src/app/models/enums/NotificationTypes";
import { NotificationView } from "projects/app/src/app/services/http/clients/api-proxies";
import { NotificationService } from "projects/app/src/app/services/http/clients/notification.service";
import { icons } from "projects/ngx-lib/src/lib/assets/icons";
import { DateTimeService } from "projects/ngx-lib/src/lib/services/date-time.service";

@Component({
    selector: "app-notification-item",
    templateUrl: "./notification-item.component.html",
    styleUrls: ["./notification-item.component.scss"]
})
export class NotificationItemComponent implements OnInit {
    @Input()
    notification?: NotificationView;

    @Output()
    notificationChange: EventEmitter<void>;

    @Input()
    isPanel?: boolean;

    svg?: SafeHtml;

    timeAgoDate?: string | null;

    constructor(
        private readonly sanitizer: DomSanitizer,
        private readonly notificationService: NotificationService,
        private readonly dateTimeService: DateTimeService
    ) {
        this.notificationChange = new EventEmitter<void>();
        this.isPanel = false;
    }

    ngOnInit(): void {
        this.svg = this.getIcon(this.notification?.notificationTypeId);
        if (this.notification) {
            this.timeAgoDate = this.getTimeAgo(this.notification.notificationDate);
        }
    }

    async onClick(id: number): Promise<void> {
        if (!this.notification?.isRead) {
            await this.notificationService.markAsRead(id);
            if (this.notification) this.notification.isRead = true;
            this.notificationChange.emit();
        }
    }

    private getTimeAgo(notificationDate: Date): string | null {
        return this.dateTimeService.getTimeAgo(notificationDate);
    }

    private getIcon(id?: NotificationTypes): SafeHtml {
        let iconName: keyof typeof icons;
        switch (id) {
            case NotificationTypes.NoteMention:
                iconName = "note";
                break;

            case NotificationTypes.OpportunityAssignment:
            case NotificationTypes.OpportunityBidStatusChange:
            case NotificationTypes.OpportunityStatusChange:
            case NotificationTypes.ExpiringDueDate:
            case NotificationTypes.ExpiringFollowUpContactDate:
                iconName = "opportunities";
                break;

            default:
                iconName = "note";
                break;
        }

        return this.sanitizer.bypassSecurityTrustHtml(icons[iconName]);
    }
}
