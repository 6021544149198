import { Component, OnInit } from "@angular/core";
import { OpportunityProbabilityView } from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityProbabilityService } from "projects/app/src/app/services/http/clients/opportunity-probability.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-opportunity-probability-editor",
    templateUrl: "./opportunity-probability-editor.component.html",
    styleUrls: ["./opportunity-probability-editor.component.scss"]
})
export class OpportunityProbabilityEditorComponent extends EditorComponentBase<number | null> implements OnInit {
    probabilityItems?: OpportunityProbabilityView[];
    selectedProbability?: OpportunityProbabilityView;

    constructor(private readonly opportunityProbabilityService: OpportunityProbabilityService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.probabilityItems = await this.opportunityProbabilityService.getAll();
        if (this.bindingProperty) this.selectedProbability = this.probabilityItems?.find(x => x.id === this.bindingProperty);
    }

    onProbabilityChange() {
        if (this.selectedProbability?.id) {
            this.bindingProperty = this.selectedProbability.id;
            this.validate();
        } else {
            this.bindingProperty = null;
        }
        this.valueChange.emit();
    }
}
