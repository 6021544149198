<div class="container">
    <lib-section-header
        title="{{ building?.name }}"
        subTitle="Buildings"
        [actionsTemplate]="actionsTemplate"
        iconName="goBack"
        [mainAdditionalTemplate]="statusTemplate"
    >
        <ng-template #statusTemplate>
            <lib-status-indicator *ngIf="!isActive" [iconName]="'archived'" [colorType]="'error'" [text]="'Archived'"></lib-status-indicator>
        </ng-template>

        <ng-template #actionsTemplate>
            <div class="actions">
                <button role="menuitem" *ngIf="currentTab === 'Overview'" class="primary-btn" (click)="onEditBuilding()">Edit</button>
                <app-kebab-menu>
                    <button role="menuitem" *ngIf="isActive" class="resetStyles" (click)="archiveBuilding()">Archive</button>
                    <button role="menuitem" *ngIf="!isActive" class="resetStyles" (click)="restoreBuilding()">Restore</button>
                </app-kebab-menu>
            </div>
        </ng-template>
    </lib-section-header>

    <div class="tabGroup">
        <lib-tab-view [(currentTab)]="currentTab" alignment="start" separation="wide">
            <lib-tab-item tabName="Overview" [bodyTemplate]="overviewTab">
                <ng-template #overviewTab>
                    <div class="tab">
                        <app-buildings-single-overview [building]="building"></app-buildings-single-overview>
                    </div>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Opportunities" [bodyTemplate]="opportunitiesTab">
                <ng-template #opportunitiesTab>
                    <div class="tab">
                        <app-opportunities-grid-single [buildingId]="building?.id"></app-opportunities-grid-single>
                    </div>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Contacts" [bodyTemplate]="contactsTab">
                <ng-template #contactsTab>
                    <div class="tab">
                        <app-buildings-single-contacts [buildingId]="building?.id" (buildingChanged)="onBuildingChanged()"></app-buildings-single-contacts>
                    </div>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Notes" [bodyTemplate]="notesTab">
                <ng-template #notesTab>
                    <div class="tab">
                        <app-notes [entityTypeId]="entityTypeId" [entityId]="building?.id"></app-notes>
                    </div>
                </ng-template>
            </lib-tab-item>
        </lib-tab-view>
    </div>
</div>
