import { Injectable } from "@angular/core";
import { ArchiveEntityClient, ArchiveEntityOptionView, IArchiveEntityClient } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class ArchiveEntityService {
    private client: IArchiveEntityClient;
    constructor() {
        this.client = new ArchiveEntityClient();
    }

    async getArchiveOptions(): Promise<ArchiveEntityOptionView[]> {
        return await this.client.archiveEntityGetArchiveOptions();
    }
}
