<div class="container">
    <lib-datepicker-dropdown
        [dateRangeEnabled]="false"
        [(selectedDate)]="selectedDate"
        (selectedDateChange)="onSelectedDateChange()"
        defaultTitle="Add {{ label }}"
        [minDate]="minDate"
        [isReadOnly]="isReadOnly"
        [timeEnabled]="timeEnabled"
        [error]="!isValid"
    >
    </lib-datepicker-dropdown>
    <app-validation-error-message [show]="!isValid" [errors]="validationErrors"></app-validation-error-message>
</div>
