<div class="container">
    <app-type-ahead-contacts
        [isReadOnly]="isReadOnly"
        [setCompany]="true"
        [displaySelected]="displaySelectedContact"
        [searchAllCompanies]="true"
        [selectedContact]="selectedItem"
        (selectedContactChange)="onSelectedContact($event)"
    >
    </app-type-ahead-contacts>
</div>
