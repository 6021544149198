import { Component, OnInit } from "@angular/core";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { NotificationTypeUserConfigurationView } from "projects/app/src/app/services/http/clients/api-proxies";
import { NotificationTypeUserConfigurationService } from "projects/app/src/app/services/http/clients/notification-type-user-configuration.service";
import { ObjectExtensionsService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";

@Component({
    selector: "app-notifications-edit",
    templateUrl: "./notifications-edit.component.html",
    styleUrls: ["./notifications-edit.component.scss"]
})
export class NotificationsEditComponent implements OnInit {
    factory: AppEditorFactory;

    notificationTypeUserConfiguration?: NotificationTypeUserConfigurationView[];

    notificationTypeUserConfigurationInitialState?: NotificationTypeUserConfigurationView[];

    isModelEqual?: boolean;

    constructor(
        private readonly notificationTypeUserConfigurationService: NotificationTypeUserConfigurationService,
        private readonly awaiter: AwaiterService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly objectExtensionService: ObjectExtensionsService
    ) {
        this.factory = new AppEditorFactory();
        this.isModelEqual = true;
    }

    async ngOnInit(): Promise<void> {
        await this.awaiter.awaitAction("Loading Notifications Options", async () => {
            this.notificationTypeUserConfiguration = await this.notificationTypeUserConfigurationService.getUserConfigurations();
            this.notificationTypeUserConfigurationInitialState = this.objectExtensionService.clone(
                this.notificationTypeUserConfiguration
            ) as NotificationTypeUserConfigurationView[];
        });
    }

    compareChanges(): void {
        this.isModelEqual = this.objectExtensionService.isEqualTo(this.notificationTypeUserConfigurationInitialState, this.notificationTypeUserConfiguration);
    }

    async onCancel(): Promise<void> {
        this.notificationTypeUserConfiguration = this.objectExtensionService.clone(
            this.notificationTypeUserConfigurationInitialState
        ) as NotificationTypeUserConfigurationView[];
        this.compareChanges();
    }

    async onSave(): Promise<void> {
        await this.awaiter.awaitAction("Saving Notifications", async () => {
            if (this.notificationTypeUserConfiguration) {
                this.notificationTypeUserConfiguration = await this.notificationTypeUserConfigurationService.save(this.notificationTypeUserConfiguration);
                this.snackbarNotificationService.success("Notifications edited successfully.");
                this.notificationTypeUserConfigurationInitialState = this.objectExtensionService.clone(
                    this.notificationTypeUserConfiguration
                ) as NotificationTypeUserConfigurationView[];
                this.compareChanges();
            }
        });
    }
}
