<div class="container">
    <div class="editorItem">
        <div class="label">Contact</div>
        <app-type-ahead-contacts
            [isReadOnly]="isReadOnly"
            [companyId]="companyId"
            [displaySelected]="displaySelectedContact"
            [selectedContact]="selectedItem"
            (selectedContactChange)="onSelectedContact($event)"
        >
        </app-type-ahead-contacts>
    </div>

    <ul *ngIf="selectedItem">
        <li>
            <div class="label">Position</div>
            <div class="value">{{ contactInfo.position }}</div>
        </li>
        <li>
            <div class="label">Email</div>
            <div class="value">{{ contactInfo.email }}</div>
        </li>
        <li>
            <div class="label">Phone</div>
            <div class="value">{{ contactInfo.phone }}</div>
        </li>
    </ul>
</div>
