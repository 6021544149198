import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { BuildingView } from "projects/app/src/app/services/http/clients/api-proxies";
import { BuildingService } from "projects/app/src/app/services/http/clients/building.service";
import { ModalService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { ModalConfirmComponent } from "../../../shared/modals/modal-confirm/modal-confirm.component";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";

@Component({
    selector: "app-buildings-single",
    templateUrl: "./buildings-single.component.html",
    styleUrls: ["./buildings-single.component.scss"]
})
export class BuildingsSingleComponent implements OnInit {
    private buildingId: number;

    building?: BuildingView;

    currentTab: string;

    isActive?: boolean;

    entityTypeId: EntityTypes;

    constructor(
        private readonly buildingService: BuildingService,
        private readonly awaiter: AwaiterService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly snackbarNotificationService: SnackbarNotificationService
    ) {
        this.buildingId = parseInt(this.route.snapshot.paramMap.get("id") ?? "0");
        this.currentTab = "Overview";
        this.isActive = true;
        this.entityTypeId = EntityTypes.Building;
    }

    async ngOnInit(): Promise<void> {
        await this.awaiter.awaitAction("Getting Building Info", async () => {
            this.building = await this.buildingService.getById(this.buildingId);
            this.isActive = this.building.isActive;
        });
    }

    onEditBuilding() {
        this.router.navigate([`/buildings-edit/${this.buildingId}`], { skipLocationChange: true });
    }

    async archiveBuilding(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Archive",
            cancelCaption: "Cancel",
            content: "Are you sure you want to archive this building?",
            title: "Archive Building"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Archiving Building", async () => {
            if (!this.building?.id) return;

            await this.buildingService.deactivate(this.building?.id);
            this.isActive = false;
        });
    }

    async restoreBuilding(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Restore",
            cancelCaption: "Cancel",
            content: "Are you sure you want to restore this building?",
            title: "Restore Building"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Restoring Building", async () => {
            if (!this.building?.id) return;
            await this.buildingService.activate(this.building.id);
            this.isActive = true;
        });
    }

    async onBuildingChanged() {
        try {
            this.building = await this.buildingService.getById(this.buildingId);
        } catch (error) {
            this.snackbarNotificationService.error("Error getting building Info");
        }
    }
}
