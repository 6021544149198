import { Component, Input } from "@angular/core";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";

@Component({
    selector: "app-opportunities-single-activity-log",
    templateUrl: "./opportunities-single-activity-log.component.html",
    styleUrls: ["./opportunities-single-activity-log.component.scss"]
})
export class OpportunitiesSingleActivityLogComponent {
    @Input()
    opportunityId?: number;

    entityType: number;

    constructor() {
        this.entityType = EntityTypes.Opportunity;
    }
}
