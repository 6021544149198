import { Component } from "@angular/core";
import { EntityTypeService } from "../../../services/http/clients/entity-type.service";
import { SearchMainEntitiesParameters, SearchMainEntitiesResultDto } from "../../../services/http/clients/api-proxies";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { EntityTypes } from "../../../models/enums/EntityTypes";
import { icons } from "projects/ngx-lib/src/lib/assets/icons";
import { Router } from "@angular/router";

interface Entity {
    icon: SafeHtml;
    type: EntityTypes;
    route: string;
}

@Component({
    selector: "app-search-main-entities",
    templateUrl: "./search-main-entities.component.html",
    styleUrls: ["./search-main-entities.component.scss"]
})
export class SearchMainEntitiesComponent {
    selectedEntity?: SearchMainEntitiesResultDto;

    searchResult?: SearchMainEntitiesResultDto[];

    searchText: string;

    svg?: SafeHtml;

    entities: { [key: string]: Entity } = {};

    constructor(
        private readonly entityTypeService: EntityTypeService,
        private readonly sanitizer: DomSanitizer,
        private readonly router: Router
    ) {
        this.searchText = "";

        this.entities = {
            company: {
                icon: this.sanitizer.bypassSecurityTrustHtml(icons.companies),
                type: EntityTypes.Company,
                route: "/companies-single"
            },
            contact: {
                icon: this.sanitizer.bypassSecurityTrustHtml(icons.contacts),
                type: EntityTypes.Contact,
                route: "/contacts-single"
            },
            building: {
                icon: this.sanitizer.bypassSecurityTrustHtml(icons.buildings),
                type: EntityTypes.Building,
                route: "/buildings-single"
            },
            opportunity: {
                icon: this.sanitizer.bypassSecurityTrustHtml(icons.opportunities),
                type: EntityTypes.Opportunity,
                route: "/opportunities-single"
            }
        };
    }

    async onEntityTextChanged(): Promise<void> {
        if (!this.searchText) {
            this.searchResult = undefined;
            return;
        };
        this.searchResult = await this.entityTypeService.searchMainEntities({
            filterText: this.searchText
        } as SearchMainEntitiesParameters);
    }

    onSelectedEntity(entity: SearchMainEntitiesResultDto): void {
        const entityTypeKey = Object.keys(this.entities).find(key => this.entities[key].type === entity.entityTypeId);

        if (entityTypeKey) {
            const route = this.entities[entityTypeKey].route;
            this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => this.router.navigate([route, entity.entityId]));
        }
    }
}
