import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CompanyView, QuickSearchCompaniesParameters } from "../../../services/http/clients/api-proxies";
import { CompanyService } from "../../../services/http/clients/company.service";
import { ModalAddCompanyComponent } from "../modals/modal-add-company/modal-add-company.component";
import { ModalService } from "projects/ngx-lib/src/public-api";
import { ITypeAheadDropdownAction } from "projects/ngx-lib/src/lib/interfaces/type-ahead-dropdown-config.interface";

@Component({
    selector: "app-type-ahead-companies",
    templateUrl: "./type-ahead-companies.component.html",
    styleUrls: ["./type-ahead-companies.component.scss"]
})
export class TypeAheadCompaniesComponent implements OnInit {
    @Input()
    selectedCompany?: CompanyView;

    @Input()
    isReadOnly?: boolean;

    @Input()
    editorInfoAllowAction?: boolean;

    @Input()
    isActive?: boolean;

    @Output()
    selectedCompanyChange = new EventEmitter<CompanyView>();

    source?: CompanyView[];

    searchText: string;

    addCompanyAction!: ITypeAheadDropdownAction;

    constructor(
        private readonly companyService: CompanyService,
        private readonly modalService: ModalService
    ) {
        this.searchText = "";
    }

    ngOnInit(): void {
        this.addCompanyAction = {
            includeAction: !!this.editorInfoAllowAction,
            name: "+ Add new company",
            action: () => this.openModal()
        };
    }

    async openModal(): Promise<void> {
        this.selectedCompany = await this.modalService.open(ModalAddCompanyComponent);
        if (this.selectedCompany) {
            this.selectedCompanyChange.emit(this.selectedCompany);
        }
    }

    async onCompanyTextChanged(): Promise<void> {
        if (!this.searchText) return;

        this.source = await this.companyService.search({
            filterText: this.searchText,
            isActive : this.isActive
        } as QuickSearchCompaniesParameters);
    }

    onCompanyChange(itemSelected: CompanyView): void {
        this.selectedCompany = itemSelected;
        this.selectedCompanyChange.emit(itemSelected);
    }
}
